import React, { useCallback } from 'react';
import moment from 'moment';
import PaymentsDetails from '../../../Payments/Modals/PaymentsDetails';
import ViewDetailsCustomer from '../../Modals/ViewDetailsCustomer';
import { useDispatch, useSelector } from 'react-redux';
import { openCustomModal } from '../../../../actions/modal';
import IconToolTip from '../../../../common/IconToolTip';
import OverflowTip from '../../../../common/OverflowTip';
import { GetWalletLogic, amountType, paymentType, transactionStatus, orderStatusCode } from '../../walletController';
import { getWalletOrderStatus, getWalletPaymentStatus, getWalletTransactions } from '../../../../actions/wallet';
import { isCustomerGroup, isSp } from '../../../../utils/role';
import { getCustomerIdOfLoginUser } from '../../../../utils/utils';
import { validateDate } from '../../../../utils/filter';
import ButtonScogoOrange from '../../../../common/Buttons/ButtonScogoOrange';
import { validateNumberValue, validateResponseDate, validateStringValue } from '../../../../utils/common';
import { DateComponent } from '../../../../common/TableReusableColumns';

const mediumFontCss = 'font-roboto font-medium 2xl:text-font12 text-font12 text-scogoprimary';
const normalFontCss = 'font-normal 2xl:text-font12 text-font12';

export const iconAndColorLookup = {
    orange: { icon: './img/amount_pending.svg', textCss: 'text-scogoorange', amountPrefixSign: '' },
    red: { icon: './img/amount_debited.svg', textCss: 'text-scogoclosed', amountPrefixSign: '' },
    green: { icon: './img/amount_credited.svg', textCss: 'text-scogoToastSuccessIcon', amountPrefixSign: '+' },
    black: { icon: './img/amount_rejected.svg', textCss: 'text-black', amountPrefixSign: '-' },
    grey: { icon: './img/amount_grey.svg', textCss: 'text-scogo99', amountPrefixSign: '' },
};

export default function Card(props) {
    const { transaction, payFE, transactionStatusQuery, fromDateQuery, toDateQuery } = props
    const dispatch = useDispatch();
    const {
        loggedUser: {
            role: { id: role },
            type,
        },
    } = useSelector((state) => state.auth);
    let isToShowViewDetailsIcon = GetWalletLogic('isToShowViewDetailsIcon', { transaction });
    let isCustomerViewDetailsEnable =
        GetWalletLogic('isCustomerViewDetailsEnable', { transaction }) &&
        Array.isArray(transaction?.sub_transaction_histories) &&
        transaction.sub_transaction_histories.length > 0;

    let isToShowPayFEInTransaction = GetWalletLogic('isToShowPayFEInTransaction', { transaction });
    let { transactionType, transactionTitle } = GetWalletLogic('getTransactionTitleOrType', { transaction });
    const isDueDateBeforeToday = transaction.due_date && moment(transaction.due_date).isValid() && moment(transaction.due_date) < moment();
    const isToShowRefreshIcon =
        (transaction.paymentType === paymentType.ticket_payment && transaction.status === transactionStatus.awaiting && isDueDateBeforeToday) ||
        (transaction.paymentType === paymentType.payment_gateway && transaction.txStatus === transactionStatus.attempted) ||
        (transaction.paymentType === paymentType.withdraw && transaction.orderStatus === orderStatusCode.awaiting);

    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    const getIconAndColorOfTransaction = useCallback((type) => {
        let icon = iconAndColorLookup.grey;
        if (type === amountType.debit) icon = iconAndColorLookup.black;
        else if (type === amountType.credit) icon = iconAndColorLookup.green;
        else if (type === amountType.rejected) icon = iconAndColorLookup.red;
        else if ([amountType.pending, amountType.approval_pending, amountType.awaiting, amountType.locked].includes(type))
            icon = iconAndColorLookup.orange;
        return icon;
    }, []);

    let createdAt = moment(transaction?.created_at).isValid() && moment(new Date(validateResponseDate(transaction?.created_at))).format('llll')

    const getDateToShow = () => {
        let label = '';
        let date = '';
        let color = ''
        if (transaction?.paymentType === paymentType.ticket_payment && transaction?.due_date && moment(transaction?.due_date).isValid()) {
            label = 'Due Date';
            date = moment(transaction?.due_date).format('llll')
            color = 'text-scogoclosed'
        } else if (transaction?.processed_at && moment(transaction?.processed_at).isValid()) {
            label = 'Processed Date';
            date = moment(transaction?.processed_at).format('llll')
            color = 'text-scogoorange'
        } else if (['Scheduled', 'Pending'].includes(transaction?.status) && transaction.scheduled_on != null) {
            label = 'Scheduled Date';
            date = moment(transaction?.scheduled_on).format('llll')
            color = 'text-scogoorange'
        }
        if (!date && !label) return <></>
        return <div className='flex'>
            <p className={mediumFontCss}>
                <span className='font-bold'>{label}: &nbsp;</span>
                <span className={`${normalFontCss} ${color}`}>{date}</span>
            </p>
        </div>
    }

    const refreshTransaction = () => {
        if (transaction.paymentType === paymentType.ticket_payment) {
            dispatch(getWalletPaymentStatus({ scogo_transaction_no: transaction.referenceId }));
        } else if (transaction.paymentType === paymentType.withdraw) {
            dispatch(getWalletOrderStatus({ orderId: transaction.scogo_tx_no }));
        } else if (transaction.paymentType === paymentType.payment_gateway) {
            const payload = { customerId, status: transactionStatusQuery || 'all' };
            if (validateDate(fromDateQuery) && validateDate(toDateQuery)) {
                payload.fromDate = fromDateQuery;
                payload.toDate = toDateQuery;
            }
            dispatch(getWalletTransactions(payload));
        }
    };

    let transactionLabel = transactionTitle;
    if (getIconAndColorOfTransaction(transactionType).amountPrefixSign === '+') transactionLabel = `${transactionTitle} (Added to Wallet)`;
    if (getIconAndColorOfTransaction(transactionType).amountPrefixSign === '-') transactionLabel = `${transactionTitle} (Debited from Wallet)`;

    const isPaidToFE = transaction.paid_to_fe !== 0 && isSp(role, type);
    const paidToFeLabel = (
        <span
            className={`bg-white text-sm px-2 py-1 border border-scogoprimary leading-6 cursor-pointer text-scogorimary rounded-lg gap-1 font-medium cursor-pointer`}
        >
            Paid to FE
        </span>
    );
    const payFeButton = <ButtonScogoOrange textOrComponent='Pay FE' onClick={() => payFE({ ticketId: transaction.ticketId })} />;

    let siteId = validateStringValue(transaction.siteId);
    if (transaction.job_ticket_number) {
        siteId = `${siteId}/${transaction.job_ticket_number}`;
    };

    if (transactionLabel === 'failed') {
        transactionLabel = `${transactionLabel} (${transaction.reason})`
    }


    return (
        <div style={{ boxShadow: ' 0px 3px 6px #00000029', height: '9rem', width: '50 rem' }} className='flex rounded-xl bg-white my-2 px-6 py-2 '>
            <div className='flex w-full items-center justify-between items-center'>
                <div className='flex items-center w-7/12'>
                    <div className='min-w-max w-1/12'>
                        <img className='h-10 w-10' src={process.env.PUBLIC_URL + getIconAndColorOfTransaction(transactionType).icon} alt='' />
                    </div>

                    <div className='h-full flex flex-col justify-center truncate w-11/12'>
                        <p className={mediumFontCss + ' w-11.5/12 truncate'}>
                            <OverflowTip textClass={mediumFontCss + ' truncate font-bold'} someLongText={transactionLabel} />
                        </p>
                        <p className={normalFontCss + ' text-scogogray flex gap-4 items-center'}>
                            <IconToolTip title='Created At'>
                                <span>
                                    {createdAt}
                                </span>
                            </IconToolTip>
                            <div className='flex items-center w-32'>
                                <div className='w-1/3 flex items-center'>
                                    {isToShowRefreshIcon && (
                                        <IconToolTip title='Refresh'>
                                            <span
                                                className='material-icons material-icons-outlined px-2 cursor-pointer text-scogoprimary text-font15 hover:bg-blue-100 hover:text-scogobgsky rounded-6px'
                                                onClick={refreshTransaction}
                                            >
                                                refresh
                                            </span>
                                        </IconToolTip>
                                    )}
                                </div>
                                <div className='w-2/3'>{isToShowPayFEInTransaction && (isPaidToFE ? paidToFeLabel : payFeButton)}</div>
                            </div>
                        </p>
                        <div className='flex'>
                            <p className={mediumFontCss}>
                                <span className='font-bold'>Transaction Id:</span>{' '}
                                <span className={normalFontCss}>
                                    {transaction?.scogo_tx_no}
                                    {[paymentType.withdraw].includes(transaction.paymentType) && transaction.payment_via
                                        ? `(${transaction.payment_via})`
                                        : ''}
                                </span>
                            </p>
                        </div>
                        {getDateToShow()}
                        {transaction?.txMsg && <SpanElement label='Message' value={transaction.txMsg} />}
                    </div>
                </div>
                <div className='py-4 w-4/12 flex items-center flex-col'>
                    {siteId && <SpanElement label='Site ID' value={siteId} />}
                    <WalletTranseferPaymentStatus status={transaction.status} type={transaction.paymentType} />
                    {transaction?.external_ticket_id && <SpanElement label='EXT ID' value={transaction.external_ticket_id} />}
                    {transaction?.po_number && <SpanElement label='PO Number' value={transaction.po_number} />}
                    {transaction.updated_at && <p className={mediumFontCss + ' flex items-center w-full gap-2'}>
                        <span className='text-scogogray '>Updated At : </span>
                        <DateComponent className='font-roboto font-medium text-font12 text-scogoprimary flex w-full gap-2' date={transaction.updated_at} text={false} timeFormat='DD MMM YYYY' />
                    </p>}
                </div>
                <RenderTransactionAmount getIconAndColorOfTransaction={getIconAndColorOfTransaction} transactionType={transactionType} transaction={transaction} isToShowViewDetailsIcon={isToShowViewDetailsIcon} isCustomerViewDetailsEnable={isCustomerViewDetailsEnable} />
            </div>
        </div>
    );
}

const SpanElement = ({ label, value }) => {
    return (
        <p className={mediumFontCss + ' flex w-full gap-2'}>
            <OverflowTip
                someLongText={
                    <>
                        <span className='text-scogogray '>{label} : </span> <span className='w-4/6'>{value}</span>
                    </>
                }
            />
        </p>
    );
};

const WalletTranseferPaymentStatus = ({ status, type }) => {

    const getWalletTransferPaymentStatus = (status) => {
        const lookup = {
            'Success': { label: 'Paid', color: 'scogoToastSuccess' },
            'Pending': { label: 'Approval Pending', color: 'scogoorange' },
            'Scheduled': { label: 'Scheduled', color: 'scogoprimary' },
            'Failure': { label: 'Rejected', color: 'scogoclosed' },
        }

        if (lookup[status]) {
            return lookup[status];
        }
        return { label: status, color: 'scogoorange' };
    }

    if (paymentType.wallet_transfer === type) {
        const { label, color } = getWalletTransferPaymentStatus(status);
        return <p className={mediumFontCss + ' flex w-full gap-2'}>
            <OverflowTip
                someLongText={
                    <>
                        <span className='text-scogogray '>Status : </span> <span className={`text-${color} w-4/6`}>{label}</span>
                    </>
                }
            />
        </p>
    }
    return <></>;
}

const RenderTransactionAmount = ({ getIconAndColorOfTransaction, transactionType, transaction, isToShowViewDetailsIcon, isCustomerViewDetailsEnable }) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);

    const onArrowClick = () => {
        if (isToShowViewDetailsIcon) {
            let heading = 'Payments';
            let modalComponent = <PaymentsDetails ticketId={transaction?.ticketId} />;
            let modalWidth = '90rem',
                modalHeight = 'auto';
            dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
        } else if (isCustomerViewDetailsEnable) {
            let heading = `Transaction Details`;
            let modalComponent = <ViewDetailsCustomer transaction={transaction} />;
            let modalWidth = '80rem',
                modalHeight = 'auto';
            dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
        }
    };

    const getViewDetailsIconElement = () => {
        const enableViewDetailsCss = 'text-scogoprimary hover:text-scogoorange cursor-pointer material-icons';
        const disabledViewDetailsCss = 'text-scogoddd cursor-not-allowed material-icons';

        const isViewDetailsEnable = isToShowViewDetailsIcon || isCustomerViewDetailsEnable;
        let arrowElem = (
            <span onClick={onArrowClick} className={isViewDetailsEnable ? enableViewDetailsCss : disabledViewDetailsCss}>
                arrow_forward_ios
            </span>
        );
        if (isViewDetailsEnable) {
            return <IconToolTip title='View Details'>{arrowElem}</IconToolTip>;
        }
        return arrowElem;
    };

    let transactionAmount = transaction.amount;
    if (isCustomerGroup(loggedUser.role.id, loggedUser.role) && transaction.txCharges) {
        transactionAmount = `${validateNumberValue(transaction.amount) + validateNumberValue(transaction.txCharges)}`
        return <div className='h-full flex items-center justify-end w-2/12'>
            <div className={`flex items-center ${getIconAndColorOfTransaction(transactionType).textCss}`}>
                <p className='p-1 pr-2 pt-2 text-font13 font-medium'>
                    <IconToolTip title={`₹ ${transactionAmount}
                    Tax Charges : ${transaction.txCharges}

                    `}>
                        <span>
                            {`${getIconAndColorOfTransaction(transactionType).amountPrefixSign} ₹ ${transactionAmount}`}
                        </span>
                    </IconToolTip>
                </p>
            </div>
            {getViewDetailsIconElement()}
        </div>
    }

    return <div className='h-full flex items-center justify-end w-2/12'>
        <div className={`flex items-center ${getIconAndColorOfTransaction(transactionType).textCss}`}>
            <p className='p-1 pr-2 pt-2 text-font13 font-medium'>
                {`${getIconAndColorOfTransaction(transactionType).amountPrefixSign} ₹ ${transactionAmount}`}
            </p>
        </div>
        {getViewDetailsIconElement()}
    </div>
}