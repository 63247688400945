import { auth, onboarding, sp, user } from '../types';
const INITIAL_STATE = {
    error: undefined,
    mobileOrEmail: '',
    forgotPasswordEmail: '',
    showForgotPasswordEmailSent: false,
    profileUpdated: null,
    loggedUser: undefined,
    redirectURL: false
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case auth.LOGIN_WITH_OTP_SUCCESS:
            return {
                ...state,
                loggedUser: action.payload,
            };
        case auth.LOGIN_WITH_PASSWORD_SUCCESS:
            return {
                ...state,
                loggedUser: action.payload,
            };
        case auth.CHECK_USER_SUCCESS:
            return {
                ...state,
                checkUserDetails: action.payload,
            };
        case auth.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
            };
        case auth.SHOW_FORGOT_PASSWORD_EMAIL_SENT:
            return {
                ...state,
                showForgotPasswordEmailSent: action.payload,
            };
        case auth.SET_MOBILE_OR_EMAIL:
            return {
                ...state,
                mobileOrEmail: action.payload.mobileOrEmail,
            };
        case auth.SET_FORGOT_PASSWORD_EMAIL:
            return {
                ...state,
                forgotPasswordEmail: action.payload.forgotPasswordEmail,
            };
        case auth.USER_PROFILE_SUCCESS: {
            return {
                ...state,
                loggedUser: action.payload,
            };
        }
        case auth.GET_USERS_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                bankDetails: action.payload,
            };

        case user.CREATE_ROOT_FOLDER_SUCCESS: {
            return {
                ...state,
                loggedUser: { ...state.loggedUser, document_folder_id: action.payload._id },
            };
        }
        case auth.GET_REGISTER_MOBILE_SUCCESS: {
            return {
                otpSent: action.payload,
            };
        }

        case auth.SET_USER_PASSWORD_SUCCESS: {
            return {
                ...state,
                setUserPwd: action.payload,
            };
        }
        case auth.VERIFY_USERS_EMAIL_SUCCESS: {
            return {
                ...state,
                isEmailVerifiedFromToken: action.payload,
            };
        }

        case auth.PUT_TO_AUTH_STORE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case auth.REQUEST_OTP_SUCCESS: {
            return {
                ...state,
                requestOTPSuccess: action.payload,
            };
        }
        case auth.REQUEST_OTP_SUPPORT_SUCCESS: {
            return {
                ...state,
                requestOTPSuccess: action.payload,
            };
        }
        case auth.REMOVE_REQUEST_OTP_SUPPORT_SUCCESS: {
            return {
                ...state,
                requestOTPSuccess: undefined,
            };
        }
        case onboarding.UPDATE_USER_PROFILE_DETAILS_SUCCESS: {
            const { updatedEmail, type, pancard_number, first_name, last_name } = action.payload;
            if (!state.loggedUser) return state;
            return {
                ...state,
                loggedUser: { ...state.loggedUser, email: updatedEmail, type, pancard_number, first_name, last_name },
            };
        }
        case auth.UPDATE_PROFILE_SUCCESS: {
            let data = action.payload;
            if (data.dob) {
                data.date_of_birth = data.dob;
            }
            return {
                ...state,
                profileUpdated: true,
                loggedUser: { ...state.loggedUser, ...data },
            };
        }
        case auth.UPDATE_PROFILE_FAILURE: {
            return {
                ...state,
                profileUpdated: false,
            };
        }
        case auth.CLEAR_UPDATE_PROFILE_STORE: {
            return {
                ...state,
                profileUpdated: INITIAL_STATE.profileUpdated,
            };
        }

        case user.VERIFY_OTP_SUCCESS: {
            let { mobile } = action.payload;
            return {
                ...state,
                mobileNumberUpdated: true,
                loggedUser: { ...state.loggedUser, mobile },
            };
        }
        case user.VERIFY_OTP_FAILURE: {
            return {
                ...state,
                mobileNumberUpdated: false,
            };
        }
        case auth.UPDATE_PROFILE_PIC_SUCCESS: {
            const { selectedLogoToShow } = action.payload;
            return {
                ...state,
                loggedUser: { ...state.loggedUser, profile_picture: selectedLogoToShow },
            };
        }

        case user.UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS:
        case user.UPLOAD_USER_ID_PROOF_SUCCESS: {
            const { address_proof_number, address_proof_type, address_proof_url, address_proof_url_back } = action.payload;
            return {
                ...state,
                loggedUser: { ...state.loggedUser, address_proof_number, address_proof_type, address_proof_url, address_proof_url_back },
            };
        }

        case sp.UPDATE_SP_CUSTOMER_STATUS_SUCCESS:
            const { customerId, status } = action.payload;
            const sp_under_customer = state.loggedUser?.sp_under_customer.map((item) => {
                if (item.fk_customer_id === customerId) {
                    return {
                        ...item,
                        status: status,
                        status_str: status === 1 ? 'Disable' : 'Enable',
                    };
                }
                return item;
            });
            return {
                ...state,
                loggedUser: { ...state.loggedUser, sp_under_customer: sp_under_customer },
            };
        case auth.UPDATE_WITHDRAW_STATUS_FROM_WEBSOCKET: {
            const { is_withdraw_disabled } = action.payload
            return {
                ...state,
                loggedUser: { ...state.loggedUser, is_withdraw_disabled },
            };
        }
        case auth.GENERATE_AFFILIATE_LINK_SUCCESS_LOGGED_USER: {
            const { affiliate_id, store_ref_code, store_affiliate_link } = action.payload;
            return {
                ...state,
                loggedUser: { ...state.loggedUser, affiliate_id, store_ref_code, store_affiliate_link }
            }
        }
        case auth.SET_REDIRECT_URL: {
            const { redirectURL } = action.payload;
            return {
                ...state,
                redirectURL: redirectURL
            }
        }
        case auth.USER_JOINED_ROOM: {
            const { room_id } = action.payload;
            const loggedUser = state.loggedUser;
            const roomIds = JSON.parse(loggedUser.room_id);
            roomIds.push(room_id);
            return {
                ...state,
                loggedUser: {
                    ...loggedUser,
                    room_id: JSON.stringify(roomIds)
                }
            }
        }
        case auth.GET_USERS_BANK_DETAILS_BY_ID_FAILURE:
        case auth.RESET_USER_PASSWORD_FAILURE:
        case auth.USER_PROFILE_FAILURE:
        case auth.CHECK_USER_FAILURE:
        case auth.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
        case auth.GET_USERS_BANK_DETAILS_FAILURE:
        case auth.GET_USERS_BANK_DETAILS_FAILURE:
        case auth.REQUEST_OTP_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default authReducer;
