import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction, openTextModalAction } from '../../../../actions/modal';
import { initiateTicket } from '../../../../actions/tickets';
import { requestLocation } from '../../../../utils/common';
import { SimpleButton } from '../../../../common/Buttons/SimpleButton';
import { RenderBoqForm } from './RenderBoqForm';



export const RenderTicketExecution = ({ memoizedTicket, setShowChecklist }) => {
    const dispatch = useDispatch();
    const [currentFeLatLong, setCurrentLatLong] = useState({ lat: '', lng: '', address: '' })

    const onReachedSiteClicked = (ticket) => {
        const fName = 'reachedSite';
        dispatch(
            openTextModalAction({
                heading: 'Reached Site?',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: fName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(initiateTicket({ formName: fName, ticketId: ticket.ticket_id, sign_off_response: 'Completed', closeModal: true, updateReachedSite: true }))
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    };

    useEffect(() => {
        if (memoizedTicket?.reachedSite === 0) {
            requestLocation(setCurrentLatLong);
        }
    }, [memoizedTicket?.reachedSite]);

    if (memoizedTicket?.reachedSite !== 0) {
        return <RenderBoqForm currentFeLatLong={currentFeLatLong} memoizedTicket={memoizedTicket} setShowChecklist={setShowChecklist} />
    }

    return <div className='flex items-center px-4 py-4 gap-4'>
        <p className='text-3xl '>Have you reached at site?</p>
        <SimpleButton
            buttonIconComponent={<span className='material-icons text-font16'>room</span>}
            btnClass={'bg-white border flex items-center justify-center border-scogoprimary leading-6 cuzrsor-pointer text-scogoprimary rounded-lg gap-1 font-medium cursor-pointer text-font11 px-2 hover:bg-scogoprimary hover:text-white md:px-2 md:py-1 px-6 py-2'}
            textOrComponent={'Yes'}
            onClick={() => onReachedSiteClicked(memoizedTicket)}
        />
    </div>
}