import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomersList } from '../../../actions/customer';
import {
    createInventorySites,
    createNewBranchRequest,
    getBranchList,
    getCityFromPincode,
    getEndUsersList,
    getSiteType,
    getSlaPolicies,
    removeEnduserListFromStore,
    removePinCityFromStore,
} from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import { clearLastCreatedEndUserFromStore, getAllCities } from '../../../actions/users';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { actualValueForDropdown, findPincode } from '../../../utils/common';
import { isScm } from '../../../utils/role';
import { getCustomerIdOfLoginUser, isValidEmail } from '../../../utils/utils';
import apis from '../../../apis';
import { FetchGoogleAddress } from '../../SupportTrack/RenderTicketCard';
import { inventory } from '../../../types';

const formName = 'addSiteForm';
export const addNewBranch = { key: 'Add New Branch', label: 'Add New Branch', value: 'add_new_branch' };

export default function AddNewSite({ fk_customer_id, pincode, enduser, address, onClose = () => { }, is_feasibility = false, isClearEnduser = true }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { branchList } = useSelector((state) => state.inventory);
    const role = loggedUser.role.id,
        type = loggedUser.type;
    const dispatch = useDispatch();
    const isToShowCustomerDropdown = isScm(role, type);
    const defaultValues = {
        fk_pincode_id: pincode ? pincode : '',
        fk_customer_id: '',
        customer_end_user: '',
        complete_site_address: address ? address : '',
    };
    const loggedUserCustomerId = getCustomerIdOfLoginUser(loggedUser);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const customer_id = fk_customer_id ? fk_customer_id : loggedUserCustomerId;

    const memoizedBranchList = useMemo(() => {
        const list = [addNewBranch];
        if (Array.isArray(branchList) && branchList.length > 0) {
            branchList.forEach((branch) => {
                list.push({ key: branch.id, label: branch.site_branch_name, value: branch.site_branch_name });
            });
        }
        return list;
    }, [branchList]);

    const getDataOnMount = useCallback(() => {
        dispatch(getAllCities());
        dispatch(getEndUsersList({ customer_id }));
        dispatch(getSiteType());
        dispatch(getSlaPolicies({ customerId: customer_id, dropdown: true }));
        if (isToShowCustomerDropdown) dispatch(getCustomersList());
    }, [dispatch, isToShowCustomerDropdown, customer_id]);

    useEffect(() => {
        getDataOnMount();

        return () => {
            dispatch(removePinCityFromStore());
            dispatch(clearLastCreatedEndUserFromStore());
            dispatch(removeEnduserListFromStore());
        };
    }, [dispatch, getDataOnMount]);

    const submit = (form) => {
        let site = {
            complete_site_address: form.complete_site_address,
            customer_end_user: actualValueForDropdown(form.customer_end_user),
            external_site_id: form.external_site_id,
            fk_city_id: actualValueForDropdown(form.fk_city_id),
            fk_customer_id: customer_id,
            fk_pincode_id: form.fk_pincode_id,
            region: form.region,
            site_contact_person_email: form.site_contact_person_email,
            site_contact_person_mobile: form.site_contact_person_mobile,
            site_contact_person_name: form.site_contact_person_name,
            sla: actualValueForDropdown(form.sla),
            zone: form.zone,
        };

        if (form.site_branch_name_select && actualValueForDropdown(form.site_branch_name_select) !== addNewBranch.value) {
            site.site_branch_name = actualValueForDropdown(form.site_branch_name_select);
        } else {
            site.site_branch_name = form.site_branch_name;
        }

        if (actualValueForDropdown(form.site_branch_name_select) && form.site_branch_name && memoizedBranchList.length > 1) {
            dispatch(
                createNewBranchRequest({
                    branchName: site.site_branch_name,
                    endUserId: actualValueForDropdown(form.customer_end_user),
                    customerId: isToShowCustomerDropdown ? actualValueForDropdown(form.fk_customer_id) : customer_id,
                })
            );
        }

        if (is_feasibility) {
            site.is_feasibility = 1;
        }
        if (isToShowCustomerDropdown) {
            site.fk_customer_id = actualValueForDropdown(form.fk_customer_id);
        }
        dispatch(createInventorySites({ data: [site], customerId: actualValueForDropdown(form.fk_customer_id), formName, closeModal: true, refresh: false }));
    };
    const methods = useForm({ defaultValues, mode: 'all' });

    const onCancel = () => {
        dispatch(closeModalAction());
        onClose();
    };

    return (
        <div className='px-4 py-2'>
            <Form
                methods={methods}
                onSubmit={submit}
                showSubmit
                isLoading={formButtonLoading[formName]}
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                onCancel={onCancel}
            >
                <AddSiteInputs memoizedBranchList={memoizedBranchList} isToShowCustomerDropdown={isToShowCustomerDropdown} fk_customer_id={customer_id} enduser={enduser} />
            </Form>
        </div>
    );
}

const AddSiteInputs = ({ isToShowCustomerDropdown, fk_customer_id, enduser, memoizedBranchList }) => {
    const { pinCity } = useSelector((state) => state.inventory);
    const { cities, endUsersList, slas } = useSelector((state) => state.inventory);
    const { customers } = useSelector((state) => state.customer);
    const { isCustomerListLoading, isEndUserListLoading, isCitiesLoading } = useSelector((state) => state.loading);
    const {
        watch,
        setValue,
        formState: { errors },
        clearErrors,
    } = useFormContext();
    const dispatch = useDispatch();

    useEffect(() => {
        if (fk_customer_id) {
            let findCustomer = customers?.find((customer) => customer.value === fk_customer_id);
            if (findCustomer) setValue('fk_customer_id', findCustomer);
        }
        if (enduser) {
            let findEnduser = endUsersList?.find((user) => user.value === enduser);
            setValue('customer_end_user', findEnduser);
        }
    }, [enduser, fk_customer_id, setValue, endUsersList, customers]);

    const customer = watch('fk_customer_id');

    useEffect(() => {
        let customerId = actualValueForDropdown(customer);
        if (customerId) {
            dispatch(getSlaPolicies({ customerId, dropdown: true }));
        }
    }, [customer, dispatch]);

    useEffect(() => {
        if (Array.isArray(cities) && cities.length > 0 && pinCity) {
            cities.forEach((elem) => {
                if (elem.value === pinCity?.fk_city_id) {
                    setValue('fk_city_id', elem);
                }
            });
        }
    }, [pinCity, cities, setValue]);
    const addressValue = watch('complete_site_address');
    let pincodeFromAddress = findPincode(addressValue);

    useEffect(() => {
        const isPincodeAddedInAddressField = addressValue && Boolean(findPincode(addressValue));
        if (isPincodeAddedInAddressField) {
            setValue('fk_pincode_id', pincodeFromAddress);
        }
    }, [setValue, pincodeFromAddress, addressValue]);
    let styleOption = {
        zIndex: 99999,
        position: 'fixed',
        width: '18%',
        overflow: 'scroll',
        height: '30%',
    };

    const customer_end_user = watch('customer_end_user');

    return (
        <>
            <div className='grid rows-fr grid-flow-row-dense grid-cols-4'>
                {isToShowCustomerDropdown && (
                    <Select className='w-full' name='fk_customer_id' label='Customer' options={customers} isLoading={isCustomerListLoading} shouldUnregister required />
                )}
                <Select className='w-full' name='customer_end_user' label='Enduser' options={endUsersList} isLoading={isEndUserListLoading} />
                <Select className='w-full' name='sla' label='SLA' options={slas} isLoading={false} />
                <Input label='Site ID' name='external_site_id' required />
                <RenderBranchName customer_end_user={customer_end_user} branchList={memoizedBranchList} customer={fk_customer_id} />
                <Input label='Region' name='region' />
                <Input label='Zone' name='zone' />
                <Input
                    className='w-full'
                    label='Pincode'
                    name='fk_pincode_id'
                    required
                    pattern={/^\d{6}$/}
                    validate={{
                        pincodeNotInSystem: async (value) => {
                            if (value === '') return true;
                            const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                            let data = response.data.data;
                            if (data) {
                                dispatch({ type: inventory.GET_CITY_FROM_PINCODE_SUCCESS, payload: data });
                            }
                            return response.data?.code === 200 || "Pincode does't exist in the System.";
                        },
                    }}
                />
                <Select className='w-full' name='fk_city_id' label='City' options={cities} isLoading={isCitiesLoading} required />

                <div className='col-span-2 w-full'>
                    <FetchGoogleAddress name={'complete_site_address'} optionStyle={styleOption} setLocation={({ pincode }) => setValue('fk_pincode_id', pincode)} />
                </div>
                <Input label='Contact Name' name='site_contact_person_name' />
                <Input
                    label='Contact Mobile'
                    name='site_contact_person_mobile'
                    validate={(mobile) => {
                        if (mobile === '') return true;
                        return mobile.length === 10 || 'Invalid Mobile';
                    }}
                />
                <Input label='Contact Email' name='site_contact_person_email' validate={(email) => email === '' || isValidEmail(email) || 'Invalid Email'} />
            </div>
        </>
    );
};

export const RenderBranchName = ({ branchList, customer_end_user, customer }) => {
    const { watch, setValue } = useFormContext();
    const dispatch = useDispatch();
    const site_branch_name_select = watch('site_branch_name_select');

    useEffect(() => {
        let payload = { customerId: actualValueForDropdown(customer) };
        if (customer_end_user) {
            payload.endUserId = actualValueForDropdown(customer_end_user);
        }
        if (payload.customerId) {
            dispatch(getBranchList(payload));
        }
        setValue('site_branch_name', '');
        setValue('site_branch_name_select', '');
    }, [customer, customer_end_user, dispatch, setValue]);

    if (branchList && branchList.length > 1) {
        return (
            <>
                <Select shouldUnregister className='w-full' name='site_branch_name_select' label='Branch Name' options={branchList} />
                {addNewBranch.value === actualValueForDropdown(site_branch_name_select) && <Input shouldUnregister label='New Branch Name' name='site_branch_name' />}
            </>
        );
    }
    return <Input shouldUnregister label='Branch Name' name='site_branch_name' />;
};
