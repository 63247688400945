import config from '../config';
import { chatSidebarTab } from '../layout/sidebar';
import { conversations, onboarding, user } from '../types';
import { validateStringValue } from '../utils/common';
import { isCluster, isCustomer, isScm, isSuperVisor } from '../utils/role';

const INITIAL_STATE = {
    error: undefined,
    notifications: {
        unreadNotificationCount: 0,
        next_offset: 0,
        notificationList: [],
        count: 0,
    },
    existingCities: [],
    isPanVerified: undefined,
    isAadharVerified: undefined,
    isGstVerified: undefined,
    isBussinessPanCardVerified: undefined,
    company_name: '',
    updatedGstId: undefined,
    openTicketNumber: undefined,
    isRoomDisabled: false,
    enableRoomId: undefined,
    hasEndUserListReachedEnd: false,
    userBankDetail: {}
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case user.SERVICETYPE_LIST_SUCCESS:
            return {
                ...state,
                serviceTypes: action.payload,
            };

        case user.USECASES_LIST_SUCCESS:
            return {
                ...state,
                usecases: action.payload,
            };
        case user.COURIER_VENDORS_LIST_SUCCESS:
            return {
                ...state,
                courierVendors: action.payload,
            };

        case user.GET_NOTIFICATION_SUCCESS: {
            let { unreadNotificationCount, next_offset, notificationList, count } = state.notifications;
            const user = { ...state.user };
            const incomingData = action.payload;
            unreadNotificationCount = incomingData.unreadCount;
            next_offset = incomingData.nextOffset;
            count = incomingData.count;
            notificationList = incomingData.notifications;
            if (incomingData.nextOffset === incomingData.notifications.length) {
                notificationList = incomingData.notifications;
            }

            return {
                ...state,
                notifications: { unreadNotificationCount, next_offset, notificationList, count },
                user,
            };
        }

        case user.GET_NEXT_NOTIFICATION_SUCCESS: {
            let { unreadNotificationCount, next_offset, notificationList, count } = state.notifications;
            const user = { ...state.user };
            const incomingData = action.payload;
            unreadNotificationCount = incomingData.unreadCount;
            next_offset = incomingData.nextOffset;
            count = incomingData.count;
            notificationList = notificationList.concat(incomingData.notifications);
            if (incomingData.nextOffset === incomingData.notifications.length) {
                notificationList = incomingData.notifications;
            }

            return {
                ...state,
                notifications: { unreadNotificationCount, next_offset, notificationList, count },
                user,
            };
        }

        case user.READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    notificationList: state.notifications.notificationList.map((item) =>
                        item.id === action.payload.id ? { ...item, viewed_time: action.payload.viewed_time } : item
                    ),
                },
            };

        case user.GET_USERS_RATECARD_SUCCESS:
            return {
                ...state,
                ratecards: action.payload,
            };
        case user.STATES_LIST_SUCCESS:
            return {
                ...state,
                states: action.payload,
            };

        case user.CITIES_LIST_SUCCESS:
            return {
                ...state,
                cities: action.payload,
            };
        case user.EXISTING_CITIES_LIST_SUCCESS:
            return {
                ...state,
                existingCities: action.payload,
            };
        case user.REMOVE_EXISTING_CITIES_LIST_FROM_STORE:
            return {
                ...state,
                existingCities: [],
            };
        case user.GET_SP_PROFILE_DETAILS_SUCCESS: {
            const transformedInfo = {
                ...action.payload,
            }
            if (action.payload?.company_detail?.user?.store_ref_code) {
                transformedInfo.company_detail.user.store_affiliate_link = `${config.store_affiliate_link_static}${action.payload.company_detail.user.store_ref_code}`;
            }
            return {
                ...state,
                spProfileDetails: transformedInfo,
            };
        }
        case user.GET_FE_PROFILE_DETAILS_SUCCESS: {
            const transformedInfo = {
                ...action.payload,
            }
            if (action.payload?.user?.store_ref_code) {
                transformedInfo.user.store_affiliate_link = `${config.store_affiliate_link_static}${action.payload.user.store_ref_code}`;
            }
            return {
                ...state,
                feProfileDetails: transformedInfo,
            };
        }
        case user.CLEAR_SP_PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                spProfileDetails: null,
                scmUserInfo: null,
                feProfileDetails: null,
            };

        case user.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case user.GET_USER_INFO_SUCCESS: {
            const transformedInfo = {
                ...action.payload,
            }
            if (action.payload?.user?.store_ref_code) {
                transformedInfo.user.store_affiliate_link = `${config.store_affiliate_link_static}${action.payload.user.store_ref_code}`;
            }
            return {
                ...state,
                scmUserInfo: transformedInfo,
            };
        }
        case user.GET_RESOURCE_SUCCESS: {
            const { parentFolder, resources } = action.payload;
            return {
                ...state,
                documentsData: resources,
                parentFolder: parentFolder,
            };
        }
        case user.CLEAR_RESOURCES: {
            return {
                ...state,
                documentsData: null,
                parentFolder: null,
            };
        }
        case user.TEAM_USER_EDIT_SUCCESS: {
            const { team_id } = action.payload;
            const updatedDetails = {
                ...state.userDetails,
                teams: state?.userDetails?.teams?.filter((elem) => elem?.id !== team_id),
            };
            return {
                ...state,
                userDetails: updatedDetails,
            };
        }

        case user.ADD_END_USER_SUCCESS: {
            return {
                ...state,
                createdEndUser: action.payload,
            };
        }
        case user.CLEAR_LAST_CREATED_ENDUSER: {
            return {
                ...state,
                createdEndUser: null,
            };
        }

        case user.BANKS_LIST_SUCCESS: {
            const banks = action.payload.map((bank) => {
                return {
                    key: `$bank_${bank.bank_id}`,
                    value: bank.bank_id,
                    label: bank.bank_name,
                };
            });
            banks.push({
                key: 'other',
                value: 'other',
                label: 'Other Bank',
            });
            return {
                ...state,
                banks,
            };
        }

        case user.UPDATE_USERS_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                bankUpdated: action.payload,
            };
        case user.CLEANUP_BANK_UPDATED_DETAILS_FROM_STORE:
            return {
                ...state,
                bankUpdated: undefined,
            };

        case user.PANCARD_VALIDATION_SUCCESS: {
            let { data } = action.payload;
            let verifiedPanDetails = {
                pan_number: data.pan_number,
                full_name: data.full_name,
            };
            return {
                ...state,
                isPanVerified: true,
                verifiedPanDetails: verifiedPanDetails,
            };
        }

        case user.PANCARD_VALIDATION_FAILURE: {
            return {
                ...state,
                isPanVerified: false,
            };
        }

        case user.BUSSINESS_PANCARD_VALIDATION_SUCCESS: {
            let { data } = action.payload;
            return {
                ...state,
                isBussinessPanVerified: true,
                company_name: data.full_name,
            };
        }

        case user.BUSSINESS_PANCARD_VALIDATION_FAILURE: {
            return {
                ...state,
                isBussinessPanVerified: false,
                company_name: '',
            };
        }
        case user.CLEAR_BUSSINESS_PANCARD_VALIDATION: {
            const isBussinessPanVerified = action.payload;
            return {
                ...state,
                isBussinessPanVerified,
                company_name: '',
            };
        }

        case user.GST_VALIDATION_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                isGstVerified: true,
                company_name: data.business_name,
            };
        }
        case user.GST_VALIDATION_FAILURE: {
            return {
                ...state,
                isGstVerified: false,
                company_name: '',
            };
        }

        case user.SUBMIT_AADHAAR_OTP_SUCCESS: {
            let { data, aadhaar_number } = action.payload;
            return {
                ...state,
                isAadharVerified: true,
                verifiedAadhaarDetails: {
                    aadhaar_number: aadhaar_number,
                    full_name: data.full_name,
                    dob: data.dob,
                    gender: data.gender,
                    isPersonAdult: data.isPersonAdult
                },
            };
        }
        case user.SUBMIT_AADHAAR_OTP_FAILURE: {
            return {
                ...state,
                isAadharVerified: false,
            };
        }

        case user.BANK_VERIFICATION_SUCCESS: {
            return {
                ...state,
                verifiedBankDetails: action.payload,
            };
        }

        case user.GET_SP_PROFILE_DETAILS_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                spProfileDetails: null,
            };
        }
        case user.GENERATE_OTP_SUCCESS: {
            return {
                ...state,
                otpGenerated: action.payload,
            };
        }
        case user.VERIFY_OTP_SUCCESS: {
            return {
                ...state,
                otpGenerated: false,
            };
        }
        case user.CLEAR_PROFILE_UPDATE_OTP_STORE: {
            return {
                ...state,
                otpGenerated: undefined,
            };
        }
        case onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_SUCCESS:
            if (!state?.spProfileDetails?.company_detail) return state;
            const { service_partner_detail } = action.payload;
            let updatedSpProfileDetails = {
                company_detail: {
                    ...state.spProfileDetails.company_detail,
                    sp_name: service_partner_detail?.sp_name,
                    sp_pan_card_number: service_partner_detail?.sp_pan_card_number,
                    sp_address_line_1: service_partner_detail?.sp_address_line_1,
                    sp_adhaar_number: service_partner_detail?.sp_adhaar_number,
                    sp_gst_no: service_partner_detail?.sp_gst_no,
                    office: service_partner_detail?.office,
                },
            };
            return {
                ...state,
                spProfileDetails: updatedSpProfileDetails,
            };

        case user.GET_GST_LIST_SUCCESS: {
            const gstResponsePayload = action.payload;
            const gstList = transformGstList(gstResponsePayload);
            return {
                ...state,
                gstList: gstList,
            };
        }

        case user.ADD_GST_SUCCESS: {
            const updatedResponseData = action.payload;
            const transformedGstList = transformGstList(updatedResponseData);
            const updatedGstList = [...state.gstList, ...transformedGstList];
            return {
                ...state,
                gstList: updatedGstList,
            };
        }

        case user.UPDATE_GST_SUCCESS: {
            const { updatePayload, gstId } = action.payload;
            const gstListCopy = state.gstList;
            const updatedData = gstListCopy?.map((item) => {
                if (item.gstId === gstId) {
                    return {
                        ...item,
                        gst_number: updatePayload?.gst,
                        gst_state: updatePayload?.state_code,
                        gst_status: updatePayload?.status,
                    };
                } else {
                    return item;
                }
            });
            return {
                ...state,
                gstList: updatedData,
                updatedGstId: gstId,
            };
        }
        case user.DELETE_GST_SUCCESS: {
            const { gstId } = action.payload;
            const gstListCopy = state.gstList;
            const updatedData = gstListCopy?.filter((item) => item.gstId !== gstId);
            return {
                ...state,
                gstList: updatedData,
            };
        }

        case user.CLEAR_UPDATE_GST_STORE: {
            return {
                ...state,
                updatedGstId: INITIAL_STATE.updatedGstId,
            };
        }

        case user.USER_FIELD_ENGINEERS_LIST_SUCCESS: {
            const { loggedUser, feList } = action.payload;
            const role = loggedUser.role.id;
            const type = loggedUser.type;
            let field_engineers = feList.engineer_list.map((fe) => {
                return {
                    fk_user_id: fe.fk_user_id,
                    name: `${fe?.user?.first_name} ${fe?.user?.last_name} ${loggedUser.id === fe?.user?.id ? '(Assign To Me)' : ''}`,
                    mobile: fe?.user?.mobile,
                    rating: fe?.rating?.avg_rating,
                };
            });
            const isAlreadyExist = field_engineers.find((user) => user.fk_user_id === loggedUser.id)
            if ((isScm(role, type) || isCustomer(role, type) || isCluster(role, type) || isSuperVisor(role, type)) && !isAlreadyExist) {
                field_engineers.unshift({
                    fk_user_id: loggedUser.id,
                    name: `${validateStringValue(loggedUser.first_name)} ${validateStringValue(loggedUser.last_name)} (Assign To Me)`,
                    mobile: loggedUser.mobile,
                });
            }

            return {
                ...state,
                fieldEngineers: field_engineers,
            };
        }

        case user.CLEAR_FIELD_ENGINEERS_LIST: {
            return {
                ...state,
                fieldEngineers: undefined,
            };
        }
        case user.GET_TECH_SUPPORT_USERS_DROPDOWN_SUCCESS: {
            return {
                ...state,
                techSupportEngineersDropdown: action.payload,
            };
        }
        case user.LIST_TECH_ENGINEERS_SUCCESS: {
            return {
                ...state,
                techEngineerList: action.payload.map((user) => ({
                    userId: user.id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                })),
            };
        }
        case user.GET_COUNT_SUCCESS: {
            return { ...state, ...action.payload };
        }
        case user.USER_PROFILE_DETAILS_SUCCESS: {
            const { userId, userDetails } = action.payload
            return { ...state, userProfileDetails: { ...state.userProfileDetails, [userId]: transformUserProfileDetails(userDetails) } };
        }
        case conversations.MARK_NOTIFICATIONS_READ_SUCCESS: {
            const { newCount } = action.payload;
            return { ...state, [chatSidebarTab.countKey]: newCount };
        }
        case user.USER_AADHAAR_VALIDATE_SUCCESS: {
            const { data, is_already_verified, isPersonAdult } = action.payload;
            return {
                ...state, is_aadhar_is_already_verified: is_already_verified, aadharKycResponse: data, isAadharVerified: is_already_verified, verifiedAadhaarDetails: {
                    aadhaar_number: data.aadhaar_number,
                    full_name: data.full_name,
                    dob: data.dob,
                    gender: data.gender,
                    isPersonAdult: isPersonAdult
                },
            };
        }
        case user.REMOVE_VALIDATE_AADHAR_RESPONSE_FROM_STORE: {
            return { ...state, is_aadhar_is_already_verified: false, aadharKycResponse: undefined, isAadharVerified: undefined, verifiedAadhaarDetails: undefined };
        }
        case user.SET_SYSTEM_UI_CONFIG: {
            return { ...state, uiConfig: action.payload }
        }
        case user.ENABLE_USER_ROOM_SUCCESS: {
            return {
                ...state,
                enableRoomId: action.payload,
            };
        }
        case user.DISABLE_USER_ROOM_SUCCESS: {
            return {
                ...state,
                isRoomDisabled: action.payload,
            };
        }
        case user.CLEAR_ENABLED_ROOM_ID: {
            return {
                ...state,
                enableRoomId: INITIAL_STATE.enableRoomId
            };
        }
        case user.GET_USER_CALL_STATUS_SUCCESS: {
            return {
                ...state,
                userCallStatus: action.payload
            }
        }
        case user.GET_INDIVIDUAL_USER_BANK_DETAIL_SUCCESS: {
            const { bank, id } = action.payload;
            return {
                ...state,
                userBankDetail: { ...state.userBankDetail, [id]: bank },
            };
        }
        case user.GET_USER_CALL_STATUS_FAILURE:
        case user.DISABLE_USER_ROOM_FAILURE:
        case user.ENABLE_USER_ROOM_FAILURE:
        case user.GET_ENDUSERS_LIST_SUCCESS: {
            const { data, page } = action.payload;
            let endUserList = data;
            const hasEndUserListReachedEnd = data && data.length === 0 && page > 1;
            if (page > 1) {
                endUserList = [...state.endUserList, ...data]
            }
            return { ...state, endUserList, hasEndUserListReachedEnd }
        }
        case user.UPLOAD_USER_ID_PROOF_BACK_FAILURE:
        case user.GET_TECH_SUPPORT_USERS_DROPDOWN_FAILURE:
        case user.GET_FE_PROFILE_DETAILS_FAILURE:
        case user.CITIES_LIST_FAILURE:
        case user.EXISTING_CITIES_LIST_FAILURE:
        case user.STATES_LIST_FAILURE:
        case user.SERVICETYPE_LIST_FAILURE:
        case user.USECASES_LIST_FAILURE:
        case user.UPDATE_USER_TERRITORIES_FAILURE:
        case user.REINVITE_FE_FAILURE:
        case user.REMOVE_USER_TERRITORIES_FAILURE:
        case user.NEW_RESOURCE_ADD_FAILURE:
        case user.NEW_RESOURCE_FILE_ADD_FAILURE:
        case user.GET_RESOURCE_FAILURE:
        case user.DELETE_RESOURCE_FAILURE:
        case user.GET_USERS_RATECARD_FAILURE:
        case user.READ_NOTIFICATION_FAILURE:
        case user.GET_NOTIFICATION_FAILURE:
        case user.GET_NEXT_NOTIFICATION_FAILURE:
        case user.COURIER_VENDORS_LIST_FAILURE:
        case user.UPDATE_USERS_BANK_DETAILS_FAILURE:
        case user.GET_GST_LIST_FAILURE:
        case user.LIST_TECH_ENGINEERS_FAILURE:
        case user.GET_COUNT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

const transformGstList = (list) => {
    let transformedList = list?.map((item) => ({
        gstId: item.id,
        gst_status: item.status,
        gst_number: item.gst,
        gst_state: parseInt(item.state_code, 10),
    }));
    return transformedList;
};

const transformUserProfileDetails = (userDetails) => {
    const valueCheck = (value) => {
        return [undefined, null, ''].includes(value) ? false : value;
    };

    let transformedList = {
        id: valueCheck(userDetails?.userId),
        userName: valueCheck(userDetails?.username),
        addressProofNumber: valueCheck(userDetails?.address_proof_number),
        addressProofType: valueCheck(userDetails?.address_proof_type),
        addressProofUrl: valueCheck(userDetails?.address_proof_url),
        chatId: valueCheck(userDetails?.chatId),
        joinedAt: valueCheck(userDetails?.created_at),
        email: valueCheck(userDetails?.email),
        mobile: valueCheck(userDetails?.mobile),
        profilePic: valueCheck(userDetails?.profilePic),
        role: valueCheck(userDetails?.role),
        type: valueCheck(userDetails?.type),
        numTicketsClosed: valueCheck(userDetails?.numClosedTickets),
        numTickets: valueCheck(userDetails?.numTickets),
        balance: userDetails?.balance,
        companyName: valueCheck(userDetails?.companyName),
        gstNumber: valueCheck(userDetails?.gstNumber),
        panNumber: valueCheck(userDetails?.panNumber),
        spName: userDetails?.spName !== userDetails?.username ? valueCheck(userDetails?.spName) : false,
        avg_sign_off_quality_rating: userDetails?.avg_sign_off_quality_rating,
        avg_work_quality_rating: userDetails?.avg_work_quality_rating,
        rating: (userDetails?.avg_sign_off_quality_rating + userDetails?.avg_work_quality_rating) / 2
    };
    return transformedList;
};

export default userReducer;
