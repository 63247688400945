import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getWalletDetailsById, removeWalletDetailsFromStore } from '../../../actions/wallet';
import { isIsp, isPsp, isSp, isSpIsp, isFe, isCluster, isSuperVisor, isScm, isCustomerGroup } from '../../../utils/role';
import SendToBank from '../../Wallet/Modals/SendToBank';
import { getUserBankDetailsById } from '../../../actions/auth';
import { isShowDailyLimit } from '../../Wallet/components/Passbook';
import { getBanksList } from '../../../actions/users';
import { validateNumberValue, validateStringValue } from '../../../utils/common';
import { bank, bankDetailPayload, upi } from '../../Payments/Modals/EditPayout/TicketBankTransferPayment';

export const BankTransfer = ({ user }) => {
    const dispatch = useDispatch();
    const { userBankDetail } = useSelector((state) => state.user);
    const { banks } = useSelector((state) => state.user);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = user.role, type = user.type;
    const { walletDetailsById } = useSelector((state) => state.wallet);
    const { isBankDetailsLoading } = useSelector((state) => state.loading);
    const [paymentType, setPaymentType] = useState(bank);


    const memoizedUserBankDetails = useMemo(() => {
        if (user && user.id && userBankDetail[user.id]) {
            return userBankDetail[user.id];
        }
        return {}
    }, [userBankDetail, user]);

    const isUserSpPspSpisp = isSp(role, type) || isPsp(role, type) || isSpIsp(role, type);
    const isUserIsIsp = isIsp(role, type);

    let vendorId = user.id;
    let vendorType = '';
    if (isUserSpPspSpisp) {
        vendorId = user?.service_partner?.service_partner_id;
        vendorType = 'SP';
    }
    if (isUserIsIsp) {
        vendorId = user?.customer_details?.customer_id;
        vendorType = 'SP';
    }

    useEffect(() => {
        if (user?.id) {
            const payload = bankDetailPayload({ userId: user?.id, role, type: type });
            dispatch(getUserBankDetailsById(payload));
        }
        return () => {
            dispatch(removeWalletDetailsFromStore());
        }
    }, [user?.id, dispatch, vendorType, vendorId, role, type]);

    useEffect(() => {
        if (user.id) {
            let account_balance = 'BANKOPEN';
            if (paymentType === upi) account_balance = 'ZWITCH'
            dispatch(getWalletDetailsById({ updateBalance: false, beneficiary: user.id, accountBalance: account_balance }));
        }
    }, [dispatch, paymentType, user?.id])

    useEffect(() => {
        if (!memoizedUserBankDetails && !banks) {
            dispatch(getBanksList());
        }
    }, [memoizedUserBankDetails, dispatch, banks]);

    const amountValidationCheck = (amount, user_wallet_amount) => {
        let minAmountCheck = 400;
        if (isCustomerGroup(loggedUser.role.id, loggedUser.type)) {
            minAmountCheck = 100
        }
        amount = amount && parseInt(amount);
        if (loggedUser.per_transaction_limit !== -1) {
            return loggedUser.per_transaction_limit >= amount || 'You have exceeded your per-transaction limit.';
        }

        return parseFloat(amount) >= minAmountCheck && amount <= user_wallet_amount || `Amount should be more than ${minAmountCheck} and less than your wallet balance.`;
    }

    return <>
        <SendToBank isShowDailyLimit={isShowDailyLimit(walletDetailsById?.withdraw_limit, user.role, user.type)} wallet={walletDetailsById} isBankDetailsLoading={isBankDetailsLoading} bankDetails={memoizedUserBankDetails} isUserSpPspSpisp={isUserSpPspSpisp} isUserIsIsp={isUserIsIsp} directBankTransfer vendorId={vendorId} type={vendorType} userId={user.id} scogoBankAccountBalance={validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance)} has_bank_details={user.has_bank_details === 1} showUpiTransefer selectedUser={{ name: `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}` }} paymentType={paymentType} setPaymentType={setPaymentType} amountValidationCheck={amountValidationCheck} showUserWalletBalance showTotalWithdrawn={false} />
    </>
}
