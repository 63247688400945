import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../actions/modal';
import IconToolTip from '../../common/IconToolTip';
import OverflowTip from '../OverflowTip';
import { signOutUserAction } from '../../actions/auth';
import { useNavigate } from 'react-router';

const CustomModal = (props) => {
    const dispatch = useDispatch();
    const { modalComponent, heading, selectedCount, modalWidth, modalHeight, maxHeight, showClose = true, showLogout = false, headerComponent = <></>, onClose = () => { } } = useSelector((state) => state.modal);
    const mediaViewRef = useRef(null)
    const closeModal = () => {
        onClose();
        dispatch(closeModalAction());
    };

    const closeMedia = (event) => {
        if (event.keyCode === 27 && event.key === "Escape") {
            dispatch(closeModalAction());
        }
    }

    useEffect(() => {
        if (mediaViewRef?.current) {
            mediaViewRef.current.focus();
        }
    }, []);

    return (
        <div ref={mediaViewRef} tabIndex={-1} onKeyDown={closeMedia} >
            <div className='opacity-25 fixed inset-0 z-50 bg-black'></div>
            <div className='justify-center  items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative mb-6 mt-12 mx-auto'>
                    <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                        <div style={{
                            width: `${modalWidth ? modalWidth : '60rem'}`,
                        }} className='flex items-center justify-between py-6 px-7 border-b  border-scogoddd rounded-t-10px w-full'>
                            <div className='w-9/12' >
                                <OverflowTip textClass={`text-3xl font-medium text-scogo15 truncate w-full`} someLongText={heading} />
                            </div>
                            <div className='w-3/12 flex items-center justify-end ml-auto gap-4'>
                                {selectedCount > 0 && <div className='text-xl text-scogoorange flex text-end'>{selectedCount} Selected</div>}
                                {headerComponent && headerComponent}
                                {showClose && (
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        <IconToolTip title='Close'>
                                            <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange'>highlight_off</span>
                                        </IconToolTip>
                                    </div>
                                )}
                                {showLogout && <RenderLogout />}
                            </div>
                        </div>
                        <div>
                            <div
                                className='h-full overflow-y-auto'
                                style={{
                                    height: `${modalHeight ? modalHeight : '65vh'}`,
                                    width: `${modalWidth ? modalWidth : '60rem'}`,
                                    maxHeight: maxHeight ? maxHeight : '65vh',
                                }}
                            >
                                {modalComponent && modalComponent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;

const RenderLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div
            className='cursor-pointer'
            onClick={() => {
                dispatch(signOutUserAction(navigate));
            }}
        >
            <IconToolTip title='Logout'>
                <span className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange'>power_settings_new</span>
            </IconToolTip>
        </div>
    );
};
