import React from 'react';

export const TicketExecutionHeader = ({ completedSteps, totalSteps, onClick, memoizedTicket, isProblemDescriptionVisible, isChecklistVisible, isBoqVisible, isReacheSiteActionVisible }) => {

    if (isProblemDescriptionVisible) {
        return <p onClick={onClick}>
            <span className="text-font13 md:text-font12 font-medium text-white">Problem Description</span>
        </p>
    }

    if (isChecklistVisible) {
        return <div className='flex items-center gap-2 text-scogoprimary cursor-pointer' onClick={onClick}>
            <span className="text-font13 md:text-font12 font-medium text-white">Ticket Execution Checklist</span>
            <span className='bg-white rounded-full px-2'>({`${completedSteps}/${totalSteps}`})</span>
        </div>
    }

    if (isBoqVisible || isReacheSiteActionVisible) {
        return <div className='flex items-center gap-2 text-scogoprimary cursor-pointer' onClick={onClick}>
            <span className="text-font13 md:text-font12 font-medium text-white">{isReacheSiteActionVisible ? 'Reached Site?' : 'Add BOQ Details'}</span>
        </div>
    }

    return <></>;
};