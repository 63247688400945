import {
    isScm,
    isCluster,
    isCustomer,
    isCustomerAdmin,
    isCustomerUser,
    isFe,
    isSp,
    isPsp,
    isIsp,
    isSuperVisor,
    SCM,
    SUPERVISOR,
    CUSTOMER,
    CUSTOMER_ADMIN,
    CUSTOMER_USER,
    isSpIsp,
    SP,
    ISP,
    isCustomerGroup,
} from '../../utils/role';
import UpdateRmaDetails from './Modal/UpdateRmaDetails';
import { isApprovePaymentAllowed, isAssignAllowed, isSignOffApproveAllowed, isTicketAdditionAllowed, isViewPoAllowed } from '../../utils/access';
import DownloadCsv from './Modal/DownloadCsv';
import { closeModalAction, openCustomModal, openFullscreenModal, openTextModalAction } from '../../actions/modal';
import {
    cloneTickets,
    deleteTicketsRequest,
    emailReport,
    getAssetConfigTemplate,
    getTicketReportLink,
    requestTrackFE,
    siteCompleted,
    updateDocketDetails,
    bulkHardCopyRecievedUpdate
} from '../../actions/tickets';
import { updateSignOffSteps } from '../../actions/ticketExecution';
import ShareFeasibilityLink from '../team/Modals/ShareFeasibilityLink';
import AssignTechSupport from './Modal/AssignTechSupport';
import CustomerApprovalReceived from './Modal/CustomerApprovalRecieved';
import EditExecutionDate from './Modal/EditExecutionDate';
import AddNewPincode from './Modal/AddNewPincode';
import { sendToastMessage } from '../../actions/toast';
import AssignSP from './Modal/AssignSP';
import BulkUploadSites from './Modal/BulkUploadSites';
import HoldTicket from './Modal/HoldTicket';
import UpdatePo from '../Payments/Modals/UpdatePO';
import moment from 'moment';
import config from '../../config';
import { downloadCustomerReport } from '../../actions/conversations';
import prodConfig from '../../config/env/production';
import DownloadCustomerReport from './Modal/DownloadCustomerReport';
import UpdateSignOffNotes from './Modal/updateSignOffNote';
import { RenderRemark } from './TicketColumns/Progress';
import RaisePo from '../raise-po';
import { validateStringValue } from '../../utils/common';
import PoApproval from './PoApproval';
import CloseFeasibility from './CloseFeasibility';
import ResumeTicket from './Modal/ResumeTicket';
import HardCloseTicket from './Modal/HardCloseTicket';
import { openTicketPaymentModal } from './TicketColumns/Price';
import DownloadRmaReports from './Modal/DownloadRmaReport';
import { withdrawTicket } from '../../actions/ticketAssignment';
import UpdateTicketDate from './Modal/UpdateTicketDate';
import { FeRating } from '../Payments/Modals/FeRating';
import CreateAppointment from './TicketColumns/CreateAppointment';
import { UnlockSignoff } from './Modal/UnlockSignoff';
import RenderUpdatePo from '../Payments/Modals/RenderUpdatePo';
import BulkUploadOfflineTransaction from './Modal/BulkOfflineTransaction';

const ticketChat = 'ticketChat';

export const allTab = {
    label: 'All',
    id: 'alltickets',
    path: 'alltickets',
};
export const newAndCustomerTab = {
    label: 'New',
    id: 'new',
    path: 'new',
};

export const triggerTab = {
    label: 'Trigger',
    id: 'triggered',
    path: 'triggered',
};
export const plannedTab = {
    label: 'Planned',
    id: 'planned',
    path: 'planned',
};
export const ongoingTab = {
    label: 'Ongoing',
    id: 'ongoing',
    path: 'ongoing',
};

export const IssuesTab = {
    label: 'Issues',
    id: 'escalated',
    path: 'escalated',
};

export const completedTab = {
    label: 'Completed',
    id: 'sitecomplete',
    path: 'sitecomplete',
};

export const closedTab = {
    label: 'Closed',
    id: 'closed',
    path: 'closed',
};

export const scheduledTab = {
    label: 'Scheduled',
    id: 'scheduled',
    path: 'scheduled',
};

export const newMainTab = {
    label: 'New Tickets',
    id: 'new',
    path: 'new',
    icon: 'confirmation_number',
    getSubTabs: () => [],
};

export const acceptedMainTicketsTab = {
    label: 'Accepted Tickets',
    id: 'accepted',
    path: 'accepted',
    icon: 'confirmation_number',
    getSubTabs: (role, type) => {
        let tabs = [];
        if (isSp(role, type) || isIsp(role, type) || isSpIsp(role, type)) {
            tabs = [allTab, ongoingTab, IssuesTab, completedTab];
        }
        return tabs;
    },
};

const getTabFromQueryString = ({ tabs, queryKey, query }) => {
    const match = query.get(queryKey);
    let queryTab;
    if (match) queryTab = match;
    return tabs.find((tab) => tab.path === queryTab);
};

export const getTicketDefaultTab = (role, type, { query }) => {
    const tabs = getTicketTabs(role, type);
    let subTabs = [];
    if (tabs.length === 0) subTabs = getTicketSubTabs(role, type);
    else {
        subTabs = tabs[0].getSubTabs(role, type);
    }
    let tab = tabs[0];
    let subTab = subTabs?.[0];

    if (query) {
        const matchingMainTab = getTabFromQueryString({ tabs: tabs, queryKey: 'mainTab', query });
        if (matchingMainTab) {
            subTabs = matchingMainTab.getSubTabs(role, type);
            subTab = subTabs[0];
        }
        const matchingSubTab = getTabFromQueryString({ tabs: subTabs, queryKey: 'tab', query });
        if (matchingMainTab) tab = matchingMainTab;
        if (matchingSubTab) subTab = matchingSubTab;
    }
    return { defaultMainTab: tab?.path, defaultSubTab: subTab?.path };
};

export const getTicketTabs = (role, type) => {
    let tabs = [];
    if (isSp(role, type) || isSpIsp(role, type) || (role === SP.role && type === ISP.type)) {
        tabs = [newMainTab, acceptedMainTicketsTab];
    }
    return tabs;
};

export const getTicketSubTabs = (role, type, { activeMainTab } = {}) => {
    let tabs = [];
    if (activeMainTab === newMainTab.path) return [];
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type) || (role === CUSTOMER.role && type === ISP.type)) {
        tabs = [allTab, newAndCustomerTab, triggerTab, plannedTab, ongoingTab, IssuesTab, completedTab, closedTab];
    }
    if (isCluster(role, type) || isSuperVisor(role, type)) {
        tabs = [allTab, newAndCustomerTab, plannedTab, ongoingTab, IssuesTab, completedTab, closedTab];
    }
    if (isPsp(role, type)) {
        tabs = [allTab, newAndCustomerTab, ongoingTab, IssuesTab, completedTab];
    }

    if (isFe(role, type)) {
        tabs = [allTab, ongoingTab, scheduledTab, IssuesTab, completedTab];
    }
    return tabs;
};

export const defaultRowsPerPage = 10;
export const defaultPage = 1;

export const scogoEvent = {
    ticketClosedByNoc: 15,
    siteNotCompleted: 56,
    reachedSite: 10,
    feNotReachedSite: 55,
    goingToSite: 66,
};
export const ticketExtraColumns = {
    price: 'Price',
    contact_details: 'Contact Details',
    partner_details: 'Partner Details',
    current_stataus: 'Current Status',
    description: 'Description',
    progress: 'Progress',
    assets: 'Assets',
    remark: 'Remark',
    shipping_details: 'Shipping Details',
};

export const showHideColumnsOption = [
    ticketExtraColumns.price,
    ticketExtraColumns.contact_details,
    ticketExtraColumns.partner_details,
    ticketExtraColumns.current_stataus,
    ticketExtraColumns.description,
    ticketExtraColumns.progress,
    ticketExtraColumns.assets,
    ticketExtraColumns.remark,
    ticketExtraColumns.shipping_details,
];

export const columnsKeys = {
    order_date: 'order_date',
    ticket_id: 'ticket_id',
    customer_details: 'customer_details',
    site_details: 'site_details',
    ticket_description: 'ticket_description',
    price: 'price',
    contact_details: 'contact_details',
    partner_details: 'partner_details',
    current_status: 'current_status',
    progress: 'progress',
    assets: 'assets',
    remarks: 'remarks',
    shipping_details: 'shipping_details',
    action: 'action',
};

export const serviceTypeIds = {
    migration: 11,
    reconcile: 12,
    feasibility: 13,
    release_asset: 14,
    scrap_asset: 15,
    asset_replace: 16,
    reverse_logistics: 4,
    support: 5,
};

export const ticketTypes = {
    field: 0,
    rma: 1, // doesn't actually get used, rma tickets are checked with is_rma_raised = 1
    support: 2,
};

export const ticketStatusList = [
    {
        label: 'Sign Off Accepted',
        tag: 'sign_off_accepted',
        labelClass: 'bg-labelLightGreen font-medium py-1',
        shownNobgLabel: true,
        color: 'scogo2e',
        check: ({ signOffAccept, signOffRequest, signOffReject, is_deleted, isOnHold, isEscalated, hardClose }) =>
            signOffAccept === 1 && signOffRequest === 1 && signOffReject === 0 && is_deleted === 0 && isOnHold === 0 && isEscalated === 0 && hardClose === 0,
    },
    {
        key: 'new',
        label: 'New',
        tag: 'new',
        color: 'scogo2e',
        check: (
            {
                fk_assigned_primary_sp_id,
                requestRaised,
                closedByNoc,
                is_deleted,
                hardClose,
                accepted_sp_id,
                reachedSite,
                accepted_fe_id,
                assignToScogo,
                ticket_owned_by,
                isOnHold,
                isEscalated,
                signOffReject,
            },
            { role, type }
        ) => {
            let baseCondition = isOnHold === 0 && isEscalated === 0 && signOffReject === 0;
            if (isSp(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isPsp(role, type)) {
                return accepted_sp_id === 0 && requestRaised === 1 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && reachedSite === 0 && baseCondition;
            } else if (isFe(role, type)) {
                return accepted_fe_id !== 0 && requestRaised === 1 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && reachedSite === 0 && baseCondition;
            } else if (isCustomerGroup(role, type)) {
                return (
                    fk_assigned_primary_sp_id === 0 &&
                    requestRaised === 0 &&
                    closedByNoc === 0 &&
                    is_deleted === 0 &&
                    hardClose === 0 &&
                    (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 0)) &&
                    baseCondition
                );
            }
            return fk_assigned_primary_sp_id === 0 && requestRaised === 0 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && baseCondition;
        },
    },
    {
        key: 'triggered',
        label: 'Triggered',
        tag: 'triggered',
        color: 'scogoorange',
        check: (
            {
                accepted_sp_id,
                requestRaised,
                closedByNoc,
                is_deleted,
                hardClose,
                reachedSite,
                signOffRequest,
                ticket_owned_by,
                assignToScogo,
                isOnHold,
                isEscalated,
                signOffReject,
            },
            { role, type }
        ) => {
            if (isScm(role, type)) {
                return (
                    accepted_sp_id === 0 &&
                    requestRaised === 1 &&
                    closedByNoc <= 0 &&
                    is_deleted === 0 &&
                    hardClose === 0 &&
                    reachedSite === 0 &&
                    signOffRequest === 0 &&
                    isOnHold === 0 &&
                    isEscalated === 0 &&
                    signOffReject === 0
                );
            } else if (isCustomerGroup(role, type)) {
                return (
                    accepted_sp_id === 0 &&
                    closedByNoc <= 0 &&
                    is_deleted === 0 &&
                    hardClose === 0 &&
                    reachedSite === 0 &&
                    signOffRequest === 0 &&
                    signOffReject === 0 &&
                    ((ticket_owned_by === 0 && requestRaised === 1) || (ticket_owned_by === 1 && assignToScogo === 1)) &&
                    isOnHold === 0 &&
                    isEscalated === 0
                );
            }
            return false;
        },
    },
    {
        key: 'planned',
        label: 'Planned',
        tag: 'planned',
        color: 'scogoprimary',
        check: ({ accepted_sp_id, reachedSite, closedByNoc, is_deleted, hardClose, signOffRequest, signOffAccept, isOnHold, isEscalated, signOffReject }, { role, type }) => {
            if (isSp(role, type) || isFe(role, type)) {
                return false;
            }
            return (
                signOffRequest === 0 &&
                signOffAccept === 0 &&
                accepted_sp_id !== 0 &&
                reachedSite === 0 &&
                closedByNoc <= 0 &&
                is_deleted === 0 &&
                hardClose === 0 &&
                isOnHold === 0 &&
                isEscalated === 0 &&
                signOffReject === 0
            );
        },
    },
    {
        label: 'Ongoing',
        tag: 'ongoing',
        color: 'scogobgsky',
        check: ({ signOffRequest, reachedSite, closedByNoc, is_deleted, hardClose, signOffAccept, isOnHold, isEscalated, signOffReject }) => {
            return (
                signOffRequest === 0 &&
                reachedSite === 1 &&
                closedByNoc <= 0 &&
                is_deleted === 0 &&
                hardClose === 0 &&
                signOffAccept === 0 &&
                isOnHold === 0 &&
                isEscalated === 0 &&
                signOffReject === 0
            );
        },
    },
    {
        key: 'on_hold',
        label: 'OnHold',
        tag: 'on_hold',
        color: 'scogoclosed',
        textTittle: 'Reason',
        check: ({ isOnHold, isEscalated, is_deleted, signOffAccept, hardClose }) =>
            (isOnHold === 1 || isEscalated === 1) && is_deleted === 0 && signOffAccept === 0 && hardClose === 0,

        getHoverText: ({ remarks } = {}) => {
            let holdRemark = ''
            if (Array.isArray(remarks)) {
                remarks.sort((a, b) => {
                    return new Date(a.created_at) - new Date(b.created_at);
                })
                const findElement = remarks.filter(remark => remark.event_master_id === 45)
                holdRemark = findElement[findElement.length - 1]?.remark ? findElement[findElement.length - 1]?.remark : ''

            }
            return holdRemark
        }
    },

    {
        key: 'sign_off_rejected',
        tag: 'sign_off_rejected',
        label: 'Sign Off Rejected',
        color: 'scogoclosed',
        textTittle: 'Reason',
        check: ({ signOffReject, is_deleted, signOffAccept, hardClose, isOnHold, isEscalated }) =>
            signOffReject === 1 && is_deleted === 0 && signOffAccept === 0 && hardClose === 0 && isOnHold === 0 && isEscalated === 0,
        getHoverText: ({ pm_rejection_message } = {}) => {

            return pm_rejection_message
        }
    },
    {
        label: 'Sign Off Requested',
        tag: 'sign_off_requested',
        labelClass: 'bg-scogoLightOrange font-medium py-1',
        shownNobgLabel: true,
        color: 'scogoorange',
        check: ({ signOffAccept, signOffRequest, is_deleted, hardClose, isOnHold, isEscalated, signOffReject }) =>
            signOffAccept === 0 && signOffRequest === 1 && is_deleted === 0 && hardClose === 0 && isOnHold === 0 && isEscalated === 0 && signOffReject === 0,


    },
    {
        key: 'site_complete',
        label: 'Site Complete',
        tag: 'site_complete',
        color: 'scogobgsky',
        check: ({ signOffAccept, is_deleted, hardClose, closedByNoc, isOnHold, isEscalated, signOffReject }) =>
            closedByNoc > 0 && is_deleted === 0 && hardClose === 0 && signOffAccept === 0 && isOnHold === 0 && isEscalated === 0 && signOffReject === 0,
    },
    {
        label: 'Hard Closed',
        tag: 'hard_closed',
        color: 'scogoclosed',
        check: ({ hardClose, is_deleted }, { role, type }) => {
            if (isSp(role, type) || isFe(role, type)) {
                return false;
            }
            return hardClose === 1 && is_deleted === 0;
        },
        getHoverText: ({ job_sign_off_notes } = {}) => {
            return validateStringValue(job_sign_off_notes);
        },
        textTittle: 'Reason',
    },

    {
        label: 'Revisit',
        tag: 'revisit',
        color: 'scogobgsky',
        check: ({ signOffRequest, isEscalated, isOnHold, revisitCount, is_revisit_required, hardClose, is_deleted }) => {
            return signOffRequest === 0 && (isEscalated === 1 || isOnHold === 1) && revisitCount === 1 && is_revisit_required === 1 && hardClose === 0 && is_deleted === 0;
        },
    },
    {
        label: 'Cancelled',
        tag: 'cancelled',
        color: 'scogo99',
        check: ({ is_deleted }) => {
            return is_deleted === 1;
        },
    },
];
// TODO check keys to keyur 
const isShowPendingDelivery = ({
    hard_copy_required,
    hard_copy_received,
    isEscalated,
    po_raised,
    signOffAccept,
    signOffRequest,
}) => {
    const basicConditon =
        isEscalated === 0 && po_raised === 0 && signOffAccept === 0 && signOffRequest === 1;

    if (hard_copy_required === 1 && hard_copy_received === 0) {
        return basicConditon;
    }

    return false;
};

export const actionLabelList = [
    {
        label: 'Pending Customer',
        icon: 'pending_actions',
        color: 'scogoorange',
        check: ({ signOffRequest, customerSignOffRequired, customerSignOffAccept, po_raised, accepted_fe_id, reachedSite }, { loggedUserRole }) => {
            return (
                SCM.role === loggedUserRole &&
                accepted_fe_id !== 0 &&
                reachedSite === 1 &&
                signOffRequest === 1 &&
                customerSignOffAccept === 0 &&
                customerSignOffRequired === 1 &&
                po_raised === 0
            );
        },
    },
    {
        label: 'Pending Scogo',
        icon: 'pending_actions',
        color: 'scogoorange',
        check: (
            { accepted_fe_id, reachedSite, signOffAccept, assignToScogo, signOffRequest, accepted_sp_id, customerSignOffRequired, customerSignOffAccept, ticket_owned_by },
            { loggedUserRole }
        ) => {
            return (
                accepted_fe_id !== 0 &&
                reachedSite === 1 &&
                signOffAccept === 0 &&
                signOffRequest === 1 &&
                accepted_sp_id !== 0 &&
                ((customerSignOffRequired === 1 && customerSignOffAccept === 1) || customerSignOffRequired === 0) &&
                (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1)) &&
                [CUSTOMER.role, CUSTOMER_ADMIN.role, CUSTOMER_USER.role].includes(loggedUserRole)
            );
        },
    },
    {
        label: 'Pending Cluster',
        icon: 'pending_actions',
        color: 'scogoorange',
        check: ({ signOffRequest, ticketType, accepted_fe_id, signOffAccept, reachedSite, isEscalated, serviceTypeId }) => {
            return (
                signOffRequest === 1 &&
                accepted_fe_id !== 0 &&
                signOffAccept === 0 &&
                reachedSite === 1 &&
                isEscalated === 0 &&
                ticketType === ticketTypes.support &&
                serviceTypeId === serviceTypeIds.migration
            );
        },
    },
    {
        label: 'Pending Docket',
        icon: 'local_shipping',
        color: 'scogoorange',
        check: (ticket) => {
            return isPendingDocket(ticket) && ticket.signOffRequest === 0;
        },
    },
    {
        label: 'Pending Delivery',
        icon: 'car_crash',
        color: 'scogobgsky',
        check: (ticket) => {
            return isShowPendingDelivery(ticket);
        },
    },

    {
        label: 'Closed',
        color: 'black',
        labelClass: 'bg-labelCloseBg border border-black text-black',
        check: ({ signOffAccept, signOffRequest, signOffReject, hardClose }) => (signOffAccept === 1 && signOffRequest === 1 && signOffReject === 0) || hardClose === 1,
    },
    {
        color: 'scogo2e',
        check: () => {
            return true;
        },
    },
];

export const ticketTypeList = [
    { label: 'Migration', color: 'scogoBasicYellow', tag: 'migration', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.migration },
    { label: 'Reconcile', color: 'scogoBasicYellow', tag: 'reconcile', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.reconcile },
    { label: 'Feasibility', color: 'scogoBasicYellow', tag: 'feasibility', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.feasibility },
    { label: 'Release Asset', color: 'scogoBasicYellow', tag: 'release_asset', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.release_asset },
    { label: 'Scrap Asset', color: 'scogoBasicYellow', tag: 'scrap_asset', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.scrap_asset },
    { label: 'Asset Replace', color: 'scogoBasicYellow', tag: 'asset_replace', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.asset_replace },
    {
        label: 'RMA Pending',
        color: 'scogobgsky',
        hoverText: 'RMA',
        tag: 'rma_pending',
        check: ({ isRmaRaised, ticketType, cl_rma_response, is_deleted, hardClose, isOnHold, isEscalated, signOffReject, signOffAccept }) =>
            ticketType === ticketTypes.field &&
            isRmaRaised === 1 &&
            ['', undefined, null].includes(cl_rma_response) &&
            is_deleted === 0 &&
            hardClose === 0 &&
            isOnHold === 0 &&
            signOffAccept === 0
    },
    {
        label: 'RMA Updated',
        color: 'scogoorange',
        hoverText: 'RMA',
        tag: 'rma_updated',
        check: ({ isRmaRaised, ticketType, cl_rma_response, is_deleted, hardClose, isOnHold, isEscalated, signOffReject, signOffAccept }) =>
            ticketType === ticketTypes.field &&
            isRmaRaised === 1 &&
            !['', undefined, null].includes(cl_rma_response) &&
            is_deleted === 0 &&
            hardClose === 0 &&
            isOnHold === 0
    },
    { label: 'Support', color: 'scogoBasicYellow', tag: 'support', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.support },
    { label: 'RL', color: 'scogobgsky', tag: 'rl', check: ({ serviceTypeId }) => serviceTypeId === serviceTypeIds.reverse_logistics },
    { label: 'PO', color: 'scogoBasicYellow', tag: 'po', check: ({ po_raised }) => po_raised === 1 },
];

export const progressStatusList = [
    {
        label: 'SP Unassigned',
        color: 'scogoclosed',
        check: (ticket, { role, type }) => {
            const { fk_assigned_primary_sp_id, requestRaised, closedByNoc, is_deleted, hardClose, accepted_sp_id, reachedSite, assignToScogo, ticket_owned_by } = ticket;
            if (isCluster(role, type) || isSuperVisor(role, type) || isPsp(role, type)) {
                return accepted_sp_id === 0 && requestRaised === 1 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && reachedSite === 0;
            } else if (isCustomerGroup(role, type)) {
                return (
                    fk_assigned_primary_sp_id === 0 &&
                    requestRaised === 0 &&
                    closedByNoc === 0 &&
                    is_deleted === 0 &&
                    hardClose === 0 &&
                    (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 0))
                );
            }
            return fk_assigned_primary_sp_id === 0 && requestRaised === 0 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0;
        },
        statusComplete: `0`,
    },
    {
        label: 'Assign To Scogo',
        color: 'scogoBasicYellow',
        check: (ticket, { role, type }) => {
            const { fk_assigned_primary_sp_id, requestRaised, closedByNoc, is_deleted, hardClose, assignToScogo, ticket_owned_by } = ticket;
            if (isCustomerGroup(role, type)) {
                return (
                    fk_assigned_primary_sp_id === 0 &&
                    requestRaised === 0 &&
                    closedByNoc === 0 &&
                    is_deleted === 0 &&
                    hardClose === 0 &&
                    (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1))
                );
            }
            return false;
        },
        statusComplete: `10`,
    },
    {
        label: 'Waiting for SP Ticket Acceptance',
        color: 'scogoBasicYellow',
        check: (ticket, { role, type }) => {
            const { accepted_sp_id, requestRaised, closedByNoc, is_deleted, hardClose, reachedSite, signOffRequest } = ticket;
            if (isSp(role, type) || isFe(role, type)) return false;
            return accepted_sp_id === 0 && requestRaised === 1 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && reachedSite === 0 && signOffRequest === 0;
        },
        statusComplete: `10`,
    },
    {
        label: 'FE Accepted Ticket But Not Reached Site',
        color: 'scogoorange',
        check: (ticket, { role, type }) => {
            const { accepted_sp_id, signOffAccept, closedByNoc, is_deleted, hardClose, reachedSite, signOffRequest, accepted_fe_id } = ticket;
            if (isSp(role, type) || isFe(role, type)) {
                return false;
            }
            return (
                signOffRequest === 0 &&
                signOffAccept === 0 &&
                accepted_sp_id !== 0 &&
                reachedSite === 0 &&
                closedByNoc === 0 &&
                is_deleted === 0 &&
                hardClose === 0 &&
                accepted_fe_id !== 0
            );
        },
        statusComplete: `20`,
    },
    {
        label: 'FE Reached Site / Work In Progress',
        color: 'scogoorange',
        check: (ticket) => {
            const { signOffAccept, signOffReject, closedByNoc, is_deleted, hardClose, reachedSite, signOffRequest } = ticket;
            return signOffRequest === 0 && reachedSite === 1 && closedByNoc === 0 && is_deleted === 0 && hardClose === 0 && signOffAccept === 0 && signOffReject === 0;
        },
        statusComplete: `40`,
    },
    {
        label: 'Ticket On Hold',
        color: 'scogoclosed',
        check: (ticket) => {
            const { signOffAccept, isOnHold, is_deleted, hardClose, isEscalated } = ticket;
            return (isOnHold === 1 || isEscalated === 1) && is_deleted === 0 && signOffAccept === 0 && hardClose === 0;
        },
        statusComplete: `50`,
    },
    {
        label: (ticket, { onClick }) => <RenderRemark ticket={ticket} onClick={onClick} />, // Site Complete
        color: '',
        check: (ticket) => {
            const { signOffAccept, closedByNoc, is_deleted, hardClose, signOffRequest } = ticket;
            return closedByNoc > 0 && is_deleted === 0 && hardClose === 0 && signOffAccept === 0 && signOffRequest === 0;
        },
        statusComplete: `50`,
    },
    {
        label: (ticket, { onClick }) => <RenderRemark ticket={ticket} onClick={onClick} />, // Signoff Request
        color: '',
        check: (ticket) => {
            const { signOffAccept, signOffRequest, is_deleted, hardClose } = ticket;
            return signOffAccept === 0 && signOffRequest === 1 && is_deleted === 0 && hardClose === 0;
        },
        statusComplete: `80`,
    },
    {
        label: (ticket, { onClick }) => <RenderRemark ticket={ticket} onClick={onClick} />, // Sign off Approved
        color: '',
        check: (ticket) => {
            const { signOffAccept, signOffRequest, is_deleted, hardClose } = ticket;
            return signOffAccept === 1 && signOffRequest === 1 && is_deleted === 0 && hardClose === 0;
        },
        statusComplete: `100`,
    },
];

const isEligibleForAssignTechSupport = ({ signOffAccept, signOffRequest, closedByNoc, ticket_owned_by, assignToScogo, role, type }) => {
    if (isScm(role, type) || isCustomerAdmin(role, type) || isCustomer(role, type)) return signOffAccept === 0 && closedByNoc <= 0 && signOffRequest === 0 && (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1));
    return false;
};

const isEligibleForCustomerApprovalRecieved = ({ ticket_owned_by, assignToScogo, role, type }) => {
    if (isScm(role, type)) return ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1);
    return false;
};

export const isEligibleForEditExecutionDate = ({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type, closedByNoc, accepted_sp_id, sign_off_lock }) => {
    let base;
    if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type)) {
        base = signOffAccept === 0 && signOffRequest === 0 && (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1)) && sign_off_lock === 0;
        if (closedByNoc > 0) {
            base = base && accepted_sp_id === 0;
            return base;
        }
        return base;
    }
    if (isCustomer(role, type) || isCustomerAdmin(role, type)) {
        base = signOffAccept === 0 && signOffRequest === 0 && ticket_owned_by === 1 && assignToScogo === 0;
        if (closedByNoc > 0) {
            base = base && accepted_sp_id === 0;
            return base;
        }
        return base;
    }
    return false;
};

const isEligibleForShareFeasibilityLink = ({ serviceTypeId, signOffAccept, role, type }) =>
    signOffAccept === 0 && serviceTypeId === serviceTypeIds.feasibility && (isScm(role, type) || isCluster(role, type) || isCustomerGroup(role, type));

const isEligibleForrequestToTrackFe = ({ is_live_tracking_enabled, execution_date, reachedSite, ticketType, accepted_fe_id }) => {
    let executionDate = moment(execution_date).format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    return today === executionDate && is_live_tracking_enabled !== 1 && reachedSite === 0 && ticketType === 0 && accepted_fe_id !== 0;
};

export const isToShowHoldIcon = ({ signOffRequest, isOnHold, isEscalated }) => {
    return signOffRequest === 0 && isOnHold === 0 && isEscalated === 0;
};


export const isToShowResumeIcon = ({ isEscalated, isOnHold, signOffRequest }, { role, type }) => {
    return signOffRequest === 0 && (isOnHold === 1 || isEscalated === 1) && (isCustomerGroup(role, type) || isScm(role, type) || isCluster(role, type));
};
export const isToShowBulkResumeIcon = ({ isEscalated, isOnHold, signOffRequest }) => {
    return signOffRequest === 0 && (isOnHold === 1 || isEscalated === 1)
};

export const isShoHardCloseIcon = ({ signOffRequest }, { role, type }) => {
    return signOffRequest === 0 && (isCustomerGroup(role, type) || isScm(role, type));
};

const isEligibleForDeleteTicket = ({ reachedSite, signOffRequest, isOnHold, isEscalated, closedByNoc }) => {
    return reachedSite === 0 && signOffRequest === 0 && isOnHold === 0 && isEscalated === 0 && closedByNoc === 0;
};

const isEligibleForTrackFe = ({ is_live_tracking_enabled, execution_date, reachedSite, ticketType }) => {
    let executionDate = moment(execution_date).format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    return today === executionDate && is_live_tracking_enabled === 1 && reachedSite === 0 && ticketType === 0;
};
const isPoRaised = ({ po_raised }) => po_raised === 1;
const isEligibleForupdatesignoff = ({ signOffRequest, job_sign_off_notes }) => signOffRequest === 1 && !['', null, undefined].includes(job_sign_off_notes);

const updateRmaDetails = {
    key: 'update_rma_details',
    conditionalRendering: (ticket) => {
        const { cl_rma_response } = ticket;
        if (cl_rma_response) {
            return { menuName: 'Edit RMA Details', menuIcon: 'app_registration', iconColor: 'text-scogoprimary' };
        }
        return { menuName: 'Update RMA Details', menuIcon: 'app_registration', iconColor: 'text-scogoprimary' };
    },
    onClick: (ticket, dispatch) => {
        const { cl_rma_response } = ticket;
        return dispatch(
            openFullscreenModal({
                heading: (
                    <span className='text-black'>
                        {cl_rma_response ? 'Edit RMA Details' : 'Update RMA Details'} :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
                    </span>
                ),
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: <UpdateRmaDetails ticketId={ticket.ticket_id} heading={"Update RMA Details"} />,
            })
        );
    },
};

const shareFeasibilityLink = {
    key: 'share_feasibility_link',
    menuName: 'Share Feasibility Link',
    menuIcon: 'share',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        const isps = [];
        return dispatch(
            openCustomModal({
                heading: 'Share Feasibility Link',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <ShareFeasibilityLink ticketId={ticket.ticket_id} isps={isps} />,
            })
        );
    },
    isDisabled: (ticket, { loggedUser }) => {
        return { disabled: ticket.signOffAccept === 1, message: "Can't Share" };
    },
};

const assignTechSupport = {
    key: 'assign_tech_support',
    menuName: 'Assign Tech Support',
    menuIcon: 'support_agent',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch) => {
        let ticketId = ticket.ticket_id;
        let heading = (
            <span className='text-black'>
                Assign Tech Support :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
            </span>
        );

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <AssignTechSupport ticketId={ticketId} isBulk={false} />,
            })
        );
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        const { signOffAccept, signOffRequest, closedByNoc, ticket_owned_by, assignToScogo } = ticket;
        return { disabled: !isEligibleForAssignTechSupport({ signOffAccept, signOffRequest, closedByNoc, ticket_owned_by, assignToScogo, role, type }), message: 'You are not eligible to perform this action' };
    },
};

const customerApprovalRecieved = {
    key: 'customer_approval_recieved',
    menuName: 'Customer Approval Recieved',
    menuIcon: 'receipt',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        let ticketIds = [];
        let ticketId = ticket.ticket_id;
        let heading = (
            <span className='text-black'>
                Customer Approval Recieved :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
            </span>
        );

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <CustomerApprovalReceived ticketId={ticketId} isBulk={false} ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        const { signOffAccept, signOffRequest, ticket_owned_by, assignToScogo } = ticket;
        return { disabled: !isEligibleForCustomerApprovalRecieved({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type }), message: '' };
    },
};
export const deleteTicket = {
    key: 'delete_ticket',
    menuName: 'Delete Ticket',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (ticket, dispatch) => {
        let formName = 'deleteTicket';
        let heading = 'Delete Ticket';
        let text = `Are you sure you want to Delete Ticket?`;
        let data = { ticketIds: [ticket.ticket_id] };
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'red',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(deleteTicketsRequest({ data, formName })),
            },
            {
                textOrComponent: 'No',
                type: 'blue',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        let closeOnBackdropClick = false;

        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
    },
    isDisabled: ({ reachedSite, signOffRequest, isOnHold, isEscalated, closedByNoc }, _) => {
        return {
            disabled: !isEligibleForDeleteTicket({ reachedSite, signOffRequest, isOnHold, isEscalated, closedByNoc }),
            message: 'Delete At This Stage Is Not Allowed',
        };
    },
};

const siteComplete = {
    key: 'site_complete',
    menuName: 'Site Complete',
    menuIcon: 'domain',
    iconColor: 'text-scogoprimary',

    onClick: (ticket, dispatch, { loggedUser }) => {

        const type = loggedUser.type;
        const role = loggedUser.role.id;
        if (isScm(role, type) && !ticket?.rating?.id && ticket.accepted_fe_id !== 0 && ticket.accepted_sp_id !== 0) {
            let heading = `Are you sure your site is completed?`;
            let modalComponent = <FeRating ticketId={ticket.ticket_id} isSiteCompletd={true} submitButtonText={'Yes'} cancelButtonText={'No'} />;

            let modalWidth = '45rem',
                modalHeight = 'auto';
            dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
        } else {
            let formName = 'siteCompleteformName';
            let heading = 'Site Completed';
            let text = `Are you sure you site is completed ?`;
            let buttons = [
                {
                    showLoading: true,
                    textOrComponent: 'Yes',
                    type: 'blue',
                    onClick: () => dispatch(siteCompleted({ ticketId: ticket.ticket_id, formName })),
                },
                {
                    textOrComponent: 'No',
                    type: 'red',
                    buttonClass: 'hover:bg-scogoLightFillRed',
                    onClick: () => dispatch(closeModalAction()),
                },
            ];
            let closeOnBackdropClick = false;

            dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
        }
    },
};

const releaseTicket = {
    key: 'release_ticket',
    menuName: 'Release ticket',
    menuIcon: 'lock_reset',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch) => {
        let formName = 'release_modal';
        let heading = 'Release Ticket';
        let text = `Are you sure you want to continue ?`;
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'blue',
                onClick: () => dispatch(withdrawTicket({ data: { ticket_id: ticket?.ticket_id }, formName, refresh: true })),
            },
            {
                textOrComponent: 'No',
                type: 'red',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        let closeOnBackdropClick = false;

        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
    },
};

const editExecutionDate = {
    key: 'edit_execution_date',
    menuName: 'Edit Execution Date',
    menuIcon: 'edit_calendar',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch) => {
        let ticketId = ticket.ticket_id;
        let heading = (
            <span className='text-black'>
                Edit Execution Date :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
            </span>
        );

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <EditExecutionDate ticketId={ticketId} isBulk={false} current_execution_date={ticket.current_execution_date} />,
            })
        );
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        const { signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, closedByNoc, accepted_sp_id, sign_off_lock } = ticket;
        return {
            disabled: !isEligibleForEditExecutionDate({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type, closedByNoc, accepted_sp_id, sign_off_lock }),
            message: 'Execution Date Edit Is Not Allowed',
        };
    },
};
const editTicket = {
    key: 'edit_ticket',
    menuName: 'Edit Ticket',
    menuIcon: 'edit',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { navigate }) => {
        navigate(`/tickets/${ticket.ticket_id}/edit`);
    },
};

const executeTicket = {
    key: 'execute_ticket',
    menuName: 'Execute Ticket',
    menuIcon: 'confirmation_number',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        let path = `/tickets/${ticket.ticket_id}/execute/initiate`;
        if (ticket.signOffRequest === 1) {
            path = `${path}?editWorkFlow=true`;
        }
        window.open(path);
    },
};

const updateDigitalForm = {
    key: 'update_digital_form',
    menuName: 'Update Digital Form',
    menuIcon: 'confirmation_number',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        const path = `/tickets/${ticket.ticket_id}/execute/initiate?editSignOffForm=true`;
        window.open(path);
    },
};

export const approvePo = {
    key: 'approve_po',
    menuName: 'Approve po',
    menuIcon: 'fact_check',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        let modalComponent = <PoApproval ticketId={ticket.ticket_id} />;
        dispatch(openFullscreenModal({ modalComponent }));
    },
};

export const feasibilityAction = {
    key: 'feasibility',
    menuName: 'Feasibility',
    menuIcon: 'cell_tower',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        let heading = 'Close Feasibility';
        let isOnlyView = false;
        if (ticket.signOffAccept === 1) {
            heading = 'View Feasibility';
            isOnlyView = true;
        }
        let modalComponent = <CloseFeasibility isOnlyView={isOnlyView} ticketId={ticket?.ticket_id} heading={heading} />;
        dispatch(openFullscreenModal({ heading, modalComponent }));
    },
};

export const updateHardCopyStatus = {
    key: 'update_docket',
    menuName: 'HardCopy Status',
    menuIcon: 'local_shipping',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        const formName = 'hard_copy_received';
        let heading = 'Hard Copy Received?';
        let text = `Are you sure you have received hard copy?`;
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'blue',
                buttonClass: 'hover:bg-scogobgsky',
                onClick: () =>
                    dispatch(
                        updateDocketDetails({
                            data: { action: 'HARD_COPY_RECEIVED', ticket_id: ticket?.ticket_id },
                            formName,
                            callCloseTicket: false
                        })
                    ),
            },
            {
                textOrComponent: 'No',
                type: 'redOutlined',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(closeModalAction()),
            },
        ];

        let closeOnBackdropClick = false;
        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName }));
    },
};

const raisePO = {
    key: 'raise_po',
    menuName: 'Raise PO',
    menuIcon: 'post_add',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        const identification = validateStringValue(ticket?.job_ticket_number);
        const heading = (
            <>
                <span className='text-black'>
                    Raise PO |&nbsp;<span className='text-scogobgsky text-font16'>{identification}</span>
                </span>
            </>
        );
        return dispatch(
            openFullscreenModal({
                modalComponent: <RaisePo ticketId={ticket.ticket_id} isRefresh heading={heading} />,
            })
        );
    },
};

const updatePo = {
    key: 'update_po',
    menuName: 'Update Po',
    menuIcon: 'edit_note',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openFullscreenModal({
                modalComponent: <RenderUpdatePo ticketId={ticket.ticket_id} ticket={ticket} isRefresh />,
            })
        );
    },
};

const addNewPincode = {
    key: 'add_new_pincode',
    menuName: 'Add New Pincode',
    menuIcon: 'add_circle_outline',
    iconColor: 'text-scogobgsky',
    onClick: (detail, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Add New Pincode',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <AddNewPincode />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const bulkCloneTicket = {
    key: 'bulk_clone_ticket',
    menuName: 'Bulk Clone Ticket',
    menuIcon: 'content_copy',
    iconColor: 'text-scogobgsky',
    onClick: (_, dispatch, { getSelectedTickets } = {}) => {
        const formName = 'bulkCloneTicket';
        let ticketIds = [];
        let tickets = getSelectedTickets();
        tickets = tickets.filter((ticket) => ticket.ticketType === ticketTypes.field);
        if (tickets.length > 0) {
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openTextModalAction({
                heading: 'Bulk Clone',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                selectedCount: ticketIds.length,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(cloneTickets({ formName, ticketsList: ticketIds, refresh: true }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (_, { getSelectedTickets = () => { } } = {}) => {
        return false;
    },
};

const bulkDeleteTicket = {
    key: 'bulk_delete_ticket',
    menuName: 'Delete Tickets',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        let formName = 'deleteTickets';
        let heading = 'Delete Tickets';
        let text = `Are you sure you want to Delete Tickets?`;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        tickets = tickets.filter((ticket) => {
            const { ticket_owned_by, assignToScogo, reachedSite } = ticket;
            return isEligibleForDelete(role, type, { ticket_owned_by, assignToScogo, reachedSite });
        });
        if (tickets.length > 0) {
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'red',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(deleteTicketsRequest({ data: { ticketIds: ticketIds }, formName })),
            },
            {
                textOrComponent: 'No',
                type: 'blue',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        let closeOnBackdropClick = false;

        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName, selectedCount: ticketIds.length }));
    },
};


const bulkUpdateHardCopyStatus = {
    key: 'bulk_update_hardcopy_recieved_status',
    menuName: 'HardCopy Status',
    menuIcon: 'local_shipping',
    iconColor: 'text-scogoorange',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        let formName = 'bulk_update_hardcopy_recieved_status';
        let heading = 'Hard Copy Received?';
        let text = `Are you sure you have received hard copy?`;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        if (tickets.length === 0) {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }
        tickets = tickets.filter((ticket) => {
            return getIsToShowPendingDelivery({ ticket, role, type })
        });
        if (tickets.length > 0) {
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Selected tickets are not eligible for update hardcopy status', status: 'warning' }));
        }
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'blue',
                buttonClass: 'hover:bg-scogobgsky',
                onClick: () =>
                    dispatch(
                        bulkHardCopyRecievedUpdate({
                            ticketIds,
                            formName,
                        })
                    ),
            },
            {
                textOrComponent: 'No',
                type: 'redOutlined',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        let closeOnBackdropClick = false;
        dispatch(openTextModalAction({ heading, text, buttons, closeOnBackdropClick, formName, selectedCount: ticketIds.length }));
    },
};

const bulkUpdateTicketDates = {
    key: 'bulk_update_ticket_dates',
    menuName: 'Update Ticket Dates',
    menuIcon: 'calendar_month',
    iconColor: 'text-scogobgsky',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        return dispatch(
            openCustomModal({
                heading: 'Update Ticket Dates',
                modalWidth: '30rem',
                modalHeight: 'auto',
                modalComponent: <UpdateTicketDate />,
            })
        );
    },
};

const bulkUploadOfflineTransfer = {
    key: 'bulk_upload_offline_transaction',
    menuName: 'Bulk Upload Offline Transaction',
    menuIcon: 'upload',
    iconColor: 'text-scogobgsky',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Offline Transaction',
                modalWidth: '40rem',
                modalHeight: 'auto',
                modalComponent: <BulkUploadOfflineTransaction />,
            })
        );
    },
};

const requestToTrackFE = {
    key: 'request_to_track_fe',
    menuName: 'Request to track FE',
    menuIcon: 'directions_bike',
    iconColor: 'text-yellow-500',
    onClick: (ticket, dispatch) => {
        let formName = 'requesttotrackFE';
        let heading = 'Request to track FE';
        let text = `Are you sure you want to sent request to track FE ?`;
        let buttons = [
            {
                showLoading: true,
                textOrComponent: 'Yes',
                type: 'red',
                buttonClass: 'hover:bg-scogoLightFillRed',
                onClick: () => dispatch(requestTrackFE({ ticketId: ticket?.ticket_id, formName })),
            },
            {
                textOrComponent: 'No',
                type: 'blue',
                onClick: () => dispatch(closeModalAction()),
            },
        ];
        dispatch(openTextModalAction({ heading, text, buttons, formName }));
    },
};

const trackFe = {
    key: 'track_fe',
    menuName: 'Track Fe',
    menuIcon: 'directions_bike',
    iconColor: 'text-scogosuccess',
    onClick: (ticket, dispatch, { navigate } = {}) => {
        if (ticket?.live_tracking_url) {
            window.open(ticket?.live_tracking_url);
        } else {
            dispatch(sendToastMessage({ message: 'Tracking Details Not Found', status: 'warning' }))
        }
    },
};

const bulkAssignTechSupport = {
    key: 'bulk_assign_tech_support',
    menuName: 'Bulk Assign Tech Support',
    menuIcon: 'support_agent',
    iconColor: 'text-scogoorange',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        let heading = 'Bulk Assign Tech Support';
        if (tickets.length > 0) {
            tickets = tickets.filter((ticket) => {
                const { signOffRequest, signOffAccept, closedByNoc, ticket_owned_by, assignToScogo } = ticket;
                return isEligibleForAssignTechSupport({ signOffAccept, signOffRequest, closedByNoc, ticket_owned_by, assignToScogo, role, type });
            });
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Tech Support', status: 'warning' }));
            }
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <AssignTechSupport ticketIds={ticketIds} isBulk={true} />,
            })
        );
    },
    isDisabled: (ticket, { getSelectedTickets = () => { } } = {}) => {
        return false;
    },
};

const bulkCustomerApprovalRecieved = {
    key: 'bulk_customer_approval_recieved',
    menuName: 'Bulk Customer Approval Recieved',
    menuIcon: 'receipt',
    iconColor: 'text-scogoprimary',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        let heading = 'Bulk Customer Approval Recieved';

        if (tickets.length > 0) {
            tickets = tickets.filter((ticket) => {
                const { signOffAccept, signOffRequest, ticket_owned_by, assignToScogo } = ticket;
                return isEligibleForCustomerApprovalRecieved({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type });
            });
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Customer Approval Recieved Action', status: 'warning' }));
            }
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <CustomerApprovalReceived isBulk={true} ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const bulkEditExecutionDate = {
    key: 'bulk_edit_execution_date',
    menuName: 'Bulk Edit Execution Date',
    menuIcon: 'edit_calendar',
    iconColor: 'text-scogoorange',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        let heading = 'Bulk Edit Execution Date';
        if (tickets.length > 0) {
            tickets = tickets.filter((ticket) => {
                const { signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, closedByNoc, accepted_sp_id, sign_off_lock } = ticket;
                return isEligibleForEditExecutionDate({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type, closedByNoc, accepted_sp_id, sign_off_lock });
            });
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Edit Execution Date', status: 'warning' }));
            }
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <EditExecutionDate isBulk={true} ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const bulkAssign = {
    key: 'bulk_assign',
    menuName: 'Bulk Assign',
    menuIcon: 'manage_accounts',
    iconColor: 'text-scogoToastSuccess',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        const role = loggedUser.role.id;
        const type = loggedUser.type;
        const frontend_controller = loggedUser.frontend_controller;
        let ticketIds = [];
        let tickets = getSelectedTickets();
        let heading = 'Bulk Assign';
        if (tickets.length > 0) {
            tickets = tickets.filter((ticket) => {
                return getIsShowAssignTicketButton({ role, type, ticket, frontend_controller }) && ticket.accepted_sp_id === 0;
            });
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });

            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Assign', status: 'warning' }));
            }
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <AssignSP isBulk={true} isReassign={false} ticketIds={ticketIds} ticket={tickets[0]} />,
            })
        );
    },
    isDisabled: (partner) => {
        return false;
    },
};

const bulkHoldTicket = {
    key: 'bulk_hold_ticket',
    menuName: 'Bulk Hold Tickets',
    menuIcon: 'pause',
    iconColor: 'text-scogoorange',
    onClick: (_, dispatch, { getSelectedTickets } = {}) => {
        let ticketIds = [];
        let tickets = getSelectedTickets();
        tickets = tickets.filter((ticket) => {
            return isToShowHoldIcon(ticket);
        });
        let heading = 'Bulk Hold Tickets';
        if (tickets.length > 0) {
            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Assign', status: 'warning' }));
            }
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <HoldTicket isBulk={true} ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: () => {
        return false;
    },
};
const bulkResumeTicket = {
    key: 'bulk_resume_ticket',
    menuName: 'Bulk Resume Tickets',
    menuIcon: 'play_arrow',
    iconColor: 'text-scogoorange',
    onClick: (_, dispatch, { getSelectedTickets } = {}) => {
        let ticketIds = [];
        let tickets = getSelectedTickets();

        tickets = tickets.filter((ticket) => {
            return isToShowBulkResumeIcon(ticket);
        });
        let heading = 'Bulk Resume Tickets'
        if (tickets.length > 0) {
            if (tickets.length === 0) {

                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Assign', status: 'warning' }));
            }
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading,
                modalWidth: '60rem',
                modalHeight: 'auto',
                selectedCount: ticketIds.length,
                modalComponent: <ResumeTicket isBulk={true} ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: () => {
        return false;
    },
};
const bulkUploadSite = {
    key: 'bulk_upload_sites',
    menuName: 'Bulk Upload Sites',
    menuIcon: 'upload',
    iconColor: 'text-scogobgsky',
    onClick: (_, dispatch, { getSelectedTickets, loggedUser } = {}) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Sites',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <BulkUploadSites />,
            })
        );
    },
    isDisabled: (partner) => {
        return false;
    },
};

const updateSignOffNotes = {
    key: 'update_sign_off_notes',
    menuName: 'Update SignOff Remark',
    menuIcon: 'note_alt',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Update SignOff Remark',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <UpdateSignOffNotes ticket={ticket} />,
            })
        );
    },
};

const appointment = {
    key: 'appointment',
    menuName: 'Ask Appointment',
    menuIcon: 'note_alt',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Ask Appointment',
                modalWidth: '40rem',
                modalHeight: 'auto',
                modalComponent: <CreateAppointment ticketId={ticket.ticket_id} />,
            })
        );
    },
};
const downloadConfigTemplate = {
    key: 'download_config_template',
    menuName: 'Asset Config Template',
    imgIcon: '/img/zipDownload.png',
    onClick: (ticket, dispatch) => {
        dispatch(getAssetConfigTemplate(ticket?.ticket_id));
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        return {
            disabled: !showDownloadAssetConfig(ticket, { role, type }),
            message: 'No reports for download',
        };
    },
};

const downloadCustomer = {
    key: 'download_customer_report',
    menuName: 'Customer Sign Off Report',
    imgIcon: './img/pdfDownload.png',
    onClick: (ticket, dispatch) => {
        dispatch(downloadCustomerReport({ ticketId: ticket?.ticket_id, attachmentIds: [], isTicketDetails: true, formName: '' }));
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        return {
            disabled: !showDownloadScogoReport(ticket, { role, type }),
            message: 'No reports for download',
        };
    },
};
const downloadScogoReport = {
    key: 'download_scogo_report',
    menuName: 'Scogo Sign Off Report',
    imgIcon: './img/pdfDownload.png',
    onClick: (ticket, dispatch) => {
        dispatch(getTicketReportLink({ ticketId: ticket?.ticket_id }));
        if (ticket.fk_sign_off_template_id > 0) {
            dispatch(updateSignOffSteps({ ticketId: ticket?.ticket_id, download: true }));
        }
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        return {
            disabled: !showDownloadScogoReport(ticket, { role, type, isScogoReport: true }),
            message: 'No reports for download',
        };
    },
};

const addAsset = {
    key: 'add_asset',
    menuName: 'Add Asset',
    menuIcon: 'queue_play_next',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { navigate }) => {
        navigate(`/tickets/${ticket.ticket_id}/edit?addAsset=true`);
    },
};

export const addDocket = {
    key: 'add_docket',
    menuName: 'Pending Docket',
    menuIcon: 'local_shipping',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { navigate }) => {
        window.open(`/tickets/${ticket?.ticket_id}/execute/initiate`);
    },
};

const holdTicket = {
    key: 'hold_ticket',
    menuName: 'Hold Ticket',
    menuIcon: 'pause',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        let heading = <span className='text-black'>Hold Ticket</span>;
        let modalComponent = <HoldTicket ticket={ticket} isBulk={false} />;
        let modalHeight = 'auto',
            modalWidth = '60rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
};

const resumeTicket = {
    key: 'resume_ticket',
    menuName: 'Resume Ticket',
    menuIcon: 'play_arrow',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        let heading = <span className='text-black'>Resume Ticket</span>;
        let modalComponent = <ResumeTicket ticket={ticket} />;
        let modalHeight = 'auto',
            modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
};

const hardCloseTicket = {
    key: 'hard_close_ticket',
    menuName: 'Hard Close',
    menuIcon: 'not_interested',
    iconColor: 'text-scogoclosed',
    onClick: (ticket, dispatch) => {
        let heading = <span className='text-black'>Hard Close Ticket</span>;
        let modalComponent = <HardCloseTicket ticket={ticket} />;
        let modalHeight = 'auto',
            modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
    isDisabled: () => {
        return false;
    },
};

const addPrice = {
    key: 'add_new_price',
    menuName: 'Add Price',
    menuIcon: 'currency_rupee',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { loggedUser, navigate }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        openTicketPaymentModal(ticket, { dispatch, role, type, navigate });
    },
    isDisabled: () => {
        return false;
    },
};

const reAssign = {
    key: 're_assign',
    menuName: 'ReAssign',
    menuIcon: 'manage_accounts',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { loggedUser }) => {
        let heading = (
            <span className='text-black'>
                ReAssign :&nbsp;<span className='text-scogobgsky text-font16'>{ticket?.job_ticket_number}</span>
            </span>
        );
        let modalComponent = <AssignSP ticket={ticket} isReassign={'Assign'} />;
        let modalHeight = 'auto',
            modalWidth = '60rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
    isDisabled: () => {
        return false;
    },
};

const assign = {
    key: 'assign',
    menuName: 'Assign',
    menuIcon: 'manage_accounts',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch, { loggedUser }) => {
        let heading = (
            <span className='text-black'>
                Assign :&nbsp;<span className='text-scogobgsky text-font16'>{ticket?.job_ticket_number}</span>
            </span>
        );
        let modalComponent = <AssignSP ticket={ticket} isReassign={'Assign'} />;
        let modalHeight = 'auto',
            modalWidth = '60rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
    isDisabled: () => {
        return false;
    },
};

const reGenrateScogoReport = {
    key: 'regenerate_scogo_report',
    menuName: 'Regenerate Sign Off Report',
    menuIcon: 'description',
    iconColor: 'text-scogoorange',
    onClick: (ticket, dispatch, { loggedUser } = {}) => {
        let emails = [];
        if (config.name === prodConfig.name) {
            emails.push('nitin@scogo.in');
        } else {
            emails.push('alpesh@scogo.in');
        }
        if (loggedUser) {
            emails.push(loggedUser?.email);
        }
        emails = emails.filter((item) => item !== undefined);
        emails = [...new Set(emails)];
        const payload = {
            ticket_id: ticket.ticket_id,
            emails: [...emails].join(','),
        };
        dispatch(emailReport({ data: payload, downloadReport: true }));
    },
    isDisabled: (ticket, { loggedUser }) => {
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        return {
            disabled: !showDownloadScogoReport(ticket, { role, type }),
            message: 'No reports for download',
        };
    },
};

const unlockSignoffAction = {
    key: 'unlock_signoff',
    menuName: 'Unlock Signoff',
    menuIcon: 'key',
    iconColor: 'text-scogoprimary',
    onClick: (ticket, dispatch) => {
        let heading = (
            <span className='text-black'>
                Unlock Signoff :&nbsp;<span className='text-scogobgsky text-font16'>{ticket?.job_ticket_number}</span>
            </span>
        );
        let modalComponent = <UnlockSignoff ticketId={ticket.ticket_id} />;
        let modalHeight = 'auto',
            modalWidth = '40rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    },
}

const showDownloadScogoReport = (ticket, { role, type, isScogoReport }) => {
    const { ticketType, signOffRequest, hardClose, is_deleted } = ticket;
    if ((isSp(role, type) || isFe(role, type) || isIsp(role, type)) && !isScogoReport) {
        return false;
    }

    return ticketType === 0 && signOffRequest === 1 && hardClose === 0 && is_deleted === 0;
};

const showDownloadAssetConfig = (ticket, { role, type }) => {
    const { asset_config_required, signOffRequest, signOffAccept, hardClose, is_deleted } = ticket;
    return asset_config_required === 1 && signOffAccept === 0 && signOffRequest === 0 && hardClose === 0 && is_deleted === 0;
};

const isEligibleForDelete = (role, type, { ticket_owned_by, assignToScogo, reachedSite }) => {
    if (isCustomer(role, type) || isCustomerAdmin(role, type)) {
        return ticket_owned_by === 1 && assignToScogo === 0 && reachedSite === 0;
    }
    if (isScm(role, type)) {
        return (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1)) && reachedSite === 0;
    }
    return false;
};

const downloadsAllReport = {
    key: 'downloads_reports',
    menuName: 'Downloads Report',
    menuIcon: 'file_download',
    iconColor: 'text-scogobgsky hover:text-scogoorange',
    subMenus: [reGenrateScogoReport, downloadScogoReport, downloadCustomer, downloadConfigTemplate],
};

export const getIsShowAssignTicketButton = ({ role, type, ticket, frontend_controller }) => {
    const { ticket_owned_by, ticketType, isRmaRaised, isEscalated, hardClose, signOffAccept, signOffRequest, closedByNoc, accepted_sp_id, additional_amount_paid, sign_off_lock, assignToScogo } = ticket;

    const ticketOwner = getTicketOwner({ ticket_owned_by, assign_to_scogo: assignToScogo })
    if (ticketOwner === 'Customer') {
        // always giving assign/reassign option when ticket owner is customer
        return true;
    }

    let showAssign = sign_off_lock === 0 &&
        isEscalated === 0 &&
        ((ticketType === ticketTypes.field && isRmaRaised === 0) || (ticketType === ticketTypes.field && isRmaRaised === 1)) &&
        hardClose === 0 &&
        signOffAccept === 0 &&
        signOffRequest === 0 &&
        !(additional_amount_paid > 0)
    if (closedByNoc > 0) {
        showAssign = showAssign && accepted_sp_id === 0;
    }
    if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type)) {
        return showAssign && isAssignAllowed(frontend_controller);
    } else if (isCustomer(role, type) || isCustomerAdmin(role) || isCustomerUser(role)) {
        return showAssign && ticket_owned_by === 1;
    } else if (isPsp(role, type) || isSp(role, type)) {
        return showAssign;
    }
    return false;
};

export const getIsshowReleaseTicketButton = ({ ticket, role, type, loggedUserId }) => {
    const { signOffRequest, accepted_fe_id, accepted_sp_id, isEscalated, hardClose, closedByNoc, ticketType, isRmaRaised } = ticket;

    const showRelease =
        (isScm(role, type) || loggedUserId === accepted_sp_id) &&
        signOffRequest === 0 &&
        accepted_fe_id !== 0 &&
        isEscalated === 0 &&
        hardClose === 0 &&
        ((ticketType === ticketTypes.field && isRmaRaised === 0) || (ticketType === ticketTypes.field && isRmaRaised === 1));

    if (closedByNoc > 0) {
        return showRelease && accepted_sp_id === 0;
    }

    return showRelease;
};

export const isToShowExecuteTicket = (ticket, { role, type, loggedUserId, loggedUserCustomerId }) => {
    const { ticketType, ticket_owned_by, assignToScogo, accepted_fe_id, signOffAccept, isEscalated, isOnHold, hardClose, signOffRequest, po_raised, serviceTypeId } = ticket;
    const ticketServiceTypes = [
        serviceTypeIds.migration,
        serviceTypeIds.reconcile,
        serviceTypeIds.feasibility,
        serviceTypeIds.release_asset,
        serviceTypeIds.scrap_asset,
        serviceTypeIds.asset_replace,
    ];

    let basicCondition = ticketType === 0 && isOnHold === 0 && isEscalated === 0 && hardClose === 0 && po_raised === 0 && !ticketServiceTypes.includes(serviceTypeId);

    const customerCondition = isCustomerGroup(role, type);
    const ujoyCustomerId = 209;

    if (loggedUserCustomerId === ujoyCustomerId) {
        return basicCondition;
    }

    if (!customerCondition) {
        return signOffRequest === 0 && basicCondition;
    }
    if (
        (isScm(role, type) && signOffAccept === 0) ||
        (customerCondition && ticket_owned_by === 1 && assignToScogo === 0) ||
        (loggedUserId === accepted_fe_id && signOffAccept === 0 && !customerCondition)
    ) {
        return basicCondition;
    }
    return false;
};

export const isToShowDigitalForm = (ticket, { role, type, loggedUserId }) => {
    const {
        ticketType,
        ticket_owned_by,
        assignToScogo,
        accepted_fe_id,
        signOffAccept,
        isEscalated,
        isOnHold,
        hardClose,
        signOffRequest,
        po_raised,
        serviceTypeId,
        fk_sign_off_template_id,
    } = ticket;
    const ticketServiceTypes = [
        serviceTypeIds.migration,
        serviceTypeIds.reconcile,
        serviceTypeIds.feasibility,
        serviceTypeIds.release_asset,
        serviceTypeIds.scrap_asset,
        serviceTypeIds.asset_replace,
    ];

    let basicCondition =
        fk_sign_off_template_id > 0 && ticketType === 0 && isOnHold === 0 && isEscalated === 0 && hardClose === 0 && po_raised === 0 && !ticketServiceTypes.includes(serviceTypeId);

    if (!isCustomerGroup(role, type)) {
        return signOffRequest === 0 && basicCondition;
    }
    if (
        (isScm(role, type) && signOffAccept === 0) ||
        (isCustomerGroup(role, type) && ticket_owned_by === 1 && assignToScogo === 0) ||
        (loggedUserId === accepted_fe_id && signOffAccept === 0 && !isCustomerGroup(role, type))
    ) {
        return basicCondition;
    }
    return false;
};

export const isToShowUpdateRmaButton = (ticket, { role, type, loggedUserId }) => {
    const { ticketType, isRmaRaised, assignToScogo, warehouse_owner, cl_rma_response, hardClose, signOffAccept, isOnHold, signOffRequest } = ticket;
    let basicCondition = ticketType === ticketTypes.field && isRmaRaised === 1 && hardClose === 0 && (signOffAccept === 0) && (isOnHold === 0);

    if (signOffRequest === 1) {
        basicCondition = ['', undefined, null].includes(cl_rma_response) && basicCondition;
    }

    if (isScm(role, type)) {
        return basicCondition || warehouse_owner === loggedUserId;
    } else if (isCustomerGroup(role, type)) {
        return basicCondition && assignToScogo === 0;
    } else if (warehouse_owner === loggedUserId) {
        return basicCondition;
    }
    return basicCondition;
};

export const isToShowEditTicket = ({ ticket_owned_by, assignToScogo, signOffAccept, ticketType }, { role, type }) => {
    if (isCustomer(role, type) || isCustomerAdmin(role, type)) {
        return ticket_owned_by === 1 && ticketType === ticketTypes.field;
    }
    if (isScm(role, type)) {
        return (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1)) && signOffAccept === 0;
    }
    return false;
};

export const showSiteComplete = ({ signOffRequest, ticketType, closedByNoc, is_deleted, hardClose }) => {
    return [0, -1].includes(closedByNoc) && ticketType === 0 && signOffRequest === 0 && is_deleted === 0 && hardClose === 0;
};

export const getTicketActions = (role, type, { ticket, loggedUserId, frontend_controller, tab, loggedUserCustomerId }) => {

    const {
        signOffAccept,
        serviceTypeId,
        po_raised,
        is_live_tracking_enabled,
        execution_date,
        reachedSite,
        ticket_owned_by,
        signOffRequest,
        job_sign_off_notes,
        parent_ticket_id,
        assignToScogo,
        accepted_fe_id,
        closedByNoc,
        requestRaised,
        accepted_sp_id,
        fk_assigned_primary_sp_id,
        ticketType,
        signOffReject,
        isEscalated,
        isOnHold,
        hardClose,
        is_deleted,
        sign_off_lock
    } = ticket;

    let actions = [
        requestToTrackFE,
        trackFe,
        downloadsAllReport,
        editTicket,
        executeTicket,
        updateDigitalForm,
        updateSignOffNotes,
        raisePO,
        shareFeasibilityLink,
        assignTechSupport,
        customerApprovalRecieved,
        editExecutionDate,
        updateRmaDetails,
        updatePo,
        approvePo,
        feasibilityAction,
        addAsset,
        holdTicket,
        resumeTicket,
        addPrice,
        hardCloseTicket,
        siteComplete,
        reAssign,
        assign,
        releaseTicket,
        unlockSignoffAction,
        deleteTicket,
        appointment,
    ];

    actions = actions.filter((action) => {
        if (action.key === siteComplete.key) {
            return (isScm(role, type) || isCluster(role, type)) && showSiteComplete(ticket);
        }

        if (action.key === raisePO.key) {
            return getIsToShowRaisePO(role, type, frontend_controller) && serviceTypeId === serviceTypeIds.feasibility && parent_ticket_id === 0;
        }

        if (action.key === releaseTicket.key) {
            return getIsshowReleaseTicketButton({ role, type, ticket, loggedUserId });
        }

        if (action.key === updateRmaDetails.key) {
            return isToShowUpdateRmaButton(ticket, { role, type, loggedUserId });
        }
        if (action.key === shareFeasibilityLink.key) {
            return (isScm(role, type) || isCluster(role, type) || isCustomerGroup(role, type)) && serviceTypeId === serviceTypeIds.feasibility;
        }
        if (action.key === updatePo.key) {
            return isPoRaised({ po_raised }) && !isIsp(role, type);
        }

        if (action.key === approvePo.key) {
            return getIsToShowApprovePoButton({ ticket, loggedUserId })
        }

        if (action.key === feasibilityAction.key) {
            return getIsEligibleToCloseFeasibility({ ticket }) && tab === ticketChat;
        }

        if (action.key === addAsset.key) {
            return getIsToAddAssetButton(ticket, { role: role, type: type, loggedUserId }) && tab === ticketChat;
        }

        if (action.key === holdTicket.key) {
            return isToShowHoldIcon(ticket) && tab === ticketChat;
        }
        if (action.key === resumeTicket.key) {
            return isToShowResumeIcon(ticket, { role, type }) && tab === ticketChat;
        }
        if (action.key === hardCloseTicket.key) {
            return isShoHardCloseIcon({ signOffRequest }, { role, type }) && tab === ticketChat;
        }

        if (action.key === addPrice.key) {
            return getIsToShowAddNewPrice({ role, type, ticket }) && tab === ticketChat;
        }
        if (action.key === reAssign.key) {
            return (
                isShowReAssign({ requestRaised, assignToScogo, role, type, accepted_sp_id, loggedUserId }) &&
                tab === ticketChat &&
                getIsShowAssignTicketButton({ role, type, ticket, frontend_controller })
            );
        }
        if (action.key === assign.key) {
            return (
                isShowAssign({ requestRaised, role, type, fk_assigned_primary_sp_id }) &&
                tab === ticketChat &&
                getIsShowAssignTicketButton({ role, type, ticket, frontend_controller })
            );
        }

        if (action.key === updateSignOffNotes.key) {
            if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type)) return isEligibleForupdatesignoff({ signOffRequest, job_sign_off_notes });
            return false;
        }

        if (action.key === executeTicket.key) {
            if (isCustomerGroup(role, type)) {
                return isToShowExecuteTicket(ticket, { role, type, loggedUserId, loggedUserCustomerId });
            }
            return isToShowExecuteTicket(ticket, { role, type, loggedUserId, loggedUserCustomerId }) && ticket.sign_off_lock !== 1;
        }

        if (action.key === updateDigitalForm.key) {
            return isToShowDigitalForm(ticket, { role, type, loggedUserId });
        }

        if (action.key === downloadsAllReport.key) {
            return showDownloadAssetConfig(ticket, { role, type }) || showDownloadScogoReport(ticket, { role, type });
        }

        if (action.key === deleteTicket.key) {
            return isEligibleForDelete(role, type, { ticket_owned_by, assignToScogo, reachedSite });
        }

        if (action.key === editTicket.key) {
            return isToShowEditTicket({ ticket_owned_by, assignToScogo, signOffAccept, ticketType }, { role, type });
        }

        if (action.key === requestToTrackFE.key) {
            if (isSp(role, type) || isFe(role, type) || isIsp(role, type) || accepted_fe_id === loggedUserId) {
                return false;
            }
            return isEligibleForrequestToTrackFe({ is_live_tracking_enabled, execution_date, reachedSite, ticketType, accepted_fe_id });
        }

        if (action.key === trackFe.key) {
            if (accepted_fe_id === loggedUserId) return false;
            return isEligibleForTrackFe({ is_live_tracking_enabled, execution_date, reachedSite, ticketType });
        }

        if (action.key === assignTechSupport.key) {
            return isEligibleForAssignTechSupport({ signOffAccept, signOffRequest, closedByNoc, ticket_owned_by, assignToScogo, role, type });
        }

        if (action.key === customerApprovalRecieved.key) {
            return isEligibleForCustomerApprovalRecieved({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type });
        }

        if (action.key === editExecutionDate.key) {
            return isEligibleForEditExecutionDate({ signOffAccept, signOffRequest, ticket_owned_by, assignToScogo, role, type, closedByNoc, accepted_sp_id, sign_off_lock });
        }

        if (action.key === unlockSignoffAction.key) {
            return isScm(role, type) && ticket.sign_off_lock === 1;
        }
        if (action.key === appointment.key) {
            return requestRaised !== 0 && ticketType === ticketTypes.field && isScm(role, type) && signOffReject === 0 && isEscalated === 0 && isOnHold === 0 && hardClose === 0 && is_deleted === 0 && closedByNoc === 0 && signOffRequest === 0
        }

        return true;
    });

    return actions;
};

const downloadTicketReport = {
    key: 'download_ticket_report',
    menuName: 'Ticket Report',
    imgIcon: './img/csvdownload.png',
    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Ticket Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={0} status='ALL' />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const downloadExpenseReport = {
    key: 'download_expense_report',
    menuName: 'Expense Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download Expense Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={0} status='ALL' module='TICKET_EXPENSE_REPORT' />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const downloadWorkflowReport = {
    key: 'download_workflow_report',
    menuName: 'Workflow Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download Workflow Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={0} status='ALL' module='TICKET_WORKFLOW_REPORT' />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const downloadTransactionReport = {
    key: 'download_transaction_report',
    menuName: 'Transaction Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download Transaction Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={0} status='ALL' module='TICKET_UPI_REPORT' />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const BulkdownloadCustomerReport = {
    key: 'bulk_download_customer_report',
    menuName: 'Customer Sign Off Report',
    imgIcon: '/img/zipDownload.png',
    onClick: (_, dispatch, { getSelectedTickets } = {}) => {
        let ticketIds = [];
        let tickets = getSelectedTickets();
        if (tickets.length > 0) {
            if (tickets.length === 0) {
                return dispatch(sendToastMessage({ message: 'Tickets Are Not Eligible For Assign', status: 'warning' }));
            }
            ticketIds = tickets.map((ticket) => {
                return ticket.ticket_id;
            });
        } else {
            return dispatch(sendToastMessage({ message: 'Select Atleast One Ticket', status: 'warning' }));
        }

        return dispatch(
            openCustomModal({
                heading: 'Download Customer Sign Off Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCustomerReport ticketIds={ticketIds} />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const downloadFeasibilityReport = {
    key: 'download_feasibility_report',
    menuName: 'Feasibility Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download Feasibility Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={0} status='FEASIBILITY' isFeasibilityReport />,
            })
        );
    },
    isDisabled: (ticket) => {
        return false;
    },
};

const downloadRmaReport = {
    key: 'download_rma_report',
    menuName: 'RMA Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download RMA Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadRmaReports />,
            })
        );
    },
    isDisabled: () => {
        return false;
    },
};

const downloadSuperAppFieldReport = {
    key: 'download_super_app_field_report',
    menuName: 'SuperApp Field Report',
    imgIcon: './img/csvdownload.png',

    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Download SuperApp Field Report',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <DownloadCsv type={2} status='ALL' />,
            })
        );
    },
    isDisabled: () => {
        return false;
    },
};

export const getDownloadReportActions = (role, type, tab, { frontend_controller, manage_partner_payments }) => {
    let actions = [];
    if (isScm(role, type)) {
        actions = [downloadTicketReport, downloadWorkflowReport, downloadRmaReport, downloadFeasibilityReport, downloadExpenseReport, BulkdownloadCustomerReport];
    } else if (isCustomerGroup(role, type)) {
        actions = [downloadTicketReport, downloadWorkflowReport, downloadFeasibilityReport, downloadRmaReport, BulkdownloadCustomerReport, downloadTransactionReport];
    } else if (isCluster(role, type)) {
        actions = [downloadTicketReport, downloadWorkflowReport, downloadRmaReport, downloadFeasibilityReport];
    } else if (isPsp(role, type)) {
        actions = [downloadTicketReport]
    }
    actions = actions.filter((action) => {
        if (action.key === downloadWorkflowReport.key) {
            return [completedTab.path, closedTab.path].includes(tab);
        } else if (action.key === downloadTransactionReport.key) {
            return isApprovePaymentAllowed(frontend_controller) && manage_partner_payments === 1;
        }
        return true;
    });
    return actions;
};

export const getBulkAction = (role, type, { ticket, loggedUser } = {}) => {
    let actions = [
        bulkUploadSite,
        bulkAssign,
        addNewPincode,
        bulkEditExecutionDate,
        bulkAssignTechSupport,
        bulkCustomerApprovalRecieved,
        bulkCloneTicket,
        bulkHoldTicket,
        bulkResumeTicket,
        bulkUpdateTicketDates,
        bulkDeleteTicket,
        bulkUpdateHardCopyStatus,
        bulkUploadOfflineTransfer
    ];

    actions = actions.filter((action) => {
        if (action.key === bulkAssign.key) {
            return isCustomerGroup(role, type) || isScm(role, type) || isCluster(role, type) || isPsp(role, type) || isSuperVisor(role, type);
        }
        if (action.key === bulkCloneTicket.key) {
            return isCustomer(role, type) || isCustomerAdmin(role, type) || isScm(role, type);
        }

        if ([bulkAssignTechSupport.key, bulkUpdateHardCopyStatus.key].includes(action.key)) {
            return isScm(role, type);
        }

        if (action.key === bulkEditExecutionDate.key) {
            return isCustomer(role, type) || isCustomerAdmin(role, type) || isScm(role, type) || isCluster(role, type);
        }
        if (action.key === addNewPincode.key) {
            return isScm(role, type) || isCustomerGroup(role, type);
        }

        if (action.key === bulkCustomerApprovalRecieved.key) {
            return isScm(role, type);
        }

        if (action.key === bulkUploadSite.key) {
            if (isSuperVisor(role, type)) {
                return isTicketAdditionAllowed(loggedUser.frontend_controller);
            }
            return isScm(role, type) || isCustomerGroup(role, type);
        }

        if ([bulkHoldTicket.key, bulkDeleteTicket.key].includes(action.key)) {
            return isScm(role, type) || isCustomer(role, type);
        }
        if (action.key === bulkResumeTicket.key) {
            return isScm(role, type)
        }
        if (action.key === bulkUpdateTicketDates.key) {
            return isCustomerGroup(role, type) && loggedUser.customer_id === 209;
        }

        if (action.key === bulkUploadOfflineTransfer.key) {
            return isCustomer(role, type) && loggedUser.manage_partner_payments === 1;
        }

        return true;
    });
    return actions;
};

export const getIsToShowAddNewPrice = ({ role, type, ticket }) => {
    const { ticket_owned_by, assignToScogo, signOffAccept } = ticket;
    if (isCustomerGroup(role, type)) {
        return signOffAccept === 0 && ticket_owned_by === 1 && assignToScogo === 0;
    }
    return signOffAccept === 0 && (ticket_owned_by === 0 || (ticket_owned_by === 1 && assignToScogo === 1));
};

const isShowReAssign = ({ requestRaised, assignToScogo, role, type, accepted_sp_id, loggedUserId }) => {
    return requestRaised === 1 || (isCustomerGroup(role, type) && assignToScogo === 1) || isSp(role, type) || (isPsp(role, type) && accepted_sp_id === loggedUserId);
};
const isShowAssign = ({ requestRaised, role, type, fk_assigned_primary_sp_id }) => {
    return ((isCluster(role, type) || isPsp(role, type)) && fk_assigned_primary_sp_id === 0) || (requestRaised === 0 && fk_assigned_primary_sp_id === 0);
};

export const getIsToShowSignOffApproveButton = ({ ticket, loggedUser } = {}) => {
    const frontend_controller = loggedUser.frontend_controller;
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    const {
        signOffAccept,
        ticketType,
        signOffRequest,
        reachedSite,
        accepted_fe_id,
        customerSignOffAccept,
        customerSignOffRequired,
        isEscalated,
        serviceTypeId,
        parent_ticket_id,
        isRmaRaised,
        cl_rma_response,
        po_raised,
    } = ticket;

    let showClAction = ticketType === ticketTypes.support && serviceTypeId === serviceTypeIds.migration;
    let showCloseFeasibility = serviceTypeId === serviceTypeIds.feasibility && parent_ticket_id === 0 && isEscalated === 0;
    let pendingDocketDetail = isPendingDocket(ticket) && signOffRequest === 1;
    let pendingDelivery = isShowPendingDelivery(ticket);
    let isCustomerSignOffRequired =
        (customerSignOffRequired === 0 || customerSignOffRequired === null || (customerSignOffRequired === 1 && customerSignOffAccept === 1)) &&
        [SCM.type, SUPERVISOR.type].includes(type);

    let check =
        isSignOffApproveAllowed(frontend_controller) &&
        po_raised !== 1 &&
        signOffRequest === 1 &&
        accepted_fe_id !== 0 &&
        signOffAccept === 0 &&
        reachedSite === 1 &&
        isEscalated === 0 &&
        !showClAction &&
        !showCloseFeasibility &&
        !pendingDocketDetail &&
        !pendingDelivery &&
        isCustomerSignOffRequired;

    if (isRmaRaised === 1) {
        check = check && !['', undefined, null].includes(cl_rma_response);
    }

    return check && !isSp(role, type) && !isFe(role, type);
};

export const getIsToShowSignOffRejectButton = ({ ticket, loggedUser } = {}) => {
    const frontend_controller = loggedUser.frontend_controller;
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    const {
        signOffAccept,
        ticketType,
        signOffRequest,
        reachedSite,
        accepted_fe_id,
        customerSignOffAccept,
        customerSignOffRequired,
        isEscalated,
        serviceTypeId,
        parent_ticket_id,
        isRmaRaised,
        cl_rma_response,
        po_raised,
    } = ticket;

    let showClAction = ticketType === ticketTypes.support && serviceTypeId === serviceTypeIds.migration;
    let showCloseFeasibility = serviceTypeId === serviceTypeIds.feasibility && parent_ticket_id === 0 && isEscalated === 0;
    let pendingDocketDetail = isPendingDocket(ticket) && signOffRequest === 1;
    let pendingDelivery = isShowPendingDelivery(ticket);

    let check =
        isSignOffApproveAllowed(frontend_controller) &&
        po_raised !== 1 &&
        signOffRequest === 1 &&
        accepted_fe_id !== 0 &&
        signOffAccept === 0 &&
        reachedSite === 1 &&
        isEscalated === 0 &&
        !showClAction &&
        !pendingDelivery &&
        !showCloseFeasibility &&
        !pendingDocketDetail;

    if (isRmaRaised === 1) {
        check = check && !['', undefined, null].includes(cl_rma_response);
    }

    if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type)) {
        return customerSignOffRequired === 1 && customerSignOffAccept === 0 && check;
    }

    return check && !isSp(role, type) && !isFe(role, type);
};

export const getIsCUstomerSignOffApproved = ({ ticket, loggedUser }) => {
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const {
        signOffAccept,
        ticketType,
        signOffRequest,
        reachedSite,
        accepted_fe_id,
        customerSignOffAccept,
        customerSignOffRequired,
        isEscalated,
        serviceTypeId,
        parent_ticket_id,
        isRmaRaised,
        cl_rma_response,
        po_raised,
        ticket_owned_by,
        assignToScogo,
    } = ticket;

    let showClAction = ticketType === ticketTypes.support && serviceTypeId === serviceTypeIds.migration;
    let showCloseFeasibility = serviceTypeId === serviceTypeIds.feasibility && parent_ticket_id === 0 && isEscalated === 0;
    let pendingDocketDetail = isPendingDocket(ticket) && signOffRequest === 1;
    let pendingDelivery = isShowPendingDelivery(ticket);

    let isCustomerSignOffRequired = (customerSignOffRequired === 1 && customerSignOffAccept === 0) || (ticket_owned_by === 1 && assignToScogo === 0);

    let check =
        po_raised !== 1 &&
        signOffRequest === 1 &&
        accepted_fe_id !== 0 &&
        signOffAccept === 0 &&
        reachedSite === 1 &&
        isEscalated === 0 &&
        !showClAction &&
        !showCloseFeasibility &&
        !pendingDelivery &&
        isCustomerSignOffRequired &&
        !pendingDocketDetail &&
        (isCustomer(role, type) || isCustomerAdmin(role, type));

    if (isRmaRaised === 1) {
        check = check && !['', undefined, null].includes(cl_rma_response);
    }

    return check;
};

export const getIsToShowApproveRejectAssetButton = ({ ticket } = {}) => {
    const { ticketType, isEscalated, serviceTypeId, hardClose, po_raised, signOffAccept } = ticket;
    return ticketType === ticketTypes.support && serviceTypeId === serviceTypeIds.migration && isEscalated === 0 && hardClose === 0 && po_raised !== 1 && signOffAccept === 0;
};

export const getIsEligibleToCloseFeasibility = ({ ticket } = {}) => {
    const { isEscalated, serviceTypeId, parent_ticket_id, po_raised } = ticket;
    return serviceTypeId === serviceTypeIds.feasibility && parent_ticket_id === 0 && isEscalated === 0 && po_raised !== 1;
};

const isPendingDocket = ({
    hard_copy_required,
    hard_copy_received,
    serviceTypeId,
    isEscalated,
    po_raised,
    signOffAccept,
    rltracking_detail,
    signOffRequest,
    is_report_generated,
}) => {
    const basicConditon =
        isEscalated === 0 && po_raised === 0 && signOffAccept === 0 && [null, '', undefined].includes(rltracking_detail) && signOffRequest === 0 && is_report_generated === 1;

    if (hard_copy_required === 1 && hard_copy_received === 0) {
        return basicConditon;
    }
    if (serviceTypeId === serviceTypeIds.reverse_logistics) {
        return basicConditon;
    }
    return false;
};

export const getIsToShowPendingDelivery = ({ ticket, role, type }) => {
    if (isCluster(role, type) || isSuperVisor(role, type) || isScm(role, type)) {
        return isShowPendingDelivery(ticket);
    }
    return false;
};

export const getIsToShowApprovePoButton = ({ ticket, loggedUserId }) => {
    if (ticket.po_raised === 1 && ticket.signOffAccept === 0) {
        let displayPoApproval = false;
        const po = ticket?.po;

        if (po && Array.isArray(po.approvers) && po.approvers.length > 0) {
            displayPoApproval = po.approvers.find((approver) => {
                return approver?.users?.find((userId) => parseInt(userId) === parseInt(loggedUserId) && !approver.closed && approver.authorization)
            })
        }
        if (po && Array.isArray(po.approvals) && po.approvals.length > 0) {
            po.approvals.find((i) => {
                return parseInt(i.userId) === parseInt(loggedUserId)
            });
        }
        return displayPoApproval;
    }
    return false;
};

export const getIsToShowRaisePO = (role, type, frontend_controller) => {
    return isScm(role, type) || (isCustomerGroup(role, type) && isViewPoAllowed(frontend_controller));
};

export const getIsToShowAddNewTicket = ({ frontend_controller, role, type }) => {
    return isTicketAdditionAllowed(frontend_controller) && !isCluster(role, type);
};

export const getIsToAddAssetButton = (ticket, { role, type, loggedUserId }) => {

    const { createdBy } = ticket;
    return isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCluster(role, type) || createdBy === loggedUserId;

    // const { ticketType, signOffAccept, createdBy, isRmaRaised, serviceTypeId, parent_ticket_id, po_raised } = ticket;
    // //  add this condition when key added ( && isBoqRequired === 1)
    // if ((parent_ticket_id !== 0 && serviceTypeId === 4) || serviceTypeId === 13 || po_raised === 1) return false;
    // return (
    //     ((ticketType === 0 && isRmaRaised === 0) || serviceTypeId === 11) &&
    //     signOffAccept === 0 &&
    //     (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type) || isCluster(role, type) || createdBy === loggedUserId)
    // );
};

export const getIsToShowReverseDocket = ({ serviceTypeId, hard_copy_required, rltracking_detail, isEscalated, po_raised, signOffAccept, hard_copy_received, signOffRequest }) => {
    if (rltracking_detail) return false;
    return (
        (serviceTypeId === serviceTypeIds.reverse_logistics || (hard_copy_required === 1 && hard_copy_received === 0)) &&
        isEscalated === 0 &&
        po_raised === 0 &&
        signOffAccept === 0 &&
        signOffRequest === 0
    );
};

export const getIsToShowAddDocketButton = ({
    serviceTypeId,
    hard_copy_required,
    rltracking_detail,
    tracking_detail,
    isEscalated,
    po_raised,
    signOffAccept,
    hard_copy_received,
    signOffRequest,
    isRmaRaised,
}) => {
    if ([serviceTypeIds.reconcile, serviceTypeIds.feasibility, serviceTypeIds.scrap_asset, serviceTypeIds.asset_replace].includes(serviceTypeId)) return false;
    if (hard_copy_received === 1) return false;

    const base = isEscalated === 0 && po_raised === 0 && signOffAccept === 0 && signOffRequest === 0;

    if (rltracking_detail && (serviceTypeId === serviceTypeIds.reverse_logistics || (hard_copy_required === 1 && hard_copy_received === 0))) {
        return false;
    }
    if ((rltracking_detail || tracking_detail) && serviceTypeId === serviceTypeIds.migration) return false;

    if ((rltracking_detail || tracking_detail) && isRmaRaised === 1) return false;

    return base;
};


export const getTicketOwner = ({ ticket_owned_by, assign_to_scogo }) => {
    if (ticket_owned_by === 0 || (ticket_owned_by === 1 && assign_to_scogo === 1)) {
        return 'Scogo';
    }
    return 'Customer';
}