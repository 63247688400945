import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RenderTicketCard, TicketSearch } from './RenderTicketCard';
import { GetQueryParam, getFileType, isLogged } from '../../utils/common';
import { useCallback } from 'react';
import NoResultFound from '../../common/NoResultFound';
import { useDispatch } from 'react-redux';
import { getTicketDetailForDetailPage, getTicketDetails, } from '../../actions/tickets';
import { openCustomModal } from '../../actions/modal';
import { getConversationDetails, putToConversationStore, setopenedConversationMessageIsLatest, updateunReadMessage, uploadFilesToChat, getConversationMessages } from '../../actions/conversations';
import { sendToastMessage } from '../../actions/toast';
import { maxChatFileSizeInBytes } from '../chat/ChatController';
import { getHeightOfUploaded } from '../chat/messageBox/InputBar/MessageInput';
import config from '../../config';
import development from '../../config/env/development';
import Cookies from 'universal-cookie';
import RenderChatMessages from '../chat/ChatMessage';
import RenderChatIcon, { RenderMapIcon } from './RenderChatIcon';
import DisabledChatInfo from './DisableChatInfo';
import TrackPage from './Track';
import RenderTicketDetails, { LoginForm } from './RenderTicketDetails';
import { SkeltonCardTwo } from '../../common/SkeltonCardTwo';
import ReactGoogleMap from './ReactGoogleMap';
import { isMobileView } from '../auth/components/NewScogoButton';
import { Skeleton } from '@mui/material';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import { trackSupportTicket } from '../../router/roleWisePageRoute';


export const futopiaCustomerId = 3706;
export const defaultDevProject = 118;
const cookies = new Cookies();

export default function SupportTrack() {
    const { ticketDetails, ticketDetailsError, noTicketFound, openTicketDetails, openTicketDetailsError } = useSelector((state) => state.tickets);
    const { isViewTicketByIdLoading, isConversationDetailsLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const { uiConfig } = useSelector(state => state.user);
    const ticketId = GetQueryParam('ticketId', Number);
    const redirectToChat = GetQueryParam('redirectToChat', Boolean);
    const redirectToEditTicket = GetQueryParam('redirectToEditTicket', Boolean);
    const redirectToTicketDetails = GetQueryParam('redirectToTicketDetails', Boolean);
    const redirectToMap = GetQueryParam('redirectToMap', Boolean);
    const shared_by = GetQueryParam('shared_by', Number);
    const [showTicketDetails, setShowTicketDetails] = useState({ detail: ticketId ? true : false, edit: false });
    const [showConversation, setShowConversation] = useState(window.innerWidth >= 1024);
    const dispatch = useDispatch();
    const { conversationDetails, storedDocumentsToSend } = useSelector((state) => state.conversations);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const [storeDocuments, setStoreDocuments] = useState([]);
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        document.title = "Support";
    }, []);

    const onFileSend = async (files, conversationId = conversationDetail._id, isAddToQueue = true, ticketId) => {
        let authToken = `Bearer ${cookies.get(`${config.name}-authorization`)}`
        const loggedUserDetails = {
            email: loggedUser.email,
            role: loggedUser.type,
            roleId: loggedUser.role.id,
            userId: loggedUser.id,
            username: `${loggedUser.first_name} ${loggedUser.last_name}`,
        };
        const filesToSend = await Promise.all(
            files.map(async (item) => {
                return item;
            })
        );
        if (conversationDetail?.unreadMessageCount > 0) {
            dispatch(updateunReadMessage({ unreadCount: 0, _id: conversationId }))
        }
        if (conversationId) {
            let customerId = 0
            if (window.location.hostname === 'localhost') {
                customerId = 1;
            } else if (uiConfig?.customer_id) {
                customerId = uiConfig.customer_id
            } else if (config.name === development.name) {
                customerId = 1
            }
            if (ticketDetails?.fk_customer_id) {
                customerId = ticketDetails?.fk_customer_id
            }
            dispatch(uploadFilesToChat({ _id: conversationId, fileData: filesToSend, from: loggedUserDetails, isSupportTicket: true, isAddToQueue, imp_image: true, callParser: true, customer_id: customerId, auth_token: authToken, source: 'chat', ticket_id: ticketId, source_id: conversationId, user_id: loggedUser.id }));
            dispatch(setopenedConversationMessageIsLatest({ isLatest: true, _id: conversationId }));
        }
    };

    const SendFilesOnClick = async ({ fileData, conversationId, isAddToQueue = true, ticketId, projectId }) => {
        const newFiles = [];
        let maxSizeFileNamesList = [];
        for (let idx = 0; idx < fileData.length; idx++) {
            const file = fileData[idx];
            if (file.size <= maxChatFileSizeInBytes) {
                const now = new Date();
                const id = now.getTime() + idx;
                try {
                    let f = { id, caption: '', file }
                    if (getFileType(file.type) === 'image') {
                        const { height, width } = await getHeightOfUploaded(file);
                        f = { ...f, height, width };
                    }
                    newFiles.push(f);
                } catch (error) {
                    console.error(error);
                }
            } else {
                maxSizeFileNamesList.push(file.name);
            }
        }

        if (maxSizeFileNamesList.length) {
            let message = `File Size of ${maxSizeFileNamesList.join(', ')} ${maxSizeFileNamesList.length > 1 ? 'are' : 'is'
                } bigger than ${maxChatFileSizeInBytes / 1000000} MB`;
            dispatch(sendToastMessage({ status: 'danger', message }));
        }

        const files = newFiles?.map((item) => {
            const res = { file: item.file, id: item.id, caption: item.caption, tags: [], height: item.height, width: item.width };
            return res;
        });
        onFileSend(files, conversationId, isAddToQueue, ticketId);
        dispatch(putToConversationStore({ storedDocumentsToSend: [] }));
    };

    useEffect(() => {
        if (storedDocumentsToSend && storedDocumentsToSend.length > 0 && conversationDetail?._id && ticketDetails?.id) {
            SendFilesOnClick({ fileData: storedDocumentsToSend, conversationId: conversationDetail._id, isAddToQueue: true, ticketId: ticketDetails?.id, projectId: ticketDetails?.fk_project_id });
            return () => {
                dispatch(putToConversationStore({ storedDocumentsToSend: [] }))
            }
        }
    }, [storedDocumentsToSend, conversationDetail?._id, dispatch, ticketDetails?.id, ticketDetails?.fk_project_id]);

    const onChatIconClick = useCallback(() => {
        setShowTicketDetails({ edit: false, detail: false });
        setShowConversation(true);
    }, []);

    const closeIconClick = useCallback(() => {
        // setShowTicketDetails(true);
        setShowConversation(false);
    }, []);

    const isUserLoggedIn = isLogged() && loggedUser;

    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {
        if (window.innerWidth < 1024 && (ticketDetails?.job_fk_pincode_id && ticketDetails?.job_site_address_1 || redirectToChat || redirectToEditTicket)) {
            if (isUserLoggedIn && redirectToChat) {
                setShowTicketDetails({ edit: false, detail: false });
                setShowConversation(true);
                const params = searchParams;
                params.redirectToEditTicket = true;
                setSearchParams(params)
            } else {

                setShowTicketDetails({ edit: true, detail: false });
                setShowConversation(false);
            }
        }

        if (window.innerWidth >= 1024) {
            setShowTicketDetails({ edit: redirectToEditTicket, detail: redirectToChat });
        }
    }, [ticketDetails?.job_fk_pincode_id, ticketDetails?.job_site_address_1, redirectToChat, isUserLoggedIn, redirectToEditTicket]);

    useLayoutEffect(() => {
        if (ticketId && !ticketDetails && isLogged() && loggedUser) {
            dispatch(getTicketDetails({ ticketId, support_track: true }));
        } else {
            if (ticketId) {
                dispatch(getTicketDetailForDetailPage({ ticketId, shared_by }))
            }
        }
    }, [ticketId, dispatch, ticketDetails, loggedUser]);

    useLayoutEffect(() => {
        if (!conversationDetail && !isConversationDetailsLoading && ticketId && ticketDetails && isUserLoggedIn) {
            dispatch(getConversationDetails({ _id: ticketId, useConversationId: true }));
        }
    }, [ticketId, dispatch, ticketDetails]);

    const onSearchIconClick = useCallback(() => {
        let modalComponent = <div className='px-4 py-4'>
            <TicketSearch closeModal={true} />
        </div>;
        let modalWidth = '75vw',
            modalHeight = '22vh';
        let head = <span>Search</span>
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
    }, [dispatch]);

    if (!isViewTicketByIdLoading && (ticketDetailsError || openTicketDetailsError) && ticketId) {
        return <div className='subContainer h-full bg-white'><div className='max-h-max w-full'>
            <NoResultFound message='Ticket details not found.' /></div></div>
    };

    const redirectToTrack = isRedirectToTrack({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails: openTicketDetails || ticketDetails });
    const redirectToDetails = isRedirectToDetails({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails: openTicketDetails || ticketDetails });

    if (redirectToTrack) {
        const userId = openTicketDetails?.accepted_fe?.id || ticketDetails?.accepted_fe_id;
        const address = openTicketDetails?.job_site_address_1 || ticketDetails?.job_site_address_1;
        let site_lat = openTicketDetails?.latitude || ticketDetails?.latitude;
        let site_lng = openTicketDetails?.longitude || ticketDetails?.longitude;
        const live_tracking_url = openTicketDetails?.live_tracking_url || ticketDetails?.live_tracking_url;
        if (parseInt(site_lat) === 0 && parseInt(site_lng) === 0 && live_tracking_url) {
            const latLong = extractLatAndLngFromUrl(live_tracking_url);
            if (latLong) {
                site_lat = latLong.lat;
                site_lng = latLong.lng;
            }
        }
        return <TrackPage ticketId={ticketId} userId={userId} address={address}
            site_lat={site_lat} site_lng={site_lng} />
    } else if (redirectToDetails) {
        return <RenderTicketDetails showActions isMobileView={isMobileView} ticketId={ticketId} isLiveTrackingEnabled={redirectToTrack} />
    }

    if (!isViewTicketByIdLoading && !ticketId) {
        return <DefaultVIew />
    }

    return (
        <>
            {
                isViewTicketByIdLoading ? <div className='px-6'> <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6, 7, 8, 9]} height={20} className={'my-4'} /></div> : <>
                    <div className='subContainer'>
                        <div className='editTicketWithChat bg-white'>
                            {showConversation && <RenderTicketConversation onSearchIconClick={onSearchIconClick} ticketDetails={ticketDetails} noTicketFound={noTicketFound} ticketId={ticketId} closeIconClick={closeIconClick} showConversation={showConversation} isUserLoggedIn={isUserLoggedIn} showMap={showMap} setShowMap={setShowMap} />}
                            {showTicketDetails.edit && <RenderTicketCard storeDocuments={storeDocuments} setStoreDocuments={setStoreDocuments} onSearchIconClick={onSearchIconClick} ticketDetails={ticketDetails} ticketId={ticketId} isUserLoggedIn={isUserLoggedIn} onChatIconClick={onChatIconClick} SendFilesOnClick={SendFilesOnClick} showMap={showMap} setShowMap={setShowMap} />}
                            {showTicketDetails.detail && <div className='edit-ticket-card-wtih-chat bg-scogolightblue flex h-full' style={{
                                flexFlow: 'column',
                            }}>
                                <RenderTicketDetails isMobileView={true} ticketId={ticketId} isLiveTrackingEnabled={redirectToTrack} />
                            </div>}
                        </div>
                    </div>
                    {!showMap && <RenderChatIcon onChatIconClick={onChatIconClick} showConversation={showConversation} loggedUser={loggedUser} ticketDetails={ticketDetails} />}
                    {!showMap && <RenderMapIcon show={redirectToTrack} />}
                </>
            }
        </>
    )
};


const RenderTicketConversation = ({ closeIconClick, showConversation, onSearchIconClick = () => { }, mainChatContanerRef, showMap, setShowMap }) => {
    const { conversationDetailsLoadFailure, conversationDetails, openedConversationId, conversationMessages } = useSelector((state) => state.conversations);
    const { loggedUser } = useSelector((state) => state.auth);
    const isUserLoggedIn = isLogged() && loggedUser;
    const { ticketDetails, noTicketFound } = useSelector((state) => state.tickets);
    const ticketId = GetQueryParam('ticketId', Number);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const openedConversationMessagesList = conversationMessages[openedConversationId];
    const dispatch = useDispatch();

    useEffect(() => {
        if (conversationDetail?._id && !openedConversationMessagesList) {
            dispatch(putToConversationStore({ openedConversationId: conversationDetail._id }))
            dispatch(getConversationMessages({ _id: conversationDetail._id }));
        }
    }, [conversationDetail?._id, dispatch]);

    if (showMap) {
        return <div className='chat-card-wtih-edit-ticket border relative' style={{ height: '100%' }}>
            <ReactGoogleMap callOnGetLocation={() => { }} mapContainerStyle={{ width: '100%', height: '72vh' }} setShowMap={setShowMap} />
        </div>
    }

    if (!isUserLoggedIn || !ticketId || (noTicketFound && !ticketDetails) || conversationDetailsLoadFailure) {
        return <DisabledChatInfo ticketId={ticketId} />
    }

    return <>
        <RenderChatMessages showChatHeader={!isMobileView} mainDivClass='overflow-hidden chat-card-wtih-edit-ticket border' onSearchIconClick={onSearchIconClick} ticketDetails={ticketDetails} noTicketFound={noTicketFound} ticketId={ticketId} onTicketModalSearchCancel={closeIconClick} isSuppportTrackMessagesElemVisible={showConversation} isUserLoggedIn={isUserLoggedIn} showTicketSearch onTicketSearhClick={onSearchIconClick} isSupportTicket openedConversationId={openedConversationId} supportTrackDetails={ticketDetails} />
    </>
};


function extractLatAndLngFromUrl(url) {
    const latRegex = /lat=([-+]?\d*\.?\d+)/;
    const lngRegex = /lng=([-+]?\d*\.?\d+)/;

    const latMatch = url.match(latRegex);
    const lngMatch = url.match(lngRegex);

    if (latMatch && lngMatch) {
        const lat = parseFloat(latMatch[1]);
        const lng = parseFloat(lngMatch[1]);
        return { lat, lng };
    } else {
        return null; // Return null if lat/lng not found
    }
}


export const isRedirectToTrack = ({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails }) => {
    const isLiveTrackingEnabled = (ticketDetails && ticketDetails.is_live_tracking_enabled === 1 && ticketDetails.reached_site === 0);
    return redirectToMap || ((!redirectToChat && !redirectToEditTicket && ticketDetails && !redirectToTicketDetails) && isLiveTrackingEnabled);
}

export const isRedirectToDetails = ({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails }) => {
    const isLiveTrackingEnabled = (ticketDetails && ticketDetails.is_live_tracking_enabled === 1 && ticketDetails.reached_site === 0);
    return redirectToTicketDetails || ((!redirectToChat && !redirectToEditTicket && ticketDetails && !redirectToMap) && !isLiveTrackingEnabled);
}


export const DefaultVIew = () => {
    const { uiConfig } = useSelector(state => state.user);
    const { loggedUser } = useSelector(state => state.auth);
    const { isUIConfigLoading } = useSelector(state => state.loading);
    const dispatch = useDispatch();

    const isUserLoggedIn = loggedUser && isLogged();

    const getLogo = () => {
        if (uiConfig) return uiConfig.logoUrl;
        return process.env.PUBLIC_URL + '/img/scogo-S-logo.png';
    }

    const onLoginClick = () => {
        const modalWidth = isMobileView ? '90vw' : '30vw';
        dispatch(
            openCustomModal({
                heading: `Login`,
                modalWidth,
                modalHeight: 'auto',
                modalComponent: <LoginForm redirect_to={`${trackSupportTicket.path}`} />,
            })
        )
    }

    return <div className='w-full h-full border relative' >
        {(isUserLoggedIn) && <div className='renderSearchIcon flex items-end px-4 absolute top-2 right-4'>
            <TicketSearch buttonPadding='px-10 py-3 text-font15 md:text-font12' searchDivClass='border-2 border-scogobgsky py-1 rounded-md w-10/12' mainDivClass='flex items-center gap-2 w-[70vw] md:w-[30vw] ml-auto' />
        </div>}
        <div className='w-full h-full font-bold flex flex-col items-center justify-center'>
            <div className='flex items-center align-middle'>
                {isUIConfigLoading ?
                    <Skeleton variant='rectangular' animation='wave' height='11rem' width='10rem' />
                    :
                    <div className='flex flex-col gap-4'>
                        <img src={getLogo()} alt='chat' className='mx-auto h-48' />
                        {!isUserLoggedIn && <ButtonScogoPrimary buttonPadding='px-10 py-4 text-font15 md:text-font12' textOrComponent={'Login to continue'} onClick={onLoginClick} />}
                    </div>
                }
            </div>
        </div>
    </div>
}