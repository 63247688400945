import React, { useMemo, useEffect, useCallback } from 'react';
import { headerCss } from '../common/Css';
import { Disclosure } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import { Outlet, useLocation, useNavigate, } from 'react-router-dom';
import { GetQueryParam, isLogged } from '../utils/common';
import { trackSupportTicket } from '../router/roleWisePageRoute';
import { HeaderLogo, profileComponent } from '../layout/header/Header';
import { RenderDetailsIcon } from '../pages/SupportTrack/RenderTicketConversations';
import { isRedirectToDetails, isRedirectToTrack } from '../pages/SupportTrack';
import { closeModalAction, openCustomModal, openTextModalAction } from '../actions/modal';
import { signOutUserAction } from '../actions/auth';
import IconToolTip from '../common/IconToolTip';
import DropdownMenu from '../common/MenuDropdown';
import TextModal from '../common/Modals/TextModal';
import CustomModal from '../common/Modals/CustomModal';
import { isMobileView } from '../pages/auth/components/NewScogoButton';
import { connectToWebsocket } from '../actions/websocket';
import { TicketSearch } from '../pages/SupportTrack/RenderTicketCard';
import MediaView from '../common/MediaView';
import { LoginForm } from '../pages/SupportTrack/RenderTicketDetails';
import OverflowTip from '../common/OverflowTip';

const SupportTrackLayout = () => {
    const { isUIConfigLoading } = useSelector(state => state.loading);
    const { openTicketDetails, ticketDetails } = useSelector((state) => state.tickets);
    const { isTextModalOpen, isCustomModalOpen, isMediaViewModalOpen } = useSelector((state) => state.modal);
    const { conversationDetails } = useSelector((state) => state.conversations);
    const { loggedUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const ticketIdQueryKey = GetQueryParam('ticketId');
    const redirectToMap = GetQueryParam('redirectToMap', Boolean);
    const redirectToChat = GetQueryParam('redirectToChat', Boolean);
    const redirectToEditTicket = GetQueryParam('redirectToEditTicket', Boolean);
    const redirectToTicketDetails = GetQueryParam('redirectToTicketDetails', Boolean);
    const isVideoMeetPage = location.pathname.includes('room');
    const openedConversationId = GetQueryParam('openedConversationId');

    const onDetailsClick = () => {
        if (isMobileView) {
            navigate(`${trackSupportTicket.path}?ticketId=${ticketIdQueryKey}&redirectToTicketDetails=true`);
        } else {
            navigate(`${trackSupportTicket.path}?ticketId=${ticketIdQueryKey}&redirectToChat=true`);
        }
    };

    const dispatch = useDispatch();

    const { websocketConnection } = useSelector((state) => state.websocket);

    useEffect(() => {
        if (isLogged() && !websocketConnection) {
            dispatch(connectToWebsocket());
        }
    }, [dispatch, websocketConnection, loggedUser]);

    const showIcons = useMemo(() => {
        let details = false;
        let edit = false;
        if (redirectToMap || isRedirectToTrack({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails: openTicketDetails || ticketDetails })) {
            details = true;
            edit = false;
        }
        if (redirectToTicketDetails || isRedirectToDetails({ redirectToChat, redirectToEditTicket, redirectToMap, redirectToTicketDetails, ticketDetails: openTicketDetails || ticketDetails })) {
            edit = true;
            details = false;
        }
        if (redirectToChat) {
            edit = false;
            details = true;
        }
        if (redirectToEditTicket) {
            details = true;
            edit = false;
        }
        if (redirectToChat && !isMobileView) {
            edit = true;
            details = false;
        }
        return { details, edit }
    }, [redirectToMap, redirectToChat, redirectToEditTicket, redirectToTicketDetails, openTicketDetails, ticketDetails]);

    const onSearchIconClick = useCallback(() => {
        let modalComponent = <div className='px-4 py-4'>
            <TicketSearch closeModal={true} />
        </div>;
        let modalWidth = '75vw',
            modalHeight = '22vh';
        let head = <span>Search</span>
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
    }, [dispatch]);


    const branch_name = openTicketDetails?.site?.site_branch_name || ticketDetails?.site_detail?.site_branch_name;
    const external_site_id = openTicketDetails?.site?.external_site_id || ticketDetails?.site_detail?.external_site_id;

    let job_ticket_number = openTicketDetails?.job_ticket_number || ticketDetails?.job_ticket_number;
    let updatedTicketNumber = ''
    if (job_ticket_number) updatedTicketNumber = extractTicketNumberPart(job_ticket_number);

    return (
        <div className='h-screen bg-scogolightblue'>
            <Disclosure as='div' className='header fixed w-full z-50 flex items-center justify-between'>
                {({ open }) => (
                    <>
                        <div className={`${headerCss?.mainDiv} w-full flex items-center justify-between `}>
                            {isUIConfigLoading ?
                                <Skeleton variant='rectangular' animation='wave' height='3rem' width='5rem' />
                                :
                                <div className='flex w-full justify-between items-center' style={{
                                    flexFlow: 'row',
                                }}>
                                    <div className='flex flex-initial'><HeaderLogo /></div>
                                    {!isVideoMeetPage && <div className='max-w-[50%] flex flex-initial text-scogoprimary items-center justify-center gap-2'>
                                        {(external_site_id || branch_name) && <span className='max-w-[70%] truncate' >
                                            <OverflowTip someLongText={`${external_site_id || branch_name}`} textClass=" text-font16 md:text-font16 whitespace-nowrap" />
                                        </span>}
                                        {job_ticket_number && <p className={`${!external_site_id && !branch_name ? 'w-full text-font16 md:text-font16' : 'max-w-[35%] text-font13 md:text-font15'}  text-scogogray whitespace-nowrap`}>
                                            ({updatedTicketNumber})
                                        </p>}
                                    </div>}
                                    {
                                        isVideoMeetPage && <div className='flex flex-auto text-scogoprimary text-2xl items-center justify-center text-center'>{conversationDetails[openedConversationId]?.conversationName}</div>
                                    }
                                    <div className='flex flex-initial gap-0 md:gap-2 items-center justify-center'>
                                        {!isVideoMeetPage ? <span onClick={onSearchIconClick} className='renderChatIcon material-icons cursor-pointer max-w-max text-font24 mr-2 '>search</span> : <></>}
                                        {showIcons.edit ? <EditTicketButton /> : <></>}
                                        {showIcons.details ? <RenderDetailsIcon extraClass='' onClick={onDetailsClick} /> : <></>}
                                        <HeaderActions />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                )}
            </Disclosure>
            <div className='flex bg-scogof8 md:overflow-auto lg:overflow-hidden'>
                <div className='mainContainerForUnauthorisedUser'>
                    <Outlet />
                </div>
            </div>
            {isTextModalOpen && <TextModal />}
            {isCustomModalOpen && <CustomModal />}
            {isMediaViewModalOpen && <MediaView />}
        </div>
    );
};

export default SupportTrackLayout;


export const EditTicketButton = () => {
    const navigate = useNavigate();
    const ticketId = GetQueryParam('ticketId', Number);
    const shared_by = GetQueryParam('shared_by', Number);
    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const onEditButtonClick = () => {
        if (isLogged() && loggedUser) {
            navigate(`${trackSupportTicket.path}?ticketId=${ticketId}&redirectToEditTicket=true`);
        } else {
            const modalWidth = isMobileView ? '90vw' : '30vw';
            let path = `${trackSupportTicket.path}?ticketId=${ticketId}&redirectToEditTicket=true`;
            if (shared_by) path = `${path}&shared_by=${shared_by}`;
            dispatch(
                openCustomModal({
                    heading: `Login`,
                    modalWidth,
                    modalHeight: 'auto',
                    modalComponent: <LoginForm ticketId={ticketId} redirect_to={path} />,
                })
            )
        }
    }
    return <div className='flex flex-col items-center gap-1'>
        <span
            onClick={onEditButtonClick}
            className=' text-scogoprimary material-icons  cursor-pointer  text-4xl ' >
            edit
        </span>
    </div>
};

const HeaderActions = () => {
    const navigate = useNavigate();
    const { loggedUser } = useSelector((state) => state.auth);
    if (!loggedUser) return <></>;

    let logoutAction = {
        menuName: 'Sign out',
        menuIcon: 'logout',
        onClick: (item, dispatch) => {

            return dispatch(
                openTextModalAction({
                    heading: 'Sign Out',
                    text: 'Are you sure you want to sign out?',
                    formName: 'signOutUser',
                    buttons: [
                        {
                            showLoading: true,
                            textOrComponent: 'Yes',
                            type: 'red',
                            buttonClass: 'hover:bg-scogoLightFillRed',
                            onClick: () => {
                                dispatch(signOutUserAction(navigate));
                            },
                        },
                        {
                            textOrComponent: 'No',
                            type: 'blue',
                            onClick: () => dispatch(closeModalAction()),
                        },
                    ],
                    closeOnBackdropClick: false,
                })
            );
        },
    };

    return <div className='w-[3.2rem] ml-2'>
        <IconToolTip title={loggedUser ? `${loggedUser.first_name} ${loggedUser.last_name}` : ''}>
            <DropdownMenu
                menuData={[logoutAction]}
                componentOrIcon={() => profileComponent(loggedUser.profile_picture)}
                iconColor={'text-scogoprimary'}
            />
        </IconToolTip>
    </div>
}


function extractTicketNumberPart(input) {
    const regex = /-\d+-\d+$/;
    const match = input.match(regex);
    if (match) {
        return match[0].substring(1);
    }
    return null;
}