import { takeEvery, call, put, race, all, take, fork, cancel, cancelled, select, delay } from 'redux-saga/effects';
import { conversations, websocket, modal, wallet, loading, toast, user, auth, tickets } from '../types';
import { eventChannel, END } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { getJWTToken, randomDelay } from '../utils/common';
import { isLogged } from '../utils/common';
import { getLoggedUser } from '../selectors/users';
import { websocketEvents } from '../utils/websocket';
import {
    getConversationChatList,
    getOpenedConversationId,
    getConversationMessagesList,
    getQueueConversationMessagesList,
    getSelectedChatTab,
    getChatNotificationCount,
    getSideBarMedia,
} from '../selectors/chat';
import lambdas from '../lambdas/apis';
import { chatType, messageType } from '../pages/chat/ChatController';
import { chatSidebarTab } from '../layout/sidebar';
import { Howl } from 'howler';
import { getViewTicket } from '../selectors/tickets';
import { getActiveCallDetails } from '../selectors/modal';
import { isScm } from '../utils/role';
import TableModal from '../pages/project/Modals/TableModal';
import { chats, teamWork } from '../router/roleWisePageRoute';

const WAIT_TIME_FOR_ERROR = 20000;

let idleTimeout;

export function createWebSocketConnection(isReconnecting) {
    return new Promise((resolve, reject) => {
        const token = getJWTToken();
        const queryParmas = isReconnecting ? '&isReconnecting=true' : '';
        if (token) {
            const awsurl = new URL(`${config.api.WebsocketBaseUrl}?token=bearer ${token}${queryParmas}`);
            const socket = new WebSocket(awsurl);
            socket.onopen = function () {
                // send ping every 9 minutes because api gateway closes idle connections after 10 minutes
                idleTimeout = setInterval(() => {
                    const token = getJWTToken();
                    socket.send(JSON.stringify({ action: 'ping', data: 'PING!', token: `bearer ${token}` }));
                }, 9 * 60 * 1000);

                resolve(socket);
            };

            socket.onerror = function (evt) {
                reject(evt);
            };
        }
    });
}

export function createSocketChannel(socket) {
    return eventChannel((emit) => {
        socket.onmessage = (event) => {
            emit(event.data);
        };
        socket.onclose = (closeEvent) => {
            if (idleTimeout) {
                clearInterval(idleTimeout);
            }
            if (closeEvent.reason === 'Going away' || isLogged()) {
                emit('reconnect');
            } else {
                emit(END);
            }
        };
        const unsubscribe = () => {
            socket.onmessage = null;
        };
        return unsubscribe;
    });
}

export function* sendMessageListener(socket) {
    while (true) {
        const data = yield take(websocket.SEND_WEBSOCKET_DATA);

        switch (data.payload.action) {
            case websocketEvents.chat.sendMessage: {
                if (!data.payload.data.messageId) data.payload.data.messageId = uuidv4();
                let message = { ...data.payload.data, _id: data.payload.data.messageId, from: data.payload.from };
                yield put({
                    type: conversations.ADD_TO_MESSAGE_QUEUE,
                    payload: { message },
                });
                yield fork(checkSendMessageError, { conversationId: data.payload.data.conversation, messageId: data.payload.data.messageId });
                break;
            }
            case websocketEvents.chat.editMessage: {
                let message = { ...data.payload.data, _id: data.payload.data.messageId };
                yield put({
                    type: conversations.EDITED_MESSAGE_QUEUE,
                    payload: { message },
                });
                yield fork(checkEditMessageError, { conversationId: data.payload.data.conversation, messageId: data.payload.data.messageId });
                break;
            }
            case websocketEvents.chat.deleteMessage: {
                yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: websocketEvents.chat.deleteMessage, isLoading: true } });
                break;
            }
            default: {
                break;
            }
        }

        const token = getJWTToken();
        socket.send(JSON.stringify({ action: data.payload.action, data: data.payload.data, token: `bearer ${token}` }));
    }
}

function* checkEditMessageError({ conversationId, messageId }) {
    yield delay(WAIT_TIME_FOR_ERROR);
    let conversationMessages = yield select(getConversationMessagesList);

    if (Array.isArray(conversationMessages?.[conversationId])) {
        conversationMessages[conversationId] = conversationMessages[conversationId].map((msg) => {
            let message = msg;
            if (messageId === msg._id && msg.isEditLoading) {
                message.isEditMessageError = true;
                if (message.isEditLoading) delete message.isEditLoading;
            }
            return message;
        });
    }
    yield put({ type: conversations.PUT_TO_CONVERSATIONS_STORE, payload: { conversationMessages } });
}

export function* checkSendMessageError({ conversationId, messageId, waitForEvent }) {
    if (waitForEvent) {
        const event = yield take([waitForEvent.success, waitForEvent.failure]);
        if (event && event.type === waitForEvent.success) return;
    }
    yield delay(WAIT_TIME_FOR_ERROR);
    let conversationsMessages = yield select(getConversationMessagesList);
    let queueConversationsMessages = yield select(getQueueConversationMessagesList);

    let openedConversationMessagesList = conversationsMessages?.[conversationId];
    let isMessageInList;
    if (Array.isArray(openedConversationMessagesList)) {
        isMessageInList = openedConversationMessagesList.find((msg) => msg?.messageId && msg.messageId === messageId);
    }

    if (!isMessageInList && Array.isArray(queueConversationsMessages[conversationId])) {
        queueConversationsMessages[conversationId] = queueConversationsMessages[conversationId].map((qMsg) => {
            if (qMsg.messageId === messageId) {
                return { ...qMsg, sendMessageError: true };
            }
            return qMsg;
        });
        yield put({ type: conversations.PUT_TO_CONVERSATIONS_STORE, payload: { messageQueueList: queueConversationsMessages } });
    }
}

export function disconnectFromWebSocket(socketChannel, socket) {
    if (socketChannel) {
        // close the channel
        socketChannel.close();
    }
    if (socket) {
        // close the WebSocket connection
        socket.close();
    }
}

export function* messageRecieveListener(socketChannel) {
    while (true) {
        try {
            const message = yield take(socketChannel);
            if (message === 'reconnect') {
                yield put({ type: websocket.CONNECT_TO_WEBSOCKET_REQUEST });
                return;
            }
            let { event, data } = JSON.parse(message);
            if (!event || !data) continue;
            yield put({ type: websocket.HANDLE_WEBSOCKET_MESSAGE, payload: { event, data } });
        } catch (error) {
            console.error('receiver error', error);
        }
    }
}

export function* listenForSocketMessages(isReconnecting) {
    let socket;
    let socketChannel;

    try {
        socket = yield call(createWebSocketConnection, isReconnecting);
        socketChannel = yield call(createSocketChannel, socket);
        // tell the application that we have a connection
        yield put({ type: websocket.CONNECT_TO_WEBSOCKET_SUCCESS, payload: { socket, message: 'Connected To WebSocket Successfully!!!' } });
        while (true) {
            // Send & Receive Message
            const { cancel } = yield race({
                task: all([call(sendMessageListener, socket), call(messageRecieveListener, socketChannel)]),
                cancel: take(websocket.DISCONNECT_FROM_WEBSOCKET_REQUEST),
            });
            if (cancel) {
                yield call(disconnectFromWebSocket, socketChannel, socket);
            }
        }
    } catch (error) {
        let message = error.message ? error.message : 'Something went Wrong!!!';
        yield put({ type: websocket.CONNECT_TO_WEBSOCKET_FAILURE, payload: { message } });
    } finally {
        if (yield cancelled()) {
            yield call(disconnectFromWebSocket, socketChannel, socket);
        } else {
            yield put({ type: websocket.CONNECT_TO_WEBSOCKET_FAILURE, payload: { message: 'WebSocket disconnected' } });
        }
    }
}

export function* connectToWebsocket(action) {
    // starts the task in the background
    const socketTask = yield fork(listenForSocketMessages, action?.payload?.isReconnecting);

    // when DISCONNECT action is dispatched, we cancel the socket task
    yield take(websocket.DISCONNECT_FROM_WEBSOCKET_REQUEST);
    yield cancel(socketTask);
    yield put({ type: websocket.DISCONNECT_FROM_WEBSOCKET_SUCCESS, payload: { message: 'Websocket disconnected Successfully!!!' } });
}

const sirenAlert = new Howl({
    src: ['/audio/siren.wav'],
});
export function* handleWebsocketMessages(action) {
    const { event, data } = action.payload;

    const loggedUser = yield select(getLoggedUser);
    const openedConversationId = yield select(getOpenedConversationId);

    if (event === websocketEvents.chat.openConversation) {
        if (data[0].type === chatType.help) {
            yield put({ type: conversations.CHANGE_CONVERSATION_COUNT, payload: { countNeedTo: 'increase', type: data[0].type } });
        }
    } else if (event === websocketEvents.chat.toggleBot) {
        let removeChat = false;
        if (isScm(loggedUser.role.id, loggedUser.type) && Array.isArray(data.tags) && data.tags.includes('requested_closure') && data.isBotEnabled) {
            removeChat = true;
        }
        yield put({ type: conversations.UPDATE_BOT_ENABLED_CONVERSATION, payload: { conversation: data, removeChat } });
    } else if (event === websocketEvents.chat.closeConversation) {
        yield put({ type: conversations.CLOSE_CONVERSATION_SUCCESS, payload: { conversation: data } });
        yield put({ type: conversations.CHANGE_CONVERSATION_COUNT, payload: { countNeedTo: 'both', type: data.type } });
    } else if (event === websocketEvents.chat.message) {
        const message = data;
        let { conversation } = data;

        const checkPath = (new RegExp('^/chats/[^/]+$').test(window.location.pathname) || window.location.pathname === chats.path)
        if (!checkPath && message.tags && Array.isArray(message.tags) && message.tags.find(({ user }) => user.userId === loggedUser.id)) {
            yield put({ type: conversations.OPEN_CHAT_ELEMENT, payload: { conversationId: conversation._id, buttonNam: '', source: 'first_load' } });
        }

        const conversationMessages = yield select(getConversationMessagesList);
        if (conversationMessages?.[conversation._id]?.length > 0) {
            yield put({ type: conversations.HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_MESSAGE_LIST, payload: { message: data, _id: conversation._id, loggedUserId: loggedUser.id } });

            // updating viewTicket if found signoff image and same conversation is opened
            if (message.type === messageType.attachments && message?.conversation?.type === chatType.ticket && openedConversationId === conversation._id) {
                const ticket = yield select(getViewTicket);
                const findSignOffImages = message.attachments.filter((attchment) => attchment.isSignOff).map((file) => ({ file_name: file.fileName, file_path: file.fileUrl, mimeType: file.mimeType }));

                if (findSignOffImages.length > 0 && ticket && parseInt(message?.conversation?.conversationId) === ticket.id) {
                    ticket.sign_off_document = findSignOffImages;
                    yield put({ type: tickets.UPDATE_VIEW_TICKET_FROM_WEBSOCKET, payload: { ticket } });
                }
            }
        }
        if (data.newGptChat) {
            yield put({ type: conversations.NEW_SCOGO_GPT_CHAT_FROM_WEBSOCKET, payload: { conversation, message: data, loggedUserId: loggedUser.id } })
            yield put({ type: conversations.GET_CONVERSTAION_DETAILS_REQUEST, payload: { _id: conversation._id } })
        }

        const { chatBoxElementData = {}, conversationDetails } = yield select((state) => state.conversations)
        let isMessageFromDiffrentUser = message.from.userId !== loggedUser.id;
        const loadedConversationDetails = conversationDetails[conversation._id];
        if (Object.keys(chatBoxElementData).includes(conversation._id) && loadedConversationDetails) {
            yield put({ type: conversations.UPDATE_UN_READ_MESSAGE_COUNT, payload: { unreadCount: isMessageFromDiffrentUser ? loadedConversationDetails.unreadMessageCount + 1 : 0, _id: conversation._id } });
        }

        if (conversation._id === openedConversationId) {
            yield put({ type: tickets.UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET, payload: { stepFieldsRelationshipId: parseInt(data?.payload?.stepFieldsRelationshipId), status: 1, attachments: data.attachments, conversation: data.conversation } });
        }

        const { selectedTab, selectedSubTab } = yield select(getSelectedChatTab);
        if (!selectedTab && !selectedSubTab) return;
        const chatBelongsToVisibleTab = selectedSubTab ? selectedSubTab.belongToTab(conversation, message, loggedUser) : selectedTab.belongToTab?.(conversation, message, loggedUser);
        if (!chatBelongsToVisibleTab) return;
        const chatList = yield select(getConversationChatList);
        const loadedConversation = chatList.find((convo) => convo._id === conversation._id);
        if (loadedConversation) {
            conversation.unreadMessageCount = isMessageFromDiffrentUser ? loadedConversation.unreadMessageCount + 1 : 0;
            conversation.sendNotificationsFor = loadedConversation.sendNotificationsFor;
            /** user has chat opened and browser tab is in focus and the message is not self originated then send 'markRead' through websocket */
            if (openedConversationId === conversation._id && document.hasFocus() && isMessageFromDiffrentUser) {
                yield put({
                    type: websocket.SEND_WEBSOCKET_DATA,
                    payload: { action: websocketEvents.chat.markRead, data: { conversation: openedConversationId } },
                });
                conversation.unreadMessageCount = 0;
            }


        } else {
            const conversationInfo = yield call(lambdas.conversations.getConversationInfo, {}, { _id: conversation._id }, { query: 'unreadCount,sendNotificationsFor' });
            if (conversationInfo.data.code === 200) {
                conversation.unreadMessageCount = isMessageFromDiffrentUser ? conversationInfo.data.data.unreadCount : 0;
                conversation.sendNotificationsFor = conversationInfo.data.data.sendNotificationsFor;
            } else {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
            }

        }

        const mergedConversation = mergeMessageAndConversation({ message, conversation });
        yield put({ type: conversations.HANDLE_MESSAGE_FROM_WEBSOCKET_FOR_CHAT_LIST, payload: { _id: conversation._id, conversation: mergedConversation } });
        yield put({ type: conversations.REMOVE_TYPING_IN_CHAT, payload: { messageFromId: message.from.userId } });

        if (conversation._id !== openedConversationId) {
            yield put({ type: conversations.UPDATE_UN_READ_MESSAGE_COUNT, payload: { unreadCount: conversation.unreadMessageCount, _id: conversation._id } });
        }

        if (conversation._id === openedConversationId) {
            yield put({ type: tickets.UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET, payload: { stepFieldsRelationshipId: parseInt(data?.payload?.stepFieldsRelationshipId), status: 1, attachments: data.attachments, conversation: data.conversation } });
        }

    } else if (event === websocketEvents.chat.deleted) {
        const conversationId = data.conversation._id;
        yield put({ type: conversations.DELETED_MESSAGE_FROM_WEBSOCKET, payload: { message: data, conversationId: data.conversation._id } });
        if (data.from.userId === loggedUser.id) {
            yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName: websocketEvents.chat.deleteMessage, isLoading: false } });
            yield put({ type: modal.CLOSE_MODAL });
        }
        const sideBarMedia = yield select(getSideBarMedia);
        if (sideBarMedia[conversationId] && sideBarMedia[conversationId].length < 5 && data.type === messageType.attachments) {
            yield put({
                type: conversations.LIST_CONVERSATION_SIDEBAR_MEDIA_REQUEST,
                payload: { conversationId },
            });
        }
    } else if (event === websocketEvents.chat.edited) {
        yield put({ type: conversations.EDITED_MESSAGE_FROM_WEBSOCKET, payload: { message: data, conversationId: data.conversation._id } });
    } else if (event === websocketEvents.chat.read && data.receipt?.user?.userId) {
        if (data.receipt.user.userId === loggedUser.id) {
            yield put({ type: conversations.MARK_READ_CONVERSATION_SUCCESS, payload: data });
            yield put({ type: conversations.MARK_NOTIFICATIONS_READ_SUCCESS, payload: { newCount: data.unreadNotificationCount } });
        } else {
            yield put({ type: conversations.MESSAGE_READ_STATUS, payload: { loggedUserId: loggedUser.id, conversationId: data.conversation } });
        }
    } else if (event === websocketEvents.chat.tagProject) {
        yield put({
            type: conversations.TAG_PROJECT_SUCCESS,
            payload: { project: { id: data.projectId, project_name: data.projectName }, conversationId: data._id },
        });
    } else if (event === websocketEvents.chat.unTagProject) {
        yield fork(handleUntagWebsocketEvent, { project: { id: data.projectId, project_name: data.projectName }, conversationId: data._id });
    } else if (event === websocketEvents.chat.giveAdminAccess) {
        yield put({
            type: conversations.GRANT_OR_REVOKE_ACCESS_SUCCESS,
            payload: { conversationId: data._id, userId: data.userId, access: 'give' },
        });
    } else if (event === websocketEvents.chat.revokeAdminAccess) {
        yield put({
            type: conversations.GRANT_OR_REVOKE_ACCESS_SUCCESS,
            payload: { conversationId: data._id, userId: data.userId, access: 'revoke' },
        });
    } else if (event === websocketEvents.wallet.withdrawWebhook) {
        yield put({ type: wallet.WITHDRAW_WALLET_AMOUNT_WEBHOOK_SUCCESS, payload: data });
    } else if (event === websocketEvents.chat.leaveConversation) {
        yield put({
            type: conversations.LEAVE_CONVERSATION_FROM_WEBSOCKET,
            payload: { leavedChatIds: data.conversationIds, userId: data.userId, loggedUserId: loggedUser.id },
        });
    } else if (event === websocketEvents.chat.addMembers) {
        const { _id, newMembers } = data;
        yield put({ type: conversations.ADD_MEMBERS_TO_CHAT_FROM_WEBSOCKET, payload: { _id, newMembers } });
    } else if (event === websocketEvents.chat.addTeams) {
        const { _id, teams } = data;
        yield put({ type: conversations.ADD_TEAMS_TO_CHAT_FROM_WEBSOCKET, payload: { _id, teams } });
    } else if (event === websocketEvents.chat.removeTeam) {
        const { _id, teamId } = data;
        yield put({ type: conversations.REMOVE_TEAM_FROM_CHAT_FROM_WEBSOCKET, payload: { _id, teamId } });
    } else if (event === websocketEvents.chat.modifiedNotificationSettings) {
        const { _id, sendNotificationsFor } = data;
        yield put({ type: conversations.CHANGE_NOTIFICATION_SETTINGS_SUCCESS, payload: { sendNotificationsFor, _id } });
    } else if (event === websocketEvents.chat.notification) {
        const { conversation } = data;
        yield put({ type: conversations.RECEIVED_CHAT_NOTIFICATION, payload: data });
        const openedConversationId = yield select(getOpenedConversationId);
        if (!document.hasFocus() || openedConversationId !== conversation._id) {
            const prevCount = yield select(getChatNotificationCount);
            yield put({ type: user.GET_COUNT_SUCCESS, payload: { [chatSidebarTab.countKey]: prevCount + 1 } });
        }
    } else if (event === websocketEvents.inventory.runOutOfSpares) {
        sirenAlert.play();
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: data.notification_message } });
    } else if ([websocketEvents.chat.rejectMedia, websocketEvents.chat.approveMedia].includes(event)) {
        const {
            conversation: { _id: conversationId },
            _id,
        } = data;
        const openedConversationId = yield select(getOpenedConversationId);
        yield put({ type: conversations.APPROVE_OR_REJECT_IMAGE_SUCCESS, payload: { conversationId, messageId: _id, attachment: data.attachment, } });
        if (conversationId === openedConversationId && websocketEvents.chat.rejectMedia === event) {
            yield put({ type: tickets.UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET, payload: { attachments: [data.attachment], stepFieldsRelationshipId: parseInt(data?.payload?.stepFieldsRelationshipId), status: 2, conversation: data.conversation } });
        }
        if (conversationId === openedConversationId) {
            yield put({ type: tickets.UPDATE_VIEW_TICKET_SIGNOFF_DOCUMENT, payload: { attachment: data.attachment } });
        }
    } else if (websocketEvents.chat.typing === event) {
        const {
            conversation: { _id: conversationId },
            user,
        } = data;
        const openedConversationId = yield select(getOpenedConversationId);
        const chatList = yield select(getConversationChatList);
        const loadedConversation = chatList?.find((convo) => convo._id === conversationId);
        if ((conversationId === openedConversationId && openedConversationId) || loadedConversation) {
            yield put({ type: conversations.TYPING_IN_CHAT, payload: { conversationId, user } });
        }
    } else if (websocketEvents.user.bulkInviteResponse === event && Array.isArray(data.invalidList) && data.invalidList.length > 0 && window.location.pathname === teamWork.path) {
        yield put({
            type: modal.OPEN_CUSTOM_MODAL,
            payload: {
                heading: `Failed to Invite`,
                modalWidth: '60vw',
                modalHeight: 'auto',
                modalComponent: <TableModal data={data} />,

            },
        });
    } else if (websocketEvents.downloadProgress.start === event) {
        yield put({ type: modal.DOWNLOAD_ZIP_START_WEBSOCKET, payload: data });
    } else if (websocketEvents.downloadProgress.progress === event) {
        yield put({ type: modal.DOWNLOAD_ZIP_PROGRESS_WEBSOCKET, payload: data });
    } else if (websocketEvents.downloadProgress.done === event) {
        yield put({ type: modal.DOWNLOAD_ZIP_DONE_WEBSOCKET, payload: data });
    } else if (websocketEvents.downloadProgress.fail === event) {
        yield put({ type: modal.DOWNLOAD_ZIP_FAIL_WEBSOCKET, payload: data });
    } else if (event === websocketEvents.wallet.updateWithdrawStatus) {
        yield put({ type: auth.UPDATE_WITHDRAW_STATUS_FROM_WEBSOCKET, payload: { is_withdraw_disabled: data.is_withdraw_disabled } });
    } else if (event === websocketEvents.ticket.signoffUnlocked) {
        yield put({ type: tickets.UPDATE_TICKET_UNLOCK_SIGNOFF_STATUS_FROM_WEBSOCKET, payload: data });
    } else if (event === websocketEvents.voiceCall.voice_call) {
        yield put({ type: tickets.RECIEVED_ACTIVE_VOICE_CALL_FROM_WEBSOCKET, payload: data });
        yield put({ type: modal.OPEN_REQUESTED_CALL_MODAL, payload: { data, isOnCall: true } });
    } else if (event === websocketEvents.voiceCall.voice_call_end) {
        const activeCallDetails = yield select(getActiveCallDetails);
        if (activeCallDetails?.id === data.id) {
            yield put({ type: modal.OPEN_REQUESTED_CALL_MODAL, payload: { data: undefined, isOnCall: false } });
        }
        yield put({ type: tickets.VOICE_CALL_END_FROM_WEBSOCKET, payload: data });
    }
    else if (event === websocketEvents.chat.videoMeetInvite) {
        let { videoMeetLink, ticket, user, openedConversationId, room_id } = data;
        if (!ticket) {
            ticket = {}
        }
        ticket.videoMeetLink = videoMeetLink;
        ticket.user = user;
        ticket.openedConversationId = openedConversationId;
        ticket.fk_room_id = room_id;
        yield call(randomDelay);
        yield put({ type: tickets.RECIEVED_ACTIVE_VOICE_CALL_FROM_WEBSOCKET, payload: ticket });
        yield put({ type: modal.OPEN_REQUESTED_CALL_MODAL, payload: { data: ticket, isOnCall: true } });
    }
    else if (event === websocketEvents.chat.holdCall) {
        const { room_id, userName } = data;
        yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `${userName} has put this call on hold, Please Wait.` } });
    }
    else if (event === websocketEvents.chat.declineCall) {
        const { room_id, userName } = data;
        yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: `${userName} has declined the call.` } });
    }
    else if (event === websocketEvents.chat.endCall || event === websocketEvents.chat.leaveCall) {
        //REFRESH
        const { room_id, userName } = data;
        const { activeCallDetails } = yield select(state => state.modal);
        if (activeCallDetails?.fk_room_id === room_id) {
            yield put({ type: modal.OPEN_REQUESTED_CALL_MODAL, payload: { data: undefined, isOnCall: false } });
        }
        yield put({ type: tickets.GET_ACTIVE_VOICE_CALL_REQUEST });
        yield put({ type: user.GET_USER_CALL_STATUS_REQUEST, payload: { userId: loggedUser?.id, status: [1, 2, 3, 4] } });
    }
    else if (event === websocketEvents.chat.clearModal) {
        const { room_id, userName } = data;
        const { activeCallDetails } = yield select(state => state.modal);
        if (activeCallDetails?.fk_room_id === room_id) {
            yield put({ type: modal.OPEN_REQUESTED_CALL_MODAL, payload: { data: undefined, isOnCall: false } });
        }
    }
}

function* handleUntagWebsocketEvent({ project, conversationId }) {
    yield delay(1000);
    yield put({
        type: conversations.UNTAG_PROJECT_SUCCESS,
        payload: { project, conversationId },
    });
}

export default function* websocketSaga() {
    yield takeEvery(websocket.CONNECT_TO_WEBSOCKET_REQUEST, connectToWebsocket);
    yield takeEvery(websocket.HANDLE_WEBSOCKET_MESSAGE, handleWebsocketMessages);
}

const mergeMessageAndConversation = ({ message, conversation }) => {
    let lastMessage = {
        from: message.from,
        type: message.type,
        _id: message._id,
        message: message.message,
        timestamp: message.timestamp,
        readReceipts: message.readReceipts,
        payload: message.payload,
        tags: message.tags,
    };
    let convo = {
        _id: conversation._id,
        active: conversation.active,
        conversation: conversation._id,
        conversationId: conversation.conversationId,
        conversationImage: conversation.conversationImage,
        conversationName: conversation.conversationName,
        conversants: conversation.conversants,
        isBotEnabled: conversation.isBotEnabled,
        lastActivity: conversation.lastActivity,
        type: conversation.type,
        unreadMessageCount: conversation.unreadMessageCount,
        sendNotificationsFor: conversation.sendNotificationsFor,
        tags: conversation.tags,
        lastMessage: lastMessage,
    };
    return convo;
};
