const microservices = {
    inventory: {
        getAllSites: { method: 'get', url: 'inventory/sites' },
        getAllAssets: { method: 'get', url: 'inventory/assets' },
        getAllWarehouses: { method: 'get', url: 'inventory/warehouses' },
        getAllSpareAssets: { method: 'get', url: 'inventory/spareassets' },
        createInventorySites: { method: 'post', url: 'inventory/sites' },
        createWarehouses: { method: 'post', url: 'inventory/warehouses' },
        getSpareCategories: { method: 'get', url: 'inventory/sparecategory' },
        getInventoryPartcodes: { method: 'get', url: 'inventory/partcodes' },
        findSiteById: { method: 'get', url: 'inventory/sites/{siteId}' },
        createAssets: { method: 'post', url: 'inventory/assets' },
        createSpareAssets: { method: 'post', url: 'inventory/spareassets' },
        deleteSite: { method: 'delete', url: 'inventory/sites/{siteId}' },
        deleteAsset: { method: 'delete', url: 'inventory/assets/{assetId}' },
        deleteWarehouse: { method: 'delete', url: 'inventory/warehouses/{warehouseId}' },
        deleteSpareAsset: { method: 'delete', url: 'inventory/spareassets/{assetId}' },
        updateSite: { method: 'put', url: 'inventory/sites/{siteId}' },
        updateAsset: { method: 'put', url: 'inventory/assets/{assetId}' },
        updateWarehouse: { method: 'put', url: 'inventory/warehouses/{warehouseId}' },
        updateSpareAsset: { method: 'put', url: 'inventory/spareassets/{assetId}' },
        migrateAsset: { method: 'post', url: 'inventory/assets/migrate' },
        scrapAssets: { method: 'post', url: 'inventory/assets/scrap' },
        replaceAsset: { method: 'post', url: 'inventory/assets/replace' },
        generateAssetBarcode: { method: 'post', url: 'inventory/assets/barcode' },
        bulkUploadAssets: { method: 'get', url: 'inventory/assets/finance/signedUrl' },
        findAllWarehouses: { method: 'get', url: 'inventory/warehouses' },
        findWarehouseById: { method: 'get', url: 'inventory/warehouses/{warehouseId}' },
        assetHistory: { method: 'get', url: 'inventory/assets/history/{assetId}' },
        getAllSiteFilters: { method: 'get', url: 'inventory/sites/filterlist' },
        getAllAssetFilters: { method: 'get', url: 'inventory/assets/filterlist' },
        getAllWarehouseFilters: { method: 'get', url: 'inventory/warehouses/filterlist' },
        getAllSpareAssetFilters: { method: 'get', url: 'inventory/spareassets/filterlist' },
        linkReconcileAssets: { method: 'post', url: 'inventory/assets/tags/link' },
        getAssetWitSerialNo: { method: 'get', url: 'inventory/assets' },
        getAssetTagDetails: { method: 'get', url: 'inventory/assets/tags/{tag}' },
        getSitePos: { method: 'post', url: 'inventory/sites/details' },
        getBranchList: { method: 'get', url: 'inventory/sites/branches' },
        createNewbranch: { method: 'post', url: 'inventory/sites/branches' },
    },
    tickets: {
        createTickets: { method: 'post', url: 'tickets' },
        notifyIsp: { method: 'post', url: 'tickets/notify/isp' },
        respondFeasibility: { method: 'post', url: 'tickets/feasibility/response' },
        ticketPayments: { method: 'get', url: 'tickets/{ticketId}/payments' },
        ticketFilterList: { method: 'get', url: 'tickets/filterlist' },
        ticketAssets: { method: 'post', url: 'tickets/list/details' },
        updateTicketRma: { method: 'put', url: 'tickets/{ticketId}/rma' },
        closeTicket: { method: 'post', url: 'tickets/{ticketId}/close' },
        ticketStats: { method: 'get', url: 'tickets/stats/{customerId}' },
        requestTrackFE: { method: 'post', url: 'tickets/requestTracking' },
        requestSignoffZip: { method: 'post', url: 'tickets/signoff/zip' },
        uploadBulkTickets: { method: 'post', url: 'tickets/bulk' },
        bulkResumeTicket: { method: 'post', url: 'tickets/bulk/resume' },
    },
    user: {
        promoteUser: { method: 'post', url: 'users/{userId}/promote' },
        demoteUser: { method: 'post', url: 'users/{userId}/demote' },
        newResource: { method: 'post', url: 'users/resource' },
        getResource: { method: 'get', url: 'users/resource' },
        deleteResource: { method: 'delete', url: 'users/resource' },
        createRootFolder: { method: 'post', url: 'users/resource/rootFolder' },
        validateTroughSurePass: { method: 'post', url: 'users/kyc' },
        getGstList: { method: 'get', url: 'users/customer-gst' },
        addGst: { method: 'post', url: 'users/customer-gst' },
        updateGstById: { method: 'put', url: 'users/customer-gst/{gstId}' },
        getGstById: { method: 'get', url: 'users/customer-gst/{gstId}' },
        deleteGstById: { method: 'delete', url: 'users/customer-gst/{gstId}' },
        userProfileDetails: { method: 'get', url: 'users/{userId}/profile/minimal' },
        removeOrGiveHelpAccess: { method: 'post', url: 'users/help/access' },
        getUserList: { method: 'get', url: 'users' },
        getUserListDetails: { method: 'post', url: 'users/list/details' },
        usersCount: { method: 'get', url: 'users/count' },
        enableUserRoom: { method: 'post', url: 'users/{userId}/voice-call/enable' },
        disableUserRoom: { method: 'post', url: 'users/{userId}/voice-call/disable' },
        roomActions: { method: 'post', url: 'users/{userId}/voice-call/actions' },
        userCallStatus: { method: 'post', url: 'users/{userId}/voice-call/user-status' },
    },
    utils: {
        bulkUpload: { method: 'post', url: 'tickets/bulk' },
    },
    payments: {
        getPOs: { method: 'get', url: 'payments/po' },
        generateInvoice: { method: 'post', url: 'payments/po/invoice' },
        getRewardsFilterList: { method: 'get', url: 'payments/rewards/filterlist' },
        getWithDrawalFilterList: { method: 'get', url: 'payments/withdraw/filterlist' },
        getPayoutsFilterList: { method: 'get', url: 'payments/payouts/filterlist' },
        getPoFilterList: { method: 'get', url: 'payments/po/filterlist' },
        getPOCategories: { method: 'get', url: 'admin/poassetcategories' },
        getPOZip: { method: 'post', url: 'payments/po/zip' },
    },
    admin: {
        getPoAssetsCategories: { method: 'get', url: 'admin/poassetcategories' },
        getIssueCategories: { method: 'get', url: 'admin/issuecategories' },
        listAllDistrict: { method: 'get', url: 'admin/districts' },
        listAllLocalities: { method: 'get', url: 'admin/localities' },
    },
    project: {
        getProjectZip: { method: 'post', url: 'tickets/signoff/zip' },
        projectPost: { method: 'post', url: 'projects/{projectId}/chat-post' },
        getProjectPostList: { method: 'get', url: 'projects/{projectId}/chat-post' },
        projectPostStatus: { method: 'put', url: 'projects/chat-post/{postId}/toggle' },
        linkedProjectOfUser: { method: 'get', url: 'users/{userId}/projects' },
        linkedProjectOfTeam: { method: 'get', url: 'users/teams/{teamId}/projects' },
        editProjectPM: { method: 'put', url: 'projects/{projectId}/pm' },
        getProjectPM: { method: 'get', url: 'projects/{projectId}/pm' },
    },
    fm: {
        getProjectTickets: { method: 'post', url: 'tickets/billing' },
    },
};

export default microservices;
