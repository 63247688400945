import React, { useMemo } from 'react';
import { textCss } from '../../../common/Css';
import OverflowTip from '../../../common/OverflowTip';
import Verticalline from '../../../common/Verticalline'
import moment from 'moment/moment';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import ViewAllRemark from '../../Ticket/Modal/ViewAllRemark';
import { useDispatch, useSelector } from 'react-redux';
import IconToolTip from '../../../common/IconToolTip';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { ticketTypes } from '../../Ticket/TicketController';
import Loader from '../../../common/Loader';
import { validateStringValue } from '../../../utils/common';

const iconNames = {
    COMPLETE: { status: "done", color: "scogosuccess" },
    ALERT: { status: 'warning', color: 'scogoclosed' },
    EMPTY: { status: '', color: 'scogogray' },
    WAITING: { status: 'hourglass_bottom', color: 'scogoorange' }
}


const Progress = ({ remarkList, ticket }) => {
    const { viewTicket } = useSelector((state) => state.tickets);
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();

    const ticketData = useMemo(() => {
        let t = {};
        if (viewTicket) t = viewTicket;
        if (ticket) return {
            ...ticket,
            fk_assigned_primary_sp_id: ticket.fk_assigned_primary_sp_id,
            request_raised: ticket.request_raised,
            fk_assigned_fe_id: ticket.fk_assigned_fe_id,
            reached_site: ticket.reached_site,
            is_escalated: ticket.is_escalated,
            is_on_hold: ticket.is_on_hold,
            is_sign_off_request: ticket.is_sign_off_request,
            is_signoff_accept: ticket.is_signoff_accept,
            job_sign_off_date: ticket.job_sign_off_date,
            reached_site_date: ticket.reached_site_date,
            sign_off_approval_date: ticket.sign_off_approval_date,
            ticket_type: ticket.ticket_type,
            created_at: ticket.created_at,
            pm_first_name: ticket.pm_detail && ticket.pm_detail.first_name,
            pm_last_name: ticket.pm_detail && ticket.pm_detail.last_name,
            sp_first_name: ticket.accepted_sp && ticket.accepted_sp.first_name,
            sp_last_name: ticket.accepted_sp && ticket.accepted_sp.last_name,
            fe_first_name: ticket.accepted_fe && ticket.accepted_fe.first_name,
            fe_last_name: ticket.accepted_fe && ticket.accepted_fe.last_name,
            fe_assigned_timestamp: ticket.accepted_fe && ticket.accepted_fe.fe_assigned_timestamp,
            broadcast_timestamp: ticket.pm_detail && ticket.pm_detail.broadcast_timestamp,
            sp_accept_timestamp: ticket.sp_accept_timestamp,
        };
        return t;

    }, [viewTicket, ticket]);

    if (isViewTicketByIdLoading) {
        return <div className='my-24  '>
            <Loader color='#F48A21' size='45' speed='1' thickness='3' marginTop='1rem' />
        </div>
    }

    const isSpAssigned = ticketData?.fk_assigned_primary_sp_id > 0;
    const isTicketRequestRaised = ticketData?.request_raised === 1;
    const isSpAcceptedTicket = ticketData?.accepted_sp_id > 0;
    const isFeAssigned = ticketData?.fk_assigned_fe_id > 0;
    const isFeAcceptedTicket = ticketData?.accepted_fe_id > 0;
    const isFeReachedSite = ticketData?.reached_site > 0;
    const isTicketEscalated = ticketData?.is_escalated > 0;
    const isTicketOnHold = ticketData?.is_on_hold > 0;
    const isSignOffRaised = ticketData?.is_sign_off_request > 0;
    const isSignOffAccepted = ticketData?.is_signoff_accept === 1;
    const ticketStatus = ticketData?.status ? ticketData?.status : '';
    const job_sign_off_date = ticketData?.job_sign_off_date ? ticketData?.job_sign_off_date : '';

    const closeModal = () => dispatch(closeModalAction())

    const reachedSiteDate = ticketData?.reached_site_date ? moment(ticketData?.reached_site_date) : undefined;
    const signOffApprovalDate = ticketData?.sign_off_approval_date ? moment(ticketData?.sign_off_approval_date) : undefined;
    const isFieldTicket = ticketData?.ticket_type === ticketTypes.field;

    const spAssignment = (ticket) => {

        let spAssignmentIntialObj = {
            label: 'Technician Alignment',
            status: 'EMPTY',
            // timestamp: `Ticket created on ${moment(ticket?.created_at).format('DD MMM YYYY h:mm a')}`
            timestamp: 'Ticket is not broadcasted'
        }

        if (!isSpAssigned && !isTicketRequestRaised) {
            spAssignmentIntialObj = {
                label: 'Technician Alignment',
                status: 'ALERT',
                // timestamp: ` by ${ticket?.pm_first_name} ${ticket?.pm_last_name} on ${moment(ticket?.created_at).format('DD MMM YYYY h:mm a')}`
                timestamp: 'Ticket is not broadcasted'
            }
        }

        if (isTicketRequestRaised && !isSpAcceptedTicket) {
            spAssignmentIntialObj = {
                label: 'Technician Alignment',
                status: 'WAITING',
                timestamp: `Ticket Broadcasted on ${moment(ticket?.broadcast_timestamp).format('DD MMM YYYY h:mm a')}`
            }
        }

        if (isSpAcceptedTicket) {
            spAssignmentIntialObj = {
                label: 'Technician Alignment',
                status: 'COMPLETE',
                timestamp: `${ticket?.sp_first_name} ${ticket?.sp_last_name}${!['', undefined, null].includes(ticket?.sp_accept_timestamp) && moment(ticket?.sp_accept_timestamp).isValid() ? ` on ${moment(ticket?.sp_accept_timestamp).format('DD MMM YYYY h:mm a')}` : ''}`
            }
        }
        return spAssignmentIntialObj;
    };

    const feAssignment = (ticket) => {
        if (!isSpAcceptedTicket) {
            return {
                label: 'FE Status',
                status: '',
                timestamp: 'Waiting for SP to Accept the Ticket'
            }
        }

        let feAssignmentIntitalObject = {
            label: 'FE Status',
            status: 'WAITING',
            timestamp: 'Waiting for SP to Assign FE'
        }

        if (!isFeAssigned) {
            feAssignmentIntitalObject = {
                label: 'FE Unassigned',
                status: 'ALERT',
                timestamp: `Waiting for SP to Assign FE`
            }
        }

        if (isFeAssigned && !isFeAcceptedTicket) {
            const time = ticket.fe_assigned_timestamp && moment(ticket.fe_assigned_timestamp).isValid() ? ` on ${moment(ticket.fe_assigned_timestamp).format('DD MMM YYYY h:mm a')}` : '';

            feAssignmentIntitalObject = {
                label: 'Waiting for SP to accept the Ticket',
                status: 'WAITING',
                timestamp: `Ticket Assigned to FE${time}`
            }
        }

        if (isFeAcceptedTicket) {
            const time = ticket?.fe_assigned_timestamp && moment(ticket?.fe_assigned_timestamp).isValid() ? ` on ${moment(ticket?.fe_assigned_timestamp).format('DD MMM YYYY h:mm a')}` : '';

            feAssignmentIntitalObject = {
                label: 'FE accepted the Ticket',
                status: 'COMPLETE',
                timestamp: `by ${ticket?.fe_first_name} ${ticket?.fe_last_name}${time}`
            }
        }
        return feAssignmentIntitalObject;
    };

    const ticketExecution = () => {
        if (!isSpAcceptedTicket || !isFeAcceptedTicket) {
            return {
                label: 'Technician reached site',
                status: '',
                timestamp: 'Waiting For Technician to Accept the ticket'
            }
        }

        let ticketExecutionIntialObj = {
            label: 'Technician reached site',
            status: 'EMPTY',
            timestamp: ticketStatus
        }

        if (!isFeReachedSite) {
            ticketExecutionIntialObj = {
                label: 'Technician reached site',
                status: 'ALERT',
                timestamp: `Ticket Execution pending`
            }
        }

        if (isFeReachedSite) {
            ticketExecutionIntialObj = {
                label: 'Technician reached site',
                status: 'COMPLETE',
                timestamp: `Ticket Execution Started ${reachedSiteDate?.isValid() ? `on ${reachedSiteDate.format('DD MMM YYYY h:mm a')}` : ''}`
            }
        }

        return ticketExecutionIntialObj;
    };

    const ticketSignOff = () => {
        if (!isFeAcceptedTicket || !isFeReachedSite || !isSpAcceptedTicket) {
            return {
                label: 'Site Completed',
                status: '',
                timestamp: 'Waiting for FE to raise Signoff request'
            }
        }


        let ticketSignOffIntialObj = {
            label: 'Site Completed',
            status: 'EMPTY',
            timestamp: ticketStatus
        }

        if (((isTicketEscalated || isTicketOnHold) && !isSignOffRaised)) {
            let timestamp = ticketStatus;
            if (ticketData.all_event_detail) {
                let findHoldStatus = ticketData.all_event_detail.find((e) => e.event_master_id === 45);
                if (findHoldStatus) timestamp = `By ${validateStringValue(findHoldStatus.first_name)} on ${moment(findHoldStatus.created_at).format('DD MMM YYYY h:mm a')}`;
            }

            ticketSignOffIntialObj = {
                label: 'Ticket On Hold',
                status: 'ALERT',
                timestamp
            }
        }

        if ((isFeReachedSite && !isTicketEscalated && !isTicketOnHold && !isSignOffRaised)) {
            ticketSignOffIntialObj = {
                label: 'Site Completed',
                status: 'WAITING',
                timestamp: ticketStatus
            }
        }

        if (isSignOffRaised) {
            ticketSignOffIntialObj = {
                label: 'Site Completed',
                status: 'COMPLETE',
                timestamp: `on ${moment(job_sign_off_date).format('DD MMM YYYY h:mm a')}`
            }
        }

        return ticketSignOffIntialObj;
    };

    const signOffApproved = () => {
        if ((!isFeAcceptedTicket || !isFeReachedSite || !isSpAcceptedTicket || !isSignOffRaised) && isFieldTicket) {
            return {
                label: 'Signoff Approved',
                status: 'EMPTY',
                timestamp: 'Signoff is not raised'
            }
        }

        let signOffIntialObj = {
            label: 'Signoff Approved',
            status: 'COMPLETE',
            timestamp: 'with PM'
        }

        if (!isSignOffAccepted) {
            signOffIntialObj = {
                label: 'Signoff Approved',
                status: 'WAITING',
                timestamp: 'with PM'
            }
        }

        if (isSignOffAccepted) {
            signOffIntialObj = {
                label: 'Signoff Approved',
                status: 'COMPLETE',
                timestamp: `Ticket closed ${signOffApprovalDate?.isValid() ? `on ${signOffApprovalDate.format('DD MMM YYYY h:mm a')}` : ''}`
            }
        }

        return signOffIntialObj;
    };

    const getProgressStepsData = (ticket) => {
        // let data = [spAssignment(ticket), feAssignment(ticket), ticketExecution(ticket), ticketSignOff(ticket), signOffApproved(ticket)];
        const ticketCreated = {
            label: 'Ticket is created',
            status: 'COMPLETE',
            timestamp: `by ${ticket?.pm_first_name} ${ticket?.pm_last_name} on ${moment(ticket?.created_at).format('DD MMM YYYY h:mm a')}`
        }
        let data = [ticketCreated, spAssignment(ticket), ticketExecution(ticket), ticketSignOff(ticket), signOffApproved(ticket)];
        // if (ticket.accepted_fe_id === ticket.accepted_sp_id) {
        //     data = [spAssignment(ticket), ticketExecution(ticket), ticketSignOff(ticket), signOffApproved(ticket)];
        // }
        return data;
    };

    const progressArray = getProgressStepsData(ticketData);
    const getIconColor = (item) => {
        if (isSignOffAccepted) return iconNames.COMPLETE.color;
        return `${iconNames[item?.status] ? iconNames[item?.status]?.color : 'scogogray'}`
    };


    const viewHistory = () => {
        let modalComponent = <ViewAllRemark remarkList={remarkList} closeModal={closeModal} ticketId={ticketData?.id} source='ticket_table' />
        let modalWidth = '60em',
            modalHeight = '40em';
        let head = 'History';
        dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
    };

    return (
        <div>
            <ul>
                {Array.isArray(progressArray) && progressArray.map((item, index) => {
                    return (
                        <div key={index}>
                            <li className={`border bottom-2 py-4 relative  ${index % 2 !== 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                <div className='flex  items-center'>
                                    <div className='px-4'>
                                        <span
                                            style={{ height: '3.5rem', width: '3.5rem' }}
                                            className={`material-icons scogoprimary text-font18 border-2 border-${getIconColor(
                                                item
                                            )} flex items-center justify-center rounded-full  font-black text-${getIconColor(item)}`}
                                        >
                                            {isSignOffAccepted ? iconNames.COMPLETE.status : iconNames[item?.status]?.status}
                                        </span>
                                    </div>
                                    <div className={`${textCss.headerThree} text-black text-font14  overflow-hidden`}>
                                        <p className='truncate cursor-pointer '>
                                            <OverflowTip someLongText={item?.label} />
                                        </p>
                                        {item.timestamp && <p className='text-scogogray font-normal text-font11'>{item.timestamp}</p>}
                                    </div>
                                </div>
                                <Verticalline marginTop='-4px' isLast={index + 1 === progressArray.length} color={getIconColor(item)} />
                            </li>
                        </div>
                    );
                })}
            </ul>
            {remarkList && Array.isArray(remarkList) && remarkList.length > 0 && <div className='px-4'> <IconToolTip title={'View History'}>
                <ButtonScogoOrange buttonClass='text-font11' textOrComponent={'View History'} onClick={viewHistory} />
            </IconToolTip></div>
            }

        </div>
    );
}


export default Progress;