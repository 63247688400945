import React, { useEffect, useState } from 'react';
import formTable from '../../../../common/formTable';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../../actions/modal';
import { getPoAssetsCategoriesDropdown } from '../../../../actions/payments';
import { viewTicketById } from '../../../../actions/tickets';
import { getUserBankDetails } from '../../../../actions/auth';
import { getBanksList } from '../../../../actions/users';
import { getConversationDetails } from '../../../../actions/conversations';
import MiniPopup from '../../../../common/MiniPopup';
import { SkeltonCardOne } from '../../../../common/SkeltonCardOne';
import { WalletPopup } from './WalletPopup';
import { PayBankPopup, payPoFormname } from './BankPopup';
import { PayManualPopup } from './ManualPopup';
import ButtonScogoOrange from '../../../../common/Buttons/ButtonScogoOrange';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import { getWalletDetailsById, getWalletTransactionsDetails, removeWalletDetailsFromStore } from '../../../../actions/wallet';
import { validateNumberValue } from '../../../../utils/common';
import { ScogoAmountBalance } from '../../../Wallet/Modals/SendToBank';
import { isCustomerGroup } from '../../../../utils/role';
import { bankDetailPayload } from '../EditPayout/TicketBankTransferPayment';

const PayPo = ({ ticket, isRefresh }) => {
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(closeModalAction());
    };
    const { poassetcategories } = useSelector((state) => state.payments);
    const { conversationDetails, openedConversationId } = useSelector((state) => state.conversations);
    const openedConversation = conversationDetails?.[openedConversationId];
    const { isConversationDetailsLoading, copyTxnDetailsLoading } = useSelector((state) => state.loading);
    const ticketPo = openedConversation?.ticket?.po;
    const { walletDetailsById } = useSelector((state) => state.wallet);

    const [activeCopyItemId, setActiveCopyItemId] = useState(undefined);

    useEffect(() => {
        const { ticketId, vendorId } = ticket;
        console.log('ticket:', ticket);
        dispatch(getPoAssetsCategoriesDropdown());
        dispatch(viewTicketById({ ticketId: ticketId }));
        dispatch(getConversationDetails({ _id: ticketId, useConversationId: true }));
        const payload = bankDetailPayload({ vendorId, isPo: true });
        dispatch(getUserBankDetails(payload));
        dispatch(getBanksList());
    }, [dispatch, ticket]);

    useEffect(() => {
        if (!copyTxnDetailsLoading) {
            setActiveCopyItemId(undefined);
        }
        return () => {
            if (!copyTxnDetailsLoading && !['', undefined, null].includes(activeCopyItemId)) {
                setActiveCopyItemId(undefined);
            }
        };
    }, []);

    useEffect(() => {
        dispatch(getWalletDetailsById({ updateBalance: false, accountBalance: 'BANKOPEN' }));

        return () => {
            dispatch(removeWalletDetailsFromStore())
        }
    }, [dispatch]);

    const getClipboardContent = ({ itemId, transactions }) => {
        const payload = {
            scogoTransactions: transactions,
            isPoItem: true,
            customerId: ticket.customer,
        };
        setActiveCopyItemId(itemId);
        dispatch(getWalletTransactionsDetails({ data: payload, copyToClipboard: true }));
    };

    const getTableColumnElement = (value) => <div className='truncate w-4/5'>{value}</div>;
    const scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance);
    const schema = {
        table: [
            {
                head: {
                    thClassName: `w-60`,
                    headName: 'Asset',
                },
                body: {
                    render: (po) => getTableColumnElement(po.assetName),
                    bodyClassName: `w-60 mx-2 `,
                },
            },
            {
                head: {
                    thClassName: `w-60 mx-2`,
                    headName: 'Category',
                },
                body: {
                    render: (po) => {
                        let poAssetCategory = Array.isArray(poassetcategories) && poassetcategories.find((i) => i.value === po.category);
                        let value = poAssetCategory?.text ? poAssetCategory.text : '';
                        return getTableColumnElement(value);
                    },
                    bodyClassName: `w-60 mx-2`,
                },
            },
            {
                head: {
                    thClassName: `w-60 mx-2`,
                    headName: 'Description',
                },
                body: {
                    render: (po) => getTableColumnElement(po.description),
                    bodyClassName: `w-60 mx-2`,
                },
            },
            {
                head: {
                    thClassName: `w-60 mx-2`,
                    headName: 'Quantity',
                },
                body: {
                    render: (po) => getTableColumnElement(po.quantity),
                    bodyClassName: `w-60 mx-2`,
                },
            },
            {
                head: {
                    thClassName: `w-60 mx-2`,
                    headName: 'Unit Price',
                },
                body: {
                    render: (po) => getTableColumnElement(po.unitPrice),
                    bodyClassName: `w-60 mx-2`,
                },
            },
            {
                head: {
                    thClassName: `w-60 mx-2`,
                    headName: 'Total Price',
                },
                body: {
                    render: (po) => getTableColumnElement(po.totalPrice),
                    bodyClassName: `w-60 mx-2`,
                },
            },
            {
                head: {
                    thClassName: `w-80 mx-2`,
                    headName: 'Action/Status',
                },
                body: {
                    bodyClassName: `w-80 mx-2`,
                    render: (po) => (
                        <GetPaymentStatus
                            po={po}
                            isRefresh={isRefresh}
                            copyTxnDetailsLoading={copyTxnDetailsLoading}
                            getClipboardContent={getClipboardContent}
                            activeCopyItemId={activeCopyItemId}
                            scogoBankAccountBalance={scogoBankAccountBalance}
                        />
                    ),
                },
            },
        ],
    };

    if (isConversationDetailsLoading) return <SkeltonCardOne />;

    return (
        <>
            <div className='px-6 py-3 max-w-max'>
                <ScogoAmountBalance />
            </div>
            <div style={{ padding: '1.25rem' }}>

                {Array.isArray(ticketPo?.poitems) &&
                    formTable({
                        schema,
                        form: ticketPo,
                        arrayName: 'poitems',
                        tableError: 'Add Invoice',
                    })}
                <div className='flex justify-end pt-6 px-4'>
                    <ButtonScogoClosedOutlined textOrComponent='Close' onClick={closeModal} />
                </div>
            </div>
        </>
    );
};

export default PayPo;

export const ActionButton = ({ buttonName, popup, disabled = false, closeMenu }) => {
    return (
        <MiniPopup
            handleClick={() => { }}
            popup={popup}
            transition={false}
            className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-hidden left-0 top-0 border-2'
            menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
            <ButtonScogoOrange disabled={disabled} textOrComponent={buttonName} buttonClass={`hover:bg-scogoorange hover:text-white z-0 h-8`} disabledClass='text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd h-8' />
        </MiniPopup>
    );
};

const GetPaymentStatus = ({ po, isRefresh, copyTxnDetailsLoading = false, activeCopyItemId = '', getClipboardContent = () => { }, scogoBankAccountBalance }) => {
    const { bankDetails } = useSelector((state) => state.auth);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;

    const lookup = {
        PAID: { label: 'PAID', color: 'scogogoing' },
        INITIATED: { label: 'INITIATED', color: 'scogoorange' },
        AWAITING: { label: 'AWAITING', color: 'scogoorange' },
        PROCESSING: { label: 'PROCESSING', color: 'scogoorange' },
    };
    let status = lookup?.[po?.payment?.status];
    if (status) {
        return (
            <>
                <div className='flex flex-row'>
                    <p className={`bg-${status.color} scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                        {status.label}
                        {status.label === 'PAID' && po?.payment?.transactions?.length > 0 ? (
                            <>
                                &nbsp;&nbsp;
                                <span
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getClipboardContent({ transactions: po?.payment?.transactions, itemId: po?._id });
                                    }}
                                    className={`material-icons text-base text-scogogray cursor-pointer hover:text-scogogray`}
                                >
                                    content_copy
                                </span>
                                {copyTxnDetailsLoading && activeCopyItemId === po?._id ? (
                                    <div className='animate-spin w-4 h-4 border-t-2 border-scogoprimary rounded-full' />
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                    {status.label === 'PAID' && !['', undefined, null].includes(po?.payment?.channel) ? (
                        <>
                            <p className={`bg-scogogoing scogoprimary max-w-max text-white text-font10 px-p-6 py-p-3 flex items-center rounded-3px mr-2 mb-1`}>
                                {po?.payment?.channel === 'BANK_TRANSFER' ? 'BANK TRANSFER' : po?.payment?.channel}
                            </p>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    const disableButtons = !status && formButtonLoading[payPoFormname];
    if (po?.payment?.status === 'UNPAID') {
        return (
            <div className='flex items-center gap-2 relative'>
                <ActionButton disabled={isCustomerGroup(role, type) || disableButtons} buttonName='Wallet' popup={<WalletPopup poItem={po} isRefresh={isRefresh} />} />
                <ActionButton disabled={isCustomerGroup(role, type) || (scogoBankAccountBalance < 400 && bankDetails) || disableButtons} buttonName='Bank' popup={<PayBankPopup poItem={po} isRefresh={isRefresh} />} />
                <ActionButton disabled={disableButtons} buttonName='Manually' popup={<PayManualPopup poItem={po} isRefresh={isRefresh} />} />
            </div>
        );
    }
};
