import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects';
import api from '../apis';
import { auth, websocket, loading, toast, wallet, modal, whatsapp, user } from '../types';
import Cookies from 'universal-cookie';
import config from '../config';
import { setJWTToken, getRootDomain, setWhatsappAlternateMobilePopup, validateStringValue } from '../utils/common';
import { isValidMobileNumber } from '../../src/utils/utils';
import { isCustomer, isFe, isIsp, isScm, isSp, SP } from '../utils/role';
import { isUserOnboarded } from '../utils/access';
import { chatTutorial, tutorialList } from '../common/Tutorial/Tutorial';
import { trackSupportTicket } from '../router/roleWisePageRoute';


const cookies = new Cookies();

export function* checkIfUserIsRegistered(action) {
    try {
        const { mobileOrEmail, navigate, isRedirect = true } = action.payload;
        yield put({ type: loading.CHECK_USER_LOADING, payload: true });
        const response = yield call(api.auth.checkIfUserIsRegistered, {}, {}, {}, { mobile: mobileOrEmail, logged_in_from: 'web' });
        if ([200, 201, 202, 203].includes(response.data.code)) {
            if ([201, 203].includes(response.data.code)) {
                if (isRedirect) {
                    navigate('/set-password');
                }
            } else if (response.data.code === 202) {
                if (isRedirect) {
                    navigate('/password');
                }
            } else {
                if (isValidMobileNumber(mobileOrEmail)) {
                    yield put({ type: auth.REQUEST_OTP_REQUEST, payload: { mobile: mobileOrEmail, navigate } });
                } else {
                    if (isRedirect) navigate('/password');
                }
            }
            yield put({ type: auth.CHECK_USER_SUCCESS, payload: response.data });
        } else {
            let error_message = `Error: ${response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}`;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } });
        }
        yield put({ type: loading.CHECK_USER_LOADING, payload: false });
    } catch (error) {
        yield put({ type: auth.CHECK_USER_FAILURE, payload: error });
    }
}

export function* requestOtp(action) {
    try {
        const { mobile, navigate, isRedirect = true } = action.payload;
        yield put({ type: loading.REQUEST_OTP_LOADING, payload: true });
        const response = yield call(api.auth.checkIfUserIsRegistered, {}, {}, {}, { mobile: mobile, skip: true });
        if ([200, 201, 202, 203].includes(response.data.code)) {
            if (isRedirect) navigate('/otp');
            yield put({ type: auth.REQUEST_OTP_SUCCESS, payload: true });
        } else {
            let error_message = `Error: ${response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}`;
            yield put({ type: auth.REQUEST_OTP_SUCCESS, payload: false });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } });
        }
        yield put({ type: loading.REQUEST_OTP_LOADING, payload: false });
    } catch (error) {
        yield put({ type: auth.REQUEST_OTP_FAILURE, payload: error });
    }
}

export function* loginWithOTP(action) {
    const { mobile, auth_otp, navigate, resendOtp, logged_in_from = 'web', waId, redirect_to } = action.payload;
    try {
        if (!resendOtp) yield put({ type: loading.SET_OTP_BUTTON_LOADING, payload: true });
        const response = yield call(api.auth.signInWithOTP, {}, {}, {}, { mobile, auth_otp, logged_in_from, skip: true, waId });
        if (response.data.code === 200) {
            const rootDomain = getRootDomain();
            setJWTToken({ token: response.data.data.token, options: { domain: rootDomain } });
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
            if (logged_in_from === 'support_view' && navigate && redirect_to) {
                yield put({ type: modal.CLOSE_MODAL });
                navigate(redirect_to);
            }
        } else {
            let error_message = `Error: ${response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}`;
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } }),
                yield put({ type: auth.AUTH_ERROR, payload: response.data.message }),
            ]);
        }
    } catch (error) {
        yield put({ type: auth.LOGIN_WITH_OTP_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_OTP_BUTTON_LOADING, payload: false });
}

export function* loginWithPassword(action) {
    const { email, password, navigate } = action.payload;
    try {
        yield put({ type: loading.SET_PASSWORD_BUTTON_LOADING, payload: true });
        const response = yield call(api.auth.signInWithPassword, {}, {}, {}, { email, password, logged_in_from: 'web' });

        if (response.data.code === 200) {
            const rootDomain = getRootDomain();
            setJWTToken({ token: response.data.data.token, options: { domain: rootDomain } });
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });

        } else {
            let error_message = `${response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}`;
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } }),
                yield put({ type: auth.AUTH_ERROR, payload: response.data.message }),
            ]);
        }
    } catch (error) {
        yield put({ type: auth.LOGIN_WITH_OTP_FAILURE, payload: error });
    }
    yield put({ type: loading.SET_PASSWORD_BUTTON_LOADING, payload: false });
}

export function* sendForgotPasswordEmail(action) {
    try {
        const { forgotPasswordEmail } = action.payload;
        yield put({ type: loading.SEND_FORGOT_PASSWORD_EMAIL_LOADING, payload: true });
        const response = yield call(api.auth.sendForgotPasswordEmail, {}, {}, {}, { email: forgotPasswordEmail });
        if ([200, 201, 202, 203].includes(response.data.code)) {
            yield put({ type: auth.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS, payload: response.data.message });
            yield put({ type: auth.SHOW_FORGOT_PASSWORD_EMAIL_SENT, payload: true });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } });
        }
        yield put({ type: loading.SEND_FORGOT_PASSWORD_EMAIL_LOADING, payload: false });
    } catch (error) {
        yield put({ type: auth.SEND_FORGOT_PASSWORD_EMAIL_FAILURE, payload: error });
    }
}

export function* signOutUser(action) {
    const location = window.location.pathname;
    let redirectionUrl = '/login';
    if (location === trackSupportTicket.path) redirectionUrl = trackSupportTicket.path;
    try {
        const navigate = action.payload;
        yield call(api.auth.userLogout, {});
        localStorage.removeItem('chatBoxElementData')
        const tutorialKeyList = tutorialList.map((element) => element.key)

        Object.keys(localStorage).forEach(key => {
            if (!tutorialKeyList.includes(key)) {
                localStorage.removeItem(key)
            }
        })

        yield put({ type: websocket.DISCONNECT_FROM_WEBSOCKET_REQUEST });
        const rootDomain = getRootDomain();
        setJWTToken({ options: { domain: rootDomain, expires: new Date() } });
        setWhatsappAlternateMobilePopup("no")
        localStorage.removeItem('__scogo_notifications');
        yield put({ type: auth.RESET_REDUX_STORE });
        navigate(redirectionUrl);
    } catch (error) {
        console.error(error);
    }
}

export function* getUserProfile(action) {
    const { navigate } = action.payload;
    try {
        const response = yield call(api.auth.userprofile);
        if (response.data.code === 200) {
            let { user } = response.data.data;
            if (isUserOnboarded(user.status)) {
                yield put({ type: auth.USER_PROFILE_SUCCESS, payload: user });
                if (isScm(user.role.id, user.type)) {
                    yield put({ type: whatsapp.GET_WHATSAPP_QR, payload: {} })
                }
            } else {

                let customerOnboardingStatus, spOnboardingStatus, feOnboardingStatus;
                if (isCustomer(user.role.id, user.type)) {
                    let response = yield call(api.auth.getCustomerStatus, {});
                    if (response.data.code === 200 && response.data.data.is_pending) {
                        customerOnboardingStatus = response.data.data;
                    }
                } else if (user.role.id === SP.role || isIsp(user.role.id, user.type) || isSp(user.role.id, user.type)) {
                    let response = yield call(api.auth.getSPOnboardingStatus, {});
                    if (response.data.code === 200) {
                        spOnboardingStatus = response.data.data;
                    }
                } else if (isFe(user.role.id, user.type)) {
                    let response = yield call(api.auth.getFEOnboardingStatus, {});
                    if (response.data.code === 200) {
                        feOnboardingStatus = response.data.data;
                    } else if (response.data.code === 400) {
                        feOnboardingStatus = {
                            field_engineer_detail: false
                        }
                    }
                } else if (isScm(user.role.id, user.type)) {
                    yield put({ type: whatsapp.GET_WHATSAPP_QR, payload: {} })
                }
                user = { ...user, customerOnboardingStatus, spOnboardingStatus, feOnboardingStatus };
                yield put({ type: auth.USER_PROFILE_SUCCESS, payload: user });
                const rootDomain = getRootDomain();
                const expires = new Date();
                expires.setDate(expires.getDate() + 30);
                cookies.set(`${config.name}-isLogged`, 'yes', { domain: rootDomain, expires, path: '/' });
            }
        } else {
            yield all([
                yield put({ type: auth.SIGN_OUT_USER, payload: navigate }),
                yield put({ type: auth.USER_PROFILE_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: auth.SIGN_OUT_USER, payload: navigate });
        yield put({ type: auth.USER_PROFILE_FAILURE, payload: error });
    }
}

export function* getUserBankDetails(action) {
    const { id, type } = action.payload;
    let queryParams = {};
    if (type) queryParams.type = type;
    try {
        yield put({ type: loading.GET_USER_BANK_DETAILS_LOADING, payload: true });
        const response = yield call(api.auth.getBankDetails, {}, { id }, queryParams);
        if (response.data.code === 200) {
            yield put({ type: auth.GET_USERS_BANK_DETAILS_SUCCESS, payload: response.data.data.bank_detail });
        } else {
            yield put({ type: auth.GET_USERS_BANK_DETAILS_SUCCESS, payload: undefined });
        }
    } catch (error) {
        yield put({ type: auth.GET_USERS_BANK_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_USER_BANK_DETAILS_LOADING, payload: false });
}

export function* getUserBankDetailsById(action) {
    const { id, type, check_user } = action.payload;
    let queryParams = { check_user };
    if (type) queryParams.type = type;
    try {
        yield put({ type: loading.GET_USER_BANK_DETAILS_LOADING, payload: true });
        const response = yield call(api.auth.getBankDetails, {}, { id }, queryParams);
        if (response.data.code === 200) {
            yield put({ type: user.GET_INDIVIDUAL_USER_BANK_DETAIL_SUCCESS, payload: { id, bank: response.data.data.bank_detail } });
        } else {
            yield put({ type: auth.GET_USERS_BANK_DETAILS_BY_ID_SUCCESS, payload: undefined });
        }
    } catch (error) {
        yield put({ type: auth.GET_USERS_BANK_DETAILS_BY_ID_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_USER_BANK_DETAILS_LOADING, payload: false });
}

export function* getWalletTransferOtp(action) {
    const { mobile, skip, bank_otp, source_url, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        yield put({ type: loading.REQUEST_OTP_LOADING, payload: true });
        const response = yield call(api.auth.checkIfUserIsRegistered, {}, {}, {}, { mobile: mobile, skip, bank_otp, source_url });
        if ([200, 201, 202, 203].includes(response.data.code)) {
        } else {
            let error_message = `Error: ${response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}`;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } });
        }
        yield put({ type: loading.REQUEST_OTP_LOADING, payload: false });
    } catch (error) {
        yield put({ type: auth.REQUEST_OTP_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getLoginOTP(action) {
    const { mobile, skip, bank_otp, amount, source_url, confirmWithdrawAmountOnSuccess, formName, redirectToOtp = false, navigate, waId } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    let body = { mobile, skip, bank_otp, amount, source_url };
    if (waId) body = { waId };

    try {
        const response = yield call(api.auth.getOTP, {}, {}, {}, body);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            if (redirectToOtp) {
                navigate('/otp');
            }
            if (confirmWithdrawAmountOnSuccess) {
                yield put({ type: wallet.SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED, payload: true });
            }
        } else {
            yield put({ type: auth.GET_OTP_FAILURE, payload: response.data.message });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message || 'Something went Wrong!' } });
        }
    } catch (error) {
        yield put({ type: auth.GET_OTP_FAILURE, payload: error });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something went Wrong!' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* registerWithMobile(action) {
    const { mobile, register_as, registered_via_whatsapp = 0, formName, referralCode } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

    try {
        const response = yield call(api.auth.registerWithMobile, {}, {}, {}, { mobile, register_as, registered_via_whatsapp, referralCode });
        if (response.data.code === 201) {
            yield put({ type: auth.GET_REGISTER_MOBILE_SUCCESS, payload: true });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'OTP Is Sent To Your Mobile Number' } });
        } else {
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message.mobile[0] });
            yield put({ type: auth.GET_REGISTER_MOBILE_SUCCESS, payload: false });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message.mobile[0] } });
        }
    } catch (error) {
        yield put({ type: auth.GET_REGISTER_MOBILE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* signUpWithOtp(action) {
    const { mobile, otp, logged_in_from = 'web', navigate, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.auth.signUpWithOtp, {}, {}, {}, { mobile, otp, logged_in_from });
        if (response.data.code === 200) {
            const rootDomain = getRootDomain();
            setJWTToken({ token: response.data.data.token, options: { domain: rootDomain } });
            let yieldAll = [];
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
            yield all(yieldAll);
        } else {
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } });
        }
    } catch (error) {
        yield put({ type: auth.GET_SIGNUP_OTP_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateProfile(action) {
    const { firstName, lastName, dob, type, alternate_mobile, gender, net_plan, net_provider, formName, working_sch, home_pincode } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    let body = {};
    if (firstName) body.first_name = firstName;
    if (lastName) body.last_name = lastName;
    if (dob) body.dob = dob;
    if (type) body.type = type;
    if (alternate_mobile) body.alternate_mobile = alternate_mobile;
    if (gender) body.gender = gender;
    if (net_plan) body.net_plan = net_plan;
    if (net_provider) body.net_provider = net_provider;
    if (working_sch) body.working_sch = working_sch;
    if (home_pincode) body.home_pincode = home_pincode;
    try {
        const response = yield call(api.auth.updateProfile, {}, {}, {}, body);
        if (response.data.code === 201) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Details Updated Sucessfully' } });
            yield put({ type: auth.UPDATE_PROFILE_SUCCESS, payload: body });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'User profile not updated successfully' } });
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message });
        }
    } catch (error) {
        yield put({ type: auth.UPDATE_PROFILE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateProfilePicture(action) {
    const { form, selectedLogoToShow } = action.payload;
    try {
        const response = yield call(api.user.uploadProfilePic, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 201) {
            yield put({ type: auth.UPDATE_PROFILE_PIC_SUCCESS, payload: { selectedLogoToShow } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
        } else {
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'File Type Not Supported!!!' } });
        }
    } catch (error) {
        yield put({ type: auth.UPDATE_PROFILE_PIC_FAILURE, payload: error });
    }
}

export function* setUserPassword(action) {
    const { data, navigate, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.user.setUserPwd, {}, {}, {}, data);
        if (response.data.code === 200) {
            const rootDomain = getRootDomain();
            setJWTToken({ token: response.data.data.token, options: { domain: rootDomain } });
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });

            yield put({ type: auth.SET_USER_PASSWORD_SUCCESS, payload: response.data });
        } else {
            yield put({ type: auth.SET_USER_PASSWORD_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: auth.SET_USER_PASSWORD_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* verifyUsersEmail(action) {
    const { token, navigate } = action.payload;
    try {
        const response = yield call(api.user.verifyMail, {}, {}, {}, { token });
        if ([200, 201].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })
            yield put({ type: auth.VERIFY_USERS_EMAIL_SUCCESS, payload: true })
        } else {
            navigate && navigate('/login')

            yield put({ type: auth.VERIFY_USERS_EMAIL_SUCCESS, payload: false })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } })
            yield put({ type: auth.VERIFY_USERS_EMAIL_FAILURE, payload: { message: response.data.message } })
            yield put({ type: auth.AUTH_ERROR, payload: response.data.message })
        }
    } catch (error) {
        yield put({ type: auth.VERIFY_USERS_EMAIL_FAILURE, payload: error });
    }
}

export function* uploadUsersIdProof(action) {
    const { form } = action.payload;
    try {
        const response = yield call(api.auth.uploadIdProofs, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 201) {
            yield put({ type: auth.UPDATE_ID_PROOF_SUCCESS, payload: response.data });
            let status = 'success';
            let message = `Id Proof Uploaded Successfully`;
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        } else {
            yield put({ type: auth.UPDATE_ID_PROOF_FAILURE, payload: response.data.message });
        }
    } catch (error) {
        yield put({ type: auth.UPDATE_ID_PROOF_FAILURE, payload: error });
    }
}


export function* resetUserPassword(action) {
    const { forgot_password_token, password, password_confirmation, formName, navigate, redirect = true } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.auth.changePassword, {}, {}, {}, { forgot_password_token, password, password_confirmation })
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response?.data?.data?.message ? response.data.data.message : 'Password changed successfully!!! You will be logged out of the platform' } });
            if (redirect) navigate('/login');
        } else {
            yield put({ type: auth.RESET_USER_PASSWORD_FAILURE, payload: { message: 'Oops Something went wrong' } })
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message ? response.data.message : 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: auth.RESET_USER_PASSWORD_FAILURE, payload: { message: error } })
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* requestOtpSupport(action) {
    try {
        const data = action.payload;
        yield put({ type: loading.REQUEST_OTP_LOADING, payload: true });
        const response = yield call(api.auth.checkIfUserIsRegistered, {}, {}, {}, data);
        if ([200, 201, 202, 203].includes(response.data.code)) {
            yield put({ type: auth.REQUEST_OTP_SUPPORT_SUCCESS, payload: true });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: validateStringValue(response.data?.data?.message) } });
        } else {
            let error_message = `Error: ${validateStringValue(response.data?.message?.message)}`;
            yield put({ type: auth.REQUEST_OTP_SUPPORT_SUCCESS, payload: false });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error_message } });
        }
    } catch (error) {
        yield put({ type: auth.REQUEST_OTP_SUPPORT_FAILURE, payload: error });
    }
    yield put({ type: loading.REQUEST_OTP_LOADING, payload: false });
}

export function* loginWithoutOtp(action) {
    const { waId, navigate, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.auth.getOTP, {}, {}, {}, { waId });
        if (response.data.code === 200) {
            const rootDomain = getRootDomain();
            setJWTToken({ token: response.data.data.token, options: { domain: rootDomain } });
            yield put({ type: auth.USER_PROFILE_REQUEST, payload: { navigate } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message.message } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something went Wrong!' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* autoLogin(action) {
    const { mob, room_id, onSuccess, onFailure } = action.payload;
    try {
        const response = yield call(api.auth.getOTP, {}, {}, {}, { mobile: mob, skip: true, source_url: true, room_id: room_id });
        if (response.data.code === 200) {
            const token = response?.data?.data?.token;
            const rootDomain = getRootDomain();
            setJWTToken({ token: token, options: { domain: rootDomain } });
            onSuccess();
        }
        else {
            onFailure();
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Auto Login Failed' } });
        onFailure();
    }
}

export default function* authSaga() {
    yield takeEvery(auth.CHECK_USER_REQUEST, checkIfUserIsRegistered);
    yield takeEvery(auth.REQUEST_OTP_REQUEST, requestOtp);
    yield takeLatest(auth.LOGIN_WITH_OTP_REQUEST, loginWithOTP);
    yield takeLatest(auth.LOGIN_WITH_PASSWORD_REQUEST, loginWithPassword);
    yield takeLatest(auth.SEND_FORGOT_PASSWORD_EMAIL_REQUEST, sendForgotPasswordEmail);
    yield takeLatest(auth.SIGN_OUT_USER, signOutUser);
    yield takeLatest(auth.USER_PROFILE_REQUEST, getUserProfile);
    yield takeLatest(auth.GET_USERS_BANK_DETAILS_REQUEST, getUserBankDetails);
    yield takeLatest(auth.GET_WALLET_TRANSFER_OTP_REQUEST, getWalletTransferOtp);
    yield takeLatest(auth.GET_OTP_REQUEST, getLoginOTP);
    yield takeLatest(auth.GET_REGISTER_MOBILE_REQUEST, registerWithMobile);
    yield takeLatest(auth.GET_SIGNUP_OTP_REQUEST, signUpWithOtp);
    yield takeLatest(auth.UPDATE_PROFILE_REQUEST, updateProfile);
    yield takeLatest(auth.UPDATE_PROFILE_PIC_REQUEST, updateProfilePicture);
    yield takeLatest(auth.SET_USER_PASSWORD_REQUEST, setUserPassword);
    yield takeLatest(auth.VERIFY_USERS_EMAIL_REQUEST, verifyUsersEmail);
    yield takeLatest(auth.UPLOAD_ID_PROOF_REQUEST, uploadUsersIdProof);
    yield takeLatest(auth.RESET_USER_PASSWORD_REQUEST, resetUserPassword);
    yield takeLatest(auth.GET_USERS_BANK_DETAILS_BY_ID_REQUEST, getUserBankDetailsById);
    yield takeLatest(auth.REQUEST_OTP_SUPPORT_REQUEST, requestOtpSupport);
    yield takeLatest(auth.LOGIN_WITHOUT_OTP_REQUEST, loginWithoutOtp);
    yield takeLatest(auth.AUTO_LOGIN, autoLogin);
}
