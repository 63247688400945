import moment from 'moment';

export const generateBoqExecutionStepForm = async (formValues, ticket) => {
    const form = new FormData();
    form.set('ticket_id', ticket.id);
    if (Array.isArray(formValues.assetsData)) {
        for (let index = 0; index < formValues.assetsData.length; index++) {
            const boqData = formValues.assetsData[index];
            form.set(`assetsData[${index}][id]`, !isNaN(boqData.id) && parseInt(boqData.id) > 0 ? boqData.id : 0);
            form.set(`assetsData[${index}][external_order_id]`, boqData.external_order_id || '');
            form.set(`assetsData[${index}][quantity]`, 1);
            form.set(`assetsData[${index}][make]`, boqData.make || '');
            form.set(`assetsData[${index}][model]`, boqData.model || '');
            form.set(`assetsData[${index}][partcode]`, boqData.partcode || '');
            form.set(`assetsData[${index}][asset_details]`, boqData.asset_details || '');
            form.set(`assetsData[${index}][additional_data]`, boqData.additional_data || '');
            form.set(`assetsData[${index}][serial_number]`, boqData.serial_number || '');
            form.set(`assetsData[${index}][is_boq_available]`, boqData.is_boq_available ? 1 : 0);
            if (!isNaN(boqData.asset_type_id) && parseInt(boqData.asset_type_id) > 0) {
                form.set(`assetsData[${index}][asset_type_id]`, boqData.asset_type_id);
            }
            form.set(`assetsData[${index}][last_worked_date]`, boqData.last_worked_date || '');
            form.set(`assetsData[${index}][latitude]`, boqData.latitude || '');
            form.set(`assetsData[${index}][longitude]`, boqData.longitude || '');
        }
    }
    if (Array.isArray(formValues.assetsConsumableData)) {
        for (let index = 0; index < formValues.assetsConsumableData.length; index++) {
            const boqData = formValues.assetsConsumableData[index];
            form.set(`assetsConsumableData[${index}][asset_type_id]`, boqData.asset_type_id);
            form.set(`assetsConsumableData[${index}][serial_number]`, boqData.serial_number || '');
            form.set(`assetsConsumableData[${index}][quantity]`, boqData.quantity || 1);
        }
    }

    return form;
};

export const generateExecutionStepForm = async (formValues, ticket) => {
    let scogoChecks = {};
    let filesToPost = [];
    let dataToSend = new FormData();
    dataToSend.set('ticket_id', ticket.id);
    await Promise.all(
        Object.keys(formValues).map(async (field, index) => {
            if (!field.includes('$') && !field.includes('scogocheckbox') && !field.includes('$geo_')) {
                let data;
                if (field.includes('namescogosingledropdown_')) {
                    if (typeof formValues[field] === 'object' && !['', undefined, null].includes(formValues[field].value)) {
                        if (formValues[field].value === 'others' && formValues[`$others${field}`]) {
                            data = formValues[`$others${field}`].toString();
                        } else {
                            data = formValues[field].value;
                        }
                    } else if (typeof formValues[field] === 'string') {
                        if (formValues[field] === 'others' && formValues[`$others${field}`]) {
                            data = formValues[`$others${field}`].toString();
                        } else {
                            data = formValues[field];
                        }
                    }
                } else if (field.includes('namescogomultidropdown_') && Array.isArray(formValues[field]) && formValues[field].length > 0) {
                    const multidata = [];
                    formValues[field].forEach((i) => {
                        if (typeof i === 'object') {
                            multidata.push(i.value);
                        } else if (typeof i === 'string') {
                            multidata.push(i);
                        }
                    });
                    data = multidata.filter((i) => i).split(',');
                } else if (field.includes('namescogodate_') && !['', undefined, null].includes(formValues[field]) && moment(formValues[field]).isValid()) {
                    data = moment(formValues[field]).format('DD-MM-YYYY HH:mm:ss');
                } else if (field.includes('namescogotime_') && !['', undefined, null].includes(formValues[field]) && moment(formValues[field]).isValid()) {
                    data = moment(formValues[field]).format('HH:mm a');
                } else {
                    data = formValues[field];
                }
                dataToSend.set(`stepsData[${index}][field_value]`, data);
                dataToSend.set(`stepsData[${index}][step_fields_relationship_id]`, parseInt(field.split('_')[1]));
                if (formValues[`$geo_${field}`]) {
                    dataToSend.set(`stepsData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                }
                if (field.split('_').length !== 2 && !isNaN(field.split('_')[2])) {
                    dataToSend.set(`stepsData[${index}][repeater_index]`, parseInt(field.split('_')[2]) + 1);
                }

                if (formValues[`$file${field}`]) {
                    await Promise.all(
                        [...formValues[`$file${field}`]].map(async (file, fileIndex) => {
                            try {
                                filesToPost.push({ file, caption: '' });
                                // const data = await compressImage(file);
                                dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                            } catch (error) {
                                console.error('compression error', error);
                                dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                            }
                        })
                    );
                }
            }
            if (field.includes('scogocheckbox') && !field.includes('$geo_')) {
                if (!field.includes('$file')) {
                    let similarWord = field.split('_')[0].slice(0, field.split('_')[0].length - 1);
                    let check = scogoChecks;
                    let filter = Object.keys(formValues).filter((data) => {
                        return data.includes(similarWord) && !data.includes('$file') && !data.includes('$geo_');
                    });
                    if (Array.isArray(check[similarWord])) {
                        check[similarWord].push(formValues[field]);
                    } else {
                        check[similarWord] = [formValues[field]];
                    }
                    scogoChecks = check;
                    if (filter.length === check[similarWord].length) {
                        scogoChecks[similarWord] = scogoChecks[similarWord].filter((item) => typeof item !== 'boolean');
                        dataToSend.set(`stepsData[${index}][field_value]`, scogoChecks[similarWord].join(','));
                        dataToSend.set(`stepsData[${index}][step_fields_relationship_id]`, parseInt(field.split('_')[1]));
                        const geoFilter = Object.keys(formValues).filter((data) => {
                            return data.includes(similarWord) && data.includes('$geo_');
                        });
                        if (geoFilter.length > 0 && formValues[geoFilter[0]]) {
                            dataToSend.set(`stepsData[${index}][geo_coordinates]`, formValues[geoFilter[0]]);
                        }
                        if (field.split('_').length !== 2 && !isNaN(field.split('_')[2])) {
                            dataToSend.set(`stepsData[${index}][repeater_index]`, parseInt(field.split('_')[2]) + 1);
                        }
                        let fileFilter = Object.keys(formValues).filter((data) => {
                            return data.includes(similarWord) && data.includes(`$file${similarWord}_${parseInt(field.split('_')[1])}`);
                        });
                        if (fileFilter.length === 1) {
                            let checkKey = `$file${similarWord}_${parseInt(field.split('_')[1])}`;
                            if (field.split('_').length !== 2) {
                                checkKey = `$file${similarWord}_${parseInt(field.split('_')[1])}_${parseInt(field.split('_')[2])}`;
                            }
                            await Promise.all(
                                [...formValues[checkKey]].map(async (file, fileIndex) => {
                                    try {
                                        filesToPost.push({ file, caption: '' });
                                        // const data = await compressImage(file);
                                        dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                                    } catch (error) {
                                        console.error('compression error', error);
                                        dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                                    }
                                })
                            );
                        }
                    }
                }
            }
            if (field.includes('$fileupload_') && !field.includes('$geo_') &&
                !field.includes('scogocheckbox') && formValues[field]) {
                await Promise.all(
                    [...formValues[field]].map(async (file, fileIndex) => {
                        dataToSend.set(`stepsData[${index}][field_value]`, file);
                        dataToSend.set(`stepsData[${index}][step_fields_relationship_id]`, parseInt(field.split('_')[1]));
                        if (formValues[`$geo_${field}`]) {
                            dataToSend.set(`stepsData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                        }
                        try {
                            filesToPost.push({ file, caption: '' });
                            // const data = await compressImage(file)
                            dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                        } catch (error) {
                            console.error('compression error', error);
                            dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                        }
                        if (field.split('_').length !== 2 && !isNaN(field.split('_')[2])) {
                            dataToSend.set(`stepsData[${index}][repeater_index]`, parseInt(field.split('_')[2]) + 1);
                        }
                    })
                );
            }
            if (field.includes('$videoupload_') && !field.includes('$geo_') &&
                !field.includes('scogocheckbox') && formValues[field]) {
                return [...formValues[field]].forEach((file, fileIndex) => {
                    filesToPost.push({ file, caption: '' });
                    dataToSend.set(`stepsData[${index}][field_value]`, file);
                    dataToSend.set(`stepsData[${index}][files][${fileIndex}]`, file);
                    dataToSend.set(`stepsData[${index}][step_fields_relationship_id]`, parseInt(field.split('_')[1]));
                    if (formValues[`$geo_${field}`]) {
                        dataToSend.set(`stepsData[${index}][geo_coordinates]`, formValues[`$geo_${field}`]);
                    }
                    if (field.split('_').length !== 2 && !isNaN(field.split('_')[2])) {
                        dataToSend.set(`stepsData[${index}][repeater_index]`, parseInt(field.split('_')[2]) + 1);
                    }
                });
            }
        })
    ).catch((error) => {
        console.error(error);
    });
    return { form: dataToSend, filesToPost };
};

export const generateIndependentTicketform = (data, existing, addNew) => {
    try {
        let ticket = { ...data };
        ticket.location_data.forEach((location) => {
            if (ticket.fk_customer_id) {
                location.fk_customer_id = ticket.fk_customer_id;
            }
            if (ticket.end_user_id) {
                location.end_user_id = ticket.end_user_id;
            }

            location.hard_copy_required = location.hard_copy_required ? parseInt(location.hard_copy_required) : 0;
            location.self_pickup_required = location.self_pickup_required ? parseInt(location.self_pickup_required) : 0;
            location.is_boq_required = location.is_boq_required ? parseInt(location.is_boq_required) : 0;
            location.is_noc_required = 1;
            location.fk_noc_template_id = location.fk_noc_template_id || 0;
            delete location.trackingDetails;
            delete location.customer;
            delete location.sow;
            delete location.sign_off_report;
            if (!addNew) {
                if (existing) {
                    location.use_existing = 1;
                } else {
                    location.create_new = 1;
                }
            }
            if (location.boq_data && Array.isArray(location.boq_data) && location.boq_data.length > 0) {
                location.boq_data.forEach((boq, boqIndex) => {
                    if (!boq.asset_type_id || boq.asset_type_id === '') {
                        location.boq_data.splice(boqIndex, 1);
                    }
                });
            }
        });
        if (ticket.fk_customer_id) {
            delete ticket.fk_customer_id;
        }
        if (ticket.end_user_id) {
            delete ticket.end_user_id;
        }
        return ticket;
    } catch (error) {
        console.error('form', error);
    }
};

export const generateCreateProjectform = (project) => {
    const isTruthyValue = (item) => {
        return ![undefined, null].includes(item);
    };
    try {
        let form = new FormData();
        Object.entries(project).forEach(([key, value]) => {
            if (key === 'sow' && isTruthyValue(value)) {
                form.set('sow', value[0]);
            } else if (key === 'sign_off_report' && isTruthyValue(value)) {
                form.set('sign_off_report', value[0]);
            } else if (key === 'teams') {
                value.map((teamId, reqindex) => {
                    return form.append(`teams[${reqindex}]`, teamId);
                });
            } else {
                if (isTruthyValue(value)) form.set(key, value);
            }
        });
        return form;
    } catch (error) {
        console.error(error);
    }
};

export const adminInviteUserForm = (data) => {
    let form = new FormData();
    Object.keys(data).forEach((key) => {
        if (key === 'sp_city_ids') {
            if (Array.isArray(data.sp_city_ids)) {
                data.sp_city_ids.map((item) => {
                    return form.append(`user[0][sp_city_ids][]`, parseInt(item));
                });
            } else {
                if (data.sp_city_ids) form.append(`user[0][sp_city_ids][]`, parseInt(data.sp_city_ids));
            }
        } else if (key === 'sp_use_cases') {
            data?.sp_use_cases?.map((item) => {
                return form.append(`user[0][sp_use_cases][]`, parseInt(item));
            });
        } else if (key === 'sp_have_gst') {
            if (data.hasOwnProperty('sp_have_gst') && data.sp_have_gst) {
                form.set(`user[0][sp_have_gst]`, 1);
            } else {
                form.set(`user[0][sp_have_gst]`, 0);
            }
        } else if (key === 'sp_pan_card_image') {
            form.set(`user[0][sp_pan_card_image]`, data.sp_pan_card_image ? data.sp_pan_card_image[0] : '');
        } else if (key === 'fe_adhar_card_image') {
            form.set(`user[0][fe_adhar_card_image]`, data.fe_adhar_card_image ? data.fe_adhar_card_image[0] : '');
        } else {
            form.set(`user[0][${key}]`, data[key]);
        }
    });
    return form;
};

export const getBankDetailsForm = (data, isSP = false) => {
    let form = new FormData();
    if (data.upi_id && data.upi_user_id && data.name_of_account_holder) {
        form.set('name_of_account_holder', data.name_of_account_holder);
        form.set('upi_user_id', parseInt(data.upi_user_id));
        form.set('upi_id', data.upi_id);
    } else {

        if (isSP) {
            form.set('sp_id', parseInt(data.sp_id));
            form.set('sp_bank_name', data.bank_name);
            form.set('sp_bank_ifsc_code', data.bank_ifsc_code);
            form.set('sp_bank_account_holder_name', data.bank_account_holder_name);
            form.set('sp_bank_account_type', data.bank_account_type);
            form.set('sp_bank_account_number', data.bank_account_number);
            form.set('user_type', 'SP');
            if (data.type) form.set('type', data.type);
        } else {
            form.set('bank_name', data.bank_name);
            form.set('bank_ifsc_code', data.bank_ifsc_code);
            form.set('bank_account_holder_name', data.bank_account_holder_name);
            form.set('bank_account_type', data.bank_account_type);
            form.set('bank_account_number', data.bank_account_number);
        }
    }
    return form;
};

export const generateaddSitesform = ({ projectId, data, existing, addNew }) => {
    try {
        let assetsRegex = /^assets_[0-9]+$/;
        let form = new FormData();
        form.set(`project_id`, projectId);
        data.location_data.map((site, index) => {
            let isAssetConfigRequired = 0;
            Object.keys(site).map((keys) => {
                if (keys === 'boq_data') {
                    site[keys].map((boq, innerindex) => {
                        if (boq && Object.keys(boq).length > 0 && boq.asset_type_id) {
                            return Object.keys(boq).map((boqkeys) => {
                                if (boqkeys === 'config_file') {
                                    isAssetConfigRequired = Array.isArray(boq.config_file) && boq.config_file.length > 0 && isAssetConfigRequired === 0 ? 1 : 0;
                                    form.set(`config_data[${index}][fk_asset_type_id]`, boq.asset_type_id ? boq.asset_type_id : '');
                                    form.set(`config_data[${index}][config_file]`, Array.isArray(boq.config_file) ? boq.config_file[0] : '');
                                } else {
                                    return form.set(`location_data[${index}][boq_data][${innerindex}][${boqkeys}]`, boq[boqkeys] ? boq[boqkeys] : '');
                                }
                            });
                        }
                    });
                } else if (keys === 'is_boq_required') {
                    if (site['fk_template_id'] === 0 && typeof site['is_boq_required'] === 'boolean') {
                        return form.set(`location_data[${index}][${keys}]`, site[keys] ? 1 : 0);
                    } else {
                        return form.set(`location_data[${index}][${keys}]`, site[keys]);
                    }
                } else if (keys === 'self_pickup_required') {
                    return form.set(`location_data[${index}][${keys}]`, site[keys] ? 1 : 0);
                } else if (keys === 'is_noc_required') {
                    return form.set(`location_data[${index}][${keys}]`, site[keys] ? 1 : 0);
                } else if (assetsRegex.test(keys)) {
                    delete site[keys];
                    return;
                } else if (keys === 'additional_assets') {
                    if (Array.isArray(site[keys]) && site[keys].length > 0) {
                        site[keys].map((asset, assetIndex) => {
                            return form.set(`location_data[${index}][additional_assets][${assetIndex}]`, asset);
                        });
                    }
                } else {
                    if (keys === 'site_id' && !isNaN(site[keys]) && parseInt(site[keys]) > 0) {
                        addNew = false;
                        existing = true;
                    }
                    return form.set(`location_data[${index}][${keys}]`, site[keys]);
                }
            });

            if (!form.has(`location_data[${index}][self_pickup_required]`)) {
                form.set(`location_data[${index}][self_pickup_required]`, 0);
            }
            if (!form.has(`location_data[${index}][is_noc_required]`)) {
                form.set(`location_data[${index}][is_noc_required]`, 1);
            }
            if (!form.has(`location_data[${index}][fk_noc_template_id]`)) {
                form.set(`location_data[${index}][fk_noc_template_id]`, 0);
            }
            if (!form.has(`location_data[${index}][external_site_id]`)) {
                form.set(`location_data[${index}][external_site_id]`, '');
            }
            if (!form.has(`location_data[${index}][site_branch_name]`)) {
                form.set(`location_data[${index}][site_branch_name]`, '');
            }
            if (!form.has(`location_data[${index}][region]`)) {
                form.set(`location_data[${index}][region]`, '');
            }
            if (!form.has(`location_data[${index}][zone]`)) {
                form.set(`location_data[${index}][zone]`, '');
            }
            if (!form.has(`location_data[${index}][courier_vendor_id]`)) {
                form.set(`location_data[${index}][courier_vendor_id]`, '');
            }
            if (!form.has(`location_data[${index}][docket_num]`)) {
                form.set(`location_data[${index}][docket_num]`, '');
            }

            if (!form.has(`location_data[${index}][job_shipping_fk_pincode_id]`)) {
                form.set(`location_data[${index}][job_shipping_fk_pincode_id]`, '');
            }
            if (!form.has(`location_data[${index}][job_shipping_address_1]`)) {
                form.set(`location_data[${index}][job_shipping_address_1]`, '');
            }
            if (!form.has(`location_data[${index}][job_shipping_fk_city_id]`)) {
                form.set(`location_data[${index}][job_shipping_fk_city_id]`, '');
            }

            form.set(`location_data[${index}][request_raised]`, 0);
            form.set(`location_data[${index}][project_type]`, 1);
            form.set(`location_data[${index}][asset_config_required]`, isAssetConfigRequired);

            if (existing) {
                form.set(`location_data[${index}][use_existing]`, 1);
            }
            if (addNew) {
                form.set(`location_data[${index}][create_new]`, 1);
            }

            if (existing) form.delete('customer');
            form.delete('trackingDetails');
        });
        return form;
    } catch (error) {
        console.error('form', error);
    }
};

export const generateSignOffStepForm = async (ticketId, formValues) => {
    let scogoChecks = {}
    delete formValues.files
    let dataToSend = { ticketId, steps: [] }
    await Promise.all(Object.keys(formValues).map(async (field, index) => {
        if (!field.includes('$') && !field.includes('scogocheckbox') && !field.includes('scogo_remark_')) {
            let step = {
                sign_off_fields_relationship_id: undefined,
                field_value: '', remark: ''
            };
            if (typeof formValues[field] === 'string' && formValues[field].toLowerCase() === 'others') {
                if (formValues[`$others${field}`]) {
                    step.field_value = formValues[`$others${field}`].toString()
                } else {
                    step.field_value = formValues[field].toString()
                }
            } else {
                step.field_value = formValues[field]
            }
            step.sign_off_fields_relationship_id = parseInt(field.split('_')[1])
            if (formValues[`scogo_remark_${field}`]) {
                step.remark = formValues[`scogo_remark_${field}`]
            }
            if (!isNaN(step.sign_off_fields_relationship_id) && step.field_value !== '') {
                dataToSend.steps.push(step)
            }
        }
        if (field.includes('scogocheckbox') && !field.includes('scogo_remark_') && !field.includes('$file')) {
            let similarWord = `^${field.split('_')[0]}_\\d+_${field.split('_')[2]}$`
            let similarWordRegex = new RegExp(similarWord)
            let check = scogoChecks;
            let step = {
                sign_off_fields_relationship_id: undefined,
                field_value: '', remark: ''
            };
            let filter = Object.keys(formValues).filter(data => {
                return similarWordRegex.test(data)
            })
            if (Array.isArray(check[similarWord])) {
                check[similarWord].push(formValues[field])
            } else {
                check[similarWord] = [formValues[field]]
            }
            scogoChecks = check
            if (filter.length === check[similarWord].length) {
                scogoChecks[similarWord] = scogoChecks[similarWord].filter(item => typeof item !== 'boolean')
                step.field_value = scogoChecks[similarWord].join(',')
                step.sign_off_fields_relationship_id = parseInt(field.split('_')[2])
                let remarkKey = `scogo_remark_${field.split('_')[0]}_0_${field.split('_')[2]}`
                if (formValues.remarkKey) {
                    step.remark = formValues[remarkKey]
                }
                if (!isNaN(step.sign_off_fields_relationship_id) && step.field_value !== '') {
                    dataToSend.steps.push(step)
                }
            }
        }
    }))
        .catch(error => {
            console.error(error)
        })
    return dataToSend
}



export const generateEditTicketForm = ({ formName,
    onSuccess,
    ticketId,
    projectId,
    endUserId,
    clusterId,
    pspId,
    supervisorId,
    externalTicketId,
    sla,
    issueCategory,
    executionDate,
    remarks,
    signOffTemplate,
    revisitRequired,
    escalationMessage,
    closedByNoc,
    techEngineers,
    description,
    pincode,
    cityId,
    assets,
    siteId,
    externalSiteId,
    siteAddress,
    branch,
    region,
    zone,
    contactName,
    contactMobile,
    contactEmail,
    forwardCourierVendor,
    reverseCourierVendor,
    forwardDocketNumber,
    reverseDocketNumber,
    forwardCost,
    reverseCost,
    forwardEta,
    reverseEta,
    hardCopyRequired,
    hardCopyReceived,
    assetPickupRequired,
    assetPickupPincode,
    assetPickupAddress,
    assetShippingPincode,
    assetShippingAddress,
    isRequestSupport,
    updateTicketDetails,
    toastSuccessMessage,
    supportAsset }) => {
    const form = new FormData();
    form.set('ticket_id', ticketId);
    if (isRequestSupport) {
        form.set('isRequestSupport', isRequestSupport);
        form.set('complete_site_address', siteAddress);
        form.set('execution_date', moment(executionDate).format('DD-MM-YYYY HH:mm:ss'));
        if (isRequestSupport && supportAsset) {
            form.set(`boq_data[0][asset_type_id]`, supportAsset.asset_type_id);
            form.set(`boq_data[0][serial_number]`, supportAsset.serial_number);
            form.set(`boq_data[0][partcode]`, supportAsset.partcode);
            form.set(`boq_data[0][quantity]`, 1);
        }
    }
    if (projectId >= 0) form.set('fk_project_id', projectId);
    if (endUserId >= 0) form.set('end_user_id', endUserId);
    if (clusterId >= 0) form.set('cluster_id', clusterId);
    if (pspId >= 0) form.set('psp_id', pspId);
    if (supervisorId >= 0) form.set('supervisor_id', supervisorId);
    form.set('external_ticket_id', externalTicketId);
    if (sla >= 0) form.set('sla', sla);
    if (issueCategory >= 0) form.set('issue_category', issueCategory);
    if (remarks) {
        form.set('execution_date', moment(executionDate).format('DD-MM-YYYY HH:mm:ss'));
        form.set('change_msg', remarks);
    }
    if (signOffTemplate >= 0) form.set('fk_sign_off_template_id', signOffTemplate);
    if (revisitRequired !== 'dont_send') {
        form.set('is_revisit_required', revisitRequired);
        if (escalationMessage) form.set('escalation_message', escalationMessage);
    }
    if (closedByNoc > 0) form.set('closed_by_noc', closedByNoc);
    if (techEngineers) form.set('tech_engineers', techEngineers);
    form.set('description', description);
    if (pincode) form.set('job_fk_pincode_id', pincode);
    if (cityId) form.set('job_fk_city_id', cityId);
    if (siteId === 'add_new_site') {
        form.set('site_id', 0);
        if (externalSiteId) form.set('external_site_id', externalSiteId);
        if (siteAddress) form.set('complete_site_address', siteAddress);
        if (branch) form.set('site_branch_name', branch);
        if (region) form.set('region', region);
        if (zone) form.set('zone', zone);
    } else {
        form.set('site_id', siteId);
    }
    if (typeof contactName === 'string') form.set('job_site_contact_name', contactName);
    if (typeof contactMobile === 'string') form.set('job_site_contact_mobile', contactMobile);
    if (typeof contactEmail === 'string') form.set('job_site_contact_mail', contactEmail);

    assets?.forEach?.((asset, i) => {
        form.set(`boq_data[${i}][quantity]`, asset.quantity);
        form.set(`boq_data[${i}][asset_type_id]`, asset.asset_type_id);
        if (typeof asset.serial_number === 'string') form.set(`boq_data[${i}][serial_number]`, asset.serial_number);
        if (typeof asset.make === 'string') form.set(`boq_data[${i}][make]`, asset.make);
        if (typeof asset.model === 'string') form.set(`boq_data[${i}][model]`, asset.model);
        if (typeof asset.partcode === 'string') form.set(`boq_data[${i}][partcode]`, asset.partcode);
        if (typeof asset.external_order_id === 'string') form.set(`boq_data[${i}][external_order_id]`, asset.external_order_id);
        form.set(`boq_data[${i}][req_to_delete]`, asset.req_to_delete);
        // only send id when there is no warehouse_id (not even 0)
        if (asset.assetId > 0 && isNaN(parseInt(asset.warehouse_id, 10))) {
            form.set(`boq_data[${i}][id]`, asset.assetId);
        }
    });

    if (forwardCourierVendor > 0) form.set('forwardCourierVendor', forwardCourierVendor);
    if (reverseCourierVendor > 0) form.set('reverseCourierVendor', reverseCourierVendor);
    if (forwardCourierVendor === 0) form.set('forwardCourierVendor', 'hand_held');
    if (reverseCourierVendor === 0) form.set('reverseCourierVendor', 'hand_held');
    if (forwardDocketNumber) form.set('forwardDocketNumber', forwardDocketNumber);
    if (reverseDocketNumber) form.set('reverseDocketNumber', reverseDocketNumber);
    if (forwardCost) form.set('forwardCost', forwardCost);
    if (reverseCost) form.set('reverseCost', reverseCost);
    if (forwardCourierVendor === 0 && forwardEta && moment(forwardEta).isValid()) form.set('forwardEta', moment(forwardEta).format('YYYY-MM-DD'));
    if (reverseCourierVendor === 0 && reverseEta && moment(reverseEta).isValid()) form.set('reverseEta', moment(reverseEta).format('YYYY-MM-DD'));

    if (hardCopyRequired !== 'dont_send') form.set('hard_copy_required', hardCopyRequired);
    if (hardCopyReceived !== 'dont_send') form.set('hard_copy_received', hardCopyReceived);
    if (assetPickupRequired !== 'dont_send') form.set('self_pickup_required', assetPickupRequired);
    if (assetPickupPincode) form.set('job_pickup_fk_pincode_id', assetPickupPincode);
    if (assetPickupAddress) form.set('job_pickup_address_1', assetPickupAddress);
    if (assetShippingPincode) form.set('job_shipping_fk_pincode_id', assetShippingPincode);
    if (assetShippingAddress) form.set('job_shipping_address_1', assetShippingAddress);

    return form;
}