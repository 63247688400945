export const SERVICETYPE_LIST_REQUEST = 'SERVICETYPE_LIST_REQUEST';
export const SERVICETYPE_LIST_SUCCESS = 'SERVICETYPE_LIST_SUCCESS';
export const SERVICETYPE_LIST_FAILURE = 'SERVICETYPE_LIST_FAILURE';

export const USECASES_LIST_REQUEST = 'USECASES_LIST_REQUEST';
export const USECASES_LIST_SUCCESS = 'USECASES_LIST_SUCCESS';
export const USECASES_LIST_FAILURE = 'USECASES_LIST_FAILURE';

export const COURIER_VENDORS_LIST_REQUEST = 'COURIER_VENDORS_LIST_REQUEST';
export const COURIER_VENDORS_LIST_SUCCESS = 'COURIER_VENDORS_LIST_SUCCESS';
export const COURIER_VENDORS_LIST_FAILURE = 'COURIER_VENDORS_LIST_FAILURE';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const GET_NEXT_NOTIFICATION_REQUEST = 'GET_NEXT_NOTIFICATION_REQUEST';
export const GET_NEXT_NOTIFICATION_SUCCESS = 'GET_NEXT_NOTIFICATION_SUCCESS';
export const GET_NEXT_NOTIFICATION_FAILURE = 'GET_NEXT_NOTIFICATION_FAILURE';

export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';
export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';

export const UPDATE_PARTNER_PROFILE_REQUEST = 'UPDATE_PARTNER_PROFILE_REQUEST';
export const UPDATE_PARTNER_PROFILE_SUCCESS = 'UPDATE_PARTNER_PROFILE_SUCCESS';
export const UPDATE_PARTNER_PROFILE_FAILURE = 'UPDATE_PARTNER_PROFILE_FAILURE';

export const PROMOTE_USER_REQUEST = 'PROMOTE_USER_REQUEST';
export const PROMOTE_USER_SUCCESS = 'PROMOTE_USER_SUCCESS';
export const PROMOTE_USER_FAILURE = 'PROMOTE_USER_FAILURE';

export const DEMOTE_USER_REQUEST = 'DEMOTE_USER_REQUEST';
export const DEMOTE_USER_SUCCESS = 'DEMOTE_USER_SUCCESS';
export const DEMOTE_USER_FAILURE = 'DEMOTE_USER_FAILURE';

export const STATES_LIST_REQUEST = 'STATES_LIST_REQUEST';
export const STATES_LIST_SUCCESS = 'STATES_LIST_SUCCESS';
export const STATES_LIST_FAILURE = 'STATES_LIST_FAILURE';

export const CITIES_LIST_REQUEST = 'CITIES_LIST_REQUEST';
export const CITIES_LIST_SUCCESS = 'CITIES_LIST_SUCCESS';
export const CITIES_LIST_FAILURE = 'CITIES_LIST_FAILURE';

export const EXISTING_CITIES_LIST_REQUEST = 'EXISTING_CITIES_LIST_REQUEST';
export const EXISTING_CITIES_LIST_SUCCESS = 'EXISTING_CITIES_LIST_SUCCESS';
export const EXISTING_CITIES_LIST_FAILURE = 'EXISTING_CITIES_LIST_FAILURE';

export const UPDATE_SERVICE_LOCATIONS_REQUEST = 'UPDATE_SERVICE_LOCATIONS_REQUEST';
export const UPDATE_SERVICE_LOCATIONS_SUCCESS = 'UPDATE_SERVICE_LOCATIONS_SUCCESS';
export const UPDATE_SERVICE_LOCATIONS_FAILURE = 'UPDATE_SERVICE_LOCATIONS_FAILURE';

export const UPDATE_USER_TERRITORIES_REQUEST = 'UPDATE_USER_TERRITORIES_REQUEST';
export const UPDATE_USER_TERRITORIES_FAILURE = 'UPDATE_USER_TERRITORIES_FAILURE';

export const GET_SP_PROFILE_DETAILS_REQUEST = 'GET_SP_PROFILE_DETAILS_REQUEST';
export const GET_SP_PROFILE_DETAILS_SUCCESS = 'GET_SP_PROFILE_DETAILS_SUCCESS';
export const GET_SP_PROFILE_DETAILS_FAILURE = 'GET_SP_PROFILE_DETAILS_FAILURE';

export const GET_FE_PROFILE_DETAILS_REQUEST = 'GET_FE_PROFILE_DETAILS_REQUEST';
export const GET_FE_PROFILE_DETAILS_SUCCESS = 'GET_FE_PROFILE_DETAILS_SUCCESS';
export const GET_FE_PROFILE_DETAILS_FAILURE = 'GET_FE_PROFILE_DETAILS_FAILURE';

export const CLEAR_SP_PROFILE_DETAILS_REQUEST = 'CLEAR_SP_PROFILE_DETAILS_REQUEST';

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export const CUSTOMER_SEND_SP_INVITES_SUCCESS = 'CUSTOMER_SEND_SP_INVITES_SUCCESS';
export const CUSTOMER_SEND_SP_INVITES_REQUEST = 'CUSTOMER_SEND_SP_INVITES_REQUEST';
export const CUSTOMER_SEND_SP_INVITES_FAILURE = 'CUSTOMER_SEND_SP_INVITES_FAILURE';

export const ADMIN_INVITE_SP_USER_REQUEST = 'ADMIN_INVITE_SP_USER_REQUEST';
export const ADMIN_INVITE_CSP_USER_REQUEST = 'ADMIN_INVITE_CSP_USER_REQUEST';

export const CUSTOMER_SEND_ADMIN_PM_INVITES_REQUEST = 'CUSTOMER_SEND_ADMIN_PM_INVITES_REQUEST';
export const CUSTOMER_SEND_ADMIN_PM_INVITES_SUCCESS = 'CUSTOMER_SEND_ADMIN_PM_INVITES_SUCCESS';
export const CUSTOMER_SEND_ADMIN_PM_INVITES_FAILURE = 'CUSTOMER_SEND_ADMIN_PM_INVITES_FAILURE';

export const SEND_INVITES_SUCCESS = 'SEND_INVITES_SUCCESS';

export const UPDATE_TECH_SUPPORT_STATUS_OF_USER_REQUEST = 'UPDATE_TECH_SUPPORT_STATUS_OF_USER_REQUEST';
export const UPDATE_TECH_SUPPORT_STATUS_OF_USER_FAILURE = 'UPDATE_TECH_SUPPORT_STATUS_OF_USER_FAILURE';
export const UPDATE_TECH_SUPPORT_STATUS_OF_USER_SUCCESS = 'UPDATE_TECH_SUPPORT_STATUS_OF_USER_SUCCESS';

export const LIST_TECH_ENGINEERS_REQUEST = 'LIST_TECH_ENGINEERS_REQUEST';
export const LIST_TECH_ENGINEERS_FAILURE = 'LIST_TECH_ENGINEERS_FAILURE';
export const LIST_TECH_ENGINEERS_SUCCESS = 'LIST_TECH_ENGINEERS_SUCCESS';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';
export const TEAM_USER_EDIT_SUCCESS = 'TEAM_USER_EDIT_SUCCESS';

export const REMOVE_EXISTING_CITIES_LIST_FROM_STORE = 'REMOVE_EXISTING_CITIES_LIST_FROM_STORE';

export const REINVITE_FE_REQUEST = 'REINVITE_FE_REQUEST';
export const REINVITE_FE_FAILURE = 'REINVITE_FE_FAILURE';

export const REMOVE_USER_TERRITORIES_REQUEST = 'REMOVE_USER_TERRITORIES_REQUEST';
export const REMOVE_USER_TERRITORIES_SUCCESS = 'REMOVE_USER_TERRITORIES_SUCCESS';
export const REMOVE_USER_TERRITORIES_FAILURE = 'REMOVE_USER_TERRITORIES_FAILURE';

export const NEW_RESOURCE_ADD_REQUEST = 'NEW_RESOURCE_ADD_REQUEST';
export const NEW_RESOURCE_ADD_SUCCESS = 'NEW_RESOURCE_ADD_SUCCESS';
export const NEW_RESOURCE_ADD_FAILURE = 'NEW_RESOURCE_ADD_FAILURE';

export const NEW_RESOURCE_FILE_ADD_REQUEST = 'NEW_RESOURCE_FILE_ADD_REQUEST';
export const NEW_RESOURCE_FILE_ADD_SUCCESS = 'NEW_RESOURCE_FILE_ADD_SUCCESS';
export const NEW_RESOURCE_FILE_ADD_FAILURE = 'NEW_RESOURCE_FILE_ADD_FAILURE';

export const GET_RESOURCE_REQUEST = 'GET_RESOURCE_REQUEST';
export const GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE';

export const ADD_END_USER_REQUEST = 'ADD_END_USER_REQUEST';
export const ADD_END_USER_SUCCESS = 'ADD_END_USER_SUCCESS';
export const ADD_END_USER_FAILURE = 'ADD_END_USER_FAILURE';

export const CLEAR_LAST_CREATED_ENDUSER = 'CLEAR_LAST_CREATED_ENDUSER';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const DELETE_RESOURCE_REQUEST = 'DELETE_RESOURCE_REQUEST';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAILURE = 'DELETE_RESOURCE_FAILURE';

export const CREATE_ROOT_FOLDER_REQUEST = 'CREATE_ROOT_FOLDER_REQUEST';
export const CREATE_ROOT_FOLDER_SUCCESS = 'CREATE_ROOT_FOLDER_SUCCESS';
export const CREATE_ROOT_FOLDER_FAILURE = 'CREATE_ROOT_FOLDER_FAILURE';

export const CLEAR_RESOURCES = 'CLEAR_RESOURCES';

export const GET_USERS_RATECARD_REQUEST = 'GET_USERS_RATECARD_REQUEST';
export const GET_USERS_RATECARD_SUCCESS = 'GET_USERS_RATECARD_SUCCESS';
export const GET_USERS_RATECARD_FAILURE = 'GET_USERS_RATECARD_FAILURE';

export const BANKS_LIST_REQUEST = 'BANKS_LIST_REQUEST';
export const BANKS_LIST_SUCCESS = 'BANKS_LIST_SUCCESS';
export const BANKS_LIST_FAILURE = 'BANKS_LIST_FAILURE';

export const UPDATE_USERS_BANK_DETAILS_REQUEST = 'UPDATE_USERS_BANK_DETAILS_REQUEST';
export const UPDATE_USERS_BANK_DETAILS_SUCCESS = 'UPDATE_USERS_BANK_DETAILS_SUCCESS';
export const UPDATE_USERS_BANK_DETAILS_FAILURE = 'UPDATE_USERS_BANK_DETAILS_FAILURE';
export const CLEANUP_BANK_UPDATED_DETAILS_FROM_STORE = 'CLEANUP_BANK_UPDATED_DETAILS_FROM_STORE';

export const USER_FIELD_ENGINEERS_LIST_SUCCESS = 'USER_FIELD_ENGINEERS_LIST_SUCCESS';
export const PANCARD_VALIDATION_REQUEST = 'PANCARD_VALIDATION_REQUEST';
export const PANCARD_VALIDATION_SUCCESS = 'PANCARD_VALIDATION_SUCCESS';
export const PANCARD_VALIDATION_FAILURE = 'PANCARD_VALIDATION_FAILURE';

export const BUSSINESS_PANCARD_VALIDATION_REQUEST = 'BUSSINESS_PANCARD_VALIDATION_REQUEST';
export const BUSSINESS_PANCARD_VALIDATION_FAILURE = 'BUSSINESS_PANCARD_VALIDATION_FAILURE';
export const BUSSINESS_PANCARD_VALIDATION_SUCCESS = 'BUSSINESS_PANCARD_VALIDATION_SUCCESS';

export const USER_AADHAAR_VALIDATE_REQUEST = 'USER_AADHAAR_VALIDATE_REQUEST';
export const USER_AADHAAR_VALIDATE_SUCCESS = 'USER_AADHAAR_VALIDATE_SUCCESS';
export const USER_AADHAAR_VALIDATE_FAILURE = 'USER_AADHAAR_VALIDATE_FAILURE';

export const GST_VALIDATION_REQUEST = 'GST_VALIDATION_REQUEST';
export const GST_VALIDATION_SUCCESS = 'GST_VALIDATION_SUCCESS';
export const GST_VALIDATION_FAILURE = 'GST_VALIDATION_FAILURE';

export const SUBMIT_AADHAAR_OTP_REQUEST = 'SUBMIT_AADHAAR_OTP_REQUEST';
export const SUBMIT_AADHAAR_OTP_SUCCESS = 'SUBMIT_AADHAAR_OTP_SUCCESS';
export const SUBMIT_AADHAAR_OTP_FAILURE = 'SUBMIT_AADHAAR_OTP_FAILURE';

export const BANK_VERIFICATION_REQUEST = 'BANK_VERIFICATION_REQUEST';
export const BANK_VERIFICATION_FAILURE = 'BANK_VERIFICATION_FAILURE';
export const BANK_VERIFICATION_SUCCESS = 'BANK_VERIFICATION_SUCCESS';

export const ESIGN_TOKEN_REQUEST = 'ESIGN_TOKEN_REQUEST';
export const ESIGN_TOKEN_FAILURE = 'ESIGN_TOKEN_FAILURE';
export const ESIGN_TOKEN_SUCCESS = 'ESIGN_TOKEN_SUCCESS';

export const CLEAR_FIELD_ENGINEERS_LIST = 'CLEAR_FIELD_ENGINEERS_LIST';

export const GET_TECH_SUPPORT_USERS_DROPDOWN_REQUEST = 'GET_TECH_SUPPORT_USERS_DROPDOWN_REQUEST';
export const GET_TECH_SUPPORT_USERS_DROPDOWN_SUCCESS = 'GET_TECH_SUPPORT_USERS_DROPDOWN_SUCCESS';
export const GET_TECH_SUPPORT_USERS_DROPDOWN_FAILURE = 'GET_TECH_SUPPORT_USERS_DROPDOWN_FAILURE';

export const GENERATE_OTP_REQUEST = 'GENERATE_OTP_REQUEST';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILURE = 'GENERATE_OTP_FAILURE';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const CLEAR_PROFILE_UPDATE_OTP_STORE = 'CLEAR_PROFILE_UPDATE_OTP_STORE';

export const UPDATE_USER_PROFILE_BANK_DETAILS_REQUEST = 'UPDATE_USER_PROFILE_BANK_DETAILS_REQUEST';
export const UPDATE_USER_PROFILE_BANK_DETAILS_SUCCESS = 'UPDATE_USER_PROFILE_BANK_DETAILS_SUCCESS';
export const UPDATE_USER_PROFILE_BANK_DETAILS_FAILURE = 'UPDATE_USER_PROFILE_BANK_DETAILS_FAILURE';

export const GET_GST_LIST_REQUEST = 'GET_GST_LIST_REQUEST';
export const GET_GST_LIST_SUCCESS = 'GET_GST_LIST_SUCCESS';
export const GET_GST_LIST_FAILURE = 'GET_GST_LIST_FAILURE';

export const UPDATE_GST_REQUEST = 'UPDATE_GST_REQUEST';
export const UPDATE_GST_SUCCESS = 'UPDATE_GST_SUCCESS';
export const UPDATE_GST_FAILURE = 'UPDATE_GST_FAILURE';

export const ADD_GST_REQUEST = 'ADD_GST_REQUEST';
export const ADD_GST_SUCCESS = 'ADD_GST_SUCCESS';
export const ADD_GST_FAILURE = 'ADD_GST_FAILURE';

export const DELETE_GST_REQUEST = 'DELETE_GST_REQUEST';
export const DELETE_GST_SUCCESS = 'DELETE_GST_SUCCESS';
export const DELETE_GST_FAILURE = 'DELETE_GST_FAILURE';

export const GET_GST_BY_ID_REQUEST = 'GET_GST_BY_ID_REQUEST';
export const GET_GST_BY_ID_SUCCESS = 'GET_GST_BY_ID_SUCCESS';
export const GET_GST_BY_ID_FAILURE = 'GET_GST_BY_ID_FAILURE';

export const CLEAR_UPDATE_GST_STORE = 'CLEAR_UPDATE_GST_STORE';
export const PUT_TO_USER_STORE = 'PUT_TO_USER_STORE';

export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS';
export const GET_COUNT_FAILURE = 'GET_COUNT_FAILURE';

export const GET_USER_PROFILE_DETAILS = 'GET_USER_PROFILE_DETAILS';
export const USER_PROFILE_DETAILS_SUCCESS = 'USER_PROFILE_DETAILS_SUCCESS';
export const USER_PROFILE_DETAILS_FAILUREE = 'USER_PROFILE_DETAILS_FAILUREE';

export const UPLOAD_USER_ID_PROOF_REQUEST = 'UPLOAD_USER_ID_PROOF_REQUEST';
export const UPLOAD_USER_ID_PROOF_SUCCESS = 'UPLOAD_USER_ID_PROOF_SUCCESS';
export const UPLOAD_USER_ID_PROOF_FAILURE = 'UPLOAD_USER_ID_PROOF_FAILURE';

export const UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST = 'UPLOAD_USER_ID_PROOF_FRONT_BACK_REQUEST';
export const UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS = 'UPLOAD_USER_ID_PROOF_FRONT_BACK_SUCCESS';
export const UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE = 'UPLOAD_USER_ID_PROOF_FRONT_BACK_FAILURE';

export const UPLOAD_USER_ID_PROOF_BACK_REQUEST = 'UPLOAD_USER_ID_PROOF_BACK_REQUEST';
export const UPLOAD_USER_ID_PROOF_BACK_SUCCESS = 'UPLOAD_USER_ID_PROOF_BACK_SUCCESS';
export const UPLOAD_USER_ID_PROOF_BACK_FAILURE = 'UPLOAD_USER_ID_PROOF_BACK_FAILURE';

export const VERIFY_USER_ADDRESS_REQUEST = 'VERIFY_USER_ADDRESS_REQUEST';
export const VERIFY_USER_ADDRESS_SUCCESS = 'VERIFY_USER_ADDRESS_SUCCESS';
export const VERIFY_USER_ADDRESS_FAILURE = 'VERIFY_USER_ADDRESS_FAILURE';


export const REMOVE_VALIDATE_AADHAR_RESPONSE_FROM_STORE = 'REMOVE_VALIDATE_AADHAR_RESPONSE_FROM_STORE';

export const SET_SYSTEM_UI_CONFIG = 'SET_SYSTEM_UI_CONFIG';
export const CLEAR_BUSSINESS_PANCARD_VALIDATION = 'CLEAR_BUSSINESS_PANCARD_VALIDATION';

export const BULK_INVITE_FILE_UPLOAD_REQUEST = 'BULK_INVITE_FILE_UPLOAD_REQUEST'
export const BULK_INVITE_FILE_UPLOAD_SUCCESS = 'BULK_INVITE_FILE_UPLOAD_SUCCESS'
export const BULK_INVITE_FILE_UPLOAD_FAILURE = 'BULK_INVITE_FILE_UPLOAD_FAILURE'

export const ENABLE_USER_ROOM_REQUEST = 'ENABLE_USER_ROOM_REQUEST';
export const ENABLE_USER_ROOM_SUCCESS = 'ENABLE_USER_ROOM_SUCCESS';
export const ENABLE_USER_ROOM_FAILURE = 'ENABLE_USER_ROOM_FAILURE';

export const DISABLE_USER_ROOM_REQUEST = 'DISABLE_USER_ROOM_REQUEST';
export const DISABLE_USER_ROOM_SUCCESS = 'DISABLE_USER_ROOM_SUCCESS';
export const DISABLE_USER_ROOM_FAILURE = 'DISABLE_USER_ROOM_FAILURE';
export const CLEAR_ENABLED_ROOM_ID = 'CLEAR_ENABLED_ROOM_ID';

export const GET_USER_CALL_STATUS_REQUEST = 'GET_USER_CALL_STATUS_REQUEST';
export const GET_USER_CALL_STATUS_SUCCESS = 'GET_USER_CALL_STATUS_SUCCESS';
export const GET_USER_CALL_STATUS_FAILURE = 'GET_USER_CALL_STATUS_FAILURE';

export const GET_ENDUSERS_LIST_REQUEST = 'GET_ENDUSERS_LIST_REQUEST'
export const GET_ENDUSERS_LIST_SUCCESS = 'GET_ENDUSERS_LIST_SUCCESS'
export const GET_INDIVIDUAL_USER_BANK_DETAIL_SUCCESS = 'GET_INDIVIDUAL_USER_BANK_DETAIL_SUCCESS';