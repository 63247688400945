import React, { useState } from 'react';
import { isCustomerGroup } from '../../../../utils/role';
import OverflowTip from '../../../../common/OverflowTip';
import IconToolTip from '../../../../common/IconToolTip';
import { useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../../actions/utils';
import UserPopup from '../../../../common/Popups/User';
import { isLogged, validateStringValue } from '../../../../utils/common';
import { openCustomModal } from '../../../../actions/modal';
import PMDetails from '../../../project/Modals/PmDetails';
import { RibbonContainer } from '../../../../components/TicketExecution/RibbonContainer';
import { useSelector } from 'react-redux';
import { getTicketOwner } from '../../../Ticket/TicketController';

export const ContactDetails = ({ viewTicket, role, type, openCardDefault, isCollapsible = false }) => {

    const dispatch = useDispatch();
    const showfeOrSp = viewTicket?.accepted_sp_id === viewTicket?.accepted_fe_id;

    const pmName = viewTicket?.scogo_pm_name && viewTicket?.scogo_pm_mobile ? validateStringValue(viewTicket.scogo_pm_name) : `${validateStringValue(viewTicket?.pm_first_name)} ${validateStringValue(viewTicket?.pm_last_name)}`;
    const pmMobile = viewTicket?.scogo_pm_mobile ? viewTicket?.scogo_pm_mobile : viewTicket?.pm_mobile;
    const pmId = viewTicket?.scogo_pm_id ? viewTicket?.scogo_pm_id : viewTicket?.created_by;


    const openPmDetails = (fk_project_id) => {
        const pmList = [{
            name: `${validateStringValue(viewTicket?.pm_first_name)} ${validateStringValue(viewTicket?.pm_last_name)}`, mobile: viewTicket?.pm_mobile,
            label: 'Project Manager'
        }];
        dispatch(openCustomModal({
            modalComponent: <PMDetails projectId={fk_project_id} pmList={pmList} />,
            modalWidth: '40rem',
            modalHeight: 'auto',
            heading: "Project Managers",
        }))
    }


    const pmDetails = {
        title: 'PM',
        id: pmId,
        name: pmName,
        mobile: pmMobile,
        isCardVisible: viewTicket?.pm_first_name?.length > 0 || viewTicket?.pm_mobile?.length > 0,
        renderComponent: <div className='max-w-max mx-auto'>
            <IconToolTip onClick={() => openPmDetails(viewTicket?.fk_project_id)} placement={'bottom'} title={`View All Project Manager's`}>
                <div className='hover:underline line-clamp-2 cursor-pointer'>View All PM</div>
            </IconToolTip >
        </div >
    };

    const feOrSpDetails = {
        title: showfeOrSp ? 'SP / FE' : 'SP',
        id: viewTicket?.accepted_sp_id,
        mobile: isCustomerGroup(role, type) ? false : viewTicket?.sp_mobile,
        isCardVisible: (viewTicket?.accepted_sp_id > 0 || (isCustomerGroup(role, type) && (viewTicket?.assign_to_scogo === 1 || viewTicket?.ticket_owned_by === 0))),
        name:
            isCustomerGroup(role, type) && viewTicket?.accepted_sp_id === 0 && (viewTicket?.assign_to_scogo === 1 || viewTicket?.ticket_owned_by === 0)
                ? 'SCOGO'
                : `${validateStringValue(viewTicket?.sp_first_name)} ${validateStringValue(viewTicket?.sp_last_name)}`,
    };
    const feDetails = {
        title: 'FE',
        id: viewTicket?.accepted_fe_id,
        name: `${validateStringValue(viewTicket?.fe_first_name)} ${validateStringValue(viewTicket?.fe_last_name)}`,
        mobile: isCustomerGroup(role, type) ? false : viewTicket?.fe_mobile,
        isCardVisible: viewTicket?.accepted_fe_id > 0 && !showfeOrSp,
    };
    const clusterDetails = {
        title: 'Cluster Lead',
        id: viewTicket?.cluster_id,
        name: `${validateStringValue(viewTicket?.cl_details?.first_name)} ${validateStringValue(viewTicket?.cl_details?.last_name)}`,
        mobile: viewTicket?.cl_details?.mobile,
        isCardVisible: viewTicket?.cluster_id > 0,
    };
    const pspDetails = {
        title: 'PSP',
        id: viewTicket?.psp_id,
        name: `${validateStringValue(viewTicket?.psp_details?.first_name)} ${validateStringValue(viewTicket?.psp_details?.last_name)}`,
        mobile: viewTicket?.psp_details?.mobile,
        isCardVisible: viewTicket?.psp_id > 0,
    };
    const superVisorDetails = {
        title: 'Supervisor',
        id: viewTicket?.supervisor_id,
        name: `${validateStringValue(viewTicket?.supervisor_details?.first_name)} ${validateStringValue(viewTicket?.supervisor_details?.last_name)}`,
        mobile: viewTicket?.supervisor_details?.mobil,
        isCardVisible: viewTicket?.supervisor_id > 0,
    };
    const localContactDetails = {
        title: 'Customer Contact',
        name: `${validateStringValue(viewTicket?.job_site_contact_name)}`,
        mobile: viewTicket?.job_site_contact_mobile,
        isCardVisible: viewTicket?.job_site_contact_name?.length > 0 || viewTicket?.job_site_contact_mobile > 0,
    };

    const usersContact = [feOrSpDetails, feDetails, clusterDetails, pspDetails, localContactDetails, superVisorDetails, pmDetails].filter((e) => e.isCardVisible);


    return (
        <RibbonContainer headerLabel={'Contact'} isCollapsible={isCollapsible} openCardDefault>

            <div className=' col-span-2 gap-3 grid grid-cols-2 justify-around mt-[0.5rem]  '>
                {usersContact?.map((user, i) => {
                    let lastIndex = i === usersContact.length - 1 ? i : 1;
                    let isToShowRanderComponent = usersContact.length % 2 === 0
                    let userLength = usersContact.length
                    return user.isCardVisible ? (
                        <UserContact userLength={userLength} user={user} lastIndex={lastIndex} i={i} isToShowRanderComponent={isToShowRanderComponent} />
                    ) : (
                        <></>
                    );
                })}
            </div>

        </RibbonContainer>

    );
};


export const UserContact = ({ userLength, user, lastIndex, i, isToShowRanderComponent }) => {

    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const handleClickonCard = (event, user) => {
        if (event.detail === 2) {
            dispatch(copyToClipboard({ data: user.mobile }));
        }
    };

    return (
        <>

            <div
                key={i}
                className={`bg-scogolightblue  mt-3 border text-center cursor-pointer rounded-md w-full shadow-md  ${lastIndex % 2 === 0 ? 'col-span-2' : ''
                    }`}
            >
                <div className='relative bg-scogoorange '>
                    <p className='bg-scogoorange text-white text-font10 px-2  shadow-md rounded-3px w-full truncate'>{user?.title}</p>

                    {isToShowRanderComponent && <p className='ml-auto absolute right-2 text-left text-white' style={{ top: '0rem' }}>
                        {user.renderComponent ? user.renderComponent : <></>}
                    </p>}
                </div>
                <div className='mt-1 px-2 ' >
                    {(user?.id && loggedUser) ? <UserPopup
                        userId={user?.id}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <span className='text-font11  text-scogoprimary pt-6 font-semibol max-w-5/7 m-auto'>
                            <OverflowTip someLongText={user?.name} />
                        </span>
                    </UserPopup> : <p className='text-font11 text-scogoprimary font-semibol max-w-5/7 m-auto'>
                        <OverflowTip someLongText={user?.name} />
                    </p>}
                    <div className='flex justify-center relative'>
                        <p className='text-font10 font-normal text-scogogray' onClick={(e) => {
                            handleClickonCard(e, user);
                        }}>{user?.mobile}</p>
                        {userLength % 2 === 1 && <p className='ml-auto absolute right-2 text-left text-font11 hover:text-scogoorange text-scogoprimary'>
                            {user.renderComponent ? user.renderComponent : <></>}
                        </p>}
                    </div>

                </div>
            </div>

        </>
    )
}