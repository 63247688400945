import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import Form from '../../common/Form';
import { useForm, useFormContext } from 'react-hook-form';
import Input from '../../common/Form/Input';
import Select from '../../common/Form/Select';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import Upload from '../../common/Form/FileUpload';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginWithOTPAction, removeOTPSuccessFromStore, requestOtpSupport } from '../../actions/auth';
import { GetQueryParam, actualValueForDropdown, findPincode, getFirstAndLastNameFromFullName, validateDateValue, validateNumberValue, validateStringValue } from '../../utils/common';
import { getIssueCategories } from '../../actions/admin';
import { createSupportTrackTickets, editTicket, getTicketFilteredList } from '../../actions/tickets';
import { SkeltonCardTwo } from '../../common/SkeltonCardTwo';
import { sendToastMessage } from '../../actions/toast';
import OverflowTip from '../../common/OverflowTip';
import SearchBox from '../../common/SearchBox';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import config from '../../config';
import production from '../../config/env/production';
import development from '../../config/env/development';
import { getAssetTagDetails, getInventoryPartcodes } from '../../actions/inventory';
import NewInput from '../../common/Form/NewInput';
import MapWithPlaces from './MapWithPlaces';
import { defaultDevProject, futopiaCustomerId } from '.';
import { trackSupportTicket } from '../../router/roleWisePageRoute';
import ReactGoogleMap from './ReactGoogleMap';
import { isMobileView } from '../auth/components/NewScogoButton';
import { isValidPincode } from '../../utils/utils';
import { setSelectedLocationOnMap } from '../../actions/utils';
import { closeModalAction, openCustomModal } from '../../actions/modal';

const formName = 'editTicket';

export const RenderTicketCard = ({ ticketId, ticketDetails, isUserLoggedIn, onChatIconClick, onSearchIconClick, storeDocuments, setStoreDocuments, SendFilesOnClick, showMap, setShowMap }) => {
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading)
    if (!isUserLoggedIn && !isViewTicketByIdLoading) {
        return <LoginForm ticketId={ticketId} />
    }

    return (<div className='edit-ticket-card-wtih-chat border flex' style={{
        flexFlow: 'column',
    }}>
        <EditTicketForm ticketDetails={ticketDetails} ticketId={ticketId} onChatIconClick={onChatIconClick} onSearchIconClick={onSearchIconClick} storeDocuments={storeDocuments} setStoreDocuments={setStoreDocuments} SendFilesOnClick={SendFilesOnClick} showMap={showMap} setShowMap={setShowMap} />
    </div >)
}
let autoComplete
let timer

const EditTicketForm = ({ ticketDetails, ticketId, onChatIconClick, onSearchIconClick, SendFilesOnClick, showMap, setShowMap }) => {
    const defaultValues = {}
    const methods = useForm({ defaultValues, mode: 'all' });
    const submitRef = useRef(null);
    const { issueCategories } = useSelector((state) => state.admin);
    const { selectedLocationOnMap } = useSelector((state) => state.utils);
    const { partCodes, tagAssetDetails } = useSelector((state) => state.inventory);
    const { loggedUser } = useSelector((state) => state.auth);
    const { formButtonLoading, editPayoutLoading, isConversationMessagesLoading, isConversationDetailsLoading, isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const { conversationDetails } = useSelector((state) => state.conversations);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const dispatch = useDispatch();
    const { setValue, watch, formState: { isDirty } } = methods;
    const { uiConfig } = useSelector(state => state.user);
    const navigate = useNavigate();
    const tagQueryKey = GetQueryParam('tag');
    const [textAreaHeight, setTextAreaHeight] = useState(2.8);

    const [selectedLocationFromSuggestedList, setSelectedLocationFromSuggestedList] = useState({});
    const filledPincode = watch('pincode');
    const issueCategoryOptions = useMemo(() => {
        if (issueCategories) return issueCategories;
        return [];
    }, [issueCategories]);

    useEffect(() => {
        let customerId = 0
        if (window.location.hostname === 'localhost') {
            customerId = 1;
        } else if (uiConfig?.customer_id) {
            customerId = uiConfig.customer_id
        } else if (config.name === development.name) {
            customerId = 1
        }
        if (ticketDetails?.fk_customer_id) {
            customerId = ticketDetails?.fk_customer_id
        }
        if (tagQueryKey && (tagAssetDetails?.customer_id || tagAssetDetails?.fk_customer_id) && (validateNumberValue(tagAssetDetails?.customer_id) || validateNumberValue(tagAssetDetails?.fk_customer_id))) {
            customerId = tagAssetDetails?.customer_id || tagAssetDetails?.fk_customer_id;
        }
        if (!issueCategories && customerId) {
            dispatch(getIssueCategories({ customerId, limit: 9999, dropdown: true }));
        }
    }, [dispatch, ticketDetails?.fk_customer_id, uiConfig?.customer_id, issueCategories, tagAssetDetails?.customer_id, tagAssetDetails?.fk_customer_id, tagQueryKey]);

    useEffect(() => {
        if (tagQueryKey && !tagAssetDetails) {
            dispatch(getAssetTagDetails({ tag: tagQueryKey }));
        }
    }, [dispatch, tagQueryKey, tagAssetDetails]);


    const previousTicketDetails = useMemo(() => {
        let address = '';
        let pincode = '';
        let description = '';
        let executionDate = '';
        let serial_number = '';
        if (ticketDetails) {
            if (ticketDetails.job_fk_pincode_id) { pincode = ticketDetails.job_fk_pincode_id };
            if (Array.isArray(ticketDetails.assets) && ticketDetails.assets.length > 0) { serial_number = ticketDetails.assets[ticketDetails.assets.length - 1].serial_number; }
            executionDate = validateDateValue(ticketDetails.execution_date);
            address = validateStringValue(ticketDetails.job_site_address_1)
            description = validateStringValue(ticketDetails.description);
        } else if (tagAssetDetails) {
            if (tagAssetDetails.site || (tagAssetDetails.device_config && tagAssetDetails.device_config.site)) {
                pincode = validateNumberValue(tagAssetDetails?.site?.fk_pincode_id) || findPincode(address) || ''
                address = validateStringValue(tagAssetDetails?.site?.complete_site_address) || validateStringValue(tagAssetDetails?.device_config?.site.device_installation_address);
            }
            serial_number = validateStringValue(tagAssetDetails.serial_number);
        };
        return { address, pincode, serial_number, executionDate, description }
    }, [ticketDetails, tagAssetDetails]);

    const createOrEditTicket = ({ formValues }) => {
        if (!ticketId && !ticketDetails) {
            createTicket(formValues);
        } else {
            const edited_execution_date = formValues?.executionDate ? new Date(moment(formValues?.executionDate).subtract({ hours: 5.5 })) : undefined;
            const payload = {
                formName,
                siteAddress: `${validateStringValue(formValues?.apartment)} ${formValues.address} `,
                issueCategory: formValues?.issue_category?.value,
                description: formValues?.description,
                pincode: formValues?.pincode,
                executionDate: edited_execution_date,
                ticketId,
                siteId: ticketDetails?.site_id,
                hardCopyReceived: "dont_send",
                assetPickupRequired: 'dont_send',
                hardCopyRequired: 'dont_send',
                revisitRequired: 'dont_send',
                externalTicketId: ticketDetails.external_ticket_id,
                isRequestSupport: true,
                updateTicketDetails: true,
                toastSuccessMessage: 'Ticket Updated Successfully'
            }
            if (formValues.model_text || formValues.serial_number || formValues.model_select) {
                const assetDetails = {
                    serial_number: formValues.serial_number,
                    asset_type_id: 19,
                }
                if (formValues.model_text) {
                    assetDetails.partcode = formValues.model_text
                }
                if (formValues.model_select) {
                    assetDetails.partcode = actualValueForDropdown(formValues.model_select)
                }
                payload.supportAsset = assetDetails;
            }
            if (formValues.additional_documents?.length > 0) {
                SendFilesOnClick({ fileData: [...formValues.additional_documents], conversationId: conversationDetail._id, isAddToQueue: true, ticketId: ticketDetails?.id || ticketId, projectId: ticketDetails?.fk_project_id });
            }
            if (window.innerWidth < 1024) {
                payload.onSuccess = () => {
                    onChatIconClick();
                    dispatch(setSelectedLocationOnMap({}))
                }
            }
            payload.redirectTo = `${trackSupportTicket.path}?ticketId=${ticketId}&redirectToChat=true`;
            payload.navigate = navigate;
            if (validateNumberValue(tagAssetDetails?.fk_site_id)) {
                payload.siteId = tagAssetDetails?.fk_site_id;
            }
            payload.closeModal = true
            dispatch(editTicket(payload));
        }
    }

    const onSubmit = (formValues) => {
        if (!formValues.pincode && formValues.address && findPincode(formValues.address)) {
            formValues.pincode = findPincode(formValues.address);
        }
        if (!formValues.pincode || (previousTicketDetails.address && previousTicketDetails.address.trim() !== formValues.address.trim() && !findPincode(formValues.address))) {
            let pincodeHeader = 'Add Pincode';
            if (previousTicketDetails.address && previousTicketDetails.address.trim() !== formValues.address.trim()) {
                pincodeHeader = `Confirm Pincode`;
            }
            takePincodeModal(formValues, pincodeHeader);
            return;
        }
        createOrEditTicket({ formValues })
    }

    const takePincodeModal = (formValues, header = 'Add Pincode') => {
        let modalWidth = '69vw',
            modalHeight = 'auto';
        let modalComponent = <TakePincode onSubmit={createOrEditTicket} formValues={formValues} pincode={filledPincode} />;
        let heading
        if (header !== `Confirm Pincode`) {

            heading = <div className='flex flex-col '>
                <span>{header}</span>
                <span className='text-lg text-scogoorange'>Pincode is missing in address.</span>
            </div>
        } else {
            heading = header
        }
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    }

    const createTicket = (formValues) => {
        let customerId = 0;
        if (uiConfig?.customer_id) {
            customerId = uiConfig.customer_id
        } else if (window.location.hostname === 'localhost' || config.name === development.name) {
            customerId = 1
        }

        let asset_type_id = 19;
        if (tagQueryKey && tagAssetDetails) asset_type_id = tagAssetDetails?.assetType?.id;

        const boq = {
            serial_number: formValues.serial_number,
            asset_type_id,
            quantity: 1
        };

        if (formValues.model_text) {
            boq.partcode = formValues.model_text
        }
        if (formValues.model_select) {
            boq.partcode = actualValueForDropdown(formValues.model_select)
        }
        let boqData = [boq];
        const edited_execution_date = formValues?.executionDate ? new Date(moment(formValues?.executionDate).subtract({ hours: 5.5 })) : undefined;
        let ticket = {
            userId: loggedUser.id,
            external_ticket_id: '',
            issue_category: actualValueForDropdown(formValues.issue_category),
            execution_date: edited_execution_date,
            fk_customer_id: customerId,
            site_id: 0,
            external_site_id: loggedUser.mobile,
            job_fk_pincode_id: formValues.pincode,
            job_site_address_1: ` ${validateStringValue(formValues?.apartment)} ${formValues.address} `,
            job_site_contact_name: `${validateStringValue(loggedUser.first_name)} ${validateStringValue(loggedUser.last_name)}`,
            job_site_contact_mail: '',
            job_site_contact_mobile: loggedUser.mobile,
            end_user_id: 0,
            is_boq_required: 0,
            self_pickup_required: 0,
            service_type_id: 5,
            use_case_id: 0,
            is_noc_required: 1,
            fk_noc_template_id: 0,
            boq_data: boqData,
            ticket_type: 0,
            title: '',
            description: formValues.description,
            ticket_source: 'SUPPORT_VIEW',
            requester_email: `${validateStringValue(loggedUser.email)}`,
            requester_mobile: loggedUser.mobile,
            requester_name: `${validateStringValue(loggedUser.first_name)} ${validateStringValue(loggedUser.last_name)}`
        };
        if (config.name === production.name && uiConfig?.customer_id === futopiaCustomerId) {
            ticket.fk_project_id = 1776;
        } else if (config.name === development.name) {
            ticket.fk_project_id = defaultDevProject; // 11 june project name
        };

        if (tagQueryKey && tagAssetDetails) {
            ticket.end_user_id = tagAssetDetails?.enduser_id;
            ticket.fk_project_id = 0;
            ticket.fk_customer_id = tagAssetDetails?.customer_id || tagAssetDetails?.fk_customer_id;
            ticket.site_id = validateNumberValue(tagAssetDetails?.fk_site_id);
            ticket.external_site_id = '';
        };

        dispatch(createSupportTrackTickets({
            data: { location_data: [ticket] }, formName, onSuccess: () => {
                dispatch(setSelectedLocationOnMap({}));
                SendFilesOnClick();
            }, existing: true, refresh: true, onFailure: () => { }, redirectToSupportTicket: true, navigate, additional_documents: formValues.additional_documents, tagId: tagQueryKey
        }));
    };

    useEffect(() => {
        const { address, pincode, serial_number, executionDate, description } = previousTicketDetails;
        setValue('address', address);
        setValue('pincode', pincode);
        setValue('serial_number', serial_number);
        setValue('description', description);
        setValue('executionDate', executionDate);
        if (address) {
            setTextAreaHeight(6.0);
        }
    }, [setValue, previousTicketDetails]);

    const partcodeOptions = useMemo(() => {
        if (Array.isArray(partCodes)) {
            return partCodes;
        }
        return [];
    }, [partCodes]);

    useEffect(() => {
        if (partcodeOptions?.length > 0 && Array.isArray(ticketDetails?.assets) && ticketDetails?.assets.length > 0) {
            let partcode = ticketDetails?.assets[ticketDetails.assets.length - 1].partcode;
            const findpartcode = partcodeOptions.find((p) => p.label === partcode);
            setValue('model_select', findpartcode);
        }
    }, [partcodeOptions, ticketDetails?.assets, setValue])

    useEffect(() => {
        if (Array.isArray(issueCategoryOptions)) {
            const find = issueCategoryOptions.find((item) => parseInt(item.value) === parseInt(ticketDetails?.issue_category));
            if (find) {
                setValue('issue_category', find);
            }
        }
    }, [issueCategoryOptions, ticketDetails?.issue_category, setValue]);

    let isDeviceDetailsFieldsMandatory = false;
    if (config.name === production.name) {
        isDeviceDetailsFieldsMandatory = [uiConfig?.customer_id, ticketDetails?.customer_details?.customer_id].includes(futopiaCustomerId) || window.location.hostname === 'localhost'
    } else if (config.name === development.name) {
        isDeviceDetailsFieldsMandatory = [uiConfig?.customer_id, ticketDetails?.customer_details?.customer_id].includes(1) || window.location.hostname === 'localhost'
    }

    useEffect(() => {
        let customerId = 0
        if (ticketDetails?.fk_customer_id) {
            customerId = ticketDetails?.fk_customer_id
        } else if (window.location.hostname === 'localhost') {
            customerId = 1;
        } else if (uiConfig?.customer_id) {
            customerId = uiConfig.customer_id
        } else if (config.name === development.name) {
            customerId = 1
        }
        if (isDeviceDetailsFieldsMandatory)
            dispatch(getInventoryPartcodes({ customerId, dropdown: true }));
    }, [ticketDetails?.customer_details?.customer_id, dispatch, isDeviceDetailsFieldsMandatory, ticketDetails?.fk_customer_id, uiConfig?.customer_id]);

    useEffect(() => {
        if (selectedLocationOnMap?.address) {
            let add = `${selectedLocationOnMap.address}`;
            if (!findPincode(add) && isValidPincode(selectedLocationOnMap.pincode)) {
                add = `${add}, ${selectedLocationOnMap.pincode}`;
            }
            setValue('address', add);
            if (isValidPincode(selectedLocationOnMap.pincode)) {
                setValue('pincode', selectedLocationOnMap.pincode);
            }
            setTextAreaHeight(6.0);
        }
        if (selectedLocationFromSuggestedList?.address) {
            let add = `${selectedLocationFromSuggestedList.address}`;
            if (!findPincode(add) && isValidPincode(selectedLocationFromSuggestedList.pincode)) {
                add = `${add}, ${selectedLocationFromSuggestedList.pincode}`;
            }
            setValue('address', add);
            if (isValidPincode(selectedLocationFromSuggestedList.pincode)) {
                setValue('pincode', selectedLocationFromSuggestedList.pincode);
            }
            setTextAreaHeight(6.0);
        }
    }, [selectedLocationOnMap?.pincode, selectedLocationOnMap?.address, selectedLocationOnMap?.city, selectedLocationFromSuggestedList?.address, selectedLocationFromSuggestedList?.pincode, selectedLocationFromSuggestedList?.city, setValue]);

    const { isShowApartmentField, showPincode } = useMemo(() => {
        let pin = false;
        const isAddressSelectedFromLocation = selectedLocationFromSuggestedList && Object.keys(selectedLocationFromSuggestedList).length > 0;
        const isAddressSelectedFromMap = selectedLocationOnMap && Object.keys(selectedLocationOnMap).length > 0;
        return { isShowApartmentField: (isAddressSelectedFromLocation || isAddressSelectedFromMap), showPincode: pin };
    }, [selectedLocationOnMap, selectedLocationFromSuggestedList]);

    if (editPayoutLoading || (isConversationMessagesLoading && isConversationMessagesLoading?.[conversationDetail?._id]) || isConversationDetailsLoading || isViewTicketByIdLoading) return <div className='px-6'> <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6, 7, 8, 9]} height={20} className={'my-4'} /></div>

    const disableAdditionalDocumentsUpload = (ticketId && !conversationDetail && !conversationDetail?._id);

    const isShowDefaultForm = ({ ticketDetails, tagAssetDetails }) => {
        const notShowForProd = [3355, 3706, 3781, '3355', '3706', '3781'];
        const notShowForDev = [319, '319'];
        if (ticketDetails) {
            return ((config.name === production.name && ticketDetails && !notShowForProd.includes(ticketDetails.fk_customer_id)) || (config.name === development.name && ticketDetails && !notShowForDev.includes(ticketDetails.fk_customer_id)));
        }
        if (tagAssetDetails) {
            const customerId = tagAssetDetails.customer_id || tagAssetDetails?.fk_customer_id;
            return ((config.name === production.name && tagAssetDetails && !notShowForProd.includes(customerId)) || (config.name === development.name && tagAssetDetails && !notShowForDev.includes(customerId)));
        }
        return true;
    };

    const openMap = () => {
        setShowMap(!showMap)
    }

    const showDefaultForm = isShowDefaultForm({ ticketDetails, tagAssetDetails });

    if (showMap && isMobileView) {
        return <div className='flex-intial'> <ReactGoogleMap mapContainerStyle={{ width: '100%', height: '72vh' }} setShowMap={setShowMap} address={previousTicketDetails.address} /> </div>
    }
    return <>
        <div className='relative h-full flex overflow-hidden'
            style={{
                flexFlow: 'column',
            }}
        >
            {!isMobileView && <div className={`chatContainerHeader flex-intial w-full flex justify-center items-center py-2 border-b-2 border-scogogray flex-initial `}>
                <div className='flex w-full gap-2'>
                    <p className={'text-scogo21 text-font13 font-medium font-rubik flex items-center justify-center w-full '}>
                        <span className='max-w-5/12'>Edit Details</span>
                        <OverflowTip textClass={'max-w-7/12 renderChatIcon text-scogoprimary'} someLongText={`: ${validateStringValue(ticketDetails?.job_ticket_number)}`} />
                    </p>
                    <span onClick={onSearchIconClick} className='renderChatIcon material-icons cursor-pointer max-w-max text-font24 ml-auto pr-3'>search</span>
                </div>
            </div>}
            <div className='px-4 pt-3 flex-intial'>
                <Form
                    methods={methods}
                    onSubmit={onSubmit}
                    submitButtonText={'Update'}
                    submitContainerClassName='py-4 px-4'
                    showCancelButton={false}
                    submitRef={submitRef}
                    onEnterPressSubmit
                >
                    <div className='grid grid-cols-1 rows-fr grid-flow-row-dense '>
                        {!showDefaultForm ? <AttachmentUpload fileValues={ticketDetails?.important_file_url ? [JSON.parse(ticketDetails?.important_file_url)] : []} disableAdditionalDocumentsUpload={disableAdditionalDocumentsUpload} inputName='additional_documents' label={uiConfig ? uiConfig?.support_track_attachment_title : 'Additional Documents (eg.: Invoice, Issue Photo/Video)'} isRequired
                        /> : <></>}

                        <FetchGoogleAddress name={'address'} inputStyle={{ 'height': '10vh' }} defaultHeight={5} setLocation={setSelectedLocationFromSuggestedList} />
                        <div className='flex items-center justify-end gap-2 cursor-pointer'>
                            <span class="material-icons text-scogoorange text-md" style={{ fontSize: '1.5rem', paddingTop: '0.3rem' }}>location_searching</span>
                            <span className='text-scogoorange text-xl' onClick={openMap}>Choose Loaction</span>
                        </div>

                        {isShowApartmentField && <Input name='apartment' label='Apartment/House No.' rows='8' />}
                        {/* {showPincode && <Input pattern={/^\d{6}$/} type={'number'} label=' Pincode' name='pincode' required />} */}
                        <div className='my-4'>
                            {showDefaultForm ? <Input name='serial_number' label='Serial No.' rows='8' /> : <></>}
                        </div>

                        {
                            showDefaultForm ? <div className='my-3'>
                                <AttachmentUpload fileValues={ticketDetails?.important_file_url ? [JSON.parse(ticketDetails?.important_file_url)] : []} disableAdditionalDocumentsUpload={disableAdditionalDocumentsUpload} inputName='additional_documents' label={uiConfig ? uiConfig?.support_track_attachment_title : 'Additional Documents (eg.: Invoice, Issue Photo/Video) '}
                                />
                            </div> : <></>
                        }
                        {
                            Array.isArray(issueCategoryOptions) && issueCategoryOptions.length > 0 ? <div className='py-4'>
                                <Select label='Problem' name='issue_category' required options={issueCategoryOptions} />
                            </div> : <></>
                        }
                        <div className='py-2'>
                            <Input name='description' label='Description' rows='8' required />
                        </div>

                    </div >
                </Form >
            </div >
            <div className='flex-intial'>
                <div className='py-4 w-full px-8'>
                    <div className='flex w-full'>
                        <ButtonScogoPrimary onClick={(e) => {
                            e.preventDefault();
                            submitRef.current.click();
                        }}
                            buttonTextClass={'submitButtonSupportText'}
                            loading={formButtonLoading[formName]}
                            textOrComponent={'Submit'}
                            disabled={!isDirty}
                        />
                    </div>
                </div>
            </div>
        </div >
    </>
}

const requestOtp = 'Request OTP';
const submitText = 'Submit';

export const LoginForm = ({ ticketId, redirect_to }) => {
    const defaultValues = {}
    const methods = useForm({ defaultValues, mode: 'all' });
    const submitRef = useRef(null);
    const { requestOTPSuccess } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { isRequestOtpButtonLoading, isOtpButtonLoading } = useSelector((state) => state.loading);
    const buttonName = requestOTPSuccess ? submitText : requestOtp;
    const { uiConfig } = useSelector(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        return () => dispatch(removeOTPSuccessFromStore());
    }, [dispatch]);

    const onSubmit = (formValues) => {
        if (formValues.name.trim().length === 0) {
            dispatch(sendToastMessage({ status: 'warning', message: 'Please enter valid name.' }));
            return;
        }

        if (buttonName === requestOtp || !requestOTPSuccess) {
            const { first_name, last_name } = getFirstAndLastNameFromFullName(formValues.name)
            const data = { first_name, last_name, skip: true, mobile: formValues.mobile, logged_in_from: 'support_view' };
            if (ticketId) {
                data.ticketId = ticketId;
            } else if (uiConfig?.customer_id) {
                data.customer_id = uiConfig.customer_id;
            } else if (window.location.hostname === 'localhost' || config.name === development.name) {
                data.customer_id = 1;
            }
            dispatch(requestOtpSupport(data));
        } else {
            dispatch(loginWithOTPAction({ mobile: formValues.mobile, otp: formValues.otp, logged_in_from: 'support_view', redirect_to, navigate }))
        }
    };

    return <div className='edit-ticket-card-wtih-chat  flex px-6  py-4' style={{
        flexFlow: 'column',
    }}>
        <div className='overflow-y-auto pt-10 w-full b'>
            <Form
                methods={methods}
                onSubmit={onSubmit}
                submitButtonText={'Update'}
                submitContainerClassName='py-4 px-4'
                showCancelButton={false}
                submitRef={submitRef}
                onEnterPressSubmit
            >
                <div className='grid grid-cols-1 rows-fr grid-flow-row-dense'>
                    <Input label='Name' name='name' type={'text'} required validate={(value) => value.trim().length > 0 || 'Invalid Name'} autofocus />
                    <Input label='Mobile' name='mobile' type={'number'} required validate={(value) => value.length === 10 || 'Invalid Mobile'} />
                    {requestOTPSuccess && <Input label='OTP' name='otp' type={'number'} required validate={(value) => value.length === 4 || 'Invalid OTP'} />
                    }
                </div>
            </Form>
        </div>
        <div className='pt-10 pb-20 w-full  '>
            <div className='flex justify-center w-full'>
                <ButtonScogoPrimary onClick={(e) => {
                    e.preventDefault();
                    submitRef.current.click();
                }}
                    buttonTextClass={'submitButtonSupportText'}
                    loading={isRequestOtpButtonLoading || isOtpButtonLoading}
                    textOrComponent={buttonName}
                />
            </div>
        </div>
    </div>
}


export const TicketSearch = ({ mainDivClass = 'flex flex-col items-center gap-2 my-8', closeModal, searchDivClass = 'w-10/12', buttonPadding = 'px-10 py-2 text-font15 md:text-font12' }) => {
    const [searchKey, setSearchKey] = useState('');
    const { isTicketListLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (value) => {
        setSearchKey(value);
    };

    const onSearchClick = (query) => {
        const q = `ticket_num{${query}}`;
        dispatch(getTicketFilteredList({ query: q, tab: 'alltickets', isSupportPath: true, navigate, closeModal }));
    }

    return <div className={mainDivClass}>
        <div className={searchDivClass}>
            <SearchBox searchTerm={searchKey} onChange={(event) => handleChange(event.target.value)} placeholder={'Ticket No'} onCancel={() => handleChange('')} isExpandingEnabled={false} inputClass='bg-scogolightblue text-black' inputWidth='w-full' />
        </div>
        <div className='my-2'>
            <ButtonScogoPrimary buttonPadding={buttonPadding} buttonTextClass={'submitButtonSupportText'} onClick={() => onSearchClick(searchKey)} textOrComponent={'Search'} loading={isTicketListLoading} />
        </div>
    </div>
}

const AttachmentUpload = ({ fileValues, disableAdditionalDocumentsUpload, inputName = 'additional_documents', label = 'Additional Documents (eg.: Invoice, Issue Photo/Video)', isRequired }) => {
    const { watch } = useFormContext();
    const additional_documents = watch('additional_documents');

    return (
        <>
            <div className='w-full'>
                <div className='flex w-full'>
                    <Upload padding='px-2 pt-2' className='w-full' name={inputName} label={label}
                        labelClass={'mt-2'} isMulti disabled={disableAdditionalDocumentsUpload} required={isRequired && fileValues?.length === 0}
                        uploadText={'Upload File'} accept='image/jpeg,application/pdf,image/png'
                        validateFileType={(file) => {
                            const isValidAttachment = [
                                'image/jpeg',
                                'image/png',
                                'application/pdf',
                            ].includes(file.type);
                            return isValidAttachment || `${file.name} is not a valid post attachment`;
                        }}
                    />
                </div>
                {Array.isArray(fileValues) && fileValues.length > 0 && !additional_documents ?
                    <>
                        <div className='flex items-center gap-2 flex-wrap px-2 py-2'>
                            {fileValues.map((file, index) => {
                                return <div className='flex items-center' key={file.name} title={file.name}>
                                    <div className='max-w-12 truncate text-font09 md:text-font11 text-scogoprimary font-medium cursor-pointer'
                                        onClick={() => window.open(file.url, '_blank')}>
                                        {file.name}
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        </>
    )
}


const TakePincode = ({ formValues, onSubmit, pincode }) => {
    const { formButtonLoading } = useSelector((state) => state.loading);
    const methods = useForm({ defaultValues: { pincode: isValidPincode(pincode) ? pincode : '' }, mode: 'all' });
    const dispatch = useDispatch();
    return (<Form
        methods={methods}
        onSubmit={(event) => onSubmit({ formValues: { ...formValues, ...event } })}
        showSubmit
        onCancel={() => dispatch(closeModalAction())}
        submitContainerClassName='py-4 px-4 flex justify-end gap-2'
        submitButtonText='Submit'
        isLoading={formButtonLoading[formName]}
    >
        <div className='px-3'>
            <Input pattern={/^\d{6}$/} type={'number'} label='Pincode' name='pincode' required />
        </div>
    </Form>)
}

export const FetchGoogleAddress = ({ name, label, inputStyle, optionStyle, defaultHeight, setLocation = () => { } }) => {
    const [place_i, setplace_id] = useState({ onOff: false, placeId: '' });
    const [options, setOption] = useState([]);
    const { watch } = useFormContext();

    const geecodingAD = () => {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            {
                placeId: place_i?.placeId,
            },
            function (responses, status) {
                if (status === "OK") {
                    var lat = responses[0].geometry.location.lat();
                    var lng = responses[0].geometry.location.lng();
                    geocoder.geocode(
                        {
                            latLng: responses[0].geometry.location,
                        },
                        function (res) {
                            if (res) {
                                let add = res[1]?.address_components;
                                var forcity = res[0].formatted_address;
                                var value = forcity.split(",");
                                let count = value.length;
                                let city = value[count - 3];
                                // let pin = add[add.length - 1].long_name;
                                let pin = validateNumberValue(add[add.length - 1].long_name) || findPincode(add) || ''
                                setLocation({
                                    lat: lat,
                                    lng: lng,
                                    pincode: pin,
                                    address: watch(name),
                                    city: city,
                                });
                            }
                        }
                    );
                }
            }
        );
    };

    useEffect(() => {
        if (place_i?.placeId && place_i?.placeId?.length > 0) {
            geecodingAD();
        }
    }, [place_i?.placeId]);

    const memoRenderName = useCallback(() => {
        return (
            <MapWithPlaces
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleMapApiKey}&v=3.exp&libraries=geometry,drawing,places&callback=Function.prototype`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                setlocation={() => { }}
            />
        );
    }, []);

    const displaySuggestions = (predictions, status) => {
        if (status === "OK") {
            let options = predictions.map((e) => {
                return { value: e.description, label: e.place_id };
            });
            setOption(options);
        } else {
            setOption([]);
        }
    };

    const handleScriptLoad = useCallback((value) => {
        if (value.length > 0) {
            autoComplete = new window.google.maps.places.AutocompleteService();
            const request = {
                input: value,
                componentRestrictions: { country: "In" },
            };
            autoComplete.getQueryPredictions(request, displaySuggestions);
        }
    }, []);

    const debounce = useCallback((value) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            handleScriptLoad(value);
        }, 1000);
    }, [handleScriptLoad]);

    const onChange = useCallback((event) => {
        if (event?.target?.value && event.target.value.length > 0 && place_i?.onOff) {
            debounce(event.target.value);
        }
        setplace_id({
            onOff: true,
            place_Id: ""
        })
    }, [place_i?.onOff, debounce]);

    return (<>
        <NewInput
            place_i={place_i}
            setplace_id={setplace_id}
            options={options}
            name={name}
            label={label}
            required={true}
            onChange={onChange}
            inputStyle={inputStyle}
            optionStyle={optionStyle}
            defaultHeight={defaultHeight}
        />
        <div className='mapContainer'>{memoRenderName()}</div>
    </>)
}
