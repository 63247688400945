import React from "react"

export default function BankCard({ bankName, ifscCode, bankAccountHolderName, bankAccountNumber }) {
    const isAccountNameOrNumberAvailable = (bankAccountHolderName && bankAccountNumber);
    let bankAccountWithHideLastFourNumbers = bankAccountNumber;
    if (bankAccountNumber?.length > 4) {
        bankAccountWithHideLastFourNumbers = new Array(bankAccountNumber?.length - 4 + 1)?.join('x') + bankAccountNumber?.slice(-4);
    }

    return (<div className=' border-b-2 border-scogobgsky h-28 mt-2 rounded-lg'>
        <div className='w-full h-full px-4 flex flex-col justify-center border-2 rounded-lg'>
            {bankName && <p className=' text-font13 text-scogo15 font-bold md:mr-0 mr-2'>
                <span className='text-scogoprimary font-medium'>Bank Name: </span>{bankName}
            </p>}
            {ifscCode && <p className='text-font13 text-scogo18 font-normal md:mr-0 mr-2'>
                <span className='text-scogoprimary font-medium'>IFSC Code: </span>{ifscCode}
            </p>}
            {isAccountNameOrNumberAvailable && <p className='text-font13 text-scogo18 font-medium md:mr-0 mr-2'>
                <span className='text-scogoprimary font-medium'>Account Details: </span>{`${bankAccountHolderName}: ${bankAccountWithHideLastFourNumbers}`}
            </p>}
        </div>
    </div>)
}