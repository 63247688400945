import { takeEvery, takeLatest, call, put, all, delay } from 'redux-saga/effects';
import api from '../apis';
import { tickets, loading, toast, modal } from '../types';
import { guid } from '../utils/utils';
import lambdas from '../lambdas/apis';
import { todayStatus } from '../router/roleWisePageRoute';

export function* getSPNewTicketsList(action) {
    const { order_by = 'broadcast_timestamp', sp_id, ticketId, user_type, limit, page } = action.payload;
    const queryParams = { order_by };
    if (!['', undefined, null].includes(user_type)) queryParams.user_type = user_type;
    if (!isNaN(sp_id) && parseInt(sp_id) > 0) {
        queryParams.sp_id = sp_id;
    }
    if (!isNaN(ticketId) && parseInt(ticketId) > 0) {
        queryParams.ticketId = ticketId;
    }
    if (limit) queryParams.limit = limit;
    if (page) queryParams.page = page;

    try {
        yield put({ type: loading.SP_ASSIGNED_NEW_TICKETS_LIST_LOADING, payload: true });
        const response = yield call(api.tickets.listSPEligibleTickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            let ticketList = response.data.data;
            if (user_type === 'ISP') {
                ticketList = [...response.data.data.data];
            }
            yield put({ type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_SUCCESS, payload: ticketList });
        } else {
            yield put({ type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_SUCCESS, payload: [] });
            yield put({ type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SP_ASSIGNED_NEW_TICKETS_LIST_LOADING, payload: false });
}

export function* getSPOngoingTickets(action) {
    const { type, order_by = 'broadcast_timestamp', sp_id, ticketId, open = false } = action.payload;
    let queryParams = { order_by };
    if (!isNaN(sp_id) && parseInt(sp_id) > 0) {
        queryParams.sp_id = sp_id;
    }
    if (!isNaN(ticketId) && parseInt(ticketId) > 0) {
        queryParams.ticketId = ticketId;
    }
    if (open) queryParams.open = open;
    let queryType = type === 'alltickets' ? 'sp_all_tickets' : type === 'new' ? 'new' : `sp_${type}_tickets`;
    queryParams[queryType] = true;
    try {
        const response = yield call(api.tickets.listSPTickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: tickets.SP_ONGOING_TICKETS_SUCCESS, payload: response.data });
        } else {
            yield put({ type: tickets.SP_ONGOING_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SP_ONGOING_TICKETS_FAILURE, payload: { message: error } });
    }
}

export function* getExecutionDateChangeRemarks(action) {
    try {
        const response = yield call(api.tickets.getExecDateChangeRemarks, {});
        if (response.data.code === 200) {
            let remarks = [];
            response.data.data.map((remark) => {
                return remarks.push({
                    key: `${guid()}_${remark.id}`,
                    value: remark.remark,
                    label: remark.remark,
                });
            });
            yield put({ type: tickets.EXEC_DATECHANGE_REMARKS_SUCCESS, payload: remarks });
        } else {
            yield put({ type: tickets.EXEC_DATECHANGE_REMARKS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.EXEC_DATECHANGE_REMARKS_FAILURE, payload: { message: error } });
    }
}

export function* acceptSPAssignedTicket(action) {
    const { data, order_by = 'broadcast_timestamp', userDetails, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.acceptTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = 'Ticket Accepted Successfully!!!';
            if (data.property === 'REASSIGN') {
                message = 'FE ReAssigned Successfully!!!';
            } else if (data.property === 'CUSTOMER_FE') {
                message = 'FE Assigned Successfully!!!';
            }
            message = response.data.data && response.data.data.message ? response.data.data.message : message;
            let yieldAll = [];
            yieldAll.push(yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }));

            if (userDetails && userDetails.role.id === 6) {
            } else {
                if (data.property === 'CUSTOMER_FE') {
                    // yieldAll.push(yield put({ type: tickets.SP_ACCEPTED_TICKETS_LIST_REQUEST }));
                } else {
                    yieldAll.push(yield put({ type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST, payload: { order_by } }));
                }
            }
            yieldAll.push(yield put({ type: modal.CLOSE_MODAL }));
            yield all(yieldAll);
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
            let yieldAll = [];
            yieldAll.push(yield put({ type: tickets.ACCEPT_SP_ASSIGNED_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } }));
            yieldAll.push(yield put({ type: modal.CLOSE_MODAL }));
            yield all(yieldAll);
        }
    } catch (error) {
        yield put({ type: tickets.ACCEPT_SP_ASSIGNED_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getExecDateTimelineAction(action) {
    const { ticket_id } = action.payload;
    try {
        yield put({ type: loading.SET_EXECUTION_DATE_TIMELINE_LOADING, payload: true });
        const response = yield call(api.tickets.getExecDateTimeline, {}, {}, {}, { ticket_id });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_EXECUTION_DATE_TIMELINE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_EXECUTION_DATE_TIMELINE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_EXECUTION_DATE_TIMELINE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_EXECUTION_DATE_TIMELINE_LOADING, payload: false });
}

export function* assignSPManually(action) {
    const { data, formName, callViewTicket = false } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.assignSP, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (callViewTicket) {
                yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: data.tickets[0] } });
            }
            yield put({ type: modal.CLOSE_MODAL });
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'SP assigned Successfully' },
            });
            if (window.location.pathname === todayStatus.path) {
                yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
            } else {
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            }
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' },
            });
            yield put({ type: tickets.ASSIGN_SP_MANUALLY_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ASSIGN_SP_MANUALLY_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* reAssignSPManually(action) {
    const { execution_date, ticket_instruction, ticketList, sp, formName, callViewTicket = false, refresh = true, update_planned_date = 0, partner_base_payment } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const withdrawResponse = yield call(api.ticketExecution.withdrawTicket, {}, {}, {}, { ticket_id: ticketList[0] });
        if (withdrawResponse.data.code === 200) {
            const response = yield call(lambdas.tickets.assignSP, {}, {}, {}, { property: 'REASSIGN', execution_date, ticket_instruction, sp, tickets: ticketList, update_planned_date, partner_base_payment });
            if (response.data.code === 200) {
                if (callViewTicket) yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: ticketList[0] } });
                yield put({ type: modal.CLOSE_MODAL });
                yield put({
                    type: toast.SEND_TOAST,
                    payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'SP assigned Successfully' },
                });

                if (window.location.pathname === todayStatus.path) {
                    if (refresh) yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                } else {
                    if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                }

            } else {
                yield put({
                    type: toast.SEND_TOAST,
                    payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' },
                });
                yield put({ type: tickets.REASSIGN_SP_MANUALLY_FAILURE, payload: { message: 'Oops Something went wrong' } });
            }
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: withdrawResponse.data.data.message ? withdrawResponse.data.data.message : 'Something went wrong' },
            });
        }
    } catch (error) {
        yield put({ type: tickets.REASSIGN_SP_MANUALLY_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* acceptTicket(action) {
    const { data, formName, callViewTicket = false } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.acceptTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = 'Ticket Accepted Successfully!!!';
            message = response.data.data && response.data.data.message ? response.data.data.message : message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });

            if (window.location.pathname === todayStatus.path) {
                yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
            } else {
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            }

            if (callViewTicket) {
                yield delay(2000);
                yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: data.tickets[0] } });
            }
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: tickets.ACCEPT_SP_MANUALLY_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' },
            });
        }
    } catch (error) {
        yield put({ type: tickets.ACCEPT_SP_MANUALLY_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* withdrawTicket(action) {
    const { data, formName, callAfterSubmit = () => { }, refresh = false, showToast = true } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.ticketExecution.withdrawTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            callAfterSubmit();
            if (showToast) yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket Released Successfully' } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                } else {
                    yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                }
            }
        } else {
            yield put({ type: tickets.WITHDRAW_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.WITHDRAW_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* ticketAssignToScogo(action) {
    const { payload, formName, refresh = true, showToast = true } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.tickets.assignToScogo, {}, {}, {}, payload);
        if (response.data.code === 200) {
            let message = 'Ticket Assigned to Scogo';
            if (response.data.data.reason) message = response.data.data.reason;
            if (showToast) yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                } else {
                    yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                }
            }

        } else {
            yield put({ type: tickets.ASSIGN_TO_SCOGO_FAILURE, payload: 'Oops Something went wrong' });
        }
    } catch (error) {
        yield put({ type: tickets.ASSIGN_TO_SCOGO_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkTicketAssignToScogo(action) {
    const { ticketIds, execution_date, ticket_instruction, formName, refresh = true, update_planned_date = 0 } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.tickets.bulkAssignToScogo, { 'Content-Type': 'multipart/form-data' }, {}, {}, { tickets: ticketIds, execution_date, ticket_instruction, update_planned_date });
        if (response.data.code === 200) {
            let message = 'Ticket Assigned to Scogo';
            if (response.data.data.reason) message = response.data.data.reason;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });

            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                } else {
                    yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                }
            };
        } else {
            yield put({ type: tickets.BULK_ASSIGN_TO_SCOGO_FAILURE, payload: 'Oops Something went wrong' });
        }
    } catch (error) {
        yield put({ type: tickets.BULK_ASSIGN_TO_SCOGO_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export default function* ticketAssignmentSaga() {
    yield takeLatest(tickets.SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST, getSPNewTicketsList);
    yield takeEvery(tickets.EXEC_DATECHANGE_REMARKS_REQUEST, getExecutionDateChangeRemarks);
    yield takeLatest(tickets.ACCEPT_SP_ASSIGNED_TICKET_REQUEST, acceptSPAssignedTicket);
    yield takeEvery(tickets.SP_ONGOING_TICKETS_REQUEST, getSPOngoingTickets);
    yield takeEvery(tickets.GET_EXECUTION_DATE_TIMELINE_REQUEST, getExecDateTimelineAction);
    yield takeEvery(tickets.ASSIGN_SP_MANUALLY_REQUEST, assignSPManually);
    yield takeEvery(tickets.REASSIGN_SP_MANUALLY_REQUEST, reAssignSPManually);
    yield takeEvery(tickets.ACCEPT_SP_MANUALLY_REQUEST, acceptTicket);
    yield takeEvery(tickets.WITHDRAW_TICKET_REQUEST, withdrawTicket);
    yield takeEvery(tickets.ASSIGN_TO_SCOGO_REQUEST, ticketAssignToScogo);
    yield takeEvery(tickets.BULK_ASSIGN_TO_SCOGO_REQUEST, bulkTicketAssignToScogo);
}
