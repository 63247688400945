import { spareAssets } from '../pages/Inventory/InventoryController';
import { inventory, loading } from '../types';
import { removeDuplicates } from '../utils/common';

const INITIAL_STATE = {
    error: undefined,
    spareAssets: [],
    rowsPerPage: 10,
    page: 1,
    filterPayload: {},
    siteId: 0,
    warehouseId: 0,
    showInventorySideDrawer: false,
    isArchivedSelected: false,
    isFilterActive: false,
    asset: undefined,
    assetNotFound: false,
    tagAssetDetails: undefined,
    assets: [],
    sitePos: undefined,
    sites: undefined,
    assetsGroupByEndusers: {},
    hasAssetsGroupByEndusersEnd: {},
    scannedAssetData: {},
    branchList: [],
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // Sites
        case inventory.GET_ALL_SITES_SUCCESS:
            return {
                ...state,
                sites: action.payload,
            };
        case inventory.GET_SITES_FILTERED_DATA_SUCCESS: {
            const { count, data } = action.payload;
            return {
                ...state,
                sites: data,
                sitesCount: count,
            };
        }

        case inventory.GET_ASSETS_FILTERED_DATA_SUCCESS: {
            const { count, data } = action.payload;
            return {
                ...state,
                assets: data,
                assetsCount: count,
            };
        }

        case inventory.ALL_SITES_COUNT_SUCCESS:
            return {
                ...state,
                sitesCount: action.payload,
            };
        case inventory.CREATE_INVENTORY_SITES_SUCCESS:
            const { duplicateSites, cratedSite } = action.payload;
            return {
                ...state,
                duplicateSites: duplicateSites,
                createdSite: cratedSite,
            };
        case inventory.REMOVE_CREATED_SITE_FROM_STORE:
            return {
                ...state,
                duplicateSites: undefined,
                createdSite: undefined,
            };
        case inventory.GET_DROPDOWN_ALL_SITES_SUCCESS:
            return {
                ...state,
                sitesDropdown: action.payload,
            };
        case inventory.ADD_SITE_DROPDOWN_LIST: {
            let dropdown = [];
            if (Array.isArray(state.sitesDropdown)) {
                dropdown = state.sitesDropdown;
            }
            dropdown = [...action.payload, ...dropdown];
            return {
                ...state,
                sitesDropdown: dropdown,
            };
        }
        case inventory.CLEAR_SITES_DROPDOWN: {
            return {
                ...state,
                sitesDropdown: null,
            };
        }

        // Assets
        case inventory.GET_ALL_ASSETS_SUCCESS: {
            const { data, source, endUserId, page } = action.payload;
            if (source === 'enduser_view') {
                const assetsGroupByEndusers = state.assetsGroupByEndusers;
                const hasAssetsGroupByEndusersEnd = state.hasAssetsGroupByEndusersEnd;
                if (page > 1) {
                    assetsGroupByEndusers[endUserId] = [...assetsGroupByEndusers[endUserId], ...data];
                } else {
                    assetsGroupByEndusers[endUserId] = data;
                }
                hasAssetsGroupByEndusersEnd[endUserId] = data.length === 0;
                return {
                    ...state,
                    assetsGroupByEndusers,
                    hasAssetsGroupByEndusersEnd,
                };
            }
            return {
                ...state,
                assets: data,
            };
        }
        case inventory.GET_TAG_ASSETS_SUCCESS:
            return {
                ...state,
                tagAssets: action.payload,
            };
        case inventory.GET_ALL_FILTER_ASSETS_SUCCESS:
            return {
                ...state,
                filterAssetsData: action.payload,
            };

        case inventory.ALL_ASSETS_COUNT_SUCCESS:
            return {
                ...state,
                assetsCount: action.payload,
            };

        case inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_SUCCESS:
            return {
                ...state,
                replaceAssetDropdown: action.payload,
            };
        case inventory.ADD_ASSETS_SUCCESS:
            return {
                ...state,
                newlyAddedAssetType: action.payload,
            };
        case inventory.REMOVE_ADDED_ASSETS_TYPE_FROM_STORE:
            return {
                ...state,
                newlyAddedAssetType: undefined,
            };

        //warehouse
        case inventory.GET_ALL_WAREHOUSE_SUCCESS:
            return {
                ...state,
                warehouses: action.payload,
            };

        case inventory.GET_WAREHOUSE_FILTERED_DATA_SUCCESS: {
            const { data, count } = action.payload;
            return {
                ...state,
                warehouses: data,
                warehouseCount: count,
            };
        }

        case inventory.GET_ALL_WAREHOUSE_FOR_FILTER_SUCCESS:
            return {
                ...state,
                allWarehouseForFilter: action.payload,
            };

        case inventory.GET_NEXT_WAREHOUSE_LIST_SUCCESS: {
            const warehouses = action.payload;
            let dataArray = [...state.warehouses, ...warehouses];
            const removeDuplicates = (arrays) => {
                const { res } = arrays.reduce(
                    ({ encountered, res }, cur) => {
                        if (!encountered.has(cur.id)) {
                            encountered.add(cur.id);
                            res.push(cur);
                        }
                        return { encountered, res };
                    },
                    { encountered: new Set(), res: [] }
                );
                return res;
            };
            const filteredWarehouse = removeDuplicates(dataArray);
            const hasReachedWarehouseListEnd = warehouses.length === 0;
            return {
                ...state,
                warehouses: filteredWarehouse,
                hasReachedWarehouseListEnd: hasReachedWarehouseListEnd,
            };
        }

        case inventory.ALL_WAREHOUSES_COUNT_SUCCESS:
            return {
                ...state,
                warehouseCount: action.payload,
            };

        case inventory.FIND_WAREHOUSE_BY_ID_SUCCESS:
            return {
                ...state,
                warehouse: action.payload,
            };

        //spare_assets
        case inventory.GET_ALL_SPARE_ASSETS_SUCCESS: {
            const { spareAssets } = action.payload;
            return {
                ...state,
                spareAssets,
            };
        }
        case inventory.GET_SPAREASSET_FILTERED_DATA_SUCCESS: {
            const { data, count } = action.payload;
            return {
                ...state,
                spareAssets: data,
                spareAssetsCount: count,
            };
        }
        case inventory.REMOVE_ALL_SPARE_ASSET_DATA_FROM_STORE:
            return {
                ...state,
                spareAssets: [],
            };

        case inventory.ALL_SPARE_ASSETS_COUNT_SUCCESS:
            return {
                ...state,
                spareAssetsCount: action.payload,
            };
        case inventory.GET_ALL_FILTER_SPAREASSET_SUCCESS:
            return {
                ...state,
                allSpareAssetsForFilter: action.payload,
            };

        //cities
        case inventory.CITIES_LIST_SUCCESS:
            return {
                ...state,
                cities: action.payload,
            };

        //enduser
        case inventory.ENDUSERS_LIST_SUCCESS:
            return {
                ...state,
                endUsersList: action.payload,
            };

        case inventory.ADD_NEW_END_USER_IN_LIST: {
            const newList = [...action.payload, ...state.endUsersList];
            return {
                ...state,
                endUsersList: newList,
            };
        }
        case inventory.REMOVE_ENDUSER_LIST_FROM_STORE: {
            return {
                ...state,
                endUsersList: undefined,
            };
        }
        //siteType
        case inventory.GET_SITE_TYPE_SUCCESS:
            return {
                ...state,
                siteType: action.payload,
            };

        //sla's
        case inventory.SLA_POLICIES_LIST_SUCCESS:
            return {
                ...state,
                slas: action.payload,
            };

        //pincode
        case inventory.GET_CITY_FROM_PINCODE_SUCCESS:
            return {
                ...state,
                pinCity: action.payload,
            };
        case inventory.REMOVE_CITY_FROM_PINCODE_DATA_FROM_STORE:
            return {
                ...state,
                pinCity: {},
            };

        //spare_category
        case inventory.GET_SPARE_CATEGORIES_SUCCESS:
            return {
                ...state,
                spareCategory: action.payload,
            };
        //partcodes
        case inventory.GET_INVENTORY_PARTCODES_SUCCESS:
            return {
                ...state,
                partCodes: action.payload,
            };

        //assetTypes
        case inventory.GET_ASSETS_SUCCESS:
            return {
                ...state,
                assetTypes: action.payload,
            };

        //siteDetails
        case inventory.FIND_SITE_BY_ID_SUCCESS:
            return {
                ...state,
                siteDataById: action.payload,
            };

        case inventory.REMOVE_SiTE_DATA_BY_ID_FROM_STORE:
            return {
                ...state,
                siteDataById: undefined,
            };
        case inventory.GET_WAREHOUSE_OWNER_DROPDOWN_SUCCESS:
            return {
                ...state,
                warehouseOwner: action.payload,
            };

        case inventory.FIND_ALL_WAREHOUSES_SUCCESS:
            return {
                ...state,
                warehousesDropDown: action.payload,
            };

        case inventory.ALL_REPAIR_CENTERS_DROPDOWN_SUCCESS:
            return {
                ...state,
                repaircentersDropdown: action.payload,
            };

        case inventory.SET_INVENTORY_SIDEDRAWER:
            return {
                ...state,
                showInventorySideDrawer: action.payload.showInventorySideDrawer,
                inventorySideDrawerName: action.payload.sideBarName,
                inventorySideDrawerPayload: action.payload.inventorySideDrawerPayload,
            };

        case inventory.GET_ASSETS_HISTORY_SUCCESS:
            return {
                ...state,
                assetHistory: action.payload,
            };

        case inventory.GET_SITES_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                sitesFiltersData: action.payload,
            };
        }
        case inventory.GET_ASSETS_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                assetsFiltersData: action.payload,
            };
        }
        case inventory.GET_SPAREASSETS_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                spareAssetFiltersData: action.payload,
            };
        }
        case inventory.GET_WAREHOUSE_FILTER_LIST_SUCCESS: {
            return {
                ...state,
                warehouseFilterData: action.payload,
            };
        }

        case inventory.SET_INVENTORY_LIMIT_OR_PAGE: {
            const { limit, page } = action.payload;

            const updatedRowsPerPageAndPage = {};
            if (limit) updatedRowsPerPageAndPage.rowsPerPage = limit;
            if (page) updatedRowsPerPageAndPage.page = page;
            return {
                ...state,
                ...updatedRowsPerPageAndPage,
            };
        }

        case inventory.CLEAR_SITE_TABLE_DATA_ON_UNMOUNT: {
            return {
                ...state,
                rowsPerPage: INITIAL_STATE.rowsPerPage,
                page: INITIAL_STATE.page,
                filterPayload: {},
                isFilterActive: false,
            };
        }

        case inventory.SET_INVENTORY_FILTER_AND_PAGINATION_DATA: {
            const { payload, isFilterActive, limit, page, query } = action.payload;
            let updatedPayload = {};
            if (isFilterActive !== undefined) updatedPayload.isFilterActive = isFilterActive;
            if (payload) updatedPayload.filterPayload = payload;
            if (limit) updatedPayload.rowsPerPage = limit;
            if (page) updatedPayload.page = page;
            if (query !== undefined) updatedPayload.query = query;

            return {
                ...state,
                ...updatedPayload,
            };
        }
        case inventory.SET_SITE_ID_IN_STORE: {
            return {
                ...state,
                siteId: action.payload,
            };
        }
        case inventory.SET_WAREHOUSE_ID_IN_STORE: {
            return {
                ...state,
                warehouseId: action.payload,
            };
        }
        case inventory.CLEAR_SITE_OR_WAREHOUSE_ID: {
            return {
                ...state,
                warehouseId: 0,
                siteId: 0,
            };
        }
        case inventory.GET_PROPERTIES_OF_SITE_SUCCESS: {
            return {
                ...state,
                selectedInventorySite: {
                    externalSiteId: action.payload.external_site_id,
                    customerId: action.payload.fk_customer_id,
                },
            };
        }
        case inventory.GET_PROPERTIES_OF_WAREHOUSE_SUCCESS: {
            return {
                ...state,
                selectedInventoryWarehouse: {
                    name: action.payload.name,
                },
            };
        }
        case inventory.SET_IS_ARCHIVE_SELECTED_IN_STORE: {
            return {
                ...state,
                isArchivedSelected: action.payload,
            };
        }

        case inventory.GET_SPAREASSET_WITH_ASSET_ID_SUCCESS: {
            let spateAssetDetail = [];
            if (Array.isArray(action.payload) && action.payload.length > 0) {
                spateAssetDetail = action.payload[0];
            }
            return {
                ...state,
                spateAssetDetail,
            };
        }

        case inventory.GET_SPAREASSET_WITH_ASSET_ID_FAILURE: {
            return {
                ...state,
                spateAssetDetail: undefined,
            };
        }
        case inventory.CLEAR_SPARE_ASSET_DETAIL: {
            return {
                ...state,
                spateAssetDetail: undefined,
            };
        }
        case inventory.GET_SITE_LIST_FROM_PINCODE_SUCCESS: {
            return {
                ...state,
                sitesByPincodeList: action.payload,
            };
        }
        case inventory.GET_ASSET_WITH_SERIAL_NUMBER_SUCCESS: {
            return {
                ...state,
                asset: action.payload.asset,
                assetNotFound: action.payload.assetNotFound,
            };
        }
        case inventory.GET_SCANNED_ASSET_SUCCESS: {
            const { asset, id } = action.payload;
            return {
                ...state,
                scannedAssetData: { ...state.scannedAssetData, [id]: asset },
            };
        }
        case inventory.REMOVE_ASSET_FROM_STORE: {
            return {
                ...state,
                asset: INITIAL_STATE.asset,
                createdAssets: undefined,
            };
        }
        case inventory.REMOVE_ASSET_NOT_FOUND_FROM_STORE: {
            return {
                ...state,
                assetNotFound: INITIAL_STATE.assetNotFound,
            };
        }
        case inventory.SEARCH_SITE_SUCCESS: {
            return {
                ...state,
                sitesDropdown: removeDuplicates([...state.sitesDropdown, ...action.payload], 'value'),
            };
        }
        case inventory.CREATE_INVENTORY_ASSETS_SUCCESS: {
            return {
                ...state,
                createdAssets: action.payload,
            };
        }
        case inventory.CLEAR_ASSETS_AND_SPARES_LISTS: {
            return { ...state, assets: null, spareAssets: null };
        }
        case inventory.GET_ASSET_TAG_DETAILS_SUCCESS: {
            const { asset } = action.payload;
            return { ...state, tagAssetDetails: asset };
        }
        case inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_SUCCESS: {
            const { data, assetId } = action.payload;
            let editedAsset = [...state.assets];
            editedAsset = editedAsset.map((asset) => {
                if (asset.id === assetId) {
                    if (asset.device_config === null) {
                        asset.device_config = { attachmentList: { files: [] } };
                    }
                    asset.device_config.attachmentList.files = [...asset.device_config.attachmentList.files, data];
                }
                return asset;
            });
            return { ...state, assets: editedAsset };
        }
        case inventory.EDIT_SPARE_ASSET_UPLOAD_ATTACHMENT_SUCCESS: {
            const { data, assetId } = action.payload;
            let editedAsset = [...state.spareAssets];
            editedAsset = editedAsset.map((asset) => {
                if (asset.id === assetId) {
                    if (asset.device_config === null) {
                        asset.device_config = { attachmentList: { files: [] } };
                    }
                    asset.device_config.attachmentList.files = [...asset.device_config.attachmentList.files, data];
                }
                return asset;
            });
            return { ...state, spareAssets: editedAsset };
        }

        case inventory.GET_SITE_POS_LIST_SUCCESS: {
            let sites_list = state.sites || [];
            let assets_list = state.assets || [];
            let { sitePos, source } = action.payload;
            if (source === 'sites' && sites_list.length > 0) {
                sites_list = sites_list.map((site) => {
                    let poList = sitePos.filter((po) => po.siteId === site.id);
                    return { ...site, poList };
                });
                return {
                    ...state,
                    sites: sites_list,
                };
            } else if (source === 'assets' && assets_list.length > 0) {
                assets_list = assets_list.map((asset) => {
                    let po = sitePos.find((po) => po.assetIds.includes(asset.id));
                    let poList = [];
                    if (po) poList.push(po);
                    return { ...asset, poList };
                });

                return { ...state, assets: assets_list };
            }

            return {
                ...state,
            };
        }
        case inventory.GET_ENDUSER_BY_SITE_POS_LIST_SUCCESS: {
            let { sitePos, enduserId } = action.payload;
            let assetsGroupByEndusers = state.assetsGroupByEndusers;
            if (assetsGroupByEndusers[enduserId] && assetsGroupByEndusers[enduserId].length > 0) {
                assetsGroupByEndusers[enduserId] = assetsGroupByEndusers[enduserId].map((asset) => {
                    let po = sitePos.find((po) => po.assetIds.includes(asset.id));
                    let poList = [];
                    if (po) poList.push(po);
                    return { ...asset, poList };
                });

                return { ...state, assetsGroupByEndusers };
            }

            return {
                ...state,
            };
        }
        case inventory.GET_BRANCH_LIST_SUCCESS: {
            return {
                ...state,
                branchList: action.payload,
            };
        }

        case inventory.GET_SITE_POS_LIST_FAILURE:
        case inventory.GET_ASSET_TAG_DETAILS_FAILURE:
        case inventory.GET_ASSET_WITH_SERIAL_NUMBER_FAILURE:
        case inventory.GET_SITES_FILTERED_DATA_FAILURE:
        case inventory.GET_ASSETS_FILTERED_DATA_FAILURE:
        case inventory.GET_WAREHOUSE_FILTERED_DATA_FAILURE:
        case inventory.GET_WAREHOUSE_OWNER_DROPDOWN_FAILURE:
        case inventory.ALL_REPAIR_CENTERS_DROPDOWN_FAILURE:
        case inventory.GET_ALL_SITES_FAILURE:
        case inventory.GET_ALL_ASSETS_FAILURE:
        case inventory.GET_ALL_WAREHOUSE_FAILURE:
        case inventory.GET_ALL_SPARE_ASSETS_FAILURE:
        case inventory.CITIES_LIST_FAILURE:
        case inventory.ENDUSERS_LIST_FAILURE:
        case inventory.GET_SITE_TYPE_FAILURE:
        case inventory.SLA_POLICIES_LIST_FAILURE:
        case inventory.GET_CITY_FROM_PINCODE_FAILURE:
        case inventory.GET_SPARE_CATEGORIES_FAILURE:
        case inventory.GET_INVENTORY_PARTCODES_FAILURE:
        case inventory.GET_ASSETS_FAILURE:
        case inventory.FIND_SITE_BY_ID_FAILURE:
        case inventory.FIND_WAREHOUSE_BY_ID_FAILURE:
        case inventory.GET_ASSETS_HISTORY_FAILURE:
        case inventory.GET_PROPERTIES_OF_SITE_FAILURE:
        case inventory.GET_PROPERTIES_OF_WAREHOUSE_FAILURE:
        case inventory.GET_SITE_LIST_FROM_PINCODE_FAILURE:
        case inventory.EDIT_ASSET_DATE_FAILURE:
        case inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

export default inventoryReducer;
