import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import Upload from '../../../common/Form/FileUpload';
import { useForm } from 'react-hook-form';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import config from '../../../config';
import { bulkUploadTicketFile } from '../../../actions/tickets';

const formName = 'bulk_upload_sites';

export default function BulkUploadOfflineTransaction() {
    const defaultValues = {};

    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const methods = useForm({ defaultValues, mode: 'all' });

    const submit = (formValues) => {
        dispatch(bulkUploadTicketFile({ formName, property: 'TICKET_ADDITIONAL_PAYMENTS', customerId, files: formValues.bulk_sites, refresh: false }))
    };

    const fileUrl = `${config.api.staticAssetsUrl}/others/bulk_ticket_additional_payments.csv`;

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Upload'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2 flex w-full pt-2'>
                <Upload className='w-full' name='bulk_sites' label={'Upload CSV'} required />
            </div>

            <div className='px-5 py-2'>
                <p className='text-scogoorange font-normal text-font11 cursor-pointer hover:text-scogobgsky max-w-max'>
                    <a href={fileUrl} download>
                        Download Sample file
                    </a>
                </p>
            </div>

        </Form >
    );
}