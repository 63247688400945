import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TicketSearch } from './RenderTicketCard';
import { Skeleton } from '@mui/material';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import { getConversationDetails } from '../../actions/conversations';
import { GetQueryParam, isLogged } from '../../utils/common';
import { isMobileView } from '../auth/components/NewScogoButton';
import { openCustomModal } from '../../actions/modal';
import { LoginForm } from './RenderTicketDetails';
import { trackSupportTicket } from '../../router/roleWisePageRoute';

export default function DisabledChatInfo({ ticketId }) {
    const { uiConfig } = useSelector(state => state.user);
    const { isUIConfigLoading, isConversationDetailsLoading } = useSelector(state => state.loading);
    const { loggedUser } = useSelector(state => state.auth);
    const { ticketDetails } = useSelector((state) => state.tickets);
    const { conversationDetails } = useSelector((state) => state.conversations);
    const conversationDetail = conversationDetails && conversationDetails[ticketId];
    const dispatch = useDispatch();
    const shared_by = GetQueryParam('shared_by', Number);

    const getLogo = () => {
        if (uiConfig) return uiConfig.logoUrl;
        return process.env.PUBLIC_URL + '/img/scogo-S-logo.png';
    }

    const onLoginClick = () => {
        const modalWidth = isMobileView ? '90vw' : '30vw';
        let path = `${trackSupportTicket.path}?ticketId=${ticketId}&redirectToEditTicket=true`;
        if (shared_by) path = `${path}&shared_by=${shared_by}`;
        dispatch(
            openCustomModal({
                heading: `Login`,
                modalWidth,
                modalHeight: 'auto',
                modalComponent: <LoginForm ticketId={ticketId} redirect_to={path} />,
            })
        )
    }

    const isUserLoggedIn = isLogged() && loggedUser;

    const chatWithUsButton = !conversationDetail && ticketDetails && isLogged() && loggedUser
    return <div className='chat-card-wtih-edit-ticket border relative' >
        {loggedUser && <div className='renderSearchIcon flex items-end px-4 absolute top-2 right-4'>
            <TicketSearch mainDivClass='flex items-center gap-2 w-96 ml-auto' />
        </div>}
        <div className='w-full h-full font-bold flex flex-col items-center justify-center'>
            <div className='flex items-center align-middle'>
                {isUIConfigLoading ?
                    <Skeleton variant='rectangular' animation='wave' height='11rem' width='10rem' />
                    :
                    <img src={getLogo()} alt='chat' className='mx-auto h-48' />}
            </div>
            <div className='flex text-center text-font24 m-2'>
                {!chatWithUsButton ? <p className='h-fit'>Chat with our Support Team</p>
                    : <ButtonScogoPrimary onClick={(e) => {
                        e.preventDefault();
                        dispatch(getConversationDetails({ _id: ticketId, useConversationId: true }));
                    }}
                        buttonTextClass={'submitButtonSupportText'}
                        textOrComponent={'Click here to chat with us'}
                        loading={isConversationDetailsLoading}
                    />}
            </div>
            {!isUserLoggedIn && <ButtonScogoPrimary buttonPadding='px-10 py-4 text-font15 md:text-font12' textOrComponent={'Login to continue'} onClick={onLoginClick} />}
        </div>
    </div>
};