import { inventory } from '../types';
import { sites as sitesTab, assets as assetsTab, warehouses as warehousesTab, spareAssets as spareAssetsTab } from '../pages/Inventory/InventoryController';

export const removeFromInventoryStore = (type) => ({
    type: inventory.REMOVE_FROM_INVENTORY_STORE_REQUEST,
    payload: { type },
});

export const getAllSites = ({ customerId, dropdown = false, limit = 10, page = 1, count = false, filter = false, giveLastTickets, giveAssetTypes, callPoList }) => ({
    type: inventory.GET_ALL_SITES_REQUEST,
    payload: { customerId, dropdown, limit, page, count, filter, giveLastTickets, giveAssetTypes, callPoList },
});

export const getAllSitesForFilter = ({ customerId, dropdown = false, limit = 25, page = 1, count = false, toFilter }) => ({
    type: inventory.GET_ALL_FILTER_SITES_REQUEST,
    payload: { customerId, dropdown, limit, page, count, toFilter },
});

export const getAllSpareAssetForFilter = ({ customerId, dropdown = false, limit = 25, page = 1, count = false }) => ({
    type: inventory.GET_ALL_FILTER_SPAREASSET_REQUEST,
    payload: { customerId, dropdown, limit, page, count },
});

export const getAllAssets = ({
    customerId,
    siteId,
    limit = 10,
    page = 1,
    count = false,
    filter = false,
    archive,
    dropdown = false,
    reconcile = false,
    tag,
    boqId,
    status,
    callPoList,
    source,
    endUserId,
}) => ({
    type: inventory.GET_ALL_ASSETS_REQUEST,
    payload: { customerId, siteId, limit, page, count, filter, archive, dropdown, reconcile, tag, boqId, status, callPoList, source, endUserId },
});

export const getAllAssetsForFilter = ({ customerId, dropdown = false, limit = 25, page = 1, count = false, toFilter }) => ({
    type: inventory.GET_ALL_FILTER_ASSETS_REQUEST,
    payload: { customerId, dropdown, limit, page, count, toFilter },
});

export const getAllWarehouses = ({ customerId, limit = 10, page = 1, count = false, dropdown = false, distance = false, createWarehouse = false }) => ({
    type: inventory.GET_ALL_WAREHOUSE_REQUEST,
    payload: { customerId, limit, page, count, dropdown, distance, createWarehouse },
});

export const getAllWarehousesForFilter = ({ customerId, limit = 25, page = 1, count = false, dropdown = false }) => ({
    type: inventory.GET_ALL_WAREHOUSE_FOR_FILTER_REQUEST,
    payload: { customerId, limit, page, count, dropdown },
});

export const getNextWarehouses = ({ limit, offset, customerId, page }) => ({
    type: inventory.GET_NEXT_WAREHOUSE_LIST_REQUEST,
    payload: { limit, offset, customerId, page },
});

export const getAllSpareAssets = ({
    customerId,
    status = 'ALL',
    working_conditon,
    warehouseId,
    limit = 10,
    page = 1,
    count = true,
    dropdown = false,
    reconcile = false,
    serial_number,
    title,
    customerIds,
    wareHouseIds,
}) => ({
    type: inventory.GET_ALL_SPARE_ASSETS_REQUEST,
    payload: {
        customerId,
        warehouseId,
        limit,
        page,
        count,
        dropdown,
        reconcile,
        status,
        working_conditon,
        serial_number,
        title,
        customerIds,
        wareHouseIds,
    },
});

export const createInventorySites = ({ data, customerId, formName, closeModal, refresh, createBranch }) => ({
    type: inventory.CREATE_INVENTORY_SITES_REQUEST,
    payload: { data, customerId, formName, closeModal, refresh, createBranch },
});

export const getAllCities = () => ({ type: inventory.CITIES_LIST_REQUEST });

export const getEndUsersList = ({ customer_id, dropdown } = {}) => ({ type: inventory.ENDUSERS_LIST_REQUEST, payload: { customer_id, dropdown } });

export const getSiteType = () => ({ type: inventory.GET_SITE_TYPE_REQUEST });

export const getSlaPolicies = ({ customerId, slaType = 'SUPPORT', dropdown = false }) => ({
    type: inventory.SLA_POLICIES_LIST_REQUEST,
    payload: { customerId, slaType, dropdown },
});

export const getCityFromPincode = ({ pincode }) => ({
    type: inventory.GET_CITY_FROM_PINCODE_REQUEST,
    payload: { pincode },
});

export const removePinCityFromStore = () => ({
    type: inventory.REMOVE_CITY_FROM_PINCODE_DATA_FROM_STORE,
    payload: {},
});

export const createInventoryWarehouses = ({ data, customerId, limit = 10, formName }) => ({
    type: inventory.CREATE_INVENTORY_WAREHOUSES_REQUEST,
    payload: { data, customerId, limit, formName },
});

export const getSpareCategories = ({ customerId, limit = 10, page = 1, count = false, dropdown = false }) => ({
    type: inventory.GET_SPARE_CATEGORIES_REQUEST,
    payload: { customerId, limit, page, count, dropdown },
});

export const getInventoryPartcodes = ({ customerId, assetTypeId, limit = 10, page = 1, count = false, dropdown = false }) => ({
    type: inventory.GET_INVENTORY_PARTCODES_REQUEST,
    payload: { customerId, assetTypeId, limit, page, count, dropdown },
});

export const getAssetsTypes = ({ type = 'ASSET', customerId, categoryId, limit = 10, page = 1, dropdown = false, count = false }) => ({
    type: inventory.GET_ASSETS_REQUEST,
    payload: { type, customerId, categoryId, limit, page, dropdown, count },
});

export const findSiteById = ({ siteId, lastTicket = false }) => ({
    type: inventory.FIND_SITE_BY_ID_REQUEST,
    payload: { siteId, lastTicket },
});

export const createInventoryAssets = ({ data, customerId, siteId, formName, refresh, onSuccess }) => ({
    type: inventory.CREATE_INVENTORY_ASSETS_REQUEST,
    payload: { data, customerId, siteId, formName, refresh, onSuccess },
});

export const createInventorySpareAssets = ({ data, customerId, warehouseId, formName }) => ({
    type: inventory.CREATE_INVENTORY_SPARE_ASSETS_REQUEST,
    payload: { data, customerId, warehouseId, formName },
});

export const deleteInventorySite = ({ siteId, customerId, formName, refresh }) => ({
    type: inventory.DELETE_INVENTORY_SITE_REQUEST,
    payload: { siteId, customerId, formName, refresh },
});

export const deleteInventoryAsset = ({ assetId, siteId, customerId, formName, enduserId }) => ({
    type: inventory.DELETE_INVENTORY_ASSET_REQUEST,
    payload: { assetId, siteId, customerId, formName, enduserId },
});

export const deleteInventoryWarehouse = ({ warehouseId, customerId, formName }) => ({
    type: inventory.DELETE_INVENTORY_WAREHOUSE_REQUEST,
    payload: { warehouseId, customerId, formName },
});

export const deleteInventorySpareAsset = ({ assetId, warehouseId, customerId, formName }) => ({
    type: inventory.DELETE_INVENTORY_SPARE_ASSETS_REQUEST,
    payload: { assetId, warehouseId, customerId, formName },
});

export const updateInventorySite = ({ data, siteId, customerId, formName, toastOnSuccess, refresh, source, additionalToast }) => ({
    type: inventory.UPDATE_INVENTORY_SITE_REQUEST,
    payload: { data, siteId, customerId, formName, toastOnSuccess, refresh, source, additionalToast },
});

export const updateInventoryAsset = ({ data, assetId, siteId, customerId, formName, refresh, closeModal, enduserId }) => ({
    type: inventory.UPDATE_INVENTORY_ASSET_REQUEST,
    payload: { data, assetId, siteId, customerId, formName, refresh, closeModal, enduserId },
});

export const updateInventoryWarehouse = ({ data, warehouseId, customerId, formName }) => ({
    type: inventory.UPDATE_INVENTORY_WAREHOUSE_REQUEST,
    payload: { data, warehouseId, customerId, formName },
});

export const updateInventorySpareAsset = ({ data, assetId, warehouseId, customerId, formName, closeModal }) => ({
    type: inventory.UPDATE_INVENTORY_SPARE_ASSETS_REQUEST,
    payload: { data, assetId, warehouseId, customerId, formName, closeModal },
});

export const migrateAssets = ({ data, customerId, siteId, warehouseId, formName, status, count, createNewSiteFormData, addingNewSite, enduserId }) => ({
    type: inventory.MIGRATE_INVENTORY_ASSET_REQUEST,
    payload: { data, customerId, siteId, warehouseId, formName, status, count, createNewSiteFormData, addingNewSite, enduserId },
});

export const scrapAssets = ({ data, formName, modalName, siteId, customerId, warehouseId, refresh }) => ({
    type: inventory.SCRAP_INVENTORY_ASSETS_REQUEST,
    payload: { data, formName, modalName, siteId, warehouseId, customerId, refresh },
});

export const replaceAssets = ({ data, formName, enduserId }) => ({
    type: inventory.REPLACE_INVENTORY_ASSET_REQUEST,
    payload: { data, formName, enduserId },
});

export const generateAssetBarcode = ({ data, formName }) => ({
    type: inventory.GENERATE_INVENTORY_ASSETS_BARCODE_REQUEST,
    payload: { data, formName },
});

export const bulkUploadSites = ({ data, customerId, endUserId, limit = 25, page = 1, count = false, formName }) => ({
    type: inventory.BULK_UPLOAD_INVENTORY_SITES_REQUEST,
    payload: { data, customerId, endUserId, limit, page, count, formName },
});

export const bulkUploadAssets = ({ data, customerId, endUserId, siteId, externalSiteId, limit = 25, page = 1, count = false, formName, refresh }) => ({
    type: inventory.BULK_UPLOAD_INVENTORY_ASSETS_REQUEST,
    payload: { data, customerId, endUserId, siteId, externalSiteId, limit, page, count, formName, refresh },
});

export const bulkUploadSpareAssets = ({ data, warehouseId, customerId, limit = 10, page = 1, count = false, formName, dropdown = false }) => ({
    type: inventory.BULK_UPLOAD_INVENTORY_SPARE_ASSETS_REQUEST,
    payload: { data, customerId, warehouseId, limit, page, count, formName, dropdown },
});

export const addAssetsMaster = ({ data, addForAssets = false, customerId, siteId, formName }) => ({
    type: inventory.ADD_ASSETS_REQUEST,
    payload: { data, addForAssets, customerId, siteId, formName },
});

export const getWareHouseOwners = ({ user_role = [6, 12], customer, userType = 'CLUSTER', status = 0 }) => ({
    type: inventory.GET_WAREHOUSE_OWNER_DROPDOWN_REQUEST,
    payload: { user_role, customer, userType },
});

export const getWareHouseDropdown = ({ customerId, count = false, distance = false, dropdown = true, createWarehouse = true, assetType, siteId, serial_number }) => ({
    type: inventory.FIND_ALL_WAREHOUSES_REQUEST,
    payload: { customerId, count, distance, dropdown, createWarehouse, assetType, siteId, serial_number },
});

export const findAllRepairCenters = ({ customerId, limit = 10, page = 1, count = false, dropdown = false, addCustomerDropdown }) => ({
    type: inventory.ALL_REPAIR_CENTERS_DROPDOWN_REQUEST,
    payload: { customerId, limit, page, count, dropdown, addCustomerDropdown },
});

export const getAllSitesDropdown = ({ customerId, dropdown = true }) => ({
    type: inventory.GET_DROPDOWN_ALL_SITES_REQUEST,
    payload: { customerId, dropdown: true },
});

export const getAllAssetsDropdown = ({
    customerId,
    siteId,
    limit = 10,
    page = 1,
    count = false,
    archive = false,
    finance = false,
    lastScannedId = 0,
    financeType = 'CAPEX',
    act = 'COMPANY',
    dropdown = true,
    reconcile = false,
}) => ({
    type: inventory.GET_DROPDOWN_REPLACE_ALL_ASSETS_REQUEST,
    payload: { customerId, siteId, limit, page, count, archive, finance, lastScannedId, financeType, act, dropdown, reconcile },
});

export const removeSpareAssetDataFromStore = () => ({
    type: inventory.REMOVE_ALL_SPARE_ASSET_DATA_FROM_STORE,
    payload: {},
});

export const removeSiteDataByIdFromStore = () => ({
    type: inventory.REMOVE_SiTE_DATA_BY_ID_FROM_STORE,
    payload: {},
});

export const setInventorySideDrawer = (showInventorySideDrawer, sideBarName, inventorySideDrawerPayload) => ({
    type: inventory.SET_INVENTORY_SIDEDRAWER,
    payload: { showInventorySideDrawer, sideBarName, inventorySideDrawerPayload },
});

export const getAssetHistory = ({ assetId, assetSource }) => ({
    type: inventory.GET_ASSETS_HISTORY,
    payload: { assetId, assetSource },
});

export const findWarehouseById = ({ warehouseId }) => ({
    type: inventory.FIND_WAREHOUSE_BY_ID_REQUEST,
    payload: { warehouseId },
});

export const getSitesFilterList = ({ projectId, customerId, endUserId, siteId, warehouseId, toFilter }) => ({
    type: inventory.GET_SITES_FILTER_LIST_REQUEST,
    payload: { projectId, customerId, endUserId, siteId, warehouseId, toFilter },
});

export const getAssetsFilterList = ({ projectId, customerId, endUserId, siteId, warehouseId, toFilter }) => ({
    type: inventory.GET_ASSETS_FILTER_LIST_REQUEST,
    payload: { projectId, customerId, endUserId, siteId, warehouseId, toFilter },
});

export const getWarehouseFilterList = ({ projectId, customerId, endUserId, siteId, warehouseId, toFilter }) => ({
    type: inventory.GET_WAREHOUSE_FILTER_LIST_REQUEST,
    payload: { projectId, customerId, endUserId, siteId, warehouseId, toFilter },
});

export const getSpareAssetFilterList = ({ module = 'INVENTORY', tab = 'spareassets', projectId, customerId, endUserId, siteId, warehouseId, toFilter }) => ({
    type: inventory.GET_SPAREASSETS_FILTER_LIST_REQUEST,
    payload: { module, tab, projectId, customerId, endUserId, siteId, warehouseId, toFilter },
});

export const clearInventoryTableDataOnUnmount = () => ({
    type: inventory.CLEAR_SITE_TABLE_DATA_ON_UNMOUNT,
});

export const setInventoryFilterAndPaginationData = ({ payload, isFilterActive, page, limit, query, siteId, warehouseId }) => ({
    type: inventory.SET_INVENTORY_FILTER_AND_PAGINATION_DATA,
    payload: { payload, isFilterActive, page, limit, query, siteId, warehouseId },
});

export const setSiteIdInStore = (siteId) => ({
    type: inventory.SET_SITE_ID_IN_STORE,
    payload: siteId,
});

export const setWarehouseIdInStore = (warehouseId) => ({
    type: inventory.SET_WAREHOUSE_ID_IN_STORE,
    payload: warehouseId,
});

export const clearSiteOrWarehouseIdFromStore = () => ({
    type: inventory.CLEAR_SITE_OR_WAREHOUSE_ID,
});

export const getPropertiesOfSite = ({ siteId, attributes }) => ({
    type: inventory.GET_PROPERTIES_OF_SITE_REQUEST,
    payload: { siteId, attributes },
});

export const getPropertiesOfWarehouse = ({ warehouseId, attributes }) => ({
    type: inventory.GET_PROPERTIES_OF_WAREHOUSE_REQUEST,
    payload: { warehouseId, attributes },
});

export const setArchiveAssetToStore = (isSelected) => ({
    type: inventory.SET_IS_ARCHIVE_SELECTED_IN_STORE,
    payload: isSelected,
});

export const getInventoryCount = ({ subTabs, selectedSubTab, customerId = 0 }) => {
    const tabCountLookup = {
        [sitesTab.id]: inventory.GET_ALL_SITES_REQUEST,
        [assetsTab.id]: inventory.GET_ALL_ASSETS_REQUEST,
        [warehousesTab.id]: inventory.GET_ALL_WAREHOUSE_REQUEST,
        [spareAssetsTab.id]: inventory.GET_ALL_SPARE_ASSETS_REQUEST,
    };
    let tab = subTabs.find((i) => i.id !== selectedSubTab).id;
    let type = tabCountLookup[tab];
    return {
        type: type,
        payload: { limit: 1, count: true, customerId },
    };
};

export const getSitesFilteredData = ({ customerId, limit, page, query, payload, count, callPoList }) => ({
    type: inventory.GET_SITES_FILTERED_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, count, callPoList },
});

export const getAssetsFilteredData = ({ customerId, limit, page, query, payload, count, siteId, archive, callPoList }) => ({
    type: inventory.GET_ASSETS_FILTERED_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, count, siteId, archive, callPoList },
});

export const getWarehouseFilteredData = ({ customerId, limit, page, query, payload, count }) => ({
    type: inventory.GET_WAREHOUSE_FILTERED_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, count },
});

export const getSpareAssetFilteredData = ({ customerId, limit, page, query, payload, count, warehouseId }) => ({
    type: inventory.GET_SPAREASSET_FILTERED_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, count, warehouseId },
});

export const linkReconcileAssets = ({ data, ticket, formName, ticketExecution = false, navigate }) => ({
    type: inventory.LINK_RECONCILE_INVENTORY_ASSETS_REQUEST,
    payload: { data, ticket, formName, ticketExecution, navigate },
});

export const getSpareAssetWithAssetId = ({ customerId, status, assetNumber }) => ({
    type: inventory.GET_SPAREASSET_WITH_ASSET_ID_REQUEST,
    payload: { customerId, status, assetNumber },
});

export const clearSpareAssetDetail = () => ({
    type: inventory.CLEAR_SPARE_ASSET_DETAIL,
});

export const getSiteListFromPincodeDropdown = ({ pincode, customerId, externalSiteId }) => ({
    type: inventory.GET_SITE_LIST_FROM_PINCODE_REQUEST,
    payload: { pincode, customerId, externalSiteId },
});

export const removeCreatedSiteFromStore = () => ({
    type: inventory.REMOVE_CREATED_SITE_FROM_STORE,
});

export const getAssetWitSerialNumber = ({ customerId, siteId, serialNumber, tag, scannedLocation, source, id }) => ({
    type: inventory.GET_ASSET_WITH_SERIAL_NUMBER_REQUEST,
    payload: { customerId, siteId, serialNumber, tag, scannedLocation, source, id },
});

export const removeAssetFromStore = () => ({
    type: inventory.REMOVE_ASSET_FROM_STORE,
});

export const removeAssetNotFoundFromStore = () => ({
    type: inventory.REMOVE_ASSET_NOT_FOUND_FROM_STORE,
});

export const searchSiteWithExternalSiteId = ({ customerId, externalSiteId, dropdown, limit, page }) => ({
    type: inventory.SEARCH_SITE_REQUEST,
    payload: { customerId, externalSiteId, dropdown, limit, page },
});

export const addListInSiteDropdown = (list) => ({
    type: inventory.ADD_SITE_DROPDOWN_LIST,
    payload: list,
});

export const addNewEndUserInList = (list) => ({
    type: inventory.ADD_NEW_END_USER_IN_LIST,
    payload: list,
});

export const removeAddedAssetTypeFromStore = () => ({
    type: inventory.REMOVE_ADDED_ASSETS_TYPE_FROM_STORE,
});

export const EditAssetHistoryCreatedDate = ({ id, date, source, assetSource, formName, assetId }) => ({
    type: inventory.EDIT_ASSET_DATE_REQUEST,
    payload: { id, date, source, assetSource, formName, assetId },
});

export const removeEnduserListFromStore = () => ({
    type: inventory.REMOVE_ENDUSER_LIST_FROM_STORE,
});

export const getAssetTagDetails = ({ tag }) => ({
    type: inventory.GET_ASSET_TAG_DETAILS_REQUEST,
    payload: { tag },
});

export const uploadAttachmentAssetConfig = ({ serial_number, file, action, mime_type, formName, assetId, assetOrSpareAsset }) => ({
    type: inventory.EDIT_ASSET_UPLOAD_ATTACHMENT_REQUEST,
    payload: { serial_number, file, action, mime_type, formName, assetId, assetOrSpareAsset },
});

export const getSitePosList = ({ siteIds, source, ticketId }) => ({
    type: inventory.GET_SITE_POS_LIST_REQUEST,
    payload: { siteIds, source, ticketId },
});

export const getBranchList = ({ endUserId, customerId }) => {
    return {
        type: inventory.GET_BRANCH_LIST_REQUEST,
        payload: { endUserId, customerId },
    };
};

export const createNewBranchRequest = ({ branchName, endUserId, customerId }) => {
    return {
        type: inventory.CREATE_BRANCH_LIST_REQUEST,
        payload: { branchName, endUserId, customerId },
    };
};
