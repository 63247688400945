import { takeEvery, call, put, takeLatest, all, select } from 'redux-saga/effects';
import { inventory, loading, toast, modal, utils, tickets } from '../../types';
import microservices from '../../lambdas/microservices';
import api from '../../apis';
import lambdas from '../../lambdas/apis';
import { DuplicateSites } from '../../pages/Modals/Inventory/duplicateSites';
import { getInventoryFilterData } from '../../selectors/inventory';
import { getLoggedUser } from '../../selectors/users';
import { getCustomerIdOfLoginUser } from '../../utils/utils';

export function* getAllSites(action) {
    const { customerId, dropdown = false, limit = 10, page = 1, count = true, giveAssetTypes = true, giveLastTickets = true, callPoList = false } = action.payload;
    const queryParams = { dropdown, limit, page, count, giveAssetTypes, giveLastTickets };
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;

    try {
        yield put({ type: loading.GET_ALL_SITES_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (response.data.data.length > 0 && callPoList) {
                const siteIds = response.data.data.map((item) => item.id);
                yield put({ type: inventory.GET_SITE_POS_LIST_REQUEST, payload: { siteIds, source: 'sites' } });
            }
            if (count) {
                yield put({ type: inventory.ALL_SITES_COUNT_SUCCESS, payload: response.data.count });
            }
            yield put({ type: inventory.GET_ALL_SITES_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.GET_ALL_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_SITES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_SITES_LOADING, payload: false });
}

export function* getAllSitesForFilter(action) {
    const { customerId, dropdown = false, limit, page, count, toFilter = [] } = action.payload;
    let queryParams = { dropdown, limit, page, count, filter: true, toFilter: toFilter.join(',') };
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
    try {
        yield put({ type: loading.GET_ALL_FILTER_SITES_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        yield put({ type: loading.GET_ALL_FILTER_SITES_LOADING, payload: false });
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_ALL_FILTER_SITES_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.GET_ALL_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_ALL_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
    }
}

export function* getAllCities(action) {
    try {
        yield put({ type: loading.CITIES_LIST_LOADING, payload: true });
        const response = yield call(api.cities.getAll, {});
        if (response.data.code === 200) {
            let cities = [];
            response.data.data.city_list.map((city) => {
                return cities.push({
                    key: `${city.city_id}_${city.fk_state_id}`,
                    value: city.city_id,
                    label: `${city.city_name}, ${city.state.state_name}`,
                });
            });
            yield put({ type: inventory.CITIES_LIST_SUCCESS, payload: cities });
        } else {
            yield put({ type: inventory.CITIES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.CITIES_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.CITIES_LIST_LOADING, payload: false });
}

export function* getEndUsersList(action) {
    const { customer_id, dropdown = true } = action.payload;
    const queryParams = {};
    if (customer_id) queryParams.customer_id = customer_id;
    try {
        yield put({ type: loading.ENDUSER_LIST_LOADING, payload: true });
        const response = yield call(api.endUsers.listAll, {}, {}, queryParams);
        if (response.data.code === 200) {
            let endUsersList = [];
            if (dropdown) {
                response.data.data.map((endUser) => {
                    return endUsersList.push({
                        key: endUser.id,
                        value: endUser.id,
                        label: endUser.end_user_name,
                    });
                });
            } else {
                endUsersList = response.data.data;
            }
            yield put({ type: inventory.ENDUSERS_LIST_SUCCESS, payload: endUsersList });
        } else {
            yield put({ type: inventory.ENDUSERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.ENDUSERS_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.ENDUSER_LIST_LOADING, payload: false });
}

export function* createInventorySites(action) {
    const { data, customerId, formName, closeModal, refresh } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.createInventorySites, {}, {}, {}, { sites: data });
        if (response.data.code === 200) {
            let toastData = {
                status: 'success',
                message: `Site Created Successfully`,
            };
            if (response.data.duplicateSites && typeof response.data.duplicateSites === 'object' && Object.keys(response.data.duplicateSites).length > 0) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        modalComponent: <DuplicateSites sitedata={response.data.duplicateSites} />,
                        heading: 'Duplicate Sites Found',
                        modalWidth: '70rem',
                        modalHeight: 'auto',
                    },
                });
            } else {
                yield all([
                    yield put({ type: toast.SEND_TOAST, payload: toastData }), // dispatching the toast outside yield all raises an extremely hard to recreate crash due to "dispatching inside a reducer"
                    yield put({ type: utils.SET_INITIAL_FORM, payload: { reinitialize: false, date: Date.now() } }),
                    yield put({
                        type: inventory.CREATE_INVENTORY_SITES_SUCCESS,
                        payload: { duplicateSites: response.data.duplicateSites, cratedSite: response.data.data[0] },
                    }),
                ]);
            }
            if (refresh) yield put({ type: inventory.GET_ALL_SITES_REQUEST, payload: { customerId } });
            if (closeModal) yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: inventory.CREATE_INVENTORY_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
            let toastData = {
                status: 'danger',
                message: response.data.data.message,
            };
            yield put({ type: toast.SEND_TOAST, payload: toastData });
        }
    } catch (error) {
        yield put({ type: inventory.CREATE_INVENTORY_SITES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getSiteType() {
    try {
        const response = yield call(lambdas.scm.getSiteType, {});
        if (response.data.code === 200) {
            let siteTypes = response.data.data;
            siteTypes = [];
            response.data.data.forEach((item) => {
                siteTypes.push({
                    key: item.id,
                    value: item.id,
                    label: item.site_name,
                });
            });
            yield put({ type: inventory.GET_SITE_TYPE_SUCCESS, payload: siteTypes });
        } else {
            yield put({ type: inventory.GET_SITE_TYPE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_SITE_TYPE_FAILURE, payload: { message: error } });
    }
}

export function* getSlaPolicies(action) {
    const { customerId, slaType = 'SUPPORT' } = action.payload;
    let queryParams = { type: slaType };
    if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
    yield put({ type: loading.LIST_SLA_LOADING, payload: true });
    try {
        const response = yield call(lambdas.policies.getAllSlas, {}, {}, queryParams);
        if (response.data.code === 200) {
            let slas = response.data.data;
            slas = [];
            response.data.data.forEach((item) => {
                slas.push({
                    key: item.id,
                    value: item.id,
                    label: `${item.priority} (${item.resolve} ${item.resolve_unit})`,
                });
            });
            yield put({ type: inventory.SLA_POLICIES_LIST_SUCCESS, payload: slas });
        } else {
            yield put({ type: inventory.SLA_POLICIES_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.SLA_POLICIES_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.LIST_SLA_LOADING, payload: false });
}

export function* getCityFromPincode(action) {
    const { pincode } = action.payload;

    try {
        const response = yield call(api.cities.getCityFromPincode, {}, {}, {}, { pincode });
        if (response.data.code === 200) {
            let data = response.data.data;

            yield put({ type: inventory.GET_CITY_FROM_PINCODE_SUCCESS, payload: data });
        } else {
            yield put({ type: inventory.GET_CITY_FROM_PINCODE_FAILURE, payload: { message: response.data.message } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_CITY_FROM_PINCODE_FAILURE, payload: { message: error } });
    }
}

export function* findSiteById(action) {
    const { siteId, lastTicket = false } = action.payload;
    yield put({ type: loading.FIND_SITE_LOADING, payload: true });

    try {
        const response = yield call(microservices.inventory.findSiteById, {}, { siteId }, { lastTicket });
        if (response.data.code === 200) {
            yield put({ type: inventory.FIND_SITE_BY_ID_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: inventory.FIND_SITE_BY_ID_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.FIND_SITE_BY_ID_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FIND_SITE_LOADING, payload: false });
}

export function* getPropertiesOfSite(action) {
    const { siteId, attributes } = action.payload;
    try {
        const response = yield call(microservices.inventory.findSiteById, {}, { siteId }, { attributes: attributes.join(','), include: '', giveSpocAndAssetCount: false });
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_PROPERTIES_OF_SITE_SUCCESS, payload: response.data.data });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response.data.data.message,
                },
            });
            yield put({ type: inventory.GET_PROPERTIES_OF_SITE_FAILURE, payload: { message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_PROPERTIES_OF_SITE_FAILURE, payload: { message: error } });
    }
}

export function* deleteInventorySite(action) {
    const { siteId, customerId, formName, refresh = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.deleteSite, {}, { siteId });
        if (response.data.code === 200) {
            let toastData = {
                status: 'success',
                message: `Site Deleted Successfully`,
            };
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: toastData });
            if (refresh) {
                yield put({ type: inventory.REFRESH_SITE_PAGE, payload: {} });
            }
        } else {
            yield put({ type: inventory.DELETE_INVENTORY_SITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.DELETE_INVENTORY_SITE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateInventorySite(action) {
    const { data, siteId, formName, toastOnSuccess = '', refresh = true, source, additionalToast } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.inventory.updateSite, {}, { siteId }, {}, data);
        if (response.data.code === 200) {
            let status = 'success';
            let message = toastOnSuccess ? toastOnSuccess : 'Site Updated Successfully!!!';
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status, message } })]);
            if (additionalToast) {
                yield put({ type: toast.SEND_TOAST, payload: { status: additionalToast.status, message: additionalToast.message } });
            }
            if (refresh) {
                if (source === 'edit_ticket') {
                    yield put({ type: inventory.GET_SITE_LIST_FROM_PINCODE_REQUEST, payload: { pincode: data.fk_pincode_id, customerId: data.fk_customer_id } });
                    yield put({ type: tickets.UPDATED_SITE_DETAILS_WHEN_EDIT_TICKET, payload: { ...data, siteId } });
                } else {
                    yield put({ type: inventory.REFRESH_SITE_PAGE, payload: {} });
                }
            }
        } else {
            yield put({ type: inventory.UPDATE_INVENTORY_SITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.UPDATE_INVENTORY_SITE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkUploadSites(action) {
    const { data, customerId, endUserId, limit = 10, page = 1, count = false, formName } = action.payload;
    let queryParams = {};
    if (!['', 'all', undefined, null].includes(customerId)) queryParams.fk_customer_id = customerId;
    if (!['', 'all', undefined, null].includes(endUserId)) queryParams.end_user_id = endUserId;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.inventory.bulkUploadSites, { 'Content-Type': 'multipart/form-data' }, {}, { ...queryParams }, data);
        if (response.data.code === 201) {
            let message = 'Site Uploaded Successfully!!!';
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: inventory.GET_ALL_SITES_REQUEST, payload: { customerId, endUserId, limit, page, count } })]);
            let duplicateSites = false,
                bulk = false;
            if (response.data.data && Array.isArray(response.data.data.duplicate_sites) && response.data.data.duplicate_sites.length > 0) {
                duplicateSites = true;
            }
            if (response.data.data && Array.isArray(response.data.data.error_pincode) && response.data.data.error_pincode.length > 0) {
                bulk = true;
            }
            if (duplicateSites || bulk) {
                yield put({
                    type: modal.OPEN_CUSTOM_MODAL,
                    payload: {
                        modalComponent: (
                            <DuplicateSites sitedata={response.data.data} bulkUploadError={bulk} inventoryType={'sites'} count={response.data.data.duplicate_sites.length} />
                        ),
                        heading: 'Upload Error',
                        modalWidth: '70rem',
                        modalHeight: 'auto',
                    },
                });
                message = 'Sites Uploaded Successfully!!!';
            }
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } });
        } else {
            yield put({ type: inventory.BULK_UPLOAD_INVENTORY_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: inventory.BULK_UPLOAD_INVENTORY_SITES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAllSitesDropdown(action) {
    const { customerId, dropdown = true } = action.payload;
    let queryParams = { dropdown };
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;

    try {
        yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        if (response.data.code === 200) {
            let dropdown = [];
            response.data.data.forEach((site) =>
                dropdown.push({
                    key: site.id,
                    label: site.external_site_id,
                    value: site.id,
                    complete_site_address: site.complete_site_address,
                    fk_pincode_id: site.fk_pincode_id,
                    site_contact_person_name: site.site_contact_person_name,
                    site_contact_person_email: site.site_contact_person_email,
                    site_contact_person_mobile: site.site_contact_person_mobile,
                })
            );
            yield put({ type: inventory.GET_DROPDOWN_ALL_SITES_SUCCESS, payload: dropdown });
        } else {
            yield put({ type: inventory.GET_DROPDOWN_ALL_SITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_DROPDOWN_ALL_SITES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: false });
}

export function* getSitesFilterList(action) {
    try {
        const { module, tab, projectId, customerId, toFilter = '' } = action.payload;
        let queryParams = { module, tab };
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
        if (toFilter) queryParams.toFilter = toFilter;

        yield put({ type: loading.GET_ALL_FILTER_SITES_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSiteFilters, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_SITES_FILTER_LIST_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
    }
    yield put({ type: loading.GET_ALL_FILTER_SITES_LOADING, payload: false });
}

export function* refreshSitePage(action) {
    const { limit = 10, page = 1, query, filterPayload = {}, isFilterActive } = yield select(getInventoryFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const isFilterOrSearchActive = isFilterActive || query?.length > 0;
    try {
        yield put({ type: loading.GET_ALL_SITES_LOADING, payload: true });
        let response;
        if (isFilterOrSearchActive) {
            let queryParams = {
                module: 'INVENTORY',
                tab: 'sites',
                limit,
                page,
                next: true,
                count: true,
                type: 0,
                giveLastTickets: true,
                giveAssetTypes: true,
                giveSpareAssetsStats: true,
            };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', 'all', undefined, null].includes(filterPayload?.status)) filterPayload.status = filterPayload.status.map((s) => s.split(' ').join('_'));
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, filterPayload);
        } else {
            const queryParams = { dropdown: false, limit, page, count: true, giveAssetTypes: true, giveLastTickets: true };
            if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                if (response.data) yield put({ type: utils.FILTER_MODULE_DATA_COUNT_SUCCESS, payload: response.data.count });
                yield put({ type: inventory.GET_SITES_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
            } else {
                yield put({ type: inventory.ALL_SITES_COUNT_SUCCESS, payload: response.data.count });
                yield put({ type: inventory.GET_ALL_SITES_SUCCESS, payload: response.data.data });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {}
    yield put({ type: loading.GET_ALL_SITES_LOADING, payload: false });
}

export function* getSitesFilteredData(action) {
    let { module = 'INVENTORY', tab = 'sites', customerId, next = true, count = false, type = 0, limit, page, query, payload, callPoList } = action.payload;
    try {
        yield put({ type: loading.GET_ALL_SITES_LOADING, payload: true });
        let queryParams = { module, tab, limit, page, next, count, type, giveLastTickets: true, giveAssetTypes: true, giveSpareAssetsStats: true };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(payload?.status)) payload.status = payload.status.map((s) => s.split(' ').join('_'));
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            if (response.data.data.length > 0 && callPoList) {
                const siteIds = response.data.data.map((item) => item.id);
                yield put({ type: inventory.GET_SITE_POS_LIST_REQUEST, payload: { siteIds, source: 'sites' } });
            }
            yield put({ type: inventory.GET_SITES_FILTERED_DATA_SUCCESS, payload: { data: response.data.data, count: response.data.count } });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_SITES_FILTERED_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.GET_ALL_SITES_LOADING, payload: false });
}

export function* getSiteListFromPincodeDropdown(action) {
    const { pincode, customerId, externalSiteId } = action.payload;
    try {
        yield put({ type: loading.GET_SITE_LIST_FROM_PINCODE_LOADING, payload: true });
        let queryParams = { dropdown: true, customerId, pincode, externalSiteId };
        const response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({
                type: inventory.GET_SITE_LIST_FROM_PINCODE_SUCCESS,
                payload: response.data.data.map((site) => ({
                    key: site.id,
                    value: site.id,
                    label: site.external_site_id,
                    region: site.region,
                    zone: site.zone,
                    branch: site.site_branch_name,
                    cityId: site.fk_city_id,
                    address: site.complete_site_address,
                    contactName: site.site_contact_person_name,
                    contactEmail: site.site_contact_person_email,
                    contactMobile: site.site_contact_person_mobile,
                    customer_end_user: site.customer_end_user,
                    fk_pincode_id: site.fk_pincode_id,
                    fk_state_id: site?.state?.state_id,
                    fk_customer_id: site.fk_customer_id,
                    site_vertical: site.site_vertical,
                    sla: site.sla,
                })),
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: inventory.GET_SITE_LIST_FROM_PINCODE_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.GET_SITE_LIST_FROM_PINCODE_LOADING, payload: false });
}

export function* searchSiteWithExternalSiteId(action) {
    const { customerId, externalSiteId, dropdown = true, limit = 10, page = 1 } = action.payload;
    let queryParams = { dropdown, externalSiteId, limit, page };
    if (!['', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;

    try {
        yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: true });
        const response = yield call(microservices.inventory.getAllSites, {}, {}, queryParams);
        if (response.data.code === 200) {
            let dropdown = [];
            response.data.data.forEach((site) =>
                dropdown.push({
                    key: site.id,
                    label: site.external_site_id,
                    value: site.id,
                    complete_site_address: site.complete_site_address,
                    fk_pincode_id: site.fk_pincode_id,
                    site_contact_person_name: site.site_contact_person_name,
                    site_contact_person_email: site.site_contact_person_email,
                    site_contact_person_mobile: site.site_contact_person_mobile,
                })
            );
            yield put({ type: inventory.SEARCH_SITE_SUCCESS, payload: dropdown });
        } else {
            yield put({ type: inventory.SEARCH_SITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.SEARCH_SITE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: false });
}

export function* getSitePosList(action) {
    const { siteIds, source, ticketId, enduserId } = action.payload;
    try {
        yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: true });
        const response = yield call(microservices.inventory.getSitePos, {}, {}, {}, { siteIds });
        if (response.data.code === 200) {
            if (source === 'ticket') {
                yield put({ type: tickets.UPDATE_TICKET_SITE_PO_SUCCESS, payload: { sitePos: response.data.data.data, ticketId } });
            } else if (source === 'enduser_view') {
                yield put({ type: inventory.GET_ENDUSER_BY_SITE_POS_LIST_SUCCESS, payload: { sitePos: response.data.data.data, enduserId } });
            } else {
                yield put({ type: inventory.GET_SITE_POS_LIST_SUCCESS, payload: { sitePos: response.data.data.data, source } });
            }
        } else {
            yield put({ type: inventory.GET_SITE_POS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: inventory.GET_SITE_POS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_SITES_DROPDOWN_LOADING, payload: false });
}

export function* getBranchList(action) {
    const { endUserId, customerId } = action.payload;
    try {
        const queryParams = { customerId };
        if (endUserId) queryParams.endUserId = endUserId;
        yield put({ type: loading.GET_ALL_BRANCH_LIST_LOADING, payload: true });
        const response = yield call(microservices.inventory.getBranchList, {}, {}, queryParams, {});
        // update
        if (response.data.code === 200) {
            yield put({ type: inventory.GET_BRANCH_LIST_SUCCESS, payload: response.data.data });
        }
    } catch (error) {
        yield put({ type: inventory.GET_BRANCH_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ALL_BRANCH_LIST_LOADING, payload: false });
}

export function* createNewbranch(action) {
    const { branchName, endUserId, customerId } = action.payload;
    try {
        const response = yield call(microservices.inventory.createNewbranch, {}, {}, {}, { branchName, endUserId, customerId });
        if (response.data.code === 200) {
        }
    } catch (error) {
        yield put({ type: inventory.CREATE_BRANCH_LIST_FAILURE, payload: { message: error } });
    }
}

export default function* siteSaga() {
    yield takeEvery(inventory.GET_ALL_SITES_REQUEST, getAllSites);
    yield takeEvery(inventory.GET_DROPDOWN_ALL_SITES_REQUEST, getAllSitesDropdown);
    yield takeEvery(inventory.GET_ALL_FILTER_SITES_REQUEST, getAllSitesForFilter);
    yield takeLatest(inventory.CREATE_INVENTORY_SITES_REQUEST, createInventorySites);
    yield takeLatest(inventory.CITIES_LIST_REQUEST, getAllCities);
    yield takeLatest(inventory.ENDUSERS_LIST_REQUEST, getEndUsersList);
    yield takeEvery(inventory.GET_SITE_TYPE_REQUEST, getSiteType);
    yield takeEvery(inventory.SLA_POLICIES_LIST_REQUEST, getSlaPolicies);
    yield takeEvery(inventory.GET_CITY_FROM_PINCODE_REQUEST, getCityFromPincode);
    yield takeEvery(inventory.FIND_SITE_BY_ID_REQUEST, findSiteById);
    yield takeEvery(inventory.GET_PROPERTIES_OF_SITE_REQUEST, getPropertiesOfSite);
    yield takeLatest(inventory.DELETE_INVENTORY_SITE_REQUEST, deleteInventorySite);
    yield takeLatest(inventory.UPDATE_INVENTORY_SITE_REQUEST, updateInventorySite);
    yield takeLatest(inventory.BULK_UPLOAD_INVENTORY_SITES_REQUEST, bulkUploadSites);
    yield takeLatest(inventory.GET_SITES_FILTER_LIST_REQUEST, getSitesFilterList);
    yield takeLatest(inventory.REFRESH_SITE_PAGE, refreshSitePage);
    yield takeLatest(inventory.GET_SITES_FILTERED_DATA_REQUEST, getSitesFilteredData);
    yield takeLatest(inventory.GET_SITE_LIST_FROM_PINCODE_REQUEST, getSiteListFromPincodeDropdown);
    yield takeLatest(inventory.SEARCH_SITE_REQUEST, searchSiteWithExternalSiteId);
    yield takeLatest(inventory.GET_SITE_POS_LIST_REQUEST, getSitePosList);
    yield takeLatest(inventory.GET_BRANCH_LIST_REQUEST, getBranchList);
    yield takeLatest(inventory.CREATE_BRANCH_LIST_REQUEST, createNewbranch);
}
