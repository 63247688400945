import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { closeModalAction, openCustomModal } from '../../../actions/modal';
import ButtonScogoOrange from '../../../common/Buttons/ButtonScogoOrange';
import { isCluster, isCustomerGroup, isPsp, isScm, isSuperVisor, isSp } from '../../../utils/role';
import { paymnetVia } from '../../Payments/PaymentController';
import { getIsToShowAddNewPrice, getTicketOwner } from '../TicketController';
import { PriceWithLabel } from '../TicketTableComponent';
import { validateNumberValue } from '../../../utils/common';
import PaymentsDetails from '../../Payments/Modals/PaymentsDetails';
import { useNavigate } from 'react-router-dom';
import { ticket as ticketPath } from '../../../router/roleWisePageRoute';
import EditPayout from '../../Payments/Modals/EditPayout';
import IconToolTip from '../../../common/IconToolTip';
import { isToShowCustomerPaymentDetails } from '../../../utils/access';

const textLabelClass = 'text-font11 text-scogogray';
const textValueClass = 'text-font12 text-black';


export const getPartnerBasePayment = ({ partner_base_payment, additional_expenses, additional_amount_paid, psp_id, psp_base_payment, loggedUserPaymentRatio, ticketSpPaymentRatio = 1, loggedUserId, acceptedSpId, signOffAccept, ticket_owned_by, assignToScogo } = {}) => {
    let basePayment = validateNumberValue(partner_base_payment) * ticketSpPaymentRatio;

    if (signOffAccept === 1) {
        basePayment = validateNumberValue(partner_base_payment);
    } else if (loggedUserId === acceptedSpId) {
        basePayment = validateNumberValue(partner_base_payment) * loggedUserPaymentRatio;
    }
    let payment = basePayment + validateNumberValue(additional_expenses) + validateNumberValue(additional_amount_paid);
    const ticketOwner = getTicketOwner({ ticket_owned_by, assign_to_scogo: assignToScogo });
    if (ticketOwner === 'Scogo' && validateNumberValue(psp_id) === 0 && validateNumberValue(psp_base_payment) && parseFloat(psp_base_payment) > 0) {
        payment += psp_base_payment
    }
    return payment;
}

export const Price = ({ ticket }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const frontend_controller = loggedUser.frontend_controller;
    const dispatch = useDispatch();
    const showAddNewPriceButton = getIsToShowAddNewPrice({ role, type, ticket });
    const navigate = useNavigate();

    const partner_base_payment = getPartnerBasePayment({
        partner_base_payment: ticket.partner_base_payment, additional_expenses: ticket.additional_expenses, additional_amount_paid: ticket.additional_amount_paid,
        psp_id: ticket.psp_id, psp_base_payment: ticket.psp_base_payment, acceptedSpId: ticket.accepted_sp_id, loggedUserId: loggedUser.id,
        ticketSpPaymentRatio: ticket.ticketSpPaymentRatio, loggedUserPaymentRatio: loggedUser.payment_ratio, signOffAccept: ticket.signOffAccept,
        assignToScogo: ticket.assignToScogo,
        ticket_owned_by: ticket.ticket_owned_by
    });

    let customer_base_price = ticket.customer_base_price + ticket.customer_additional_expenses;
    let incentive = 0;
    let incentiveWarehouse = 0;
    let incentiveSuperVisor = 0;
    let incentivePsp = 0;
    let incentiveCluster = 0;
    let showIncentives = false;

    if (ticket.warehouse_owner > 0 && (ticket.warehouse_owner_base_payment > 0 || ticket.warehouse_expenses > 0)) incentiveWarehouse = ticket.warehouse_owner_base_payment + ticket.warehouse_expenses;
    if (ticket.supervisor_id > 0 && (ticket.supervisor_base_payment > 0 || ticket.supervisor_amount_paid)) incentiveSuperVisor = ticket.supervisor_base_payment + ticket.supervisor_amount_paid;
    if (ticket.psp_id > 0 && (ticket.psp_base_payment > 0 || ticket.psp_additional_incentive)) incentivePsp = ticket.psp_base_payment + ticket.psp_additional_incentive;
    if (ticket.cluster_id > 0 && (ticket.cluster_base_payment > 0 || ticket.additional_incentive)) incentiveCluster = ticket.cluster_base_payment + ticket.additional_incentive;

    if (isScm(role, type)) {
        incentive = incentiveWarehouse + incentivePsp + incentiveSuperVisor + incentiveCluster;
        showIncentives = true;
    } else if (isCluster(role, type)) {
        incentive = incentiveCluster;
        showIncentives = true;
        if (loggedUser.id === ticket.warehouse_owner) incentive += incentiveWarehouse;
    } else if (isPsp(role, type)) {
        incentive = incentivePsp;
        showIncentives = true;
        if (loggedUser.id === ticket.warehouse_owner) incentive += incentiveWarehouse;
    } else if (isSuperVisor(role, type)) {
        incentive = incentiveSuperVisor;
        showIncentives = true;
        if (loggedUser.id === ticket.warehouse_owner) incentive += incentiveWarehouse;
    }

    const showCustomerBilling = (isScm(role, type) && isToShowCustomerPaymentDetails(frontend_controller)) || (isCustomerGroup(role, type) && ticket.assignToScogo === 1 && ticket.ticket_owned_by === 0);


    let showVendorBilling = loggedUser.partner_working_under !== 'CUSTOMER';
    if (isCustomerGroup(role, type)) {
        showVendorBilling = ticket.assignToScogo === 0 && ticket.ticket_owned_by === 1;
    }

    const openVendorpaymentDetail = () => {
        let heading = 'Payments';
        let modalComponent = <PaymentsDetails ticketId={ticket?.ticket_id} user_type={'SP'} />;
        let modalWidth = '80rem',
            modalHeight = 'auto';
        dispatch(openCustomModal({ modalComponent, heading, modalWidth, modalHeight }));
    }

    return (
        <div className='pl-2'>
            {showCustomerBilling && (
                <PriceWithLabel
                    labelClass={textLabelClass}
                    valueClass={textValueClass}
                    label={'Customer Billing'}
                    value={customer_base_price}
                    renderLabel={<RenderApprovalRecievedLabel ticket={ticket} />}
                />
            )}

            {(showVendorBilling && partner_base_payment > 0) ? <PriceWithLabel onClick={openVendorpaymentDetail} valueClass={`${textValueClass} hover:text-scogoorange`} labelClass={`${textLabelClass} hover:text-scogoorange`} label={'Vendor'} value={partner_base_payment} className='flex gap-2 cursor-pointer' /> : <></>}
            {showIncentives && <PriceWithLabel valueClass={textValueClass} labelClass={textLabelClass} label={'Incentives'} value={incentive} className='flex gap-2 break-words' />}
            {showAddNewPriceButton && (
                <ButtonScogoOrange buttonClass='text-font10' textOrComponent={'Add New'} onClick={() => openTicketPaymentModal(ticket, { role, type, navigate, dispatch })} />
            )}
        </div>
    );
};

export const openTicketPaymentModal = (ticket, { role, type, heading = 'Payments', navigate, dispatch }) => {
    const showAdditionalAmountApprove = ticket?.ticket_basic_payment_status?.payment_via === paymnetVia.throughWallet && ticket.request_additional_amount > 0;
    const showApproveSignOff =
        (isScm(role, type) || isSuperVisor(role, type)) &&
        ticket.signOffRequest === 1 &&
        ticket.signOffAccept === 0 &&
        (ticket.customerSignOffRequired === 0 || (ticket.customerSignOffRequired === 1 && ticket.customerSignOffAccept === 1));

    if (isSp(role, type)) {
        dispatch(
            openCustomModal({
                heading: (
                    <span className='text-black'>
                        {heading} :&nbsp;<span className='text-scogobgsky text-font16'>{ticket.job_ticket_number}</span>
                    </span>
                ),
                modalWidth: '85vw',
                modalHeight: 'auto',
                modalComponent: (
                    <EditPayout
                        ticketId={ticket.ticket_id}
                        showAdditionalAmountApprove={showAdditionalAmountApprove}
                        isServiceParnter={true}
                        showApproveSignOff={showApproveSignOff}
                        tableStyle={{}}
                        onCancel={() => dispatch(closeModalAction())}
                    />
                ),
            })
        );

    } else {
        let path = `${ticketPath.path}/${ticket.ticket_id}/edit-payout?source=ticket`
        if (showAdditionalAmountApprove) path = `${path}&showAdditionalAmountApprove=true`
        if (showApproveSignOff) path = `${path}&showApproveSignOff=true`
        if (heading) path = `${path}&heading=${heading}`
        navigate(path)
    }
};


const RenderApprovalRecievedLabel = ({ ticket }) => {
    let content = '';
    let titleText = '';
    let bgcolor = '';
    if (ticket?.customer_approval_received === 1 && ticket?.customer_po_received === 1) {
        content = 'CBA-POR'
        titleText = 'Customer Billing Approved & PO Received'
        bgcolor = 'bg-scogogoing'
    }
    if (ticket?.customer_approval_received === 1 && ticket?.customer_po_received === 0) {
        content = 'CBA-POP'
        titleText = 'Customer Billing Approved & PO Pending'
        bgcolor = 'bg-scogoorange'

    }
    if (ticket?.customer_invoice_id !== null) return <span className='bg-scogobgsky text-white ml-4 rounded-md text-font09 px-3'> Invoiced</span>

    if (content) {
        return <IconToolTip title={titleText}><span className={`${bgcolor} text-white ml-4 rounded-md text-font09 px-3`}> {content}</span></IconToolTip>
    }
    return <></>
}