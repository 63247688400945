import React from 'react'
import { ApproveAndInclueInReportIcon, RejectIcon } from '../messageBox/Messages/AttachmentMessage/icons'
import { useDispatch } from 'react-redux'
import { approveImageAndIncludeInReport, approveOrRejectCardMessageAction, rejectImage } from '../../../actions/conversations'
import IconToolTip from '../../../common/IconToolTip'
import { useSelector } from 'react-redux'

function ApprovedRejectIcon({ showApprovalOptions, isApproved, approver, rejecter, conversationId, messageId, attachmentId, callStepFields, isCardMessage }) {
    const dispatch = useDispatch()
    const { approveOrRejectMediaLoadingIdSet, approveOrRejectCardMessageLoadingIdSet } = useSelector(state => state.loading);
    let rejectFunction = rejectImage;
    let approveFunction = approveImageAndIncludeInReport;

    if (isCardMessage) {
        rejectFunction = approveOrRejectCardMessageAction;
        approveFunction = approveOrRejectCardMessageAction;
    }
    return (
        <>
            {showApprovalOptions && (
                <>
                    <RejectIcon onClick={() => dispatch(rejectFunction({ conversationId, messageId, attachmentId, callStepFields, isApproved: false }))} isLoading={isCardMessage ? approveOrRejectCardMessageLoadingIdSet.has(messageId) : approveOrRejectMediaLoadingIdSet?.has(attachmentId)} isCardMessage={isCardMessage} />
                    <ApproveAndInclueInReportIcon onClick={() => dispatch(approveFunction({ conversationId, attachmentId, messageId, callStepFields, isApproved: true }))} isLoading={isCardMessage ? approveOrRejectCardMessageLoadingIdSet.has(messageId) : approveOrRejectMediaLoadingIdSet?.has(attachmentId)} isCardMessage={isCardMessage} />
                </>
            )}
            {isApproved === true && (
                <>
                    <IconToolTip title={approver ? `Approved by ${approver?.username} and will be included in Report` : `Approved`}>
                        <div
                            className='flex items-center gap-1 text-font09 text-scogoorange border border-scogoorange rounded px-2  '
                            style={{ backgroundColor: 'rgba(255,255,255, 0.6)' }}
                        >
                            <span className='material-icons text-font08'>check</span>
                            Approved
                        </div>
                    </IconToolTip>
                    <RejectIcon onClick={() => dispatch(rejectFunction({ conversationId, messageId, attachmentId, callStepFields, isApproved: false }))} isLoading={isCardMessage ? approveOrRejectCardMessageLoadingIdSet.has(messageId) : approveOrRejectMediaLoadingIdSet?.has(attachmentId)} isCardMessage={isCardMessage} />
                </>
            )}
            {isApproved === false && (
                <IconToolTip title={rejecter ? `Rejected by ${rejecter?.username}` : `Rejected`}>
                    <div
                        className='flex items-center gap-1 text-font09 text-scogoclosed border border-scogoclosed rounded px-2  '
                        style={{ backgroundColor: 'rgba(255,255,255, 0.6)' }}
                    >
                        <span className='material-icons text-font08'>block</span>
                        Rejected
                    </div>
                </IconToolTip>
            )}
        </>
    )
}

export default ApprovedRejectIcon