import React from 'react';
import IconToolTip from '../../../../common/IconToolTip';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import { openCustomModal, openMediaViewModal } from '../../../../actions/modal';
import { useDispatch } from 'react-redux';
import { updateExecutionSteps, uploadSignOffReport } from '../../../../actions/ticketExecution';
import { ViewFilesModal } from './ViewFilesModal'
import { signoff } from '../../messageBox/ChatComponents/ChatSubHeader';
import { SimpleButton } from '../../../../common/Buttons/SimpleButton';
import { getFileType, setGoogleEvents } from '../../../../utils/common';
import { sendToastMessage } from '../../../../actions/toast';
import { googleEvents } from '../../../../common/GoogleEvent';
import { useSelector } from 'react-redux';
import RaiseSignOff from '../../../../components/TicketExecution/modals/RaiseSignOff';
import { isMobileView } from '../../../auth/components/NewScogoButton';
import { isCustomerGroup, isEndUser } from '../../../../utils/role';

const formName = 'updateExecutionSteps';

const ExecuteSidebar = ({ ticket, steps, isChatPopup, openedConversation, isSupportTicket, showBoqBackButton, setShowChecklist }) => {
    const dispatch = useDispatch();
    const { conversationMessages } = useSelector((state) => state.conversations);
    const onSubmit = (files, step) => {
        if (step.key === signoff.key) {
            dispatch(uploadSignOffReport({ formValues: { ticket_id: ticket.ticket_id }, showToast: true, files, formName, updateViewTicket: true }))
        } else {
            let formValues = new FormData();
            formValues.set('ticket_id', ticket.ticket_id);
            formValues.set(`stepsData[1][field_value]`, '')
            formValues.set(`stepsData[1][step_fields_relationship_id]`, parseInt(step.step_fields_relationship_id))
            formValues.set(`stepsData[1][caption]`, step.field_title)
            files.forEach((file, index) => {
                formValues.set(`stepsData[1][files][${index}]`, file);
            })
            dispatch(updateExecutionSteps({ formValues, formName, closeModal: true, refresh: true, source: 'chat_steps', ticket_id: ticket.ticket_id }));
        }
    }

    const viewImageList = (media) => {
        const findConversationMessages = conversationMessages && openedConversation && conversationMessages[openedConversation._id];
        const attachments = media.map((file) => {
            if (findConversationMessages) {
                for (let message of findConversationMessages) {
                    const attachment = message.attachments.find((item) => {
                        return item.fileUrl === file.file_path;
                    });
                    if (attachment) {
                        return attachment;
                    }
                }
            }
            return { fileUrl: file.file_path, fileName: file.file_name, type: file.mimeType }
        });
        dispatch(openMediaViewModal({ attachments, isTicketChat: true, callStepFields: true, isExecuteImage: true, currentConversationId: openedConversation?._id }));
    };

    const isAnyStepHasNoFile = steps.find((step) => !step?.file_values?.length);
    const isAnyStepRejected = steps.find((step) => step.status === 2);
    const isExecuteDisabled = isAnyStepRejected || isAnyStepHasNoFile || ticket.signOffRequest !== 0 || ticket.signOffAccept !== 0;

    return (
        <div className={`overflow-hidden h-full `}>
            <div className='h-full ' style={{ maxHeight: '81vh', }}>
                <div className='overflow-hidden' style={{ maxHeight: '76vh', }}>
                    {[...steps,].map((step, index) => {
                        return <div className={`${index % 2 === 0 ? 'bg-white' : 'bg-tagmsg'} py-3 md:py-0`}>
                            <ImageField step={step} onSubmit={onSubmit} viewImageList={viewImageList} ticket={ticket} isChatPopup={isChatPopup} />
                        </div>
                    })}
                </div>
                <RenderBottomAction isSupportTicket={isSupportTicket} ticket={ticket} isExecuteDisabled={isExecuteDisabled} showBoqBackButton={showBoqBackButton} setShowChecklist={setShowChecklist} />
            </div>
        </div>
    );
}


export default ExecuteSidebar;

const ImageField = ({ onSubmit = () => { }, step, viewImageList, ticket }) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const isFileUploaded = step && Array.isArray(step.file_values) && step.file_values.length > 0;
    let accepted = isFileUploaded && step.status !== 2;
    let rejected = step.status === 2;
    if (step.key === signoff.key) {
        rejected = ticket.signOffReject === 1;
    }
    const isEditDisable = ticket.signOffRequest !== 0;

    const viewModal = ({ files = [], fileForView }) => {
        const heading = <span className='text-black'>
            {step.field_title}
        </span>;

        const modalWidth = isMobileView ? '85vw' : '50rem';
        dispatch(openCustomModal({
            heading, modalWidth, modalHeight: 'auto', modalComponent: <ViewFilesModal fileForView={fileForView} onSubmit={(addedFiles) => {
                const allFiles = [...addedFiles, ...files];
                onSubmit(allFiles, step);
            }} showButtons formName={formName} />
        }))
    }

    const showUploadButton = (!isFileUploaded || rejected);
    const showViewAndEditIcons = isFileUploaded && !rejected;


    let isUploadDisabled = ticket?.reachedSite === 0;
    if (isCustomerGroup(loggedUser.role.id, loggedUser.type) || isEndUser(loggedUser.role.id)) {
        isUploadDisabled = false;
    };

    let acceptInput = 'image/*';
    if (step.input_type === 'video') {
        acceptInput = 'image/*,video/*';
    }

    const onImageUpload = (event) => {
        let files = Array.from(event.target.files);
        files = files.filter((file) => {
            if (step.input_type === 'video') {
                return ['video', 'image'].includes(getFileType(file.type));
            } else if (step.input_type === 'fileupload') {
                return getFileType(file.type) === 'image';
            }
            return false
        });
        if (files.length !== Array.from(event.target.files).length) {
            let message = 'Please Select Image Only';
            if (step.input_type === 'video') {
                message = 'Please Select Image and Video Only';
            }
            dispatch(sendToastMessage({ message, status: 'warning' }))
        }
        const fileForView = files.map((file) => {
            const imageUrl = URL?.createObjectURL(file);
            return { file_path: imageUrl, file_name: file.name, mimeType: file.type };
        })
        if (fileForView.length > 0) viewModal({ fileForView, isUpload: true, files });
    }


    return <div className='flex items-center justify-start py-2 px-4 overflow-y-auto'>
        <div className={`w-10/12 flex items-center gap-2`}>
            <Icon isFileUploaded={isFileUploaded} accepted={accepted} rejected={rejected} />
            <IconToolTip title={step.field_title}>
                <p className='md:text-font10  text-font13 text-black italic'>{step.field_title}</p>
            </IconToolTip>
        </div>
        <div className='w-2/12 flex items-center justify-end pr-2'>
            {
                showUploadButton && <div className='ml-2 cursor-pointer relative'>
                    <input disabled={isUploadDisabled} accept={acceptInput} name='key' type='file' className='absolute cursor-pointer opacity-0 z-20 h-full w-full' onChange={onImageUpload} value='' multiple={true} />
                    <ButtonScogoPrimary textOrComponent={'Upload'} buttonPadding='md:px-2 md:py-1 px-6 py-2' buttonClass='w-20' disabledClass='text-black flex items-center justify-center rounded-6px text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd w-20' disabled={isUploadDisabled} />
                </div>
            }
            {
                showViewAndEditIcons && <div className='flex items-center justify-center ml-2'>
                    <span className='text-scogobgsky px-2 cursor-pointer material-icons hover:text-scogoorange md:text-font13 text-font18' onClick={() => viewImageList(step.file_values)}>visibility</span>
                    <div className='cursor-pointer relative flex items-center'>
                        {!isEditDisable && <input disabled={false} name='key' type='file' className='absolute cursor-pointer opacity-0 z-20 h-full w-full' onChange={onImageUpload} value='' multiple={true} accept='image/jpeg,image/png' />}
                        <span className={isEditDisable ? 'text-scogogray px-2 cursor-not-allowed material-icons md:text-font13 text-font18' : 'text-scogobgsky px-2 cursor-pointer material-icons hover:text-scogoorange md:text-font13 text-font18'} onClick={() => {
                            if (isEditDisable) return;
                            viewModal({ fileForView: step.file_values })
                        }}>edit</span>
                    </div>
                </div>
            }
        </div>
    </div >
}

const Icon = ({ accepted, rejected }) => {
    let iconName = 'image';
    let color = 'text-black';
    let status = 'Upload Files';
    if (accepted) {
        iconName = 'check_box';
        color = 'text-scogoToastSuccessIcon'
        status = 'Accepted';
    } else if (rejected) {
        iconName = 'disabled_by_default';
        color = 'text-scogoclosed'
        status = 'Rejected';
    }

    return <IconToolTip title={status}>
        <span className={`material-icons ${color}`} style={{ fontSize: '2rem' }}>{iconName}</span>
    </IconToolTip>
}


const RenderBottomAction = ({ isSupportTicket, ticket, isExecuteDisabled, setShowChecklist, showBoqBackButton }) => {
    let btnClasss = 'bg-white border flex items-center justify-center border-scogoprimary leading-6 cuzrsor-pointer text-scogoprimary rounded-lg gap-1 font-medium cursor-pointer text-font11 px-2 hover:bg-scogoprimary hover:text-white md:px-2 md:py-1 px-6 py-3'
    if (isExecuteDisabled) btnClasss = 'text-black flex items-center justify-center rounded-6px text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd md:px-2 md:py-1 px-6 py-2'
    const dispatch = useDispatch();

    const callRaiseSignOff = () => {
        const transformTicket = {
            id: ticket.ticket_id,
            sp_email: ticket.sp_email,
            pm_email: ticket.pm_email,
            fe_email: ticket.fe_email,
            project_contact_person_email_address: ticket.project_contact_person_email_address,
            is_rma_raised: ticket.isRmaRaised,
            ticket_type: ticket.ticketType,
        };

        const modalWidth = isMobileView ? '85vw' : '50rem';

        dispatch(
            openCustomModal({
                heading: `Sign Off`,
                modalWidth,
                modalHeight: 'auto',
                modalComponent: <RaiseSignOff
                    ticket={transformTicket}
                    job_sign_off_notes={''}
                    isSupportTicket={isSupportTicket}
                    showRemarkField
                />
            }))
    };

    const onExecuteTicketCLick = (ticket) => {
        setGoogleEvents(googleEvents.checklist.main_event, googleEvents.checklist.checklist_execute);
        if (isSupportTicket) {
            callRaiseSignOff();
        } else {
            let path = `/tickets/${ticket.ticket_id}/execute/initiate`;
            if (ticket.signOffRequest === 1) {
                path = `${path}?editWorkFlow=true`;
            }
            window.open(path);
        }
    };

    return <div className='flex items-center justify-between px-6 py-4 gap-2 mb-2' style={{ height: '5vh', }}>
        <div className='flex items-center gap-2'>
            {!isSupportTicket && <span>{'Raise Sign Off?'}</span>}
            <SimpleButton
                buttonIconComponent={<span className='material-icons text-font16'>room_preferences</span>}
                btnClass={btnClasss}
                textOrComponent={isSupportTicket ? 'Submit' : 'Execute'}
                onClick={() => onExecuteTicketCLick(ticket)}
                disabled={isExecuteDisabled || ticket?.sign_off_lock === 1}
                onHoverTitle={ticket?.sign_off_lock === 1 ? 'SP/FE can not raise sign off of this ticket, as execution date is more than 7 days old' : ''}
            />
        </div>
        {showBoqBackButton && <div className='flex items-center gap-2'>
            <SimpleButton
                btnClass={ticket.signOffRequest === 1 ? 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd md:px-2 md:py-1 px-6 py-2' : 'bg-white border flex items-center justify-center border-scogoprimary leading-6 cuzrsor-pointer text-scogoprimary rounded-lg gap-1 font-medium cursor-pointer text-font11 hover:bg-scogoprimary hover:text-white md:px-2 md:py-1 px-6 py-2'}
                textOrComponent={'BOQ'}
                onClick={() => setShowChecklist(false)}
                disabled={ticket.signOffRequest === 1}
                onHoverTitle={ticket?.sign_off_lock === 1 ? 'SP/FE can not raise sign off of this ticket, as execution date is more than 7 days old' : ''}
            />
        </div>}
    </div>
}